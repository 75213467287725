import React from "react";
import { Routes, Route } from "react-router-dom";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import List from "./List";

import Details from "./AddEdit";
import { Header, Grid } from "semantic-ui-react";

import { RESET_SURVEY_AI_LOG } from "constants/actions";

function WebApiLog() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2" style={{ marginTop: "50px" }}>
            Survey AI Log
            <Header.Subheader>
              View the survey AI Logs.
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>


      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default WebApiLog;
