import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';


import { Header } from 'semantic-ui-react'

function EmailTemplates() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "20px" }}>
        Documentation
        <Header.Subheader>
          View Routes and Models
        </Header.Subheader>
       
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
      </Routes>
    </>
  );
}

export default EmailTemplates;
