import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Button, Pagination } from "semantic-ui-react";
import styled from "styled-components";
import Product from "./Product/index";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CLEAR_PRODUCT_GROUP,
  CLEAR_PRODUCT_PACKAGE,
  CLEAR_PRODUCT_PRICE,
  CLEAR_PROD_ORG_CONTRACT,
} from "constants/actions";

import GroupList from "./Group/List";
import ProductList from "./Product/List";
import ProductPriceList from "./Price/List";
import ProductPackage from "./Package/List";
import ProductContract from "./Contract/List";


function ProductMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function createGroup() {
    dispatch({
      type: CLEAR_PRODUCT_GROUP,
      payload: null,
    });
    setTimeout(() => {
      navigate("/app/product/group/new");
    }, 50);
  }
  function createProduct() {
    dispatch({
      type: CLEAR_PRODUCT_GROUP,
      payload: null,
    });
    setTimeout(() => {
      navigate("/app/product/product/new");
    }, 50);
  }

  function createPrice() {
    dispatch({
      type: CLEAR_PRODUCT_PRICE,
      payload: null,
    });
    setTimeout(() => {
      navigate("/app/product/price/new");
    }, 50);
  }


  function duplicatePackage() {
    dispatch({
      type: CLEAR_PRODUCT_PACKAGE,
      payload: null,
    });
    setTimeout(() => {
      navigate("/app/product/package/duplicate");
    }, 50);
  }

  function createPackage() {
    dispatch({
      type: CLEAR_PRODUCT_PACKAGE,
      payload: null,
    });
    setTimeout(() => {
      navigate("/app/product/package/new");
    }, 50);
  }

  function createContract() {
    dispatch({
      type: CLEAR_PROD_ORG_CONTRACT,
      payload: null,
    });
    setTimeout(() => {
      navigate("/app/product/contract/new");
    }, 50);
  }
  return (
    <PageContainer>
      <Section>
        <SectionHeader>
          Group
          <Button primary onClick={createGroup}>
            Create Group
          </Button>
        </SectionHeader>
        <GroupList />
      </Section>

      <Section>
        <SectionHeader>
          Product Features
          <Button primary onClick={createProduct}>
            Create Product
          </Button>
        </SectionHeader>
        <ProductList />
      </Section>

      <Section>
        <SectionHeader>
          Prices
          <Button primary onClick={createPrice}>
            Create
          </Button>
        </SectionHeader>
        <ProductPriceList />
      </Section>

      <Section>
        <SectionHeader>
          Product Package (Default or order [0] is applied to all new organization)
          <Button secondary onClick={duplicatePackage}>
            Duplicate
          </Button>
          <Button primary onClick={createPackage}>
            Create
          </Button>
        </SectionHeader>
        <ProductPackage />
      </Section>

      <Section>
        <SectionHeader>
          Product Contract
          <Button primary onClick={createContract}>
            Create
          </Button>
        </SectionHeader>
        <ProductContract />
      </Section>
    </PageContainer>
  );
}

export default ProductMain;

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }
`;
