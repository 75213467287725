import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";
import { format } from "date-fns";
import {
  SG_GET_PRODUCT_PACKAGE_SELECT,
  SG_DUPLICATE_PRODUCT_PACKAGE,
} from "constants/actions";
import { Icon, Message, Button, Header, Input } from "semantic-ui-react";
import { BasicErrorMessage } from "utilities/ErrorMessages";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [prodPackageSelected, setProdPackageSelected] = useState(null);
  const [templateDetails, setTemplateDetails] = useState({
    name: "",
    sort_order: 0,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  // Fetch product packages on mount
  useEffect(() => {
    dispatch({ type: SG_GET_PRODUCT_PACKAGE_SELECT });
  }, [dispatch]);

  const { get_product_package_select, get_product_package } = useSelector(
    (state) => ({
      get_product_package_select: state.product_package.select_list,
      get_product_package: state.product_package,
    }),
    shallowEqual
  );

  // Navigate to duplicated package when updated
  useEffect(() => {
    if (get_product_package?.updated?.id) {
      navigate(`/app/product/package/edit/${get_product_package?.updated?.id}`);
    }
  }, [get_product_package, navigate]);

  // Validate inputs
  useEffect(() => {
    const ErrorList = [];

    if (!templateDetails?.name) {
      ErrorList.push("We need a name");
    }

    if (!templateDetails?.sort_order) {
      ErrorList.push("We need a sort order");
    }

    if (!prodPackageSelected?.id) {
      ErrorList.push("Select a product package");
    }

    setFormErrors(ErrorList);
  }, [templateDetails, prodPackageSelected]);

  const handleChange = (e, { name, value }) => {
    console.log(name, value);
    setTemplateDetails({ ...templateDetails, [name]: value });
  };

  const handleDuplicate = () => {
    setPristine(false);

    if (formErrors.length === 0) {
      const payload = {
        ...templateDetails,
        product_package_id: prodPackageSelected.id,
        new_name: templateDetails.name,
        new_sort_order: templateDetails.sort_order,
      };

      dispatch({
        type: SG_DUPLICATE_PRODUCT_PACKAGE,
        payload,
      });
    }
  };

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/app/product`)}
          name="arrow left"
        />
        Duplicate Product Package
      </Header>

      <BasicErrorMessage />
      {formErrors.length > 0 && !pristine && (
        <Message negative header="We need these details!" list={formErrors} />
      )}

      <FormColumn>
        <FormSection>
          <LabelHead>Product Package</LabelHead>
          <Select
            name="product_package"
            options={get_product_package_select}
            onChange={(e) => setProdPackageSelected(e)}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={prodPackageSelected}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Name</LabelHead>
          <StyledInput
            name="name"
            value={templateDetails?.name}
            onChange={handleChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Sort Order</LabelHead>
          <StyledInput
            name="sort_order"
            value={templateDetails?.sort_order}
            onChange={handleChange}
          />
        </FormSection>
      </FormColumn>

      <SectionDiv lastSection={true}>
        <Button floated="right" color="green" onClick={handleDuplicate}>
          Duplicate
        </Button>
      </SectionDiv>
    </>
  );
};

export default Details;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const SectionDiv = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;
