import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_ORGANIZATION_SELECT,
  SG_FETCH_WEB_API_CREDENTIAL,
  SG_EDIT_WEB_API_CREDENTIAL,
  SG_ADD_WEB_API_CREDENTIAL,
  SG_DELETE_WEB_API_CREDENTIAL,

} from "constants/actions";
//
import {
  Icon, Message, Button, Header, List, Input,
  Confirm, Form, Segment, Loader, Dimmer, Label
} from 'semantic-ui-react'
//
import { EncodeNodeBase64 } from 'utilities/base64';
//
import { currentApi } from "WebConfig"
//
import { format } from 'date-fns'
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"

import { WEB_API_STATUS, WEB_API_DIRECTION, API_CRED_NAMES } from "data/AdminData"
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: '',
    full_token: '',
    internal_url: '',
  });
  const [orgSelected, setOrgSelected] = useState([])
  const [statusSelected, setStatusSelected] = useState([])
  const [directionSelected, setDirectionSelected] = useState([])

  const [loading, setLoading] = useState(false)

  const [valueNames, setValueNames] = useState([])

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_WEB_API_CREDENTIAL,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });

  }, [dispatch]);

  const {
    get_web_api_cred,
    get_organization_select } = useSelector(
      (state) => ({
        get_employee_record_list: state.employee_record_list,
        get_web_api_cred: state.web_api_credential,
        get_organization_select: state.organizations.organization_select,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_web_api_cred?.[templateId]) {
      setTemplateDetails(get_web_api_cred?.[templateId])
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_web_api_cred?.[templateId]?.organization)?.[0])
      setStatusSelected(WEB_API_STATUS.filter(sd => sd.value === get_web_api_cred?.[templateId]?.status)?.[0])
      setDirectionSelected(WEB_API_DIRECTION.filter(sd => sd.value === get_web_api_cred?.[templateId]?.direction)?.[0])
    }
  }, [get_web_api_cred, templateId, get_organization_select, valueNames]);

  function BackArrow() {
    navigate(`/app/web-api-cred`)
  }

  useEffect(() => {
    if (get_web_api_cred?.created?.id) {
      navigate(`/app/web-api-cred/details/${get_web_api_cred?.created?.id}`)
    }
    if (get_web_api_cred?.updated?.id) {
      navigate(`/app/web-api-cred/details/${get_web_api_cred?.updated?.id}`)
    }
  }, [get_web_api_cred, navigate]);


  useEffect(() => {
    setLoading(get_web_api_cred?.pending)
    setValueNames(get_web_api_cred?.employee_record_value)
  }, [get_web_api_cred]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_WEB_API_CREDENTIAL,
        payload: {
          id: templateId,
        }
      });
      navigate(`/app/web-api-cred?reload=true`)
    }
  }
  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_EDIT_WEB_API_CREDENTIAL,
          payload: {
            id: templateId,
            organization: orgSelected?.id,
            status: statusSelected?.value,
            direction: directionSelected?.value,
            name: templateDetails?.name,
            username: templateDetails?.username,
            password: templateDetails?.password,
            full_token: templateDetails?.full_token,
            external_url: templateDetails?.external_url,
            internal_url: templateDetails?.internal_url,
            description: templateDetails?.description,
            sample_response: templateDetails?.sample_response,

          }
        });

      } else {

        dispatch({
          type: SG_ADD_WEB_API_CREDENTIAL,
          payload: {
            organization: orgSelected?.id,
            status: statusSelected?.value,
            direction: directionSelected?.value,
            name: templateDetails?.name,
            username: templateDetails?.username,
            password: templateDetails?.password,
            full_token: templateDetails?.full_token,
            external_url: templateDetails?.external_url,
            internal_url: templateDetails?.internal_url,
            description: templateDetails?.description,
            sample_response: templateDetails?.sample_response,

          }
        });
      }
      setPristine(true)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name")
    }

    if (!statusSelected?.value) {
      ErrorList.push("Select an Status")
    }
    if (!directionSelected?.value) {
      ErrorList.push("Select an Direction")
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization")
    }
    if (directionSelected?.value === 21) {
      if (!templateDetails?.username) {
        ErrorList.push("Enter a Username")
      }
      if (!templateDetails?.password) {
        ErrorList.push("Enter a password")
      }
    }
    setFormErrors(ErrorList)
  }, [templateDetails, statusSelected,
    directionSelected,
    orgSelected, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e)
    let _ques = templateDetails
    _ques[`${name}`] = e.target.value
    setTemplateDetails(_ques)
    setValueChanged(e.target.value + name)
    if (directionSelected?.value === 21) {
      templateDetails.full_token = EncodeNodeBase64(`${templateDetails?.username}:${templateDetails?.password}`)
      var url = currentApi;
      var pathname = new URL(url).hostname;
      templateDetails.internal_url = `https://${templateDetails?.username}:${templateDetails?.password}@${pathname}/api/organization/v1/inbound-webhook-v1/`
    }
  }

  function ChangeFormEndValues(e, name) {
    // console.log(name, e)
    let _ques = templateDetails
    _ques[`${name}`] = e
    setTemplateDetails(_ques)
    setValueChanged(e + name)

  }

  function ChangeDirection(e) {
    setDirectionSelected(e)
    setValueChanged(e)
  }

  function ChangeStatus(e) {
    setStatusSelected(e)
    setValueChanged(e)
  }

  function ChangeOrg(e) {
    setOrgSelected(e)
    setValueChanged(e)
  }

  // console.log(templateDetails?.sample_response)
  // console.log(templateDetails)
  // console.log(new URL(currentApi).hostname)
  useEffect(() => {
    if (directionSelected?.value === 21) {
      if (templateDetails) {
        if (templateDetails?.username?.length > 0 && templateDetails?.password?.length > 0)
          templateDetails['full_token'] = EncodeNodeBase64(`${templateDetails?.username}:${templateDetails?.password}`)
          var url = currentApi;
        if (url) {
          var pathname = new URL(url).hostname;
          templateDetails['internal_url'] = `https://${templateDetails?.username}:${templateDetails?.password}@${pathname}/api/organization/v1/inbound-webhook-v1/`
        }
      }
    }
    // DecodeBase64
  }, [directionSelected, templateDetails]);


  return (
    <>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Header as='h2'>
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow} name='arrow left' />
          {templateId ? `Edit Details of ${templateDetails?.name}` : "Add New Credential"}
        </Header>
        {formErrors.length > 0 && !pristine &&
          <Message negative
            //   onDismiss={clearErrors}
            header='We need these details!'
            list={formErrors}
          />
        }
        <SectionDiv>
          <BasicErrorMessage />
        </SectionDiv>
        {templateId &&
          <SectionDiv>
            <Container>
              <List horizontal>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Name</List.Header>
                      {templateDetails?.name}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Organization</List.Header>
                      {templateDetails?.organization ?
                        get_organization_select.filter(org => org.id === templateDetails?.organization)?.[0]?.name :
                        "For all Org"}
                    </ListContainer>
                  </List.Content>
                </List.Item>



                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Created</List.Header>
                      {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                    </ListContainer>

                  </List.Content>
                </List.Item>

              </List>
            </Container>
          </SectionDiv>
        }

        <SectionDiv>
          <FormRow>
            <FormLabel>
              <LabelHead>Direction</LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="direction"
                options={WEB_API_DIRECTION}
                onChange={(e) => ChangeDirection(e)}
                value={directionSelected}
              />
            </FormInput>
          </FormRow>


          <FormRow>
            <FormLabel>
              <LabelHead>Name</LabelHead>
            </FormLabel>
            <FormInput>
              {directionSelected?.value === 21 ?
                <Select
                  name="name"
                  options={API_CRED_NAMES}
                  onChange={(e) => ChangeFormEndValues(e.value, "name")}
                  value={API_CRED_NAMES.filter(ap => ap.value === templateDetails?.name)}
                />
                :
                <Input
                  fluid
                  name="name"
                  defaultValue={templateDetails?.name}
                  onChange={(e) => ChangeFormValues(e, "name")}
                />}
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>Username</LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="username"
                defaultValue={templateDetails?.username}
                onChange={(e) => ChangeFormValues(e, "username")}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>Password</LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="password"
                defaultValue={templateDetails?.password}
                onChange={(e) => ChangeFormValues(e, "password")}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>External URL</LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                name="external_url"
                defaultValue={templateDetails?.external_url}
                onChange={(e) => ChangeFormValues(e, "external_url")}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>Internal URL</LabelHead>
            </FormLabel>
            <FormInput>
              <Input
                fluid
                disabled={directionSelected?.value === 21 ? true : false}
                name="internal_url"
                defaultValue={templateDetails?.internal_url}
                onChange={(e) => ChangeFormValues(e, "internal_url")}
              />
            </FormInput>
          </FormRow>


          <FormRow>
            <FormLabel>
              <LabelHead>Token</LabelHead>

            </FormLabel>

            <FormInput>
              {directionSelected?.value === 21 ? 
              <Label basic color='red'>
                {` ${templateDetails?.full_token ? templateDetails?.full_token: 'For inward post token gets auto generated and is uneditable'}`}
              </Label>
              :
               <Input
                fluid
                disabled={directionSelected?.value === 21 ? true : false}
                name="full_token"
                defaultValue={templateDetails?.full_token}
                onChange={(e) => ChangeFormValues(e, "full_token")}
              />
            }
            
            </FormInput>
          </FormRow>


          <FormRow>
            <FormLabel>
              <LabelHead>Status</LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="status"
                options={WEB_API_STATUS}
                onChange={(e) => ChangeStatus(e)}
                value={statusSelected}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>Organization</LabelHead>
            </FormLabel>
            <FormInput>
              <Select
                name="organization"
                options={get_organization_select}
                onChange={(e) => ChangeOrg(e)}
                getOptionLabel={(option) => `${option.id} # ${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                value={orgSelected}
              />
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>
              <LabelHead>Description</LabelHead>
            </FormLabel>
            <FormInput>

              <Form>
                <Form.TextArea
                  placeholder='Describe this api...'
                  name="description"
                  defaultValue={templateDetails?.description}
                  onChange={(e) => ChangeFormValues(e, "description")}
                />

              </Form>

            </FormInput>
          </FormRow>
          <FormRow>
            <FormLabel>
              <LabelHead>Sample Response</LabelHead>
            </FormLabel>
            <FormInput>

              <Form>
                <Form.TextArea
                  placeholder='Sample Response...'
                  name="sample_response"
                  defaultValue={templateDetails?.sample_response}
                  onChange={(e) => ChangeFormValues(e, "sample_response")}
                />

              </Form>

            </FormInput>
          </FormRow>
          {/* sample_response */}
        </SectionDiv>



        <SectionDiv lastSection={true}>
          {templateId &&
            <>
              <Button floated='left'
                color="red"
                onClick={() => setConfirmOpen(true)}>
                Delete
              </Button>
              <Confirm
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onConfirm={DeleteQuestion}
              />
            </>}

          <Button floated='right'
            color="green"
            onClick={UpdateQuestions}>
            {templateId ? "Update" : "Create"}
          </Button>
        </SectionDiv>
        <SectionDiv>

        </SectionDiv>
      </Segment>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;

`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1 0;
`

const FormInput = styled.div`
  flex: 6 1;
`
