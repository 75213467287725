import Home from "assets/images/nav/home.svg";
import Management from "assets/images/nav/management.svg";

import Gpt from "assets/images/nav/blog.svg";
import Organizations from "assets/images/nav/organizations.svg";

import Employees from "assets/images/nav/employees.svg";
import Questions from "assets/images/nav/questions.svg";
import Categories from "assets/images/nav/categories.svg";
import SettingsNav from "assets/images/nav/settings-nav.svg";
import EmailTemplates from "assets/images/nav/templates.svg";

import SurveyData from "assets/images/nav/survey-data.svg";
import AuditSurvey from "assets/images/nav/review-survey.svg";
import PdfTemplate from "assets/images/nav/notebook-template.svg";

import L360 from "assets/images/nav/360.svg";
import RecordList from "assets/images/nav/value-list.svg";
import RecordValues from "assets/images/nav/value-record.svg";
import Demostration from "assets/images/nav/demostration.svg";
import Leadership from "assets/images/nav/leadership.svg";
import Apps from "assets/images/nav/apps.svg";
import Data from "assets/images/nav/data.svg";
import Server from "assets/images/nav/server.svg";
import Settings from "assets/images/nav/settings.svg";
import Report from "assets/images/nav/report.svg";
import Culture from "assets/images/nav/culture.svg";
import Mood from "assets/images/nav/mood.svg";
import Personality from "assets/images/nav/personality.svg";

import Admin from "assets/images/nav/admin.svg";
import MyMood from "assets/images/group-12.svg";
import Survey from "assets/images/nav/survey.svg";

import Calendar from "assets/images/nav/calendar.png";
import Action from "assets/images/nav/action.png";

// before you add things here check if the route is valid
// checking this data is done in sidebar.js
// use the name property to loop through
export const CommonNav = [
  {
    icon: "address book",
    link: "/settings/management",
    title: "Team Management",
    image: Management,
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
];
export const OtpNav = [];
export const NavData = [
  {
    header: "Menu",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/dashboard",
    title: "Dashboard",
    image: Home,
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    image: Report,
    link: "/app/marketing-roi",
    title: "Marketing ROI",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    // No link for the top-level item
    link: null,
    title: "Product",
    image: Report,
    role: 9,
    paid: true,
    team: false,
    group: "MainMenu",
    subNav: [
      {
        link: "/app/product",
        title: "Product Features",
      },
      {
        link: "/app/product/list",
        title: "Product List",
      },
    ],
  },
  {
    image: MyMood,
    link: "/app/gpt-web-chat",
    title: "Gpt Web Chat",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Report,
    link: "/app/gpt-recommendation-log",
    title: "Data Story Log / Gpt Recom Log",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: L360,
    link: "/app/task-template",
    title: "Task Template",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    link: "/app/organizations",
    title: "Organizations",
    image: Organizations,
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    // No link for the top-level item
    link: null,
    title: "Employee",
    image: Employees,
    role: 9,
    paid: true,
    team: false,
    group: "MainMenu",
    subNav: [
      {
        link: "/app/employees",
        title: "Employees",
      },
      {
        link: "/app/employee-record-list",
        title: "Emp Rec List",
      },
      {
        link: "/app/employee-record-value",
        title: "Emp Rec Value",
      },
    ],
  },
  {
    // No link for the top-level item
    link: null,
    title: "Run Tasks",
    image: Apps,
    role: 9,
    paid: true,
    team: false,
    group: "MainMenu",
    subNav: [
      {
        link: "/app/survey-standard",
        title: "Survey Standard",
      },
      {
        link: "/app/predictive-model",
        title: "Predictive Model",
      },
    ],
  },
  {
    image: Report,
    link: "/app/questions",
    title: "Questions",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: EmailTemplates,
    link: "/app/email-templates",
    title: "Email Templates",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Report,
    link: "/app/pdf-templates",
    title: "Pdf Templates",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    link: "/app/categories",
    title: "Categories",
    image: Report,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/brief-schedule",
    title: "Surveys / Brief Schedule",
    image: AuditSurvey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/schedule-template-report",
    title: "Schedule Reports",
    image: Survey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/pdf-reports",
    title: "PDF Reports",
    image: Survey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/category-compliance",
    title: "Category Compliance",
    image: Categories,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/app-server",
    title: "Application Servers",
    image: Server,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    // No link for the top-level item
    link: null,
    title: "Unused",
    image: Survey,
    role: 9,
    paid: true,
    team: false,
    group: "MainMenu",
    subNav: [
      {
        link: "/app/ml-operations",
        title: "Knowledge Base Tagging",
      },
      {
        link: "/app/category-questions",
        title: "Category Questions",
      },
      {
        link: "/app/report-templates",
        title: "Report Templates",
      },
      {
        link: "/app/survey-schedule",
        title: "Team Tool",
      },
      {
        link: "/app/user-uploads",
        title: "Upload Employees",
      },
      {    
        link: "/app/gpt-recommendation",
        title: "Gpt Recommendation",
      },
      {
        link: "/app/demo-survey-response",
        title: "Demo Response",
      },
      {
        link: "/app/gpt",
        title: "Gpt",
      },
    ],
  },
  {
    // No link for the top-level item
    link: null,
    title: "Web Creds",
    image: Apps,
    role: 9,
    paid: true,
    team: false,
    group: "MainMenu",
    subNav: [
      {
        link: "/app/web-api-cred",
        title: "Api Cred",
      },
      {
        link: "/app/web-api-log",
        title: "Api Log",
      },
    ],
  },


  {
    // No link for the top-level item
    link: null,
    title: "Extra",
    image: Apps,
    role: 9,
    paid: true,
    team: false,
    group: "MainMenu",
    subNav: [
      {
        link: "/app/settings",
        title: "Settings",
      },
      {
        link: "/app/api-documentation",
        title: "Documentation",
      },
      {
        link: "/app/activity-log",
        title: "Activity Log",
      },
      {
        link: "/app/app-url",
        title: "App Urls",
      },
      {
        link: "/app/survey-ai-log",
        title: "Survey AI Log",
      },
    ],
  },
];

export default NavData;
