import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from "date-fns";

import { Card, Button, List } from "semantic-ui-react";

const RowCard = ({ row, templates, ResetUpdates }) => {
  const navigate = useNavigate();

  function handleClick() {
    ResetUpdates();
  
    const timer1 = setTimeout(() => {
      navigate(`/app/pdf-reports/details/${row?.id}`);
    }, 500);
    return () => clearTimeout(timer1);
   
  }
  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
         

          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>ID</List.Header>#{row?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Status</List.Header>
                    {row?.status} # {row?.status_text}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Type</List.Header>
                    {templates && row?.template_id} #{" "}
                    {
                      templates?.filter((rw) => rw.id == row?.template_id)?.[0]
                        ?.type
                    }
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Organization</List.Header>#{row?.organization}{" "}
                    - {row?.organization_name}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Employee</List.Header>#{row?.employee} -{" "}
                    {row?.employee_name} - {row?.employee_email}
                  </ListContainer>
                </List.Content>
              </List.Item>
             
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Last Updated</List.Header>
                    {row?.updated_at &&
                      format(new Date(row?.updated_at), 'dd-MMM-yyyy hh:mm a')}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>

          <Card.Description>
            <Button
              floated="right"
              onClick={handleClick}
            >
              More Details
            </Button>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;
