import React, { useEffect, useState } from "react";
import styled from "styled-components";


import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

//
import { Icon, Header} from "semantic-ui-react";
//

//
import DataForm from "./DataForm";

//
const Details = () => {
  const navigate = useNavigate();
  const [templateDetails, setTemplateDetails] = useState({});



  function BackArrow() {
    navigate(`/app/app-server`);
  }

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Add New Application Server
      </Header>
      
      <SectionDiv>
        <DataForm
        templateDetails={templateDetails} 
        setTemplateDetails={setTemplateDetails}
        />
      </SectionDiv>

      <SectionDiv></SectionDiv>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

