import {
    GET_SIGNUP_TOKENS,
    DELETE_SIGNUP_TOKEN,
    CLEAR_SIGNUP_TOKEN,

    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    signup_tokens: [],

    pending: false,
  };
  
  export default function SignupToken (state = initialState, action) {
    switch (action.type) {
      case GET_SIGNUP_TOKENS:
        return {
          ...state,
          signup_tokens: action.payload,
          pending: false,
        };


      case DELETE_SIGNUP_TOKEN:
        return {
          ...state,
          payments: state.signup_tokens.filter(
            (row) => row.id !== action.payload
          ),
        };
  
      case CLEAR_SIGNUP_TOKEN:
        return {
          ...state,
          signup_tokens: [],
          pending: false,
        };
      case CLEAR_ALL:
        return {
          ...state,
          signup_tokens: [],
          pending: false,
        };
      default:
        return state;
    }
  }
  