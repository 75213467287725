import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from "semantic-ui-react";
import { format } from "date-fns";

const EmployeeRecordValue = ({ rows }) => {
  const navigate = useNavigate();
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Id</Table.HeaderCell>
            <Table.HeaderCell width={2}>Ref ID</Table.HeaderCell>
            <Table.HeaderCell width={1}>Org</Table.HeaderCell>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
            <Table.HeaderCell width={1}>User Task Id</Table.HeaderCell>
            <Table.HeaderCell width={2}>Updated</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{row?.id}</Table.Cell>
                  <Table.Cell>{row?.task_ref_id}</Table.Cell>
                  <Table.Cell>{row?.organization}</Table.Cell>
                  <Table.Cell>{row?.name}</Table.Cell>
                  <Table.Cell>{row?.status_text}</Table.Cell>
                  <Table.Cell>{row?.task_template_user_tasks}</Table.Cell>
                  <Table.Cell>
                    {row?.updated_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy hh:mm a")}
                  </Table.Cell>
                  <Table.Cell>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/app/task-template/details/${row?.id}`)
                      }
                      name="angle double right"
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </>
  );
};

EmployeeRecordValue.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default EmployeeRecordValue;
