import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";

import SearchList from "./SearchList";
import RowCard from "./RowCard";
import Select from "react-select";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_GPT_RECOMMENDATION_LOG,
  UPDATE_GPT_RECOMMENDATION_LOG_PAGE_NUMBER,
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";

const List = ({ reload }) => {
  // employee_record_value
  const dispatch = useDispatch();

  //   const [resetPageLoad, setResetPageLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState("");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // if the response is ok 200
    if (searchList) {
      dispatch({
        type: SG_GET_GPT_RECOMMENDATION_LOG,
        payload: `page=${page}&${searchList}`,
      });
    } else if (searchTerm) {
      dispatch({
        type: SG_GET_GPT_RECOMMENDATION_LOG,
        payload: `page=${page}&search=${searchTerm}`,
      });

    } else {
      dispatch({
        type: SG_GET_GPT_RECOMMENDATION_LOG,
        payload: `page=${page}`,
      });
    }
    setLoading(true);
  }, [dispatch, page, searchTerm, reload, searchList]);


  const { get_gpt_recommendation_log } = useSelector(
    (state) => ({
      get_gpt_recommendation_log: state.gpt_recommendation_log,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_recommendation_log?.gpt_recommendation?.results);
    setPage(get_gpt_recommendation_log?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_gpt_recommendation_log?.gpt_recommendation?.count /
          get_gpt_recommendation_log?.gpt_recommendation?.page_size
      )
    );
  }, [get_gpt_recommendation_log, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_GPT_RECOMMENDATION_LOG_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_gpt_recommendation_log?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_gpt_recommendation_log]);

  return (
    <Container>
      
      <SectionDiv>
        <SearchList searchList={searchList} setSearchList={setSearchList} setPage={setPage} />
      </SectionDiv>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages || 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
