import React, { useEffect, useState, Suspense } from "react";
import styled from "styled-components";
import { Icon, Header, Image, List } from "semantic-ui-react";
import { format } from "date-fns";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  PULL_EMPLOYEE,
  PULL_ORGANIZATION,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SIGNUP_TOKENS,
} from "constants/actions";

// Lazy-loaded components
const LoginInfo = React.lazy(() => import("settings/logins/List"));
const LoginHistory = React.lazy(() => import("settings/LoginHistory/List"));
const TokenList = React.lazy(() => import("briefToken/List"));
const BriefResponseList = React.lazy(() => import("briefResponse/List"));
const EmployeeLogList = React.lazy(() => import("employeeLog/List"));
const EmployeeRecord = React.lazy(() => import("employeeRecord/List"));
const SignupToken = React.lazy(() => import("./SignupToken"));
const CategoryTags = React.lazy(() => import("./CategoryTags"));
const EmployeePermission = React.lazy(() => import("EmployeePermission"));

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [employeeId, setEmployeeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [employeeCategory, setEmployeeCategory] = useState(null);
  const [empUpdateRow, setEmpUpdateRow] = useState(true);
  const [signupTokenResponse, setSignupTokenResponse] = useState(null);

  function BackArrow() {
    navigate(-1);
  }

  useEffect(() => {
    setEmployeeId(id);
  }, [id]);

  useEffect(() => {
    if (employeeId) {
      dispatch({
        type: PULL_EMPLOYEE,
        payload: { id: employeeId },
      });
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
      dispatch({
        type: SG_GET_SIGNUP_TOKENS,
        payload: `employee=${employeeId}`,
      });
    }
  }, [dispatch, employeeId, empUpdateRow]);

  useEffect(() => {
    if (employeeDetails?.organization) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: employeeDetails?.organization },
      });
    }
  }, [dispatch, employeeDetails?.organization]);

  const { get_employees, get_employee_categories, get_signup_tokens } =
    useSelector(
      (state) => ({
        get_employees: state.employees,
        get_employee_categories: state.employee_category.employee_category,
        get_signup_tokens: state.signuptoken.signup_tokens,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_signup_tokens?.results && get_signup_tokens?.results.length > 0) {
      setSignupTokenResponse(get_signup_tokens?.results);
    }
  }, [get_signup_tokens]);

  useEffect(() => {
    setEmployeeDetails(get_employees?.[employeeId]);
    setUserId(get_employees?.[employeeId]?.account);
  }, [get_employees, employeeId]);

  useEffect(() => {
    setEmployeeCategory(null);
    const filteredCategory = get_employee_categories.filter(
      (em) => em.employee === Number(employeeId)
    );
    if (filteredCategory.length > 0) {
      setEmployeeCategory(filteredCategory[0]?.category);
    }
  }, [get_employee_categories, employeeId]);

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Details of {employeeDetails?.first_name} {employeeDetails?.last_name}{" "}
        {" ("}
        {employeeDetails?.email}
        {") # "}
        {employeeDetails?.id}
      </Header>
      <SectionDiv>
        <Image floated="right" size="small" src={employeeDetails?.picture} />
      </SectionDiv>

      <List horizontal>
        <List.Item>
          <List.Content>
            <ListContainer>
              <LinkBox
                onClick={() =>
                  navigate(
                    `/app/organizations/details/${employeeDetails?.organization}`
                  )
                }
              >
                <List.Header>Organization</List.Header>
                {employeeDetails?.organization}
              </LinkBox>
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Account Type</List.Header>
              {employeeDetails?.account_type_text}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Role</List.Header>
              {employeeDetails?.role_text}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Email Status</List.Header>
              {employeeDetails?.email_status_text}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Team</List.Header>
              {employeeDetails?.team_name}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Login Enabled</List.Header>
              {employeeDetails?.enable_login ? "Yes" : "No"}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>User Code</List.Header>
              {employeeDetails?.user_code}
            </ListContainer>
          </List.Content>
        </List.Item>
      </List>

      <List horizontal>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Joined Date</List.Header>
              {employeeDetails?.join_date &&
                format(new Date(employeeDetails?.join_date), "dd-MMM-yyyy")}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Survey Status</List.Header>
              {employeeDetails?.survey_status === 1 ? "Yes" : "No"}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Add Survey users</List.Header>
              {employeeDetails?.survey_add_users === 1 ? "Yes" : "No"}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Can Schedule Surveys</List.Header>
              {employeeDetails?.survey_schedule_text}
            </ListContainer>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <ListContainer>
              <List.Header>Status</List.Header>
              {employeeDetails?.status === 1 ? (
                <Icon color="green" name="check" />
              ) : (
                <Icon color="red" name="archive" />
              )}
              <small>{employeeDetails?.status_text}</small>
            </ListContainer>
          </List.Content>
        </List.Item>
      </List>

      {/* Lazy-loaded sections wrapped with Suspense */}
      <Suspense fallback={<LoadingSpinner />}>
        <SectionDiv>
          <Header as="h3">Employee Permission</Header>
          {employeeCategory && <EmployeePermission employeeId={employeeId} />}
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Category Tags</Header>
          {employeeCategory && <CategoryTags rows={employeeCategory} />}
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Employee Records</Header>
          {employeeId && <EmployeeRecord employeeId={employeeId} />}
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Signup Token</Header>
          <SignupToken jsonData={signupTokenResponse} />
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Login Details</Header>
          <LoginInfo employeeId={employeeId} />
          {userId && <LoginHistory userId={userId} />}
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Survey Token's and Status</Header>
          <TokenList employeeId={employeeId} />
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Survey's Completed</Header>
          <BriefResponseList
            employeeId={employeeId}
            empUpdateRow={empUpdateRow}
            setEmpUpdateRow={setEmpUpdateRow}
          />
        </SectionDiv>

        <SectionDiv>
          <Header as="h3">Employee Log</Header>
          <EmployeeLogList employeeId={employeeId} />
        </SectionDiv>
      </Suspense>
    </>
  );
};

export default Details;

// Styled Components
const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
`;

const LinkBox = styled.div`
  margin-top: 20px;
  border-style: solid;
  border-color: coral;
  border-width: thin;
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
`;

// Loading Spinner Component
const LoadingSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 4px;

  &:after {
    content: "";
    width: 40px;
    height: 40px;
    border: 6px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
