import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import AddEdit from './AddEdit';
import Edit from './AddEdit';
import Details from './Details';
import { Header, Grid, Button } from 'semantic-ui-react'
import { useNavigate, useLocation } from "react-router-dom";

function AppList() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <>
            <Grid.Row columns={2}>
                {(location.pathname !== '/app/app-url/new') &&
                    <Grid.Column>
                        <Header as='h2' style={{ marginTop: "50px" }}>
                            List of Apps
                            <Header.Subheader>
                                <p>Create / update List of Apps with your login creds</p>

                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                }
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    {(location.pathname !== '/app/app-url/new') &&
                        <Button primary
                            onClick={() => navigate(`/app/app-url/new`)}
                        >Add New</Button>
                    }

                </Grid.Column>
            </Grid.Row>

            <Routes>
                <Route path="/" element={<List />} />
                <Route path="new" element={<AddEdit />} />
                <Route path="details/:id" element={<Details />} />
                <Route path="edit/:id" element={<Edit />} />
            </Routes>
        </>
    );
}

export default AppList;
