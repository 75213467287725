import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import IL from "assets/images/logo.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { NavData } from "./navigation";

import { pathList } from "routes/routeMap";

import {
  SG_FETCH_USER_EMPLOYEE,
  PULL_ORGANIZATION,
} from "constants/actions";

const SubNav = (props) => {
  return (
    <Sub onClick={() => props.navigate(props.data.link)}>
      <Text>{props.data.title}</Text>
    </Sub>
  );
};

const NavItem = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <NavContainer open={open && props.open} >
      <IconContainer selected={props.selected == props.data.link} onClick={() => props.navigate(props.data.link)}>
        {props.data.image ?
          <IconImage src={props.data.image} />
          : <Icon name={props.data.icon} size={"small"} style={{ width: 30, fontSize: 24 }} />}
        <Text>{props.name ? props.name : props.data.title}</Text>
      </IconContainer>
      {props.data.subNav
        ? props.data.subNav.map((x, id) => {
          return <SubNav data={x} key={id} navigate={props.navigate} />;
        })
        : ""}

      {props.data.subNav && props.open ? (
        <Expand onClick={() => setOpen(!open)}>
          <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            <Chevron open={open}>
              <Icon
                name={"chevron down"}
                size={"small"}
              />
            </Chevron>
          </div>
        </Expand>
      ) : (
        ""
      )}
    </NavContainer>
  );
};

const MobileNav = () => {
  let navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  const location = useLocation();

  const [selected, setSelected] = useState(null);

  const [employee, setEmployee] = useState(false)
  const [accountId, setAccountId] = useState(1)
  const [roleId, setRoleId] = useState(0)
  const [organization, setOrganization] = useState(false)

  const [validRoutes, setValidRoutes] = useState([])
  // const [loading, setLoading] = useState(true)

  const navigate = (url) => {
    navigateTo(url)
    setShow(false)
  }


  const { get_auth, get_employees, get_organizations } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_organizations: state.organizations
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_auth?.token) {

      setAccountId(get_employees?.userEmp?.account_type);
      setRoleId(get_employees?.userEmp?.role);
      setOrganization(get_organizations[get_auth.organization_id])
      setEmployee(get_employees?.userEmp)
    }

  }, [get_employees, get_auth, get_organizations]);

  useEffect(() => {
    // if the response is ok 200
    if (get_auth?.organization_id) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: get_auth.organization_id },
      });
    }
    // dispatch({ type: LOAD_TEAMS });
  }, [dispatch, get_auth]);

  useEffect(() => {
    // if the response is ok 200
    if (get_auth?.employee_id) {
      // console.log(get_auth.token)
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: { id: get_auth.employee_id },
      });
    }
  }, [dispatch, get_auth]);

  useEffect(() => {

    const EnabledList = []
    pathList.map(path => {
      let Found = organization?.services_enabled?.filter(
        srv => srv?.name === path?.name && srv?.enabled === true
      )?.[0]
      if (Found?.id > 0) {
        EnabledList.push(path.name)
      }
      return null
    })
    EnabledList.push('Common')

    setValidRoutes(EnabledList)

    // const timer1 = setTimeout(() => {
    //   setLoading(false);
    // }, 2000)
    // return () => clearTimeout(timer1)

  }, [organization]);


  useEffect(() => {
    const currentPath = location.pathname;
    setSelected(currentPath);
  }, [location]);


  return (
    <Container>
      <HamContainer>
        <Icon name={show ? "close" : "bars"} onClick={() => setShow(!show)} />
      </HamContainer>
      <Logo src={IL} />
      <MobileScreen show={show}>
        <MobileContainer>
          {NavData.map((item, i) => {
            // console.log(item)
            // console.log("My roleId", roleId)
            if (!validRoutes.includes(item.group)) {
              return null;
            }
            if (organization?.days_for_trial < 0 && item.paid) {
              return null
            }
            if (organization?.payment_status > 8 && item?.paid) {
              return null
            }
            if (item.role < roleId) {
              return null
            }

            if (item.admin && accountId != 5) {

              return null
            }

            if (item.team && item.title != 'My mood') {
              if (roleId > 10) {
                return null
              }
            }

            if (item.personality) {
              // console.log(item)
              if (!employee?.personality) {
                return null
              }
            }

            if (item.leadership) {
              // console.log(item)
              if (!employee?.leadership) {
                return null
              }
            }

            if (roleId < 9 && item.title == "My Data") {
              return <NavItem
                data={item}
                selected={selected}
                open={show} key={i} navigate={(l) => navigate(l)}
                name="Member Analytics"
              />
            }

            return (
              <NavItem data={item} selected={selected} open={show} key={i} navigate={(l) => navigate(l)} />
            );
          })}
        </MobileContainer>
        <Edge onClick={() => setShow(false)} />
      </MobileScreen>
    </Container>
  )
}


export default MobileNav

const Container = styled.div`
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0px;
  box-shadow: 0 10px 10px 0 rgba(161, 161, 161, 0.2);
  height: 70px;
  min-height: 70px;
  z-index: 9999;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: row;

  a{
  	color:#4a4a4a;
  }
`;

const Logo = styled.img`
  height: 35px;

  @media (min-width: 1024px) {
    margin-left: 38px;
  }
`;

const HamContainer = styled.div`
	position:absolute;
	left:10px;
	font-size:20px;
	color:#1e2172;
`

const MobileContainer = styled.div`
	height:100%;
	width:80vw;
	background-color:white;
	flex-direction:column;
	padding-top:50px;
  background-color:#15056b;

`
const MobileScreen = styled.div`
  height:100vh;
  width:100vw;
  z-index:9999;
  position:fixed;
  top:65px;
  left:${props => props.show ? '0' : '-100vw'};
  display:flex;
  flex-direction:row;
  overflow:hidden;
  transition:all 300ms;
`

const Edge = styled.div`
  width:20vw;
  background-color:rgba(0,0,0,0.1);
`

const IconContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${(props) => (props.selected ? "#4700f9" : "#9b9b9b")};
  font-size: 35px;
  width: 200px;
  border-left: ${(props) => (props.selected ? "4px solid #4700f9" : "none")};
  padding-left: ${(props) => (props.selected ? "6px" : "10px")};
  display: flex;
  flex-direction: row;
`;


const Text = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  margin-left: 10px;
  color: white;
`;


const Sub = styled.div`
  width: 100%;
  width: 225px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-family: "Red Hat Display", sans-serif;
`;

const NavContainer = styled.div`
  position: relative;
  max-height: ${(props) => (props.open ? "1000px" : "32px")};
  overflow: hidden;
  transition: max-height 1s ease;
  margin: 5px 0 5px 0;


`;

const Expand = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  color: #9b9b9b;
  width:100px;
  height:100%;
  z-index:20;
`;

const Chevron = styled.div`
   position: absolute;
  right: 5px;
  top: 10px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 300ms;
`

const IconImage = styled.img`
   width:26px;
  height:26px;
  margin-right:6px;
`
