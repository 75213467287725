import React from "react";
import { Routes, Route } from "react-router-dom";
import styled from 'styled-components';

import Home from "./Home";
import Product from "./Product/index";
import Group from "./Group/index";
import Price from "./Price/index";
import Package from "./Package/index";
import Contract from "./Contract/index";
import ProductList from "./Package/CardList"

function ProductMain() {

  return (
    <>
    <Container>
      <StyledRow>
        <StyledColumn>
          <StyledHeader>
            Product Feature Manager
            
          </StyledHeader>
          <Subheader>
              View the products features enable / disable add / remove features, align them
              to organizations.
            </Subheader>
        </StyledColumn>
      </StyledRow>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/list" element={<ProductList />} />
        <Route path="/product/*" element={<Product />} />
        <Route path="/group/*" element={<Group />} />
        <Route path="/price/*" element={<Price />} />
        <Route path="/package/*" element={<Package />} />
        <Route path="/contract/*" element={<Contract />} />
      </Routes>
      </Container>
    </>
  );
}

export default ProductMain;

const Container = styled.div`
  margin-top: 50px;
`;

const StyledRow = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  
`;

const Subheader = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;
