import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_MANAGER } from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Segment,

} from "semantic-ui-react";

const List = ({ hideOrgSelect, orgId }) => {
  const dispatch = useDispatch();

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [queryParams, setQueryParams] = useState([]);

  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (orgId > 0) {
      dispatch({
        type: SG_GET_EMPLOYEE_MANAGER,
        payload: `organization=${orgId}`,
      });
    }
  }, [dispatch, orgId]);

  useEffect(() => {}, [dispatch, queryParams]);

  useEffect(() => {
    if (get_employees?.queryParams) {
      setQueryParams(get_employees?.queryParams);
    }
  }, [get_employees]);

  useEffect(() => {
    setResults(get_employees?.managerList);
  }, [get_employees]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_employees?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_employees]);

  return (
    <Container>
      <p>Total Managers [{results?.length}]</p>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) => <RowCard row={result} key={result?.id} />)}
        </Card.Group>
      </Segment>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

