import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";

import {
  SG_FETCH_DEBRIEF_SUMMARY,
  SG_GET_DEBRIEF_ANALYTICS,
} from "constants/actions";

import ViewDetails from "./ViewDetails"

const Matcher = ({newResponse, setNewResponse}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
 
  const [templateId, setTemplateId] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_ANALYTICS,
    });
  }, [dispatch]);


  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SUMMARY,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId,]);

  return (
    <Container>
      <ViewDetails  newResponse={newResponse} setNewResponse={setNewResponse} />
    </Container>
  );
};

export default Matcher;

const Container = styled.div`
  margin-top: 50px;
  padding-left: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

