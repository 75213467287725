import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
  SG_POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
} from "constants/actions";

import {
  Button,
  Modal,
  Header,
  Dimmer,
  Loader,
  Segment,
  Grid,
} from "semantic-ui-react";

export default function SurveySummary({ debriefDetails }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [excelLink, setExcelLink] = useState("");

  function getSummary() {
    if (debriefDetails?.id) {
      dispatch({
        type: SG_POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
        payload: {
          debrief_schedule_id: debriefDetails?.id,
        },
      });
    }
  }
  const { get_brief_summary, get_excel_summary } = useSelector(
    (state) => ({
      get_brief_summary: state.debrief_schedule,
      get_excel_summary: state.debrief_schedule?.excel_summary,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoading(get_brief_summary?.pending);
  }, [get_brief_summary, dispatch]);

  function downloadSummary() {
    if (excelLink) {
      window.open(excelLink, "_blank");
      dispatch({ type: POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY, payload: null }); // Reset the download state
    }
  }

  useEffect(() => {
    if (get_excel_summary) {
      let excelLink = get_excel_summary?.excel_link;
      // Check if the protocol is missing, add it if necessary
      setExcelLink(excelLink);
    }
  }, [get_excel_summary, dispatch]);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button floated="right" color="violet">
          Survey Summary
        </Button>
      }
    >
      {" "}
      <Modal.Description>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Dimmer.Dimmable as={Segment} dimmed={loading}>
                <Dimmer active={loading}>
                  <Loader />
                  <Header as="h2" icon inverted>
                    Loading, Please wait.
                  </Header>
                </Dimmer>
                <OuterContainer>
                  <HeaderDiv>Click to Generate and Download Summary</HeaderDiv>

                  <ButtonContainer>
                    {excelLink ? (
                      <Button color="blue" onClick={downloadSummary}>
                        Download File
                      </Button>
                    ) : (
                      <Button color="violet" onClick={getSummary}>
                        Generate Summary
                      </Button>
                    )}
                  </ButtonContainer>
                </OuterContainer>
              </Dimmer.Dimmable>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Description>
    </Modal>
  );
}
const OuterContainer = styled.div`
  margin: 20px 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const HeaderDiv = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 20px;
`;
