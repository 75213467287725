import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_TASK_TEMPLATE,
  SG_GET_ORGANIZATION_SELECT,
  SG_DELETE_TASK_TEMPLATE,
  UPDATE_TASK_TEMPLATE_PAGE_NUMBER,
  RESET_TASK_TEMPLATE,
} from "constants/actions";
//
import ReactJson from "react-json-view";
import { Environment } from "WebConfig"
//
import {
  Dimmer,
  Loader,
  Segment,
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import { BasicErrorMessage } from "utilities/ErrorMessages";
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [orgSelected, setOrgSelected] = useState([]);

  const [loading, setLoading] = useState(false);

  const [apiCreds, setApiCreds] = useState([]);
  const [apiCredSelected, setApiCredSelected] = useState([]);

  const [apiParam, setApiParam] = useState("");

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  function BackArrow() {
    navigate(`/app/task-template`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId > 0) {
      dispatch({
        type: RESET_TASK_TEMPLATE,
      });
      dispatch({
        type: SG_FETCH_TASK_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: UPDATE_TASK_TEMPLATE_PAGE_NUMBER,
        payload: 1,
      })
      dispatch({
        type: SG_DELETE_TASK_TEMPLATE,
        payload: {
          id: templateId,
        },
      });
    }
  }

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  const { get_task_template, get_organization_select } = useSelector(
    (state) => ({
      get_task_template: state.task_template,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_task_template?.[templateId]) {
      setTemplateDetails(get_task_template?.[templateId]);
      setApiParam(get_task_template?.[templateId]?.param);
      setOrgSelected(
        get_organization_select.filter(
          (sy) => sy.id === get_task_template?.[templateId]?.organization
        )?.[0]
      );
      setApiCredSelected(
        apiCreds.filter(
          (sy) => sy.id === get_task_template?.[templateId]?.web_api_credential
        )
      );
    }
  }, [get_task_template, templateId, get_organization_select, apiCreds]);

  useEffect(() => {
    const ErrorList = [];
    if (apiCredSelected?.direction > 10 && apiCredSelected?.direction < 20) {
      if (!apiParam) {
        ErrorList.push("Enter a Param");
      }
    }

    if (!apiCredSelected?.id) {
      ErrorList.push("Select an Api");
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization");
    }

    setFormErrors(ErrorList);
  }, [apiParam, apiCredSelected, orgSelected, valueChanged]);

  useEffect(() => {
    setLoading(get_task_template?.pending);
    if (get_task_template?.deleted) {
      navigate(`/app/task-template`);
    }
  }, [get_task_template, navigate]);

  

  return (
    <>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>

        <Header as="h2">
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow}
            name="arrow left"
          />
          {templateId
            ? `Details of ${templateDetails?.id} # ${templateDetails?.name}`
            : "Add New Api Pull"}
        </Header>
        {formErrors.length > 0 && !pristine && (
          <Message
            negative
            //   onDismiss={clearErrors}
            header="We need these details!"
            list={formErrors}
          />
        )}
        <SectionDiv>
          <BasicErrorMessage />
        </SectionDiv>

        {templateId && (
          <SectionDiv>
            <Container>
              <List horizontal>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Org</List.Header>
                      {templateDetails?.organization}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Name</List.Header>
                      {templateDetails?.name}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Task Ref Id</List.Header>
                      {templateDetails?.task_ref_id}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Organization</List.Header>
                      {templateDetails?.organization
                        ? get_organization_select.filter(
                            (org) => org.id === templateDetails?.organization
                          )?.[0]?.name
                        : "For all Org"}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Created</List.Header>
                      {templateDetails?.created_at &&
                        format(
                          new Date(templateDetails?.created_at),
                          "dd-MMM-yyyy"
                        )}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Status</List.Header>
                      {templateDetails?.status_text}
                    </ListContainer>
                  </List.Content>
                </List.Item>
              </List>
              {templateId && (
                <>
                  <Button
                    floated="left"
                    color="red"
                    onClick={() => setConfirmOpen(true)}
                  >
                    Delete
                  </Button>
                  <Confirm
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onConfirm={DeleteQuestion}
                  />
                </>
              )}
            </Container>
          </SectionDiv>
        )}
        {!templateId && (
          <SectionDiv lastSection={true}>
            {/* <Button floated="right" color="green" onClick={UpdateQuestions}>
              New Pull
            </Button> */}
          </SectionDiv>
        )}

        <SectionDiv>
          <SectionHeader>Assigned Employees</SectionHeader>
          <ReactJson src={templateDetails?.assigned_employees} />
        </SectionDiv>

        <SectionDiv>
          <SectionHeader>Task Template User Requests</SectionHeader>
          <ReactJson
            src={templateDetails?.task_template_task_template_user_request}
          />
        </SectionDiv>

        <SectionDiv>
          <SectionHeader>Task Responses</SectionHeader>
          <ReactJson src={templateDetails?.task_responses} />
        </SectionDiv>

        <SectionDiv>
          <SectionHeader>User Tasks</SectionHeader>
          <ReactJson src={templateDetails?.task_template_user_tasks} />
        </SectionDiv>

        <SectionDiv>
          <SectionHeader>User Requests [front end]</SectionHeader>
          <ReactJson src={templateDetails?.user_task_status} />
        </SectionDiv>

        <SectionDiv>
          <SectionHeader>User Reports</SectionHeader>
          {templateDetails?.task_reports?.length > 0 ? (
            templateDetails?.task_reports.map((report, index) => (
              <SectionDiv>
                <LabelHead>{report?.name ? report?.name : "No Name"}</LabelHead>
                <LabelHead>
                  {report?.sort_order ? report?.sort_order : "No sort_order"}
                </LabelHead>
                <ReactJson src={report} key={index} />
              </SectionDiv>
            ))
          ) : (
            <p>No Reports</p>
          )}
        </SectionDiv>

        <SectionDiv>
          <SectionHeader>Email Template</SectionHeader>
          <LabelText>ID # {templateDetails?.task_template_email?.id}</LabelText>
          <LabelText>Subject {templateDetails?.task_template_email?.email_subject}</LabelText>
          <LabelText>Content </LabelText>
          <LabelTextBoxed>

          
          <div dangerouslySetInnerHTML={{ __html: templateDetails?.task_template_email?.email_content }}></div>
              </LabelTextBoxed>
          
          <ReactJson src={templateDetails?.task_template_email?.delivery_response} />
        </SectionDiv>
        {/*  */}
      </Segment>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const SectionHeader = styled.h3`
  font-weight: 700;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const LabelText = styled.label`
  padding: 0.5em 1em 0.5em 0;
  display: block;
`;

const LabelTextBoxed = styled.label`
  margin: 0.5em 1em 0.5em 1em;  
  padding: 0.5em 1em 0.5em 1em;
  display: block;
  border: 1px solid #dcdcdc;
  background-color: #f5f5f5;
`;
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
