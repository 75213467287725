import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDispatch } from "react-redux";
//
import { Button } from "semantic-ui-react";
//
import { SG_POST_APP_SERVERS_MAKE_BUILD } from "constants/actions";
//
import { APP_UPDATE_TYPE } from "data/AdminData";

const CreateNew = ({ template }) => {
  const dispatch = useDispatch();

  function CreateServerProcess(process) {
    if (process?.make && template?.locked_status === 1) {
      dispatch({
        type: SG_POST_APP_SERVERS_MAKE_BUILD,
        payload: {
          server_id: template.id,
          make: process.make,
          update_type: process.update_type_id,
          update_description: process.description,
          // Add Param for custom update
        },
      });
    }
  }

  return (
    <>
      <SectionDiv>
        

        {template?.details?.builds ? (
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>ID</TableHeader>
                  <TableHeader>Make</TableHeader>
                  <TableHeader>Next Steps</TableHeader>
                  <TableHeader>Type</TableHeader>
                  <TableHeader>Description</TableHeader>
                  <TableHeader>Actions</TableHeader>
                </tr>
              </thead>
              <tbody>
                {template?.details?.builds &&
                  template?.details?.builds.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row?.id}</TableCell>
                      <TableCell>{row?.make}</TableCell>
                      <TableCell>{row?.next_steps}</TableCell>
                      <TableCell>{APP_UPDATE_TYPE.find((option) => option?.value === row?.update_type_id)?.label} </TableCell>
                      <TableCell>{row?.description}</TableCell>
                      <TableCell>
                        <Button
                          primary
                          disabled={template?.locked_status !== 1}
                          onClick={() => CreateServerProcess(row)}
                        >
                          {row?.action}
                        </Button>
                      </TableCell>

                      {/* {!viewOnly && <TableCell>Delete</TableCell>} */}
                    </TableRow>
                  ))}

                {/* Add more rows as needed */}
              </tbody>
            </Table>{" "}
          </TableContainer>
        ) : (
          <StyledNoBuildSteps>No Build steps defined</StyledNoBuildSteps>
        )}
      </SectionDiv>
    </>
  );
};

export default CreateNew;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const StyledNoBuildSteps = styled.div`
  padding: 10px;
  color: #999;
  font-style: italic;
  text-align: center;
  font-size: 1.5em;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
`;
