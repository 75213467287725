import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Form, Button } from "semantic-ui-react";
import { SEARCH_SURVEY_STRUCTURE } from "constants/actions";

const SearchBar = ({ surveyStrucRow }) => {
  const dispatch = useDispatch();
  const [searchBox, setSearchBox] = useState("");
  const [searchHeading, setSearchHeading] = useState("");
  const [searchOption, setSearchOption] = useState("");

  const { get_survey_structure_search } = useSelector(
    (state) => ({
      get_survey_structure_search: state.surveystructure.search,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSearchBox(get_survey_structure_search);
  }, [get_survey_structure_search]);

  function OnButtonClick() {
    dispatch({
      type: SEARCH_SURVEY_STRUCTURE,
      payload: searchBox,
    });

    if (searchBox && surveyStrucRow) {
      setSearchHeading("");
      if (surveyStrucRow.find((row) => row.name === searchBox)) {
        setSearchHeading(surveyStrucRow.find((row) => row.name === searchBox));
      }
      if (surveyStrucRow.find((row) => row.id === searchBox)) {
        setSearchHeading(surveyStrucRow.find((row) => row.id === searchBox));
      }
      setSearchOption("");
      surveyStrucRow.map((row) => {
        if (row?.options) {
          if (row.options.find((op) => op?.id === searchBox)) {
            setSearchOption(row.options.find((op) => op?.id === searchBox));
            setSearchHeading(row);
            return null;
          }
          if (row.options.find((op) => op?.name === searchBox)) {
            setSearchOption(row.options.find((op) => op?.name === searchBox));
            setSearchHeading(row);
            return null;
          }
          return null;
        }
        return null;
      });
    }
  }

  function ClearSearch() {
    dispatch({
      type: SEARCH_SURVEY_STRUCTURE,
      payload: "",
    });
  }

  useEffect(() => {
    if (searchBox && surveyStrucRow) {
      setSearchHeading("");
      if (surveyStrucRow.find((row) => row.name === searchBox)) {
        setSearchHeading(surveyStrucRow.find((row) => row.name === searchBox));
      }
      if (surveyStrucRow.find((row) => row.id === searchBox)) {
        setSearchHeading(surveyStrucRow.find((row) => row.id === searchBox));
      }
      setSearchOption("");
      surveyStrucRow.map((row) => {
        if (row?.options) {
          if (row.options.find((op) => op?.id === searchBox)) {
            setSearchOption(row.options.find((op) => op?.id === searchBox));
            setSearchHeading(row);
            return null;
          }
          if (row.options.find((op) => op?.name === searchBox)) {
            setSearchOption(row.options.find((op) => op?.name === searchBox));
            setSearchHeading(row);
            return null;
          }
          return null;
        }
        return null;
      });
    }
  }, [searchBox, surveyStrucRow]);

  //   Primary Name
  // 1c3ebf12-e8e2-45b2-ae5d-9fe93b4184ed
  // ca0199d0-a6c5-4435-b744-44b26b7c3afc

  //   Artistic Swimming
  // bf6bac6a-a369-41c4-99dd-cb0b9caeb0c1
  // 3d79f7da-23cc-4aa7-bd8d-7ed5cf2751e7
  // 50af0814-5127-4e72-9e5f-a68c3f274e3c

  //   console.log(surveyStrucRow);
  //   console.log("searchHeading", searchHeading);
  //   console.log("searchOption", searchOption);

  return (
    <>
      <StyledForm>
        <StyledInput
          label="Search Within the Org Name or ID (NON OTP)"
          value={searchBox}
          onChange={(e) => setSearchBox(e.target.value)}
          placeholder="Search..."
        />
        <StyledButton type="submit" onClick={OnButtonClick}>
          Search
        </StyledButton>
        <StyledButton color="blue" onClick={ClearSearch}>
          Clear
        </StyledButton>
      </StyledForm>
      <ResultsContainer>
        {searchHeading && (
          <ResultItem>
            <Title>Found in Heading</Title>
            <Text found={searchHeading?.name === searchBox}>
              Name: {searchHeading?.name}
            </Text>
            <Text found={searchHeading?.id === searchBox}>
              ID: {searchHeading?.id}
            </Text>
          </ResultItem>
        )}
        {searchOption && (
          <ResultItem>
            <Title>Found in Option</Title>
            <Text found={searchOption?.name === searchBox}>
              Name: {searchOption?.name}
            </Text>
            <Text found={searchOption?.id === searchBox}>
              ID: {searchOption?.id}
            </Text>
          </ResultItem>
        )}
      </ResultsContainer>
    </>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
};

export default SearchBar;

// Styled components
const StyledForm = styled(Form)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
`;

const StyledInput = styled(Form.Input)`
  flex: 1;
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  background-color: #0079d3;

  color: white;
  &:hover {
    background-color: #005fa3;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ResultItem = styled.div`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: ${(props) => (props.found ? "red" : "inherit")};
  font-weight: ${(props) => (props.found ? "bold" : "inherit")};
`;
