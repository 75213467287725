import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Message,
  Segment,
  Dimmer,
  Loader,
  Card,
  Pagination,
  Button,
} from "semantic-ui-react";

import RowCard from "./RowCard";

import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  UPDATE_DEBRIEF_PDF_REPORT_PAGE_NUMBER,
  SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  SG_GET_DEBRIEF_PDF_REPORT,
  SG_GET_ORGANIZATION_SELECT,
  RESET_DEBRIEF_PDF_REPORT_UPDATES,
  UPDATE_DEBRIEF_PDF_REPORT_QUERY_PARAMS,
} from "constants/actions";
import { DEBRIEF_PDF_REPORT_STATUS } from "data/AdminData";

const TemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  // const [organizationSelect, setOrganizationSelect] = useState([])
  const [orgSelected, setOrgSelected] = useState([]);
  const [resportStatus, setReportStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1);
  const [queryParams, setQueryParams] = useState([]);

  const { get_debrief_pdf_report, get_organization_select } = useSelector(
    (state) => ({
      get_debrief_pdf_report: state.debrief_pdf_report,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (queryParams?.length > 0) {
      dispatch({
        type: SG_GET_DEBRIEF_PDF_REPORT,
        payload: queryParams.join("&"),
      });
    } else {
      dispatch({
        type: SG_GET_DEBRIEF_PDF_REPORT,
      });
    }

    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
    });
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (get_debrief_pdf_report?.pending) {
      setLoading(get_debrief_pdf_report?.pending);
    }
    const timer1 = setTimeout(() => {
      setLoading(get_debrief_pdf_report?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_debrief_pdf_report]);

  useEffect(() => {
    if (get_debrief_pdf_report?.queryParams) {
      setQueryParams(get_debrief_pdf_report?.queryParams);
    }
  }, [get_debrief_pdf_report]);

  useEffect(() => {
    if (queryParams?.length > 0) {
      const parameters = {};

      queryParams.forEach((item) => {
        const [name, value] = item.split("=");
        parameters[name] = value;
      });
      if (parameters?.["status"]) {
        setReportStatus(
          DEBRIEF_PDF_REPORT_STATUS.filter(
            (status) => status.id == parameters["status"]
          )[0]
        );
      }

      // Check if organization filter is present
      if (parameters?.["organization"]) {
        setOrgSelected(
          get_organization_select.filter(
            (org) => org.id == parameters["organization"]
          )[0]
        );
      }
    }
  }, [queryParams, get_organization_select]);

  function ResetUpdates() {
    dispatch({
      type: RESET_DEBRIEF_PDF_REPORT_UPDATES,
    });
  }

  function applyFilters(statusId, orgId, pageNumber = 1) {
    let _queryParams = [];

    // Check if report status filter is present
    if (pageNumber) {
      _queryParams.push(`page=${pageNumber}`);
    }

    // Check if report status filter is present
    if (statusId) {
      _queryParams.push(`status=${statusId}`);
    }

    // Check if organization filter is present
    if (orgId) {
      _queryParams.push(`organization=${orgId}`);
    }
    dispatch({
      type: UPDATE_DEBRIEF_PDF_REPORT_QUERY_PARAMS,
      payload: _queryParams,
    });
    // Dispatch action with combined filters
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT,
      payload: _queryParams.join("&"),
    });
  }

  function handleStatusFilter() {
    applyFilters(resportStatus?.id, orgSelected?.id);
  }

  function UpdateOrganization() {
    applyFilters(resportStatus?.id, orgSelected?.id);
  }

  useEffect(() => {
    setTemplates(get_debrief_pdf_report?.templates?.response);
    setResults(get_debrief_pdf_report?.debrief_pdf_report?.results);
    setPage(get_debrief_pdf_report?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_debrief_pdf_report?.debrief_pdf_report?.count /
          get_debrief_pdf_report?.debrief_pdf_report?.page_size
      )
    );
    if (
      get_debrief_pdf_report?.debrief_pdf_report?.count %
      get_debrief_pdf_report?.debrief_pdf_report?.page_size
    ) {
      setTotalPages(
        Math.ceil(
          get_debrief_pdf_report?.debrief_pdf_report?.count /
            get_debrief_pdf_report?.debrief_pdf_report?.page_size
        )
      );
    }
  }, [get_debrief_pdf_report, page, ReloadPage]);

  function handlePageChange(event, value) {
    setPage(value.activePage);

    dispatch({
      type: UPDATE_DEBRIEF_PDF_REPORT_PAGE_NUMBER,
      payload: value.activePage,
    });
    applyFilters(resportStatus?.id, orgSelected?.id, value.activePage);
  }

  return (
    <Container>
      <SingleRowContent>
        <SingleRowContent>
          <SectionCell>
            <Select
              name="org_preference"
              options={get_organization_select}
              isClearable
              onChange={setOrgSelected}
              getOptionLabel={(option) => `${option.id} # ${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              value={orgSelected}
            />
          </SectionCell>
          <Button onClick={UpdateOrganization}>Filter</Button>
        </SingleRowContent>

        <SingleRowContent>
          <SectionCell>
            <Select
              name="debrief_report_status"
              options={DEBRIEF_PDF_REPORT_STATUS}
              isClearable
              onChange={setReportStatus}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => `${option.id}`}
              value={resportStatus}
            />
          </SectionCell>
          <Button onClick={handleStatusFilter}>Filter</Button>
        </SingleRowContent>
        <SingleRowContent>
          <Button
            color="green"
            onClick={() => navigate(`/app/pdf-reports/templates/`)}
          >
            View Templates
          </Button>
        </SingleRowContent>
      </SingleRowContent>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results ? (
            results.map((result) => (
              <RowCard
                row={result}
                key={result?.id}
                get_organization_select={get_organization_select}
                templates={templates}
                ResetUpdates={ResetUpdates}
              />
            ))
          ) : (
            <SectionDiv>
              <p>No results found</p>
            </SectionDiv>
          )}
        </Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages ? totalPages : 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default TemplateList;

const Container = styled.div`
  margin-bottom: 30px;
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 30px 5px;
`;
const SectionCell = styled.div`
  min-width: 350px;
`;
