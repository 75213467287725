import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import List from "./List";
import Details from "./Details";

import ListRecent from "briefResponse/ListRecent";

import { Header, Button } from "semantic-ui-react";
function BriefScheduled() {
  const navigate = useNavigate();
  const { pathname } = window.location;

  return (
    <>
      <Header as="h2" style={{ marginTop: "50px" }}>
        Scheduled List (no team data){" "}
        {pathname !== "/app/brief-schedule/recent" && (
          <Button
            floated="right"
            onClick={() => navigate(`/app/brief-schedule/recent`)}
          >
            Recent Responses
          </Button>
        )}
        <Header.Subheader>
          List of Performance, Practise, Culture Audit, EDI Survey's Scheduled
          and a place to view them.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="recent" element={<ListRecent />} />
      </Routes>
    </>
  );
}

export default BriefScheduled;
