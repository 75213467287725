import React from "react";
import styled from "styled-components";

// Create the component
const ActionItem = ({ data, FetchActivityStatus, get_activity_update }) => {
  // console.log(get_activity_update);
  return (
    <Container>
      <Property>Action:</Property>
      <Value>
        <pre>{JSON.stringify(data?.action, null, 2)}</pre>
      </Value>

      <Property>Result:</Property>
      <Value>
        <pre>{JSON.stringify(data?.result, null, 2)}</pre>
      </Value>

      <Property>Action Type:</Property>
      <Value>{data?.action_type}</Value>

      <Property>ID:</Property>
      <Value>{data?.id}</Value>

      {/* Render other properties here */}

      <Property>Status:</Property>
      <Value>{data?.status}</Value>

      <Property>Task ID:</Property>
      <Value>
        {data?.task_id}{" "}
        <button onClick={() => FetchActivityStatus(data?.task_id)}>
          Get current Status
        </button>
      </Value>

      {get_activity_update[data?.task_id]?.id && (
        <>
          <Property>Update:</Property>
          <Value>
            <pre>
              {JSON.stringify(get_activity_update[data?.task_id], null, 2)}
            </pre>
          </Value>
        </>
      )}

      <Property>Created At:</Property>
      <Value>{data?.created_at}</Value>
      <Property>Updated At:</Property>
      <Value>{data?.updated_at}</Value>
    </Container>
  );
};

export default ActionItem;

// Define styled components
const Container = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  background-color: #f9f9f9;
`;

const Property = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Value = styled.div`
  margin-bottom: 10px;
`;
