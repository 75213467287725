import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";
import { CustomMessages, CustomMessagesList } from "Messages/Errors/Message";
import {
    CLEAR_ERRORS,
} from "constants/actions";

export const BasicErrorMessage = () => {
    const dispatch = useDispatch();

    // Get cleaned errors from the Redux state
    const get_errors = useSelector(
        (state) => state.errors,
        shallowEqual
    );

    const [submitErrors, setSubmitErrors] = useState([]);

    useEffect(() => {
        // Check if we have any errors in the state
        if (get_errors?.fullError?.data) {
            const errorData = get_errors.fullError.data;

            // Process each error and map it to a message
            const errors = Object.values(errorData).map((message) => {
                if (CustomMessagesList.hasOwnProperty(message)) {
                    return { message, isError: CustomMessagesList[message].isError };
                } else {
                    return { message, isError: true }; // Default to error if message not found in CustomMessagesList
                }
            });

            setSubmitErrors(errors);
        }
    }, [get_errors]);

    const handleDismiss = () => {
        // Clear errors in Redux and reset local state
        dispatch({ type: CLEAR_ERRORS });
        setSubmitErrors([]);
    };

    if (submitErrors?.length > 0) {
        return (
            submitErrors.map(({ message, isError }, i) => (
                <Message onDismiss={handleDismiss} color={isError ? "red" : "blue"} key={i}>
                    <CustomMessages message={message} />
                </Message>
            ))
        );
    }

    return null;
};

export default BasicErrorMessage;
