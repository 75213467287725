import {
  UPDATE_SURVEY_AI_LOG_PAGE_NUMBER,
  GET_SURVEY_AI_LOG_PENDING,
  GET_SURVEY_AI_LOG,
  CLEAR_SURVEY_AI_LOG,
  RESET_SURVEY_AI_LOG,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  survey_ai_log: [],
  pending: false,
};

export default function Payment(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_AI_LOG_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SURVEY_AI_LOG:
      return {
        ...state,
        survey_ai_log: action.payload,
        pending: false,
      };
    case RESET_SURVEY_AI_LOG:
      return {
        ...state,
        survey_ai_log: [],
      };
    case CLEAR_SURVEY_AI_LOG:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
