import {
  UPDATE_DEBRIEF_PDF_REPORT_PAGE_NUMBER,
  UPDATE_DEBRIEF_PDF_REPORT_QUERY_PARAMS,
  GET_DEBRIEF_PDF_REPORT_PENDING,
  GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  GET_DEBRIEF_PDF_REPORT,
  FETCH_DEBRIEF_PDF_REPORT,
  // CREATE_DEBRIEF_PDF_REPORT,
  DELETE_DEBRIEF_PDF_REPORT,
  UPDATE_DEBRIEF_PDF_REPORT,
  CLEAR_DEBRIEF_PDF_REPORT,
  RESET_DEBRIEF_PDF_REPORT,
  RESET_DEBRIEF_PDF_REPORT_UPDATES,
  REGENERATE_DEBRIEF_PDF_REPORT,
  GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  debrief_pdf_report: [],
  templates: [],
  aws_s3_link: false,
  updated: false,
  pending: false,
  pageNumber: 1,
  queryParams: [],
};

export default function ReactPdfReport(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DEBRIEF_PDF_REPORT_PAGE_NUMBER:
      return {
        ...state,
        pending: false,
        pageNumber: action.payload
      };
    case UPDATE_DEBRIEF_PDF_REPORT_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload
      };
    case GET_DEBRIEF_PDF_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DEBRIEF_PDF_REPORT_TEMPLATES:
      return {
        ...state,
        pending: false,
        templates: action.payload,
      };
    case GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK:
      return {
        ...state,
        pending: false,
        aws_s3_link: { ...state.aws_s3_link, [action.payload.share_id]: action.payload },
      };
    case REGENERATE_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };
      
    case GET_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        pending: false,
        debrief_pdf_report: action.payload,
      };

    case FETCH_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        pending: false,
        [action.payload.id]: action.payload,
      };
    case UPDATE_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        updated: action.payload,
        [action.payload.id]: action.payload,
      };

    // case CREATE_DEBRIEF_PDF_REPORT:
    //   return {
    //     ...state,
    //     created: action.payload,
    //     debrief_pdf_report: [...state.debrief_pdf_report, action.payload],
    //   };

    case DELETE_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        pending: false,
        updated: action.payload,
      };

    case CLEAR_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        ...initialState,
      };
    case RESET_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        updated: false,
        aws_s3_link: false,
      };
    case RESET_DEBRIEF_PDF_REPORT_UPDATES:
      return {
        ...state,
        aws_s3_link: false,
        updated: false,
        pending: false,
        pageNumber: 1,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
