import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactJson from "react-json-view";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_DEBRIEF_PDF_REPORT,
  SG_UPDATE_DEBRIEF_PDF_REPORT,
  SG_DELETE_DEBRIEF_PDF_REPORT,
  RESET_DEBRIEF_PDF_REPORT,
  SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK,
  SG_REGENERATE_DEBRIEF_PDF_REPORT,
} from "constants/actions";
//
import { Icon, Label, Button, Header, List } from "semantic-ui-react";
//
import { format } from "date-fns";
//
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState(null);
  const [emailRef, setEmailRef] = useState({});
  const [downloadLink, setDownloadLink] = useState(false);

  function BackArrow() {
    navigate(`/app/pdf-reports`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_PDF_REPORT,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_debrief_pdf_report } = useSelector(
    (state) => ({
      get_debrief_pdf_report: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_debrief_pdf_report?.updated) {
      navigate(`/app/pdf-reports`);
    }
  }, [get_debrief_pdf_report, navigate]);

  useEffect(() => {
    setTemplateDetails(get_debrief_pdf_report?.[templateId]);
  }, [get_debrief_pdf_report, templateId]);

  useEffect(() => {
    setDownloadLink(get_debrief_pdf_report?.aws_s3_link);
  }, [get_debrief_pdf_report]);

  function RemoveFile(delete_share_id) {
    //  SG_UPDATE_DEBRIEF_PDF_REPORT

    if (templateId && delete_share_id) {
      dispatch({
        type: RESET_DEBRIEF_PDF_REPORT,
      });
      dispatch({
        type: SG_UPDATE_DEBRIEF_PDF_REPORT,
        payload: { id: templateId, delete_share_id: delete_share_id },
      });
    }
  }

  // SG_DELETE_DEBRIEF_PDF_REPORT
  function DeleteRow() {
    //  SG_DELETE_DEBRIEF_PDF_REPORT
    if (templateId) {
      dispatch({
        type: RESET_DEBRIEF_PDF_REPORT,
      });
      dispatch({
        type: SG_DELETE_DEBRIEF_PDF_REPORT,
        payload: { id: templateId },
      });
    }
  }

  // SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK
  function GenerateLink(share_id) {
    if (templateId) {
      dispatch({
        type: SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK,
        payload: { id: templateId, share_id: share_id },
      });
    }
  }

  function RegeneratePdfReport() {
    if (templateId) {
      dispatch({
        type: SG_REGENERATE_DEBRIEF_PDF_REPORT,
        payload: { id: templateId },
      });
    }
  }

  function ViewFile(share_id) {
    if (share_id) {
      window.open(downloadLink[share_id]?.url, "_blank");
    }
  }

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Details of {templateDetails?.organization} #{" "}
        {templateDetails?.organization_name}
      </Header>
      <SingleRowContent>
        <span>
          Created By Employee {templateDetails?.employee} #{" "}
          {templateDetails?.employee_name} - {templateDetails?.employee_email}
        </span>
      </SingleRowContent>
      <SectionDiv>
        <Container>
          <List horizontal>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Status</List.Header>
                  {templateDetails?.status} # {templateDetails?.status_text}
                  <Button
                    color="orange"
                    size="tiny"
                    onClick={RegeneratePdfReport}
                  >
                    Re Generate
                  </Button>
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Tracking Code</List.Header>
                  {templateDetails?.tracking_code}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Template Id</List.Header>
                  {templateDetails?.template_id}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Survey Id</List.Header>
                  {templateDetails?.debrief_schedule
                    ? templateDetails?.debrief_schedule
                    : "N/A"}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Created</List.Header>
                  {templateDetails?.created_at &&
                    format(
                      new Date(templateDetails?.created_at),
                      "dd-MMM-yyyy hh:mm a"
                    )}
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Last Updated</List.Header>
                  {templateDetails?.updated_at &&
                    format(
                      new Date(templateDetails?.updated_at),
                      "dd-MMM-yyyy hh:mm a"
                    )}
                </ListContainer>
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </SectionDiv>
      <SectionDiv>
        <ReactJson src={templateDetails?.params} />
      </SectionDiv>
      <SectionDiv lastSection={true}>
        <Button floated="right" color="red" onClick={DeleteRow}>
          Delete Reports
        </Button>
      </SectionDiv>
      <SectionDiv>
        <DownloadFiles
          rows={templateDetails?.download_files}
          sharedWith={templateDetails?.share_report}
          RemoveFile={RemoveFile}
          GenerateLink={GenerateLink}
          ViewFile={ViewFile}
          downloadLink={downloadLink}
          additionalInfo={templateDetails?.additional_info}
        />
      </SectionDiv>
    </>
  );
};

export default Details;

function DownloadFiles({
  rows,
  sharedWith,
  RemoveFile,
  GenerateLink,
  ViewFile,
  downloadLink,
  additionalInfo,
}) {
  if (!rows) {
    return (
      <SectionDiv>
        <p>No files to download</p>
      </SectionDiv>
    );
  }

  return rows.map((row, index) => {
    return (
      <SectionDiv key={index} bordered>
        <SingleRowItem>
          <HeaderDiv>{row?.file_name}</HeaderDiv>
          <SingleRowContent>
            {downloadLink[row?.share_id] ? (
              <Button color="green" onClick={() => ViewFile(row?.share_id)}>
                View
              </Button>
            ) : (
              row?.file_name && (
                <Button
                  color="blue"
                  onClick={() => GenerateLink(row?.share_id)}
                >
                  Download
                </Button>
              )
            )}
          </SingleRowContent>
        </SingleRowItem>

        <SingleRowItem>
          <SectionRow>Share Id</SectionRow>
          <SectionRow>{row?.share_id}</SectionRow>
        </SingleRowItem>
        <SingleRowItem>
          <SectionRow>Parent</SectionRow>
          <SectionRow>{row?.parent}</SectionRow>
        </SingleRowItem>
        <SingleRowItem>
          <SectionRow>Primary</SectionRow>
          <SectionRow>{JSON.stringify(row?.primary)}</SectionRow>
        </SingleRowItem>
        <SingleRowItem>
          <SectionRow>Category</SectionRow>
          <SectionRow>{row?.category}</SectionRow>
        </SingleRowItem>
        <SingleRowItem>
          <SectionRow>File Location</SectionRow>
          <SectionRow>{row?.file_location}</SectionRow>
        </SingleRowItem>
        <SingleRowItem>
          <SectionRow>File Name</SectionRow>
          <SectionRow>{row?.file_name}</SectionRow>
        </SingleRowItem>
        <SingleRowItem>
          <SectionRow>Shared With</SectionRow>
          <SectionRow>{JSON.stringify(sharedWith?.[row?.share_id])}</SectionRow>
        </SingleRowItem>
        <SectionDiv>
          {row?.file_name && (
            <Button
              floated="right"
              color="blue"
              onClick={() => RemoveFile(row?.share_id)}
            >
              Remove this file
            </Button>
          )}
        </SectionDiv>
        <SectionDiv>
          <ReactJson
            src={additionalInfo.filter((ai) => ai.share_id === row?.share_id)}
          />
        </SectionDiv>
      </SectionDiv>
    );
  });
}

const SectionDiv = styled.div`
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
  border: ${(props) =>
    props.bordered === true ? "1px solid #dcdcdc;" : "none"};
  padding: ${(props) => (props.bordered === true ? "20px;" : " 10px 0px;")};
`;

const Container = styled.div`
  display: flex;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const HeaderDiv = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px; /* Padding for header */
`;

const SingleRowContent = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SingleRowItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const SectionRow = styled.div`
  align-self: flex-start; /* Align left */
  margin-right: 10px;
  min-width: 150px;
`;

const SectionCell = styled.div`
  min-width: 350px;
`;
