import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Icon,
  Button,
  Header,
  Image,
  Card,
  Message,
  Modal,
} from "semantic-ui-react";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import Select from "react-select";
//
import LogoUpload from "./LogoUpload";
//
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  PULL_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SG_GET_SURVEY_STRUCTURES,
  UPDATE_EMPLOYEE_PAGE_NUMBER,
  SG_GET_SURVEY_QUESTION_SELECT,
  SG_GET_ALL_SURVEY_QUESTION_SELECT,
  SG_GET_ORGANIZATION_STYLE,
} from "constants/actions";
//
import {
  RowEditList,
  RowObjectInputEdit,
  RulesSubList,
  SurveySequenceList,
  OrgCategoryObjectInputEdit,
} from "./Components";
//
import AccountList from "account/List";
import EmployeeList from "employees/List";
import ManagerList from "employees/Managers";
import CategoryRowCard from "categories/List/RowCard";
//
import UpdateOrg from "./UpdateOrg";
//
import { SUB_DOMAIN_NAMES, ORG_PAID_STATUS } from "data/AdminData";
import { CommonTimezones } from "data/timezone";
import OnBoarding from "./onboarding/index";
import Questions from "./onboarding/Questions";
import CategoryQuestions from "./CategoryQuestions";
//
const Details = ({ setShowHeaderTxt }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [openOrgEdit, setOpenOrgEdit] = React.useState(false);

  const [organizationId, setOrganizationId] = useState(null);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [organizationStylingSelect, setOrganizationStylingSelect] = useState(
    []
  );
  const [orgStyleSelected, setOrgStyleSelected] = useState([]);
  const [domainSelected, setDomainSelected] = useState(null);
  const [paymentStatusSelected, setPaymentStatusSelected] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [updateValue, setUpdateValue] = useState(false);
  const [stylingList, setStylingList] = useState([]);

  const [orgIndustryCategory, setOrgIndustryCategory] = useState([]);
  const [updateIndustryValue, setUpdateIndustryValue] = useState(false);

  const [stylingMsg, setStylingMsg] = useState(false);
  const [timeZone, setTimeZone] = useState({
    value: "America/Halifax",
    label: "America/Halifax",
    text: "America/Halifax",
  });

  const [results, setResults] = useState([]);

  function BackArrow() {
    navigate("/app/organizations");
  }

  useEffect(() => {
    setOrganizationId(id);
  }, [id]);

  useEffect(() => {
    setShowHeaderTxt(false);
  }, [setShowHeaderTxt]);

  const utcDateTime = utcToZonedTime(new Date(), "UTC");
  const [localDateTime, setLocalDateTime] = useState(
    zonedTimeToUtc(new Date(), "America/Halifax")
  );

  useEffect(() => {
    if (organizationDetails?.time_zone) {
      const date = new Date();
      const timeZone = organizationDetails?.time_zone;
      const zonedDate = utcToZonedTime(date, timeZone);
      // const pattern = 'dd.MMM.yyyy HH:mm XXX (z)'
      // const output = format(zonedDate, pattern)
      setLocalDateTime(zonedDate);
    }
  }, [organizationDetails]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organizationId },
      });
      dispatch({
        type: SG_GET_SURVEY_QUESTION_SELECT,
      });
      dispatch({
        type: SG_GET_ALL_SURVEY_QUESTION_SELECT,
      });
      dispatch({
        type: UPDATE_EMPLOYEE_PAGE_NUMBER,
        payload: 1,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_ORGANIZATION_STYLE,
      });
    }
  }, [dispatch, organizationId, stylingMsg]);

  const {
    get_organizations,
    get_survey_question_select,
    get_survey_question_all_select,
    get_survey_structure,
  } = useSelector(
    (state) => ({
      get_organizations: state.organizations,
      get_survey_question_select: state.surveyquestion.survey_question_select,
      get_survey_question_all_select:
        state.surveyquestion.survey_question_all_select,
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationDetails(get_organizations?.[organizationId]);
    setServiceList(get_organizations?.[organizationId]?.services_enabled);
    setStylingList(get_organizations?.[organizationId]?.styling);
    setOrganizationStylingSelect(get_organizations?.organization_style);
    setOrgIndustryCategory(get_organizations?.[organizationId]?.org_category);
  }, [get_organizations, organizationId]);

  useEffect(() => {
    setResults(get_survey_structure?.results);
  }, [get_survey_structure]);

  function UpdateDetails() {
    setUpdateValue(false);
    if (organizationId) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          id: organizationId,
          services_enabled: serviceList,
          styling: stylingList,
        },
      });
    }
  }

  function UpdateIndustryCategoryValues() {
    if (organizationId) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          id: organizationId,
          org_category: orgIndustryCategory,
        },
      });
      setStylingMsg({
        header: "Category Changed",
        content: JSON.stringify(orgIndustryCategory),
      });
    }
  }

  useEffect(() => {
    setDomainSelected(
      SUB_DOMAIN_NAMES.filter(
        (sd) => sd.value === organizationDetails?.sub_domain
      )?.[0]
    );
    setPaymentStatusSelected(
      ORG_PAID_STATUS.filter(
        (sd) => sd.value === organizationDetails?.payment_status
      )?.[0]
    );
    setTimeZone(
      CommonTimezones.filter(
        (sd) => sd.value === organizationDetails?.time_zone
      )?.[0]
    );
  }, [organizationDetails]);

  function UpdateDomain() {
    if (organizationId) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          id: organizationId,
          sub_domain: domainSelected?.value,
          payment_status: paymentStatusSelected?.value,
          time_zone: timeZone.value,
        },
      });
    }
  }

  function ReplaceStyling(e) {
    // console.log(e?.id)
    // console.log(e?.styling)
    setStylingMsg(false);
    setStylingList(false);
    setOrgStyleSelected(e);
    if (e?.styling) {
      setStylingList(e?.styling);
    } else {
      setStylingList(false);
    }
  }

  function UpdateCurrentStyling() {
    if (organizationId) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          id: organizationId,
          styling: stylingList,
        },
      });
      setStylingMsg({
        header: "Styling Changed",
        content: JSON.stringify(stylingList),
      });
    }
  }

  function DeleteRules() {
    let _newList = stylingList;
    delete _newList["rules"];

    if (organizationId) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          id: organizationId,
          styling: stylingList,
        },
      });
      setStylingMsg({
        header: "Rules Deleted",
        content: "Existing rules have been deleted",
      });
    }
  }

  function EditOrgName() {
    if (organizationDetails?.id) {
      setOpenOrgEdit(true);
    }
  }

  return (
    <>
      <SemiImportant>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Details of {organizationDetails?.name}{" "}
        <Icon
          style={{ cursor: "pointer" }}
          onClick={EditOrgName}
          name="external alternate"
        />
        <Image floated="right" size="mini" src={organizationDetails?.logo} />
      </SemiImportant>

      <OnBoarding organizationId={organizationId} />

      <SectionDiv>
        <Container>
          <SingleRowContent>
            <SectionCell>
              <p>
                Created{" "}
                {organizationDetails?.created_at &&
                  format(
                    new Date(organizationDetails?.created_at),
                    "dd-MMM-yyyy"
                  )}
              </p>
              <p>Domain {organizationDetails?.sub_domain_text}</p>
            </SectionCell>
            <SectionCell>
              <p>UTC Time {format(utcDateTime, "dd-MMM-yyyy hh:mm")}</p>
              <p>
                Local Time{" "}
                {localDateTime &&
                  format(localDateTime, "dd-MMM-yyyy hh:mm XXX (z)")}
              </p>
            </SectionCell>
            <SectionCell>
              User Timezone {organizationDetails?.time_zone}
            </SectionCell>
          </SingleRowContent>
        </Container>
      </SectionDiv>
      <SingleRowContent>
        <SectionCellDropDown>
          <label>Paid Status</label>
          <Select
            name="payment_status"
            options={ORG_PAID_STATUS}
            isClearable
            onChange={setPaymentStatusSelected}
            value={paymentStatusSelected}
          />
        </SectionCellDropDown>
        <SectionCellDropDown>
          <label>Set Domain</label>
          <Select
            name="domain"
            options={SUB_DOMAIN_NAMES}
            isClearable
            onChange={setDomainSelected}
            value={domainSelected}
          />
        </SectionCellDropDown>
        <SectionCellDropDown>
          <label>Set Time zone</label>
          <Select
            name="timezone"
            options={CommonTimezones}
            isClearable
            onChange={setTimeZone}
            value={timeZone}
          />
        </SectionCellDropDown>
        <SectionCellDropDown>
          <Button style={{ marginTop: "20px" }} onClick={UpdateDomain}>
            Update
          </Button>
        </SectionCellDropDown>
      </SingleRowContent>
      <SectionDiv>
        <Header as="h3">Services Enabled</Header>
        <Header.Subheader>
          For custom questions to appear in drop down list it needs to have sort
          order &gt; 100
        </Header.Subheader>
        <SectionDiv>
          <RowEditList
            serviceList={serviceList}
            setServiceList={setServiceList}
            setUpdateValue={setUpdateValue}
            get_survey_question_select={get_survey_question_select}
          />
          <Button
            color="violet"
            onClick={UpdateDetails}
            disabled={!updateValue}
          >
            Update
          </Button>
        </SectionDiv>
      </SectionDiv>
      {/* Survey Styling Start */}
      {stylingMsg && (
        <Message
          positive
          onDismiss={() => setStylingMsg(false)}
          header={stylingMsg.header}
          content={stylingMsg.content}
        />
      )}
      <SectionDiv>
        <Header as="h3">Styling</Header>
        <Header.Subheader>Styling for Organization</Header.Subheader>
        <SingleRowContent>
          <SectionCell>
            <label>Select An Existing Styling</label>
            <Select
              name="OrganizationStyling"
              options={organizationStylingSelect}
              isClearable
              onChange={ReplaceStyling}
              value={orgStyleSelected}
              getOptionLabel={(answers) => answers.name}
              getOptionValue={(answers) => answers.id}
            />
          </SectionCell>
          <SectionCell>
            <Button
              disabled={
                orgStyleSelected
                  ? !Object.values(orgStyleSelected).length > 0
                  : true
              }
              style={{ marginTop: "20px" }}
              onClick={UpdateCurrentStyling}
            >
              Replace Styling
            </Button>
          </SectionCell>
        </SingleRowContent>

        <SectionDiv>
          <RowObjectInputEdit
            stylingList={stylingList}
            setStylingList={setStylingList}
            setUpdateValue={setUpdateValue}
          />
          <Button
            color="violet"
            onClick={UpdateDetails}
            disabled={!updateValue}
          >
            Update
          </Button>
        </SectionDiv>
      </SectionDiv>
      {/* Survey Styling End */}
      {/* Survey Sequence Start */}
      {stylingMsg && (
        <Message
          positive
          onDismiss={() => setStylingMsg(false)}
          header={stylingMsg.header}
          content={stylingMsg.content}
        />
      )}
      {/* Survey Sequence */}
      <SectionDiv>
        <Header as="h3">Survey Sequence</Header>
        <Header.Subheader>
          Set the Survey components and its Sequence, if this info is missing it
          would set default sequence. It would be Category question and Audit
          Question.
        </Header.Subheader>

        <SectionDiv>
          <SurveySequenceList
            stylingList={stylingList}
            setStylingList={setStylingList}
            setUpdateValue={setUpdateValue}
            get_survey_question_all_select={get_survey_question_all_select}
          />
          {stylingList?.survey_sequence && (
            <Button
              color="violet"
              onClick={UpdateDetails}
              disabled={!updateValue}
            >
              Update
            </Button>
          )}
        </SectionDiv>
      </SectionDiv>
      {/* Survey Sequence end */}

      <SectionDiv>
        <Header as="h3">
          Rules for Survey
          {stylingList?.rules && (
            <Icon
              style={{
                cursor: "pointer",
                marginTop: "5px",
              }}
              onClick={DeleteRules}
              name="trash alternate"
            />
          )}
        </Header>
        <Header.Subheader>
          Ask additional questions based on low or high score
        </Header.Subheader>

        <SectionDiv>
          <RulesSubList
            stylingList={stylingList}
            setStylingList={setStylingList}
            setUpdateValue={setUpdateValue}
          />
          {stylingList?.rules && (
            <Button
              color="violet"
              onClick={UpdateDetails}
              disabled={!updateValue}
            >
              Update
            </Button>
          )}
        </SectionDiv>
      </SectionDiv>

      <SectionDiv>
        <Header as="h3">Organization Category</Header>
        <Header.Subheader>
          Organization Category to classify information based on the business
          category
        </Header.Subheader>
        <SectionDiv>
          <OrgCategoryObjectInputEdit
            stylingList={orgIndustryCategory}
            setStylingList={setOrgIndustryCategory}
            setUpdateValue={setUpdateIndustryValue}
          />
          <Button
            color="violet"
            onClick={UpdateIndustryCategoryValues}
            disabled={!updateIndustryValue}
          >
            Update
          </Button>
        </SectionDiv>

        <SectionDiv showBorder>
          <HeaderText>Survey Questions</HeaderText>
          <Header.Subheader>
            List of questions for any given survey
          </Header.Subheader>
          <CategoryQuestions />
          <Questions organizationId={organizationId} />
        </SectionDiv>
      </SectionDiv>
      <SectionDiv showBorder>
        <HeaderText>Survey Structure (Categories)</HeaderText>
        <Header.Subheader>Your Categories would appear below</Header.Subheader>

        <Card.Group>
          {results &&
            results.map((result) => (
              <CategoryRowCard row={result} key={result?.id} />
            ))}
        </Card.Group>
      </SectionDiv>

      <SectionDiv>
        <LogoUpload organization={organizationDetails} />
      </SectionDiv>

      <SectionDiv showBorder>
        <HeaderText>Survey Scheduled</HeaderText>
        <Header.Subheader>
          Your Survey's Scheduled would appear below
        </Header.Subheader>
      </SectionDiv>

      <SectionDiv showBorder>
        <HeaderText>Login Accounts</HeaderText>

        <AccountList orgId={organizationId} />
      </SectionDiv>

      <SectionDiv showBorder>
        <HeaderText>Managers</HeaderText>

        <ManagerList hideOrgSelect={true} pageNo={1} orgId={organizationId} />
      </SectionDiv>

      <SectionDiv showBorder>
        <HeaderText>Employees</HeaderText>
        {/* Employee Summary */}

        <EmployeeList hideOrgSelect={true} pageNo={1} orgId={organizationId} />
      </SectionDiv>
      {/* <SectionDiv lastRow showBorder>
                <Header as='h3'>Upload Employees</Header>
                <Header.Subheader>
                    Upload Users with a CSV file
                </Header.Subheader>
                <Button floated='right'
                    onClick={() => navigate(`/app/user-uploads`)}>
                    Upload Users
                </Button>
            </SectionDiv> */}

      <Modal closeIcon onClose={() => setOpenOrgEdit(false)} open={openOrgEdit}>
        <Modal.Header>Update Org Details</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <UpdateOrg
              organizationDetails={organizationDetails}
              setOpenOrgEdit={setOpenOrgEdit}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  min-height: 110px;
  margin-bottom: ${(props) => (props.lastRow ? "100px" : "0px")};
  ${(props) => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${(props) => props.showBorder && "padding: 15px"};
`;

const Container = styled.div`
  display: flex;
`;

const SemiImportant = styled.p`
  background-color: #ff9e2c;
  padding: 1rem;
  font-size: x-large;
`;

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`;

const HeaderText = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
`;
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SectionCellDropDown = styled.div`
  padding: 30px 15px;
  min-width: 350px;
  z-index: 900;
`;

const SectionCell = styled.div`
  padding: 30px 15px;
  min-width: 350px;
`;

const ImageBox = styled.image`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
`;
