import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_SURVEY_STANDARDS,
  UPDATE_SURVEY_STANDARD_PAGE_NUMBER,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Button,
} from "semantic-ui-react";

const List = ({ reload }) => {
  // employee_record_value
  const dispatch = useDispatch();
  let navigate = useNavigate();

  //   const [resetPageLoad, setResetPageLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_SURVEY_STANDARDS,
      payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm, reload]);

  const { get_survey_standard } = useSelector(
    (state) => ({
      get_survey_standard: state.survey_standard,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_survey_standard?.survey_standard?.results);
    setPage(get_survey_standard?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_survey_standard?.survey_standard?.count /
          get_survey_standard?.survey_standard?.page_size
      )
    );
  }, [get_survey_standard, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_SURVEY_STANDARD_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    if (get_survey_standard?.pending === true) {
      setLoading(get_survey_standard?.pending);
    } else {
      const timer1 = setTimeout(() => {
        setLoading(get_survey_standard?.pending);
      }, 2000);
      return () => clearTimeout(timer1);
    }
  }, [get_survey_standard.pending]);

  return (
    <Container>
      <SectionDiv lastSection={true}>
      <Button
          floated="left"
          color="violet"
          onClick={() => {
            navigate("/app/survey-standard/app-survey-standards");
          }}
        >
          View App Survey Standard
        </Button>

        <Button
          floated="right"
          color="green"
          onClick={() => {
            navigate("/app/survey-standard/create");
          }}
        >
          Create
        </Button>
      </SectionDiv>
      <SectionDiv>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </SectionDiv>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages || 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
