import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import AddEdit from './AddEdit';

function ProductContract() {
  return (
    <>
  
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="edit/:id" element={<AddEdit />} />
      </Routes>
    </>
  );
}

export default ProductContract;
