import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactJson from "react-json-view";

const RowCardValue = ({ data }) => {
  const [requestBody, setRequestBody] = useState({});
  const [responseBody, setResponseBody] = useState({});
  const [responseBodyError, setResponseBodyError] = useState("");
  const [requestBodyError, setRequestBodyError] = useState("");

// Helper function to clean the JSON strings
const cleanJsonString = (str) => {
    return str
      .replace(/'/g, '"') // Replace single quotes with double quotes
      .replace(/None/g, '') // Replace Python None with JSON null
      .replace(/UUID\((.*?)\)/g, '"$1"') // Replace UUID with string
      .replace(/True/g, 'true') // Replace Python True with JSON true
      .replace(/False/g, 'false') // Replace Python False with JSON false
      .replace(/,\s*}/g, '}') // Remove trailing commas before closing braces
      .replace(/,\s*]/g, ']'); // Remove trailing commas before closing brackets
  };

  useEffect(() => {
    try {
      if (data["Request Body"]) {
        const parsedRequestBody = JSON.parse(
          data["Request Body"].replace(/'/g, '"')
        );
        setRequestBody(parsedRequestBody);
      }
    } catch (e) {
      //   console.error("Error parsing Request Body:", e);
      setRequestBodyError({ "Error parsing Request Body:": data["Request Body"] });
    }

    try {
      if (data["Response Body"]) {
        const cleanedResponseBody = cleanJsonString(data["Response Body"]);
        // console.log("cleanedResponseBody", cleanedResponseBody);
        const parsedResponseBody = JSON.parse(cleanedResponseBody);
        setResponseBody(parsedResponseBody);
      }
    } catch (e) {
    //   console.error("Error parsing Response Body:", e);
      setResponseBodyError({ "Error parsing Response Body:": JSON.stringify(data["Response Body"]) });
    }
  }, [data]);

  return (
    <Container>
      <FullWidthSection>
        <Header>Request Body</Header>
        <ReactJson src={requestBody} />
        {requestBodyError && JSON.stringify(data["Response Body"])}
      </FullWidthSection>

      <FullWidthSection>
        <Header>Response Body</Header>
        <ReactJson src={responseBody} />
        {responseBodyError && JSON.stringify(data["Response Body"])}
      </FullWidthSection>

      <RowContainer>
      
      <SectionDiv>
          <Header>On Date</Header>
          <p>{data["On Date"]}</p>
        </SectionDiv>

        <SectionDiv>
          <Header>Process Time</Header>
          <p>{data["Process Time"]}</p>
        </SectionDiv>

        <SectionDiv>
          <Header>Environment</Header>
          <p>{data["Environment"]}</p>
        </SectionDiv>
      </RowContainer>
    </Container>
  );
};

export default RowCardValue;

const Container = styled.div`
  margin: 10px 0;
`;

const FullWidthSection = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px 0;
`;

const RowContainer = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
`;

const SectionDiv = styled.div`
  flex: 1;
  padding: 15px;
  border-right: 1px solid #ccc;

  &:last-child {
    border-right: none;
  }
`;

const Header = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
`;
