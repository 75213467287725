import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Pagination } from "semantic-ui-react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { format } from 'date-fns'
import {
  UPDATE_PREDICTIVE_MODEL_PAGE_NUMBER,
  SG_GET_PREDICTIVE_MODEL,
  RESET_PREDICTIVE_MODEL,
} from "constants/actions";

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1);

  const { get_predictive_model } = useSelector(
    (state) => ({
      get_predictive_model: state.predictive_model.predictive_model,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: RESET_PREDICTIVE_MODEL,
    });
    dispatch({
      type: SG_GET_PREDICTIVE_MODEL,
    });
  }, [dispatch]);

  useEffect(() => {
    setResults(get_predictive_model?.results);
    setPage(get_predictive_model?.pageNumber);
    setTotalPages(Math.ceil(get_predictive_model?.count / get_predictive_model?.page_size));
    if (get_predictive_model?.count % get_predictive_model?.page_size) {
      setTotalPages(Math.ceil(get_predictive_model?.count / get_predictive_model?.page_size));
    }
  }, [get_predictive_model, page]);

  function handlePageChange(e, { activePage }) {
    setPage(activePage);
    dispatch({
      type: UPDATE_PREDICTIVE_MODEL_PAGE_NUMBER,
      payload: activePage,
    });
    dispatch({
      type: SG_GET_PREDICTIVE_MODEL,
      payload: `page=${activePage}`
    });
  }

  function EditTemplate(templateId) {
    navigate(`/app/predictive-model/edit/${templateId}`);
  }

  function TemplateDetails(templateId) {
    navigate(`/app/predictive-model/details/${templateId}`);
  }

  return (
    <>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th># ID</th>
              <th>Organization</th>
              <th>Track Id</th>
              <th>Status</th>
              <th>Updated</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {results &&
              results.map((product, index) => (
                <tr key={index}>
                  <td>{product.id}</td>
                  <td>{product?.organization} - {product?.organization_name}</td>
                  <td>{product?.token} </td>
                  <td>{product.status_text}</td>
                  <td>{product?.updated_at && format(new Date(product?.updated_at), 'dd-MMM-yyyy HH:mm a')}
                  </td>
                  {/* <td>
                    <Clickable onClick={() => EditTemplate(product?.id)}>
                      Edit{" "}
                    </Clickable>{" "}
                    |
                    <Clickable onClick={() => TemplateDetails(product.id)}>
                      Details{" "}
                    </Clickable>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      </TableContainer>

      <Pagination
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        activePage={page}
        totalPages={totalPages ? totalPages : 0}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default List;

const TableContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }
`;

const Clickable = styled.span`
  color: blue;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;
