import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  // SG_GET_APP_SERVERS
  SG_FETCH_APP_SERVER,
  SG_EDIT_APP_SERVER,
  SG_ADD_APP_SERVER,
  SG_DELETE_APP_SERVER,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
  Form,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import DataForm from "./DataForm";
// import { BasicErrorMessage } from "utilities/ErrorMessages";
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({});

  const [valueNames, setValueNames] = useState([]);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_APP_SERVER,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_app_server } = useSelector(
    (state) => ({
      get_app_server: state.app_server,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_app_server?.[templateId]) {
      setTemplateDetails(get_app_server?.[templateId]);
    }
  }, [get_app_server, templateId]);

  useEffect(() => {
    if (get_app_server?.updated?.id) {
      navigate(`/app/app-server/details/${get_app_server?.updated?.id}`);
    }
  }, [get_app_server, navigate]);

  function BackArrow() {
    navigate(`/app/app-server`);
  }

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {templateId
          ? `Edit Details of ${templateDetails?.name}`
          : "Add New Application Server"}
      </Header>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <SectionDiv>
        <DataForm
          templateDetails={templateDetails}
          setTemplateDetails={setTemplateDetails}
          templateId={templateId}
        />
      </SectionDiv>
      <SectionDiv></SectionDiv>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
