import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  RESET_PRODUCT_PACKAGE,
  SG_FETCH_PRODUCT_PACKAGE,
  SG_EDIT_PRODUCT_PACKAGE,
  SG_ADD_PRODUCT_PACKAGE,
  SG_DELETE_PRODUCT_PACKAGE,
} from "constants/actions";
import {
  Icon,
  Message,
  Button,
  Header,
  Input,
  Checkbox,
  TextArea,
  Confirm,
} from "semantic-ui-react";
import { BasicErrorMessage } from "utilities/ErrorMessages";
import ProductList from "./ProductList";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: "",
    active: true,
    sort_order: 0,
    description: "",
  });

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: RESET_PRODUCT_PACKAGE,
      });
      dispatch({
        type: SG_FETCH_PRODUCT_PACKAGE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_product_package } = useSelector(
    (state) => ({
      get_product_package: state.product_package,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_product_package?.[templateId]) {
      setTemplateDetails(get_product_package?.[templateId]);
    }
  }, [get_product_package, templateId]);

  useEffect(() => {
    if (get_product_package?.created?.id) {
      navigate(`/app/product/package/edit/${get_product_package?.created?.id}`);
    }
    if (get_product_package?.updated?.id) {
      navigate(
        `/app/product/package/details/${get_product_package?.updated?.id}`
      );
    }
  }, [get_product_package, navigate]);

  useEffect(() => {
    const ErrorList = [];
    if (!templateDetails?.name) {
      ErrorList.push("Enter a name");
    }
    setFormErrors(ErrorList);
  }, [templateDetails]);

  const handleChange = (e, { name, value }) => {
    setTemplateDetails({ ...templateDetails, [name]: value });
  };

  const handleCheckboxChange = (e, { name, checked }) => {
    setTemplateDetails({ ...templateDetails, [name]: checked });
  };

  const handleSubmit = () => {
    setPristine(false);
    if (formErrors.length === 0) {
      const payload = { ...templateDetails };
      if (templateId) {
        dispatch({
          type: SG_EDIT_PRODUCT_PACKAGE,
          payload: { id: templateId, ...payload },
        });
      } else {
        dispatch({
          type: SG_ADD_PRODUCT_PACKAGE,
          payload,
        });
      }
    }
  };

  const handleDelete = () => {
    if (templateId) {
      dispatch({
        type: SG_DELETE_PRODUCT_PACKAGE,
        payload: { id: templateId },
      });
    }
  };

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/app/product`)}
          name="arrow left"
        />
        {templateId
          ? `Edit Details of ${templateDetails?.name}`
          : "Add New Product Package"}
      </Header>

      <BasicErrorMessage />
      {formErrors.length > 0 && !pristine && (
        <Message negative header="We need these details!" list={formErrors} />
      )}

      <FormColumn>
        <FormSection>
          <LabelHead>Name</LabelHead>
          <StyledInput
            name="name"
            value={templateDetails?.name}
            onChange={handleChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Active</LabelHead>
          <StyledCheckbox
            name="active"
            checked={templateDetails?.active}
            onChange={handleCheckboxChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Sort Order</LabelHead>
          <StyledInput
            name="sort_order"
            type="number"
            value={templateDetails?.sort_order}
            onChange={handleChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Description</LabelHead>
          <StyledTextArea
            name="description"
            value={templateDetails?.description}
            onChange={handleChange}
          />
        </FormSection>
      </FormColumn>

      <SectionDiv lastSection={true}>
        {templateId && (
          <>
            <Button
              floated="left"
              color="red"
              onClick={() => setConfirmOpen(true)}
            >
              Delete Product Package
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={handleDelete}
            />
          </>
        )}

        <Button floated="right" color="green" onClick={handleSubmit}>
          {templateId ? "Update Product Package" : "Product Package - Step 1"}
        </Button>
      </SectionDiv>

      {templateId && (
        <SectionDiv>
          <ProductList
            templateId={templateId}
            templateDetails={templateDetails}
          />
        </SectionDiv>
      )}
    </>
  );
};

export default Details;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px; /* Add some padding for spacing */
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%; /* Full width */
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const StyledInput = styled(Input)`
  width: 100%; /* Ensure the input takes the full width */
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 0; /* Adjust as needed */
`;

const StyledTextArea = styled(TextArea)`
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  min-height: 100px;
  resize: vertical;
  &:focus,
  &:hover {
    border-color: blue;
  }
`;

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SectionDiv = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;
