import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_APP_SURVEY_STANDARDS,
  SG_CLEAR_SERVER_CACHE,
  CLEAR_SERVER_CACHE_MSG,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Segment,
  Button,
  Message,
} from "semantic-ui-react";

const List = ({ reload }) => {
  // employee_record_value
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clearCache, setClearCache] = useState(false);

  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_APP_SURVEY_STANDARDS,
    });

    setLoading(true);
  }, [dispatch]);

  const { get_survey_standard } = useSelector(
    (state) => ({
      get_survey_standard: state.survey_standard,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_survey_standard?.portal_survey_standard);
  }, [get_survey_standard]);

  useEffect(() => {
    setClearCache(get_survey_standard.cache_status);
  }, [get_survey_standard]);

  function resetCache() {
    dispatch({
      type: SG_CLEAR_SERVER_CACHE
    });
    setLoading(true);
  }

  function resetCacheMessage() {
    dispatch({
      type: CLEAR_SERVER_CACHE_MSG,
    });
  }

  console.log("get_survey_standard", get_survey_standard);
  console.log("clearCache", clearCache?.message);
  useEffect(() => {
    if (get_survey_standard?.pending === true) {
      setLoading(get_survey_standard?.pending);
    } else {
      const timer1 = setTimeout(() => {
        setLoading(get_survey_standard?.pending);
      }, 2000);
      return () => clearTimeout(timer1);
    }
  }, [get_survey_standard.pending]);

  return (
    <Container>
      <SectionDiv lastSection={true}>
        <Button
          floated="left"
          color="violet"
          onClick={() => {
            navigate("/app/survey-standard/");
          }}
        >
          Back
        </Button>

        <Button
          floated="right"
          color="green"
          onClick={() => {
            resetCache();
          }}
        >
          Reset Cache
        </Button>
      </SectionDiv>

      {clearCache?.message && <SectionDiv>
        <Message
          onDismiss={resetCacheMessage}
          header="Updated!"
          content={clearCache?.message}
        />
      </SectionDiv>}

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
