import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_AI_LOG } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";

const List = ({ hideOrgSelect }) => {
  const dispatch = useDispatch();

  const [results, setResults] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_AI_LOG,
    });
  }, [dispatch]);

  const { get_survey_ai_log } = useSelector(
    (state) => ({
        get_survey_ai_log: state.survey_ai_log,
      // web_api_cred_list
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_survey_ai_log?.survey_ai_log?.response?.logs) {
        setResults(get_survey_ai_log.survey_ai_log.response.logs.sort((a, b) => {
            const dateA = a["On Date"] ? new Date(a["On Date"]) : null;
            const dateB = b["On Date"] ? new Date(b["On Date"]) : null;

            if (dateA && dateB) {
                return dateB - dateA;  // Sort by date if both dates exist
            } else if (dateA) {
                return -1;  // a comes before b if only a has a date
            } else if (dateB) {
                return 1;   // b comes before a if only b has a date
            } else {
                return 0;   // If neither have dates, maintain their order
            }
        }));
    }
    setLoading(get_survey_ai_log?.pending);
}, [get_survey_ai_log]);


  return (
    <Container>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
         {results.map((row, i) => <RowCard data={row} key={i}  />)}
        </Card.Group>
      </Segment>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
