import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_APP_LIST_URLS, 
    UPDATE_APP_LIST_URL_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

const List = () => {
    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_APP_LIST_URLS,
            payload: `page=${page}${searchTerm?`&search=${searchTerm}` : ''}`,
        });
        setLoading(true)
    }, [dispatch, page, searchTerm]);

    const { 
        get_app_url_list } = useSelector(
        (state) => ({
            get_app_url_list: state.applisturl,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_app_url_list?.app_url_list?.results);
        setPage(get_app_url_list?.pageNumber)
        setTotalPages(Math.ceil(get_app_url_list?.app_url_list?.count / get_app_url_list?.app_url_list?.page_size)) 
        if (get_app_url_list?.app_url_list?.count % get_app_url_list?.app_url_list?.page_size) {
            setTotalPages(Math.ceil(get_app_url_list?.app_url_list?.count / get_app_url_list?.employees?.page_size))   
        }
        
    }, [get_app_url_list, page]);

    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_APP_LIST_URL_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (

        <Container>
            
            <SectionDiv>
                {/* <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm} /> */}

            </SectionDiv>
      

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && 
                    <RowCard 
                        rows={results} 
                    />
                    }
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`