import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import rootReducer from './reducers';

import { rootSaga } from 'sagas/index';

// Initialize state
const initialState = {};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware, logger),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
});

sagaMiddleware.run(rootSaga);

export default store;
