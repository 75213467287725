import {
  UPDATE_TASK_TEMPLATE_USER_RESPONSE_PAGE_NUMBER,
  GET_TASK_TEMPLATE_USER_RESPONSE_PENDING,
  GET_TASK_TEMPLATE_USER_RESPONSE,
  DELETE_TASK_TEMPLATE_USER_RESPONSE,
  FETCH_TASK_TEMPLATE_USER_RESPONSE,
  EDIT_TASK_TEMPLATE_USER_RESPONSE,
  ADD_TASK_TEMPLATE_USER_RESPONSE,
  CLEAR_TASK_TEMPLATE_USER_RESPONSE,
  RESET_TASK_TEMPLATE_USER_RESPONSE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  task_template_user_response: [],
  updated: false,
  pageNumber: 1,
  pending: false,
};

export default function TaskTemplateUserTask(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TASK_TEMPLATE_USER_RESPONSE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
        pending: false,
        updated: false,
      };
    case GET_TASK_TEMPLATE_USER_RESPONSE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        task_template_user_response: action.payload,
        pending: false,
      };
    case FETCH_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };
    case EDIT_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };
    case DELETE_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };
    case ADD_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };
    case RESET_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        updated: false,
      };
    case CLEAR_TASK_TEMPLATE_USER_RESPONSE:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
