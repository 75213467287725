import React from "react";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import Details from "./Details";
import LogDetails from "./log/Details";
import { Header, Grid, Button } from "semantic-ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CLEAR_APP_SERVER_UPDATE } from "constants/actions";

function AppList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  function CreateRow() {
    dispatch({
      type: CLEAR_APP_SERVER_UPDATE,
    });
    navigate(`/app/app-server/new`);
  }

  function ResetData() {
    dispatch({
      type: CLEAR_APP_SERVER_UPDATE,
    });
  }
  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2" style={{ marginTop: "50px" }}>
            List of Apps
            <Header.Subheader>
              <p>Create / update List of Servers</p>
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Container>
            {location.pathname !== "/app/app-server/new" && (
              <Button floated="left" primary onClick={CreateRow}>
                Add New
              </Button>
            )}

            <Button floated="right" color="brown" onClick={ResetData}>
              Reset
            </Button>
          </Container>
        </Grid.Column>
      </Grid.Row>

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="new" element={<Add />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="edit/:id" element={<Edit />} />
        <Route path="log-details/:id" element={<LogDetails />} />
      </Routes>
    </>
  );
}

export default AppList;

const Container = styled.div`
  padding: 10px 0;
`;
