import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Message, Modal, Input, Button, List, Icon,
    Checkbox } from 'semantic-ui-react'

import Select from "react-select";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { CATEGORY_COMPLIANCE_HEADERS, 
    PriorityList, CATEGORY_VISIBILITY, 
    CATEGORY_INPUT } from "data/AdminData"

import {
    SG_EDIT_SURVEY_STRUCTURE,
    SG_SURVEY_STRUCTURE_COMPLIANCE_SELECT
} from "constants/actions";



const NewCategory = (props) => {

    const { rowId,
        trigger,
        surveyStruc,
        setSurveyStruc,
        surveyStrucRow,
        setSurveyStrucRow,
        openNewCategory,
        setOpenNewCategory,
        organizationId,
        surveyStructureId,
        setUpdateJsonFile, 
        setLoading,
    } = props

    const dispatch = useDispatch();

    const [pristine, setPristine] = useState(true)
    const [listName, setListName] = useState('')
    // const [open, setOpen] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [color, setColor] = useState('#BCBCBC')
    const [formErrors, setFormErrors] = useState([]);
    const [prioritySelected, setPrioritySelected] = useState(false)
    const [visibilitySelected, setVisbilitySelected] = useState(false)
    const [inputSelected, setInputSelected] = useState(false)

    const [demographicValue, setDemographicValue] = useState(false)
    const [hiddenValue, setHiddenValue] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(false)
    const [optionList, setOptionList] = useState([])
    const [selectedOptionList, setSelectedOptionList] = useState(false)

    // console.log(rowId)
    const { get_category_compliance } = useSelector(
        (state) => ({
            get_category_compliance: state.surveystructurecompliance.survey_struture_select,
        }),
        shallowEqual
    );

    useEffect(() => {
        setPrioritySelected(PriorityList.find(({ value }) => value === surveyStruc?.priority))
        setVisbilitySelected(CATEGORY_VISIBILITY.find(
            ({ value }) => value === surveyStruc?.visibility))
        setInputSelected(CATEGORY_INPUT.find(
            ({ value }) => value === surveyStruc?.input))
        setDemographicValue(surveyStruc?.demographic? surveyStruc?.demographic : false)
        setHiddenValue(surveyStruc?.hidden? surveyStruc?.hidden : false)
    }, [surveyStruc]);

    useEffect(() => {
        const PriorityId = CATEGORY_COMPLIANCE_HEADERS.filter(cc => cc.small === prioritySelected?.value)?.[0]?.value
        dispatch({
            type: SG_SURVEY_STRUCTURE_COMPLIANCE_SELECT,
            payload: `priority=${PriorityId || ''}`
        });
    }, [dispatch, prioritySelected]);

    function ChangedPriority(e) {
        AddDetails("priority", e.value)
        setPrioritySelected(e)
        setSelectedOptions(false)
        setOptionList([])
        setSelectedOptionList(false)
    }

    function ChangedVisibility(e) {
        AddDetails("visibility", e.value)
        setVisbilitySelected(e)
    }
    function ChangedDemograhic() {
        
        setDemographicValue(!demographicValue)
        // setVisbilitySelected(e)
        AddDetails("demographic", !demographicValue)
    }

    function ChangedHidden() {
        
        setHiddenValue(!hiddenValue)
        // setVisbilitySelected(e)
        AddDetails("hidden", !hiddenValue)
    }
    // console.log(demographicValue)

    function ChangedInput(e) {
        AddDetails("input", e.value)
        setInputSelected(e)
    }

    function ChangedOption(e) {
        setSelectedOptions(e)
        setOptionList(e.categories)
    }

    function ChangedOptionList(e) {
        setSelectedOptionList(e)
    }
    
    // console.log("surveyStruc", surveyStruc)
    // console.log(surveyStruc?.demographic)
    // console.log(demographicValue)

    const onSubmit = () => {
        if (formErrors.length > 0) {
            setPristine(false)
            return
        }
        setPristine(true)
        setOpenNewCategory(false)
        // const _strucRows = []
        let NewRow = []
        const _strucRow = []
        if (rowId) {
            surveyStrucRow.filter(st => st?.id !== rowId).map(sl => {
                return _strucRow.push(sl)
            })
            _strucRow.push(surveyStruc)
            NewRow = _strucRow
            setSurveyStrucRow(_strucRow)
        } else {
            if (surveyStrucRow?.length > 0) {
                surveyStrucRow.map(sl => {
                    return _strucRow.push({ id: uuidv4(), ...sl })
                })
                // console.log("Add Rows")
                _strucRow.push({
                    "id": uuidv4(),
                    "subfactors": [],
                    ...surveyStruc
                })
                NewRow = _strucRow
                setSurveyStrucRow(_strucRow)
            } else {
                // console.log("New Rows")
                NewRow = [
                    {
                        "id": uuidv4(),
                        "subfactors": [],
                        ...surveyStruc
                    }]
                setSurveyStrucRow(NewRow)
            }
        }


        dispatch({
            type: SG_EDIT_SURVEY_STRUCTURE,
            payload: {
                id: surveyStructureId,
                organization: organizationId,
                categories: NewRow,
            },
        });
        setSurveyStruc({})
        setLoading(true)
        setUpdateJsonFile(0)
    }



    useEffect(() => {
        const ErrorList = []
        if (!surveyStruc?.priority) {
            ErrorList.push("Select a Priority")
        }
        if (!surveyStruc?.name) {
            ErrorList.push("Enter a Name")
        }
        if (!surveyStruc?.subtitle) {
            ErrorList.push("Enter a Subtitle")
        }

        if (!surveyStruc?.options || surveyStruc?.options?.length === 0) {
            ErrorList.push("Add atleast one option")
        }
        setFormErrors(ErrorList)
    }, [surveyStruc, updated]);

    function reset() {
        setOpenNewCategory(false)
    }

    function AddAllToList() {
        const _survey = surveyStruc
        const _options = _survey?.options ? _survey?.options : []
        optionList.map(o => 
            _options.push(o)
            )
        
        _survey.options = _options
        setSurveyStruc(_survey)
        setListName('')
        setUpdated(Math.random())
    }

    function AddToList() {
        setSurveyStruc(prevState => ({
            ...prevState,
            options: [
                ...(prevState.options || []),
                selectedOptionList,
            ],
        }));
        setListName('');
        setUpdated(Math.random());
    }
    

    function RemoveFromList(key) {
        let newErrors = formErrors;
    
        setSurveyStruc(prevState => {
            const RemoveName = prevState.options[key]?.name;
            let SubFactors = prevState.subfactors || [];
    
            // Remove subfactors if they match the removed option
            SubFactors = SubFactors.filter(ss => ss.option !== RemoveName);
    
            // Remove the option from the list
            const newOptions = prevState.options.filter((_, index) => index !== key);
    
            // Handle form errors if no options are left
            if (newOptions.length === 0) {
                newErrors = [...formErrors, "Add at least one option"];
            }
    
            // Update the survey structure
            return {
                ...prevState,
                options: newOptions,
                subfactors: SubFactors.length > 0 ? SubFactors : prevState.subfactors,
            };
        });
    
        // Trigger updates
        setFormErrors(newErrors);
        setUpdated(Math.random());
        setUpdateJsonFile(Math.random());
    }
    
    

    function AddDetails(name, value) {
        setSurveyStruc(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setUpdated(Math.random());
        setUpdateJsonFile(Math.random());
    }
    



    return (
        <Modal
            onClose={() => reset()}
            onOpen={() => setOpenNewCategory(true)}
            open={openNewCategory}
            trigger={trigger}
            size={"small"}
        >
            <Modal.Header>
                {rowId ? "Update " : "Create "}category

            </Modal.Header>
            <div style={{ display: 'flex' }}>
                <Half>
                    {formErrors.length > 0 && !pristine &&
                        <Message negative
                            //   onDismiss={clearErrors}
                            header='We need these details!'
                            list={formErrors}
                        />
                    }

                    <FormContainerTop>
                        <Label>Priority</Label>
                        <Select
                            placeholder="Select Priority"
                            onChange={ChangedPriority}
                            value={prioritySelected}
                            options={PriorityList}
                        />
                    </FormContainerTop>

                    <FormContainer>
                        <Label>Category Name</Label>
                        <Input
                            fluid
                            placeholder='Category Name'
                            name="name"
                            // defaultValue={surveyStruc?.name}
                            value={surveyStruc?.name || ''}
                            onChange={(v) => AddDetails("name", v.target.value)}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>French Category Name (fr_name)</Label>
                        <Input
                            fluid
                            placeholder='French Category Name (fr_name)'
                            name="name"
                            // defaultValue={surveyStruc?.name}
                            value={surveyStruc?.fr_name || ''}
                            onChange={(v) => AddDetails("fr_name", v.target.value)}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Subtitle</Label>
                        <Input
                            fluid
                            placeholder='subtitle'
                            name="subtitle"
                            onChange={(v) => AddDetails("subtitle", v.target.value)}
                            value={surveyStruc?.subtitle || ''}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Visibility</Label>
                        <Select
                            placeholder="Select Visibility"
                            onChange={ChangedVisibility}
                            value={visibilitySelected}
                            options={CATEGORY_VISIBILITY}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Input</Label>
                        <Select
                            placeholder="Select input"
                            onChange={ChangedInput}
                            value={inputSelected}
                            options={CATEGORY_INPUT}
                        />
                    </FormContainer>
                    <FormContainer>
                        <Label>Demographic</Label>
                        <Checkbox 
                            onClick={ChangedDemograhic} 
                            checked={demographicValue === true}
                            toggle /> 
                    </FormContainer>

                    <FormContainer>
                        <Label>Hidden</Label>
                        <Checkbox 
                            onClick={ChangedHidden} 
                            checked={hiddenValue === true}
                            toggle /> 
                    </FormContainer>
                    {/*  */}
                    <FormContainer>
                        <Label>Question</Label>
                        <Input
                            fluid
                            placeholder='Question'
                            type="question"
                            onChange={(v) => AddDetails("question", v.target.value)}
                            value={surveyStruc?.question || ''}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>French Question (fr_question)</Label>
                        <Input
                            fluid
                            placeholder='French Question'
                            type="fr_question"
                            onChange={(v) => AddDetails("fr_question", v.target.value)}
                            value={surveyStruc?.fr_question || ''}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Option Headings</Label>
                        <List divided verticalAlign='middle'>
                            {surveyStruc?.options?.length > 0 &&
                                surveyStruc?.options.map((item, key) => {
                                    return (
                                        <List.Item key={key}>
                                            <RowList>
                                                <Icon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => RemoveFromList(key)}
                                                    name='minus circle' />

                                                <ColorBlock color={item?.color} />
                                                <List.Content>{item?.name}</List.Content>
                                            </RowList>

                                        </List.Item>
                                    )
                                })}
                        </List>

                    </FormContainer>
                    <FormContainer>
                        <Label>Select one Options List</Label>
                        <FullRowList>
                            <FormContainer width={'80%'}>
                                <Select
                                    placeholder="Select Options"
                                    onChange={ChangedOption}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionValue={(option) => option?.id}
                                    value={selectedOptions}
                                    options={get_category_compliance}
                                />
                            </FormContainer>
                            <FormContainer width={'18%'}>
                                <StyledButton onClick={AddAllToList}>Add All</StyledButton>
                            </FormContainer>
                        </FullRowList>

                    </FormContainer>

                    <FormContainer>

                        <Label>Add Options from List</Label>
                        <FullRowList>
                            <FormContainer width={'80%'}>
                                <Select
                                    placeholder="Select Options"
                                    onChange={ChangedOptionList}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionValue={(option) => option?.id}
                                    value={selectedOptionList}
                                    options={optionList}
                                />
                            </FormContainer>
                            <FormContainer width={'18%'}>
                                <StyledButton onClick={AddToList}>Add +</StyledButton>
                            </FormContainer>
                        </FullRowList>

                    </FormContainer>


                    <Submit
                        floated='right'
                        disable={formErrors.length > 0 && !pristine}
                        onClick={onSubmit}>
                        {rowId ? "update" : "Create"}
                    </Submit>
                </Half>
            </div>
        </Modal >
    )
}

export default NewCategory

const Half = styled.div`
	width:100%;
	padding:30px 30px;
`

const RowList = styled.div`
    display: flex;
    align-items: stretch; 
    justify-content: flex-start;
`

const FullRowList = styled.div`
    display: flex;
    justify-content: space-between;
`


const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const StyledButton = styled(Button)`
	margin-left:10px;
`

const FormContainer = styled.div`
    width: ${props => props.width ? props.width : '100%'};
	margin-top:20px;
`

const FormContainerTop = styled.div`
    width: 100%;
	margin-top:0px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;
`
