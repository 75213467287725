import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Segment, Dimmer, Loader, Card, Button } from "semantic-ui-react";
import ReactJson from "react-json-view";

import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES } from "constants/actions";

const TemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const { get_debrief_pdf_report } = useSelector(
    (state) => ({
      get_debrief_pdf_report: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_debrief_pdf_report?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_debrief_pdf_report]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
    });
  }, [dispatch]);

  useEffect(() => {
    setResults(get_debrief_pdf_report?.templates?.response);
  }, [get_debrief_pdf_report]);

  return (
    <Container>
      <SectionCell>
        <Button
          floated="right"
          color="green"
          onClick={() => navigate(`/app/pdf-reports/`)}
        >
          Back
        </Button>
      </SectionCell>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
      <SectionDiv>
        <ReactJson
          src={results}
        />
      </SectionDiv>
      <SectionDiv></SectionDiv>
    </Container>
  );
};

export default TemplateList;

const RowCard = ({ rows }) => {
  return rows.map((row, index) => (
    <Card key={index}>
      <Card.Content>
        <Card.Header>
          {row?.name} # {row?.type}{" "}
        </Card.Header>
        <Card.Meta>
          Key # {row?.key} ID # {row?.id}
        </Card.Meta>
        <Card.Meta>
          Folder {" => "} {row?.folder}
        </Card.Meta>
        <Card.Meta>{row?.description}</Card.Meta>
        <Card.Description>{row?.template}</Card.Description>
        <Card.Description>
          {row?.active ? "Active" : "In Active"}
        </Card.Description>
      </Card.Content>
    </Card>
  ));
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const SectionCell = styled.div`
  padding: 30px 15px;
  min-width: 350px;
`;
