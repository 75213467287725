import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_SURVEY_QUESTION,
  SG_EDIT_SURVEY_QUESTION,
  SG_ADD_SURVEY_QUESTION,
  SG_DELETE_SURVEY_QUESTION,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
//
import ReactJson from "react-json-view";
//
import { SURVEY_TYPE, STATUS } from "data/AdminData";
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages";
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [questionId, setQuestionId] = useState(false);

  const [questionDetails, setQuestionDetails] = useState({
    name: "",
    sort_order: "",
  });

  const [loading, setLoading] = useState(false);
  const [questionJson, setQuestionJson] = useState();

  const [statusSelected, setStatusSelected] = useState({});
  const [surveyTypeSelected, setSurveyTypeSelected] = useState({});

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  function BackArrow() {
    navigate(`/app/questions`);
  }

  useEffect(() => {
    setQuestionId(id);
    setLoading(true);
  }, [id]);

  useEffect(() => {
    if (questionId) {
      dispatch({
        type: SG_FETCH_SURVEY_QUESTION,
        payload: { id: questionId },
      });
    }
  }, [dispatch, questionId]);

  const { get_questions } = useSelector(
    (state) => ({
      get_questions: state.surveyquestion,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_questions?.[questionId]) {
      setQuestionDetails(get_questions?.[questionId]);
      setQuestionJson(get_questions?.[questionId]?.questions);
      setStatusSelected(
        STATUS.filter(
          (sy) => sy.value === get_questions?.[questionId]?.status
        )?.[0]
      );
      setSurveyTypeSelected(
        SURVEY_TYPE.filter(
          (sy) => sy.value === get_questions?.[questionId]?.survey_type
        )?.[0]
      );
    }
  }, [get_questions, questionId]);

  useEffect(() => {
    if (get_questions?.created?.id) {
      navigate(`/app/questions/details/${get_questions?.created?.id}`);
    }
  }, [get_questions, navigate]);

  function EditQuestion(e) {
    setQuestionJson(e?.jsObject);
  }

  function DeleteQuestion() {
    if (questionId) {
      dispatch({
        type: SG_DELETE_SURVEY_QUESTION,
        payload: {
          id: questionId,
        },
      });
      navigate(`/app/questions?reload=true`);
    }
  }
  function UpdateQuestions() {
    setPristine(false);
    if (formErrors.length === 0) {
      if (questionId) {
        dispatch({
          type: SG_EDIT_SURVEY_QUESTION,
          payload: {
            id: questionId,
            status: statusSelected?.value,
            survey_type: surveyTypeSelected?.value,
            sort_order: questionDetails?.sort_order,
            name: questionDetails?.name,
            url_location: questionDetails?.url_location,
          },
        });
        // navigate(`/app/questions/detail/${questionId}`);
      } else {
        dispatch({
          type: SG_ADD_SURVEY_QUESTION,
          payload: {
            status: statusSelected?.value,
            survey_type: surveyTypeSelected?.value,
            sort_order: questionDetails?.sort_order,
            name: questionDetails?.name,
            url_location: questionDetails?.url_location,
          },
        });
      }
      setPristine(true);
    }
  }

  function UpdateQuestionJson() {
    if (!questionJson) {

      const ErrorList = formErrors;
      ErrorList.push("Enter Question in JSON format");
      setFormErrors(ErrorList);
      return;
    }
    if (questionId) {
      dispatch({
        type: SG_EDIT_SURVEY_QUESTION,
        payload: {
          id: questionId,
          questions: questionJson,
        },
      });
      // navigate(`/app/questions/detail/${questionId}`);
    }
  }


  useEffect(() => {
    const ErrorList = [];
    if (!questionDetails?.sort_order) {
      ErrorList.push("Enter a Sort Order");
    }
    if (!questionDetails?.name) {
      ErrorList.push("Enter a Name");
    }
    if (!questionDetails?.url_location) {
      ErrorList.push("Enter a Url Location");
    }
    if (!statusSelected?.value) {
      ErrorList.push("Select Status");
    }

    if (!surveyTypeSelected?.value) {
      ErrorList.push("Select Survey Type");
    }
    setFormErrors(ErrorList);
  }, [questionDetails, statusSelected, surveyTypeSelected, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value);
    let _ques = questionDetails;
    _ques[`${name}`] = e.target.value;
    setQuestionDetails(_ques);
    setValueChanged(e.target.value + name);
  }

  const handleJsonEdit = (edit) => {
    // Handle JSON edits here
    setQuestionJson(edit.updated_src);
  };

  // Function to handle node addition
  function handleNodeAdd(data) {
    setQuestionJson(data.updated_src);
  }
  // Function to handle node deletion
  const handleNodeDelete = (path) => {
    setQuestionJson(path.updated_src);
  };

  // console.log(questionJson)

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {questionId
          ? `Edit Details of ${questionDetails?.name}`
          : "Add New Question"}
      </Header>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <SectionDiv>
        <BasicErrorMessage />
        {questionId &&
          `Created ${
            questionDetails?.created_at &&
            format(new Date(questionDetails?.created_at), "dd-MMM-yyyy")
          }`}
      </SectionDiv>
      <StyledBorderDiv>
      <SectionDiv>
        <Container>
          <List horizontal>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Id (sort_order)</List.Header>

                  <Input
                    onChange={(e) => ChangeFormValues(e, "sort_order")}
                    value={questionDetails?.sort_order}
                    placeholder="sort order"
                  />
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Name</List.Header>
                  <Input
                    onChange={(e) => ChangeFormValues(e, "name")}
                    value={questionDetails?.name}
                    placeholder="name"
                  />
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>URL Location (in Survey)</List.Header>
                  <Input
                    onChange={(e) => ChangeFormValues(e, "url_location")}
                    value={questionDetails?.url_location}
                    placeholder="name"
                  />
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer></ListContainer>
              </List.Content>
            </List.Item>

            {questionId && (
              <>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Created</List.Header>
                      {questionDetails?.created_at &&
                        format(
                          new Date(questionDetails?.created_at),
                          "dd-MMM-yyyy"
                        )}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Last Updated</List.Header>
                      {questionDetails?.updated_at &&
                        format(
                          new Date(questionDetails?.updated_at),
                          "dd-MMM-yyyy"
                        )}
                    </ListContainer>
                  </List.Content>
                </List.Item>
              </>
            )}
          </List>
        </Container>
      </SectionDiv>
      <SectionDiv>
        <SingleRowContent>
          <ListContainer>
            <List.Header>Survey Type</List.Header>
            <SectionCell>
              <Select
                name="survey_type"
                options={SURVEY_TYPE}
                isClearable
                onChange={setSurveyTypeSelected}
                // getOptionLabel={(option) => `${option.name}`}
                // getOptionValue={(option) => `${option.id}`}
                value={surveyTypeSelected}
              />
            </SectionCell>
          </ListContainer>
          <ListContainer>
            <List.Header>Survey Status</List.Header>
            <SectionCell>
              <Select
                name="status"
                options={STATUS}
                isClearable
                onChange={setStatusSelected}
                value={statusSelected}
              />
            </SectionCell>
          </ListContainer>
        </SingleRowContent>
      </SectionDiv>
      <SectionDiv lastSection={true}>
        <Button floated="right" color="green" onClick={UpdateQuestions}>
          {questionId ? "Update Question Details" : "Create New"}
        </Button>
      </SectionDiv>
</StyledBorderDiv>
      {questionId && (
        <StyledBorderDiv>
                  <Button floated="right" color="green" onClick={UpdateQuestionJson}>
              Update Json
            </Button>
            <SectionDiv>
            <ReactJson
              src={questionJson}
              onEdit={handleJsonEdit}
              onDelete={handleNodeDelete}
              onAdd={handleNodeAdd}
            />
          </SectionDiv>
          <SectionDiv lastSection={true}>
            {questionId && (
              <>
                <Button
                  floated="left"
                  color="red"
                  onClick={() => setConfirmOpen(true)}
                >
                  Delete Question
                </Button>
                <Confirm
                  open={confirmOpen}
                  onCancel={() => setConfirmOpen(false)}
                  onConfirm={DeleteQuestion}
                />
              </>
            )}

            <Button floated="right" color="green" onClick={UpdateQuestionJson}>
              Update Json
            </Button>
          </SectionDiv>
              
          <SectionDiv>
            <JSONInput
              id="a_unique_id"
              placeholder={questionDetails?.questions}
              // colors={darktheme}
              onChange={EditQuestion}
              locale={locale}
              height="800px"
              width="100%"
            />
            </SectionDiv>
            <SectionDiv lastSection={true}>
            {questionId && (
              <>
                <Button
                  floated="left"
                  color="red"
                  onClick={() => setConfirmOpen(true)}
                >
                  Delete Question
                </Button>
                <Confirm
                  open={confirmOpen}
                  onCancel={() => setConfirmOpen(false)}
                  onConfirm={DeleteQuestion}
                />
              </>
            )}

            <Button floated="right" color="green" onClick={UpdateQuestionJson}>
              Update Json
            </Button>
          </SectionDiv>
        </StyledBorderDiv>
      )}
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
  clear: both;
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const SectionCell = styled.div`
  padding: 10px 0px;
  min-width: 350px;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const LeftHalf = styled.div`
  // background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
`;

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`;

// using styled components create a div with border and padding
const StyledBorderDiv = styled.div`
  border: 1px solid black;
  padding: 10px;
`;