import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_APP_SERVER_LOGS,
  UPDATE_APP_SERVER_LOG_PAGE_NUMBER,
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";

const List = ({ templateID, changed }) => {
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [reloadPage, setReloadPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // if the response is ok 200
    if (templateID) {
      dispatch({
        type: SG_GET_APP_SERVER_LOGS,
        payload: `page=${page}&app_server=${templateID}${
          searchTerm ? `&search=${searchTerm}` : ""
        }`,
      });
    }
    setLoading(true);
  }, [dispatch, page, searchTerm, templateID, reloadPage, changed]);

  const { get_app_server_log } = useSelector(
    (state) => ({
      get_app_server_log: state.app_server_log,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_app_server_log?.created?.id) {
      setReloadPage(get_app_server_log?.changed?.id);
    }
  }, [get_app_server_log]);

  useEffect(() => {
    setResults(get_app_server_log?.app_server_log_list?.results);
    setPage(get_app_server_log?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_app_server_log?.app_server_log_list?.count /
          get_app_server_log?.app_server_log_list?.page_size
      )
    );
    if (
      get_app_server_log?.app_server_log_list?.count %
      get_app_server_log?.app_server_log_list?.page_size
    ) {
      setTotalPages(
        Math.ceil(
          get_app_server_log?.app_server_log_list?.count /
            get_app_server_log?.app_server_log_list?.page_size
        )
      );
    }
  }, [get_app_server_log, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_APP_SERVER_LOG_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

  return (
    <Container>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page || 1}
          totalPages={totalPages || 1}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
