import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_FETCH_SCHEDULE_TEMPLATE,
  SG_DELETE_SCHEDULE_TEMPLATE,
} from "constants/actions";
//
import {
  Icon,
  Button,
  Header,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//

import ReactJson from "react-json-view";

//
const DisplayDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [loading, setLoading] = useState(false);

  function BackArrow() {
    navigate(`/app/schedule-template-report`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_SCHEDULE_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_schedule_template } = useSelector(
    (state) => ({
      get_schedule_template: state.schedule_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_schedule_template?.[templateId]) {
      setTemplateDetails(get_schedule_template?.[templateId]);
    }
  }, [get_schedule_template, templateId]);

  useEffect(() => {
    if (get_schedule_template?.pending) {
      setLoading(get_schedule_template?.pending);
    }
    const timer1 = setTimeout(() => {
      setLoading(get_schedule_template?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_schedule_template]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_SCHEDULE_TEMPLATE,
        payload: {
          id: templateId,
        },
      });
    }
  }

  return (
    <>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Header as="h2">
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow}
            name="arrow left"
          />
          {templateId && `Details of ${templateDetails?.external_model}`}
        </Header>

        <Row>
          <LeftColumn>
            <Label htmlFor="id">ID#</Label>
          </LeftColumn>
          <RightColumn>{templateDetails?.id}</RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="id">Status#</Label>
          </LeftColumn>
          <RightColumn>
            {templateDetails?.status} # {templateDetails?.status_text}
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="created">Created At</Label>
          </LeftColumn>
          <RightColumn>
            {templateDetails?.created_at &&
              format(
                new Date(templateDetails?.created_at),
                "dd-MMM-yyyy hh:mm a"
              )}
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="created">Updated At</Label>
          </LeftColumn>
          <RightColumn>
            {templateDetails?.updated_at &&
              format(
                new Date(templateDetails?.updated_at),
                "dd-MMM-yyyy hh:mm a"
              )}
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="organization">Organization:</Label>
          </LeftColumn>
          <RightColumn>
            {templateDetails?.organization} #{" "}
            {templateDetails?.organization_name}
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="organization">Employee:</Label>
          </LeftColumn>
          <RightColumn>
            {templateDetails?.employee} # {templateDetails?.employee_name} -{" "}
            {templateDetails?.employee_email}
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="reportTemplate">Template:</Label>
          </LeftColumn>
          <RightColumn>{templateDetails?.recommendation_template}</RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="model">Model:</Label>
          </LeftColumn>
          <RightColumn>{templateDetails?.external_model}</RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="type_of">Type Of:</Label>
          </LeftColumn>
          <RightColumn>{templateDetails?.type_of_text}</RightColumn>
        </Row>

        <ColoredContainer>
          <Row>
            <LeftColumn>
              <Label htmlFor="primary_uid">All Survey with Primary:</Label>
            </LeftColumn>
            <RightColumn>{templateDetails?.primary_uid}</RightColumn>
          </Row>
          <Row>
            <LeftColumn>
              <Label htmlFor="category_name">Category:</Label>
            </LeftColumn>
            <RightColumn>
              {templateDetails?.detailed_info?.category_head}
            </RightColumn>
          </Row>

          <Row>
            <LeftColumn>
              <Label htmlFor="category_role">Role:</Label>
            </LeftColumn>
            <RightColumn>
              {templateDetails?.detailed_info?.category_option}
            </RightColumn>
          </Row>
        </ColoredContainer>
        <SectionDiv>
          <Button floated="right" color="red" onClick={DeleteQuestion}>
            Delete
          </Button>
        </SectionDiv>
        <SectionDiv>
        <Header as="h2">
            Data Stories
            <Header.Subheader>Click to view generated Data Stories</Header.Subheader>
          </Header>
        <ContainerBox>
            {templateDetails?.detailed_info?.chat_recommendation_id &&
              templateDetails?.detailed_info?.chat_recommendation_id.map((n, i) => {
                return (
                  <ContainerField key={i}>
                    <Button
              color="blue"
              onClick={() =>
                navigate(
                  `/app/gpt-recommendation/details/${n}`
                )
              }
            >
              View {n}
            </Button>
                  </ContainerField>
                );
              })}
          </ContainerBox>
          
        </SectionDiv>
        <SectionDiv>
          <ReactJson src={templateDetails?.detailed_info} />
        </SectionDiv>
      </Segment>
    </>
  );
};

export default DisplayDetails;

function OrSeperator({ TextValue }) {
  return (
    <OrSeperatorContainer>
      <VerticalLine />
      <OrText>{TextValue}</OrText>
      <VerticalLine />
    </OrSeperatorContainer>
  );
}

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  /* Add your custom styles here */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
const ColoredContainer = styled.div`
  /* Add your custom styles here */
  border: 1px solid #ff0000; /* Red border */
  padding: 30px 10px;
  margin: 30px 0px;
`;

const LeftColumn = styled.div`
  flex: 1;
  text-align: left;
`;

const RightColumn = styled.div`
  flex: 4;
`;

const RightAlignedColumn = styled.div`
  justify-content: flex-end;
`;

const Label = styled.label`
  font-size: 16px;
`;

const OrSeperatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;

const OrText = styled.p`
  margin: 0 10px;
  font-weight: bold;
`;


const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ContainerField = styled.span`
  padding: 5px;
  margin: 5px 10px;

`;
