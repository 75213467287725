import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { format } from 'date-fns'

import { Card, Button, List, Icon } from 'semantic-ui-react'
import SurveyResponse  from "./SurveyResponse";

const RowCard = ({ row, selectBriefSchedule, AddToSelectedSurveyList }) => {
 
  // console.log(row)
  const [found, setFound] = useState(false)
  useEffect(() => {
    setFound(selectBriefSchedule.includes(row?.debrief_schedule?.id))
  },[selectBriefSchedule, row])
  
  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Container>
            <List horizontal>

              <List.Item>
                <ListContainer>
                   <List.Header>Debrief Survey ID</List.Header>
                   {row?.debrief_schedule?.id}
                </ListContainer>
              </List.Item>
              {/* row */}
              <List.Item>
                <ListContainer>
                   <List.Header>Survey Name</List.Header>
                   {row?.debrief_schedule?.name}
                </ListContainer>
              </List.Item>
              {/* row */}
               {/* row */}
               <List.Item>
                <ListContainer>
                   <List.Header>Survey Status</List.Header>
                   {row?.debrief_schedule?.status_text}
                </ListContainer>
              </List.Item>
              {/* row */}
              <List.Item>
                  <ListContainer>
                     <List.Header>Created</List.Header>
                     {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy')}
                  </ListContainer>
              </List.Item>
              {/* row */}
              <List.Item>
                  <ListContainer>
                     <List.Header>Updated</List.Header>
                     {row?.updated_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy')}
                  </ListContainer>
              </List.Item>
              {/* row */}
            </List>
          </Container>
          {/* this is where we show existing survey summary */}
          <SurveyResponse brief_scheduled_id={row?.debrief_schedule?.id} />

          <Card.Description>
          

            <Button floated='right'
              onClick={() => AddToSelectedSurveyList(row?.debrief_schedule?.id)}
              inverted={found === true}
              color={found === true? 'purple': 'green'}
              >
              {found? "De Select" : "Select"}
            </Button>
          </Card.Description>
        </Card.Content>

      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;


const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const ListImageContainer = styled.div`
  margin: 10px 10px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`
