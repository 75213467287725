import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  APP_SERVER_PENDING,
  GET_APP_SERVERS,
  SG_GET_APP_SERVERS,
  DELETE_APP_SERVER,
  SG_DELETE_APP_SERVER,
  FETCH_APP_SERVER,
  SG_FETCH_APP_SERVER,
  EDIT_APP_SERVER,
  SG_EDIT_APP_SERVER,
  ADD_APP_SERVER,
  SG_ADD_APP_SERVER,
  GET_APP_SERVERS_MAKE_COMMANDS,
  SG_GET_APP_SERVERS_MAKE_COMMANDS,
  POST_APP_SERVERS_MAKE_BUILD,
  SG_POST_APP_SERVERS_MAKE_BUILD,
  ALL_ERRORS,
} from "constants/actions";


function* loadTemplate(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.GET_APP_SERVERS, action.payload);
    yield put({ type: GET_APP_SERVERS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionLoad() {
  yield takeLatest(SG_GET_APP_SERVERS, loadTemplate);
}

function* fetchTemplate(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.FETCH_APP_SERVER, action.payload);
    yield put({ type: FETCH_APP_SERVER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionFetch() {
  yield takeLatest(SG_FETCH_APP_SERVER, fetchTemplate);
}

function* updateTemplate(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.EDIT_APP_SERVER, action.payload);
    yield put({ type: EDIT_APP_SERVER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionUpdate() {
  yield takeLatest(SG_EDIT_APP_SERVER, updateTemplate);
}

function* addTemplate(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.ADD_APP_SERVER, action.payload);
    yield put({ type: ADD_APP_SERVER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionAdd() {
  yield takeLatest(SG_ADD_APP_SERVER, addTemplate);
}


function* deleteTemplate(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.DELETE_APP_SERVER, action.payload);
    yield put({ type: DELETE_APP_SERVER, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionDelete() {
  yield takeLatest(SG_DELETE_APP_SERVER, deleteTemplate);
}

function* loadMakeCommands(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.GET_APP_SERVERS_MAKE_COMMANDS, action.payload);
    yield put({ type: GET_APP_SERVERS_MAKE_COMMANDS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionLoadMakeCommands() {
  yield takeLatest(SG_GET_APP_SERVERS_MAKE_COMMANDS, loadMakeCommands);
}

function* addBuildCommand(action) {
  try {
    yield put({ type: APP_SERVER_PENDING });
    const json = yield call(api.POST_APP_SERVERS_MAKE_BUILD, action.payload);
    yield put({ type: POST_APP_SERVERS_MAKE_BUILD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionAddBuildCommand() {
  yield takeLatest(SG_POST_APP_SERVERS_MAKE_BUILD, addBuildCommand);
}

export default function* index() {
  yield all([
    functionLoad(),
    functionFetch(),
    functionUpdate(),
    functionAdd(),
    functionDelete(),
    functionLoadMakeCommands(),
    functionAddBuildCommand(),
  ]);
}
