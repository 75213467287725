import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Blog from 'blog/BlogViews';
import CreateBlog from 'blog/BlogViews/CreateEdit';
import { Header } from 'semantic-ui-react'

function Blogs() {

  return (
    <>
      {/* <div style={{ marginTop: "100px" }}>
        <nav>
          <Link to="demo">My Profile</Link>
        </nav>
      </div> */}
      <Header as='h2' style={{ marginTop: "50px" }}>
        Blog
        <Header.Subheader>
          Manage your blog content.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="edit/:id" element={<CreateBlog />} />
        <Route path="create" element={<CreateBlog />} />
      </Routes>
    </>
  );
}

export default Blogs;
