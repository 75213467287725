import React from 'react';
import ReactDOM from 'react-dom/client'; // Change to 'react-dom/client' in React 18
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// Create the root element using React 18's createRoot API
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

reportWebVitals(); // Use this to send metrics to your backend if needed

// Service workers configuration
serviceWorker.unregister(); // Still defaulting to unregister; change to register() if you need offline support
