import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { format } from "date-fns";

import { Table } from "semantic-ui-react";

const CreatedListDetails = ({ rows }) => {

  return (
    <>
      
      {rows &&
      <Table stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#ID</Table.HeaderCell>
            <Table.HeaderCell>Survey -A  </Table.HeaderCell>
            <Table.HeaderCell>Model - A </Table.HeaderCell>
            <Table.HeaderCell>AI Summary - A </Table.HeaderCell>
            <Table.HeaderCell>Survey - B</Table.HeaderCell>
            <Table.HeaderCell>Model - B </Table.HeaderCell>
            <Table.HeaderCell>AI Summary - B </Table.HeaderCell>
            <Table.HeaderCell>Updated</Table.HeaderCell>
            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          
            {rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{row?.id}</Table.Cell>
                  <Table.Cell>{row?.option_a?.survey_name}</Table.Cell>
                  <Table.Cell>{row?.option_a?.model_name}</Table.Cell>
                  <Table.Cell>{row?.option_a?.status_text}</Table.Cell>
                  <Table.Cell>{row?.option_b?.survey_name}</Table.Cell>
                  <Table.Cell>{row?.option_b?.model_name}</Table.Cell>
                  <Table.Cell>{row?.option_b?.status_text}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    {row?.created_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy")}
                  </Table.Cell>
                    <Table.Cell textAlign="right">
                        {/* <ContainerFieldSelectable onClick={() => EditRow(row)}>
                        More Details
                      </ContainerFieldSelectable> */}
                      
                    </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      }
      
    </>
  );
};

CreatedListDetails.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default CreatedListDetails;


const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
