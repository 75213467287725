import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import List from "./List.js";
import Details from "./Detail.js";
import DetailedView from "./DetailedView.js";
import Create from "./Create.js";
import { Header, Icon, Button } from "semantic-ui-react";

function CategoryStructure() {
  const navigate = useNavigate();
  const location = useLocation();
  const [newResponse, setNewResponse] = useState(0)
  const [loadData, setLoadData] = useState(false);

  function BackArrow() {
    navigate("/app/ml-operations/");
  }

  function GetNew() {
    setNewResponse(Math.random())
    navigate("/app/ml-operations/create/");
  }

  const { get_debrief_summary } = useSelector(
    (state) => ({
      get_debrief_summary: state.debrief_summary,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_debrief_summary?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_debrief_summary.pending]);

  return (
    <>
      <HeaderContainer>
        <HeaderText as="h2" onClick={BackArrow}>
            <Icon
              style={{ cursor: "pointer" }}
              onClick={BackArrow}
              name="home"
            />
            ML Operations for Survey Responses
        </HeaderText>
          <AddNewButton
          disabled={loadData}
          primary
          onClick={GetNew}
        >
          Get New
        </AddNewButton>
       
      </HeaderContainer>

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details newResponse={newResponse} setNewResponse={setNewResponse} />} />
        <Route path="detailed-view/:id" element={<DetailedView newResponse={newResponse} setNewResponse={setNewResponse} />} />
        <Route path="create" element={<Create newResponse={newResponse} setNewResponse={setNewResponse} />} />
      </Routes>
    </>
  );
}

export default CategoryStructure;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const HeaderText = styled(Header)`
  margin-top: 40px;
  cursor: pointer;
`;

const AddNewButton = styled(Button)`
  display: ${({ hide }) => (hide ? "none" : "block")};
`;
