import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { format } from "date-fns";

import { Table, Confirm, Icon } from "semantic-ui-react";

const RowCard = ({ rows, homepage, EditRow, DeleteRow }) => {

  const [deleteRow, setDeleteRow] = useState(false);

  function DeleteSelectedRow() {
    DeleteRow(deleteRow);
    setDeleteRow(false);
  }

  return (
    <>
      

      <Table stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#ID</Table.HeaderCell>
            <Table.HeaderCell>Model</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            
            <Table.HeaderCell>Updated</Table.HeaderCell>
            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{row?.id}</Table.Cell>
                  <Table.Cell>{row?.gpt_model_main}</Table.Cell>
                  <Table.Cell>{row?.status_text}</Table.Cell>
   
                  <Table.Cell>
                    {" "}
                    {row?.created_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy")}
                  </Table.Cell>
                    <Table.Cell textAlign="right">
                        <ContainerFieldSelectable onClick={() => EditRow(row)}>
                        More Details
                      </ContainerFieldSelectable>
                      <Icon
                        link
                        name="trash"
                        onClick={() => setDeleteRow(row)}
                      />
                    </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Confirm
        open={deleteRow?.id ? true : false}
        header={`Delete Prompt - Sort Order ${deleteRow?.sort_order} Warning`}
        content={`Warning this sort order ${deleteRow?.sort_order} would have to manually set`}
        onCancel={() => setDeleteRow(false)}
        onConfirm={DeleteSelectedRow}
      />
    </>
  );
};

RowCard.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default RowCard;


const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
