// src/reportWebVitals.js
import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

const sendToBackend = (metric) => {
  const body = JSON.stringify(metric);
  console.log(body);
//   const url = 'http://localhost:8000/web-vitals'; // Update this URL to match your backend

//   // Use navigator.sendBeacon if available for better performance
//   if (navigator.sendBeacon) {
//     navigator.sendBeacon(url, body);
//   } else {
//     fetch(url, {
//       body,
//       method: 'POST',
//       keepalive: true,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//   }
};

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getLCP(onPerfEntry);
    getFCP(onPerfEntry);
    getTTFB(onPerfEntry);
  }
};

const defaultExport = (metric) => reportWebVitals(sendToBackend);

export default defaultExport;
