import {
    UPDATE_APP_SERVER_LOG_PAGE_NUMBER,
    APP_SERVER_LOG_PENDING,
    GET_APP_SERVER_LOGS,
    DELETE_APP_SERVER_LOG,
    FETCH_APP_SERVER_LOG,
    EDIT_APP_SERVER_LOG,
    ADD_APP_SERVER_LOG,
    CLEAR_APP_SERVER_LOG,
    CLEAR_APP_SERVER_LOG_UPDATE,
    CLEAR_ALL,
    } from "constants/actions";
  
  const initialState = {
    app_server_log_list: [],
    pageNumber: 1,
    pending: false,
    created: false,
    updated: false,
    deleted: false,

  };
  
  export default function AppUrlList(state = initialState, action) {
    switch (action.type) {
      case UPDATE_APP_SERVER_LOG_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
      case APP_SERVER_LOG_PENDING: {
        return {
          ...state,
          pending: true
        }
      }
      case GET_APP_SERVER_LOGS:
        return {
          ...state,
          app_server_log_list: action.payload,
          updated: false,
          pending: false,
        };
      case FETCH_APP_SERVER_LOG:
        return { 
          ...state, 
          [action.payload.id]: action.payload, 
          pending: false
         };
      case EDIT_APP_SERVER_LOG:
        return {
          ...state,
          [action.payload.id]: action.payload,
          updated: action.payload,
          pending: false,
        };
      case DELETE_APP_SERVER_LOG:
        return {
          ...state,
          deleted: action.payload,
          pending: false,
        };
  
      case ADD_APP_SERVER_LOG:
        return {
          ...state,
          created: action.payload,
          pending: false,
        };
     case CLEAR_APP_SERVER_LOG_UPDATE:
      return {
        ...state,
        created: false,
        updated: false,
        deleted: false,
      }
      case CLEAR_APP_SERVER_LOG:
        return {
          ...state,
          ...initialState
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState
        };
  
      default:
        return state;
    }
  }
  