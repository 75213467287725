import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  // SG_GET_APP_SERVERS
  SG_FETCH_APP_SERVER,
  SG_EDIT_APP_SERVER,
  SG_ADD_APP_SERVER,
  SG_DELETE_APP_SERVER,
  SG_GET_APP_SERVERS_MAKE_COMMANDS,
  SG_POST_APP_SERVERS_MAKE_BUILD,
} from "constants/actions";
//
import ReactJson from "react-json-view";

import { Message, Button, List, Input, Confirm } from "semantic-ui-react";
//
import { format } from "date-fns";
//
import Select from "react-select";
import {
  APP_SERVER_STATUS,
  APP_SERVER_LOCATION,
  APP_TECH_STACK_CHOICES,
  APP_OS_CHOICES,
  APP_BUILD_REQUIRED_CHOICES,
  APP_LOCKED_STATUS_CHOICES,
  APP_UPDATE_TYPE,
} from "data/AdminData";

import { BasicErrorMessage } from "utilities/ErrorMessages";

import JsonTable from "./JsonTable";
import JsonRowEdit from "./JsonRowEdit";

//
const Details = ({ templateDetails, setTemplateDetails, templateId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [statusName, setStatusNames] = useState(APP_SERVER_STATUS[0]);
  const [serverLoc, setServerLoc] = useState(APP_SERVER_LOCATION[0]);

  const [lockedStatus, setLockedStatus] = useState(
    APP_LOCKED_STATUS_CHOICES[0]
  );
  const [buildRequired, setBuildRequired] = useState(
    APP_BUILD_REQUIRED_CHOICES[0]
  );
  const [techStack, setTechStack] = useState(APP_TECH_STACK_CHOICES[0]);
  const [osDeployed, setOsDeployed] = useState(APP_OS_CHOICES[0]);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [questionJson, setQuestionJson] = useState();
  const [selectedJsonRow, setSelectedJsonRow] = useState();
  const [templateDetailsJsonBuilds, setTemplateDetailsJsonBuilds] = useState();
  const [templateDetailsJson, setTemplateDetailsJson] = useState({
    build: [],
  });
  const [changed, setChanged] = useState(false);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_APP_SERVER,
        payload: {
          id: templateId,
        },
      });
    }
  }

  const { get_app_server } = useSelector(
    (state) => ({
      get_app_server: state.app_server,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_app_server?.changed) {
      setChanged(get_app_server?.changed);
    }
  }, [get_app_server])

  useEffect(() => {
    if (changed?.id) {
      navigate(`/app/app-server/details/${changed?.id}`);
    } 
   
    // if (changed) {
    //   navigate(`/app/app-server/`);
    // }
  }, [changed, navigate]);

  useEffect(() => {
    if (templateDetails?.status) {
      setStatusNames(
        APP_SERVER_STATUS.find((x) => x.value === templateDetails?.status)
      );
    }
  }, [templateDetails]);

  useEffect(() => {
    dispatch({
      type: SG_GET_APP_SERVERS_MAKE_COMMANDS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (templateDetails?.server_location) {
      setServerLoc(
        APP_SERVER_LOCATION.find(
          (x) => x.value === templateDetails?.server_location
        )
      );
    }
    if (templateDetails?.build_required) {
      setBuildRequired(
        APP_BUILD_REQUIRED_CHOICES.find(
          (x) => x.value === templateDetails?.build_required
        )
      );
    }
    if (templateDetails?.os_deployed) {
      setOsDeployed(
        APP_OS_CHOICES.find((x) => x.value === templateDetails?.os_deployed)
      );
    }
    if (templateDetails?.tech_stack) {
      setTechStack(
        APP_TECH_STACK_CHOICES.find(
          (x) => x.value === templateDetails?.tech_stack
        )
      );
    }
    if (templateDetails?.details) {
      setTemplateDetailsJson(templateDetails?.details);
    }
  }, [templateDetails]);

  useEffect(() => {
    if (templateDetails?.details) {
      setQuestionJson(templateDetails?.details);
    }
  }, [templateDetails.details]);

  function UpdateQuestions() {
    setPristine(false);
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_EDIT_APP_SERVER,
          payload: {
            id: templateId,
            name: templateDetails?.name,
            server_ip: templateDetails?.server_ip,
            url: templateDetails?.url,
            private_ip: templateDetails?.private_ip,
            sort_order: templateDetails?.sort_order,
            status: statusName?.value,
            server_location: serverLoc?.value,
            details: templateDetails?.details,
            build_required: buildRequired?.value,
            tech_stack: techStack?.value,
            os_deployed: osDeployed?.value,
          },
        });
      } else {
        dispatch({
          type: SG_ADD_APP_SERVER,
          payload: {
            name: templateDetails?.name,
            server_ip: templateDetails?.server_ip,
            url: templateDetails?.url,
            private_ip: templateDetails?.private_ip,
            sort_order: templateDetails?.sort_order,
            status: statusName?.value,
            server_location: serverLoc?.value,
            details: templateDetails?.details,
            build_required: buildRequired?.value,
            tech_stack: techStack?.value,
            os_deployed: osDeployed?.value,
          },
        });
      }
      setPristine(true);
    }
  }

  function UpdateDataRow(jsonRowDetails) {
    if (!templateId) {
      console.error("Template ID is not defined");
      return;
    }
    let _templateDetailsJson = templateDetailsJson;
    if (!templateDetailsJson?.builds) {
      _templateDetailsJson = {
        ...templateDetailsJson,
        builds: [jsonRowDetails],
      };
    } else {
      // Check if jsonRowDetails.id is in templateDetailsJson?.builds
      if (
        !templateDetailsJson.builds.some(
          (item) => item.id === jsonRowDetails.id
        )
      ) {
        // If not, add it to the array
        const updatedBuild = [...templateDetailsJson.builds, jsonRowDetails];
        _templateDetailsJson = {
          ...templateDetailsJson,
          builds: updatedBuild,
        };
      } else {
        // If it is in the array, update the array
        const updatedBuild = templateDetailsJson.builds.map((item) => {
          if (item.id === jsonRowDetails.id) {
            return jsonRowDetails;
          }
          return item;
        });
        _templateDetailsJson = {
          ...templateDetailsJson,
          builds: updatedBuild,
        };
      }
    }

    // console.log("Update JSON");
    // console.log(jsonRowDetails);
    // console.log("**********************");
    // console.log(templateDetailsJson);
    // console.log("**********************");
    // console.log(_templateDetailsJson);
    dispatch({
      type: SG_EDIT_APP_SERVER,
      payload: {
        id: templateId,
        details: _templateDetailsJson,
      },
    });
  }

  useEffect(() => {
    const ErrorList = [];
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name");
    }
    if (!templateDetails?.server_ip) {
      ErrorList.push("Enter the server_ip");
    }
    // if (!templateDetails?.private_ip) {
    //   ErrorList.push("Enter the private_ip");
    // }
    if (!statusName?.value) {
      ErrorList.push("Select Status");
    }
    if (!serverLoc?.value) {
      ErrorList.push("Select Server Location");
    }
    if (!buildRequired?.value) {
      ErrorList.push("Select If Build Required");
    }
    if (!techStack?.value) {
      ErrorList.push("Select Tech Stack");
    }
    if (!osDeployed?.value) {
      ErrorList.push("Select OS");
    }
    setFormErrors(ErrorList);
  }, [
    templateDetails,
    valueChanged,
    statusName,
    serverLoc,
    buildRequired,
    techStack,
    osDeployed,
  ]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = templateDetails;
    _ques[`${name}`] = e.target.value;
    setTemplateDetails(_ques);
    setValueChanged(e.target.value + name);
  }

  function UpdateQuestionJson() {
    if (!questionJson) {
      const ErrorList = formErrors;
      ErrorList.push("Enter Question in JSON format");
      setFormErrors(ErrorList);
      return;
    }
    if (templateId) {
      console.log("Update JSON");
      dispatch({
        type: SG_EDIT_APP_SERVER,
        payload: {
          id: templateId,
          details: questionJson,
        },
      });
      // navigate(`/app/questions/detail/${questionId}`);
    }
  }

  const handleJsonEdit = (edit) => {
    // Handle JSON edits here
    setQuestionJson(edit.updated_src);
  };
  // Function to handle node addition
  function handleNodeAdd(data) {
    setQuestionJson(data.updated_src);
  }
  // Function to handle node deletion
  const handleNodeDelete = (path) => {
    setQuestionJson(path.updated_src);
  };

  return (
    <>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId && (
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header># Id</List.Header>
                    {templateDetails?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at &&
                      format(
                        new Date(templateDetails?.created_at),
                        "dd-MMM-yyyy"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>
        </SectionDiv>
      )}

      <SectionDiv>
        <FormRow>
          <FormLabel>
            <LabelHead>Name</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>URI</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="name"
              defaultValue={templateDetails?.url}
              onChange={(e) => ChangeFormValues(e, "url")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>IP</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="server_ip"
              defaultValue={templateDetails?.server_ip}
              onChange={(e) => ChangeFormValues(e, "server_ip")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Private IP</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="private_ip"
              defaultValue={templateDetails?.private_ip}
              onChange={(e) => ChangeFormValues(e, "private_ip")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Status</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="model"
              options={APP_SERVER_STATUS}
              // isClearable
              onChange={setStatusNames}
              value={statusName}
              // getOptionLabel={(option) => option.label}
              // getOptionValue={(option) => option.value}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Build Required</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="build_required"
              options={APP_BUILD_REQUIRED_CHOICES}
              // isClearable
              onChange={setBuildRequired}
              value={buildRequired}
              // getOptionLabel={(option) => option.label}
              // getOptionValue={(option) => option.value}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Tech Stack</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="tech_stack"
              options={APP_TECH_STACK_CHOICES}
              // isClearable
              onChange={setTechStack}
              value={techStack}
              // getOptionLabel={(option) => option.label}
              // getOptionValue={(option) => option.value}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>OS</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="os_deployed"
              options={APP_OS_CHOICES}
              // isClearable
              onChange={setOsDeployed}
              value={osDeployed}
              // getOptionLabel={(option) => option.label}
              // getOptionValue={(option) => option.value}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Location</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="server_location"
              options={APP_SERVER_LOCATION}
              // isClearable
              onChange={setServerLoc}
              value={serverLoc}
              // getOptionLabel={(option) => option.label}
              // getOptionValue={(option) => option.value}
            />
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Sort Order</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="sort_order"
              defaultValue={templateDetails?.sort_order}
              onChange={(e) => ChangeFormValues(e, "sort_order")}
            />
          </FormInput>
        </FormRow>

        {/* sample_response */}
      </SectionDiv>

      <SectionDiv lastSection={true}>
        {templateId && (
          <>
            <Button
              floated="left"
              color="red"
              onClick={() => setConfirmOpen(true)}
            >
              Delete
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={DeleteQuestion}
            />
          </>
        )}

        <Button floated="right" color="green" onClick={UpdateQuestions}>
          {templateId ? "Update" : "Create"}
        </Button>
      </SectionDiv>
      <SectionDiv>
        <JsonTable
          rowData={templateDetailsJson?.builds}
          setSelectedJsonRow={setSelectedJsonRow}
        />
      </SectionDiv>
      <JsonRowEdit
        jsonRowDetails={selectedJsonRow}
        setSelectedJsonRow={setSelectedJsonRow}
        get_app_server={get_app_server}
        UpdateDataRow={UpdateDataRow}
      />
      <SectionDiv>
        {templateDetails?.id && (
          <>
            <StyledBorderDiv>
              <Button
                floated="right"
                color="violet"
                onClick={UpdateQuestionJson}
              >
                Update Json
              </Button>
              <SectionDiv>
                <ReactJson
                  src={questionJson}
                  onEdit={handleJsonEdit}
                  onDelete={handleNodeDelete}
                  onAdd={handleNodeAdd}
                />
              </SectionDiv>
            </StyledBorderDiv>
          </>
        )}
      </SectionDiv>
      <SectionDiv></SectionDiv>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;

const StyledBorderDiv = styled.div`
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
`;

const FormHeader = styled.label`
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
`;
