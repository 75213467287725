import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import AddEdit from './AddEdit';
import Details from './AddEdit';
import { Header, Grid, Button } from 'semantic-ui-react'
import { useNavigate, useLocation } from "react-router-dom";

function EmployeeRecordValue() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Header as='h2' style={{ marginTop: "50px" }}>
                        Web Api Credentials
                        <Header.Subheader>
                            <p>Create / update web Api Credentials for Organization 
                                to pull external data</p>
                           
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    {(location.pathname !== '/app/web-api-cred/new') &&
                        <Button primary
                            onClick={() => navigate(`/app/web-api-cred/new`)}
                        >Add New</Button>
                    }

                </Grid.Column>
            </Grid.Row>

            <Routes>
                <Route path="/" element={<List />} />
                <Route path="new" element={<AddEdit />} />
                <Route path="details/:id" element={<Details />} />
            </Routes>
        </>
    );
}

export default EmployeeRecordValue;
