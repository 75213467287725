import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  APP_SERVER_LOG_PENDING,
  GET_APP_SERVER_LOGS,
  SG_GET_APP_SERVER_LOGS,
  DELETE_APP_SERVER_LOG,
  SG_DELETE_APP_SERVER_LOG,
  FETCH_APP_SERVER_LOG,
  SG_FETCH_APP_SERVER_LOG,
  EDIT_APP_SERVER_LOG,
  SG_EDIT_APP_SERVER_LOG,
  ADD_APP_SERVER_LOG,
  SG_ADD_APP_SERVER_LOG,
ALL_ERRORS,
} from "constants/actions";


function* loadTemplate(action) {
  try {
    yield put({ type: APP_SERVER_LOG_PENDING });
    const json = yield call(api.GET_APP_SERVER_LOGS, action.payload);
    yield put({ type: GET_APP_SERVER_LOGS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionLoad() {
  yield takeLatest(SG_GET_APP_SERVER_LOGS, loadTemplate);
}

function* fetchTemplate(action) {
  try {
    yield put({ type: APP_SERVER_LOG_PENDING });
    const json = yield call(api.FETCH_APP_SERVER_LOG, action.payload);
    yield put({ type: FETCH_APP_SERVER_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionFetch() {
  yield takeLatest(SG_FETCH_APP_SERVER_LOG, fetchTemplate);
}

function* updateTemplate(action) {
  try {
    yield put({ type: APP_SERVER_LOG_PENDING });
    const json = yield call(api.EDIT_APP_SERVER_LOG, action.payload);
    yield put({ type: EDIT_APP_SERVER_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionUpdate() {
  yield takeLatest(SG_EDIT_APP_SERVER_LOG, updateTemplate);
}

function* addTemplate(action) {
  try {
    yield put({ type: APP_SERVER_LOG_PENDING });
    const json = yield call(api.ADD_APP_SERVER_LOG, action.payload);
    yield put({ type: ADD_APP_SERVER_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionAdd() {
  yield takeLatest(SG_ADD_APP_SERVER_LOG, addTemplate);
}


function* deleteTemplate(action) {
  try {
    yield put({ type: APP_SERVER_LOG_PENDING });
    const json = yield call(api.DELETE_APP_SERVER_LOG, action.payload);
    yield put({ type: DELETE_APP_SERVER_LOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionDelete() {
  yield takeLatest(SG_DELETE_APP_SERVER_LOG, deleteTemplate);
}


export default function* index() {
  yield all([
    functionLoad(),
    functionFetch(),
    functionUpdate(),
    functionAdd(),
    functionDelete(),
  ]);
}
