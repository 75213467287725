import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import { Link,useNavigate, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { NavData } from "./navigation";

import {
  PULL_ORGANIZATION,
  SET_WHITE_LABEL,
} from "constants/actions";

import { white_label_data } from "data/white_label";
import InnerLogicLogo from "assets/images/logo.png";

const SubNav = (props) => {
  return (
    <Link to={props.data.link}>
      <SubNavigation>
        <Text>{props.data.title}</Text>
      </SubNavigation>
    </Link>
  );
};

const NavItem = (props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = (e) => {
    if (props.data.subNav) {
      setOpen(!open);
      e.preventDefault(); // Prevents the default link click behavior if subNav exists
    }
  };

  return (
    <NavContainer
      open={open && props.open && props.data.subNav}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {/* Top-level item, triggers toggle on click */}
      {props.data.link ? (
        <Link to={props.data.link} onClick={toggleOpen}>
          <TopLevelItem>
            <IconContainer
              selected={props.selected === props.data.link}
              highlight={props.highlight}
            >
              {props.data.image ? (
                <IconImage src={props.data.image} />
              ) : (
                <Icon
                  name={props.data.icon}
                  size={"small"}
                  style={{ width: 20, fontSize: 14, margin: 0, marginLeft: -5 }}
                />
              )}
              <Text selected={props.selected === props.data.link}>
                {props.name ? props.name : props.data.title}
              </Text>
            </IconContainer>

            {/* Chevron inside the top-level item */}
            {props.data.subNav && (
              <Chevron open={open}>
                <Icon name={open ? "chevron up" : "chevron down"} size="small" />
              </Chevron>
            )}
          </TopLevelItem>
        </Link>
      ) : (
        <TopLevelItem onClick={toggleOpen}>
          <IconContainer
            selected={props.selected === props.data.link}
            highlight={props.highlight}
          >
            {props.data.image ? (
              <IconImage src={props.data.image} />
            ) : (
              <Icon
                name={props.data.icon}
                size={"small"}
                style={{ width: 20, fontSize: 14, margin: 0, marginLeft: -5 }}
              />
            )}
            <Text selected={props.selected === props.data.link}>
              {props.name ? props.name : props.data.title}
            </Text>
          </IconContainer>

          {/* Chevron inside the top-level item */}
          {props.data.subNav && (
            <Chevron open={open}>
              <Icon name={open ? "chevron up" : "chevron down"} size="small" />
            </Chevron>
          )}
        </TopLevelItem>
      )}

      {/* Sub-navigation items */}
      {props.data.subNav &&
        open &&
        props.data.subNav.map((subItem, id) => (
          <Link to={subItem.link} key={id}>
            <SubNav data={subItem} />
          </Link>
        ))}
    </NavContainer>
  );
};


const SideBar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  const [accountId, setAccountId] = useState(1);
  const [roleId, setRoleId] = useState(0);
  const [employee, setEmployee] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoutes, setValidRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [organizationLogo, setOrganizationLogo] =
    React.useState(InnerLogicLogo);

  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );


  const { get_auth, get_employees, get_organizations, get_selectedOrg } =
    useSelector(
      (state) => ({
        get_auth: state.auth,
        get_employees: state.employees,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  useEffect(() => {
    // if the response is ok 200
    if (Number(get_auth?.organization_id) > 0) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: get_auth.organization_id },
      });
    }
    // dispatch({ type: LOAD_TEAMS });
    dispatch({
      type: SET_WHITE_LABEL,
      payload: white_label_data["Nuwave"],
    });
  }, [dispatch, get_auth]);

  useEffect(() => {
    setOrganizationLogo(organization?.logo);
  }, [organization]);

  useEffect(() => {
    if (get_auth?.token) {
      if (Number(EmployeeId)) {
        // setAccountId(get_employees[get_auth.employee_id]?.account_type);
        setRoleId(get_employees[get_auth.employee_id]?.role);

        setEmployee(get_employees[get_auth.employee_id]);
        setOrganization(get_organizations[get_auth.organization_id]);
        setOrganizationId(get_auth.organization_id);
      }
      if (Number(AmbassadorId)) {
        setAccountId(5);
        // setRoleId(5);
        // setEmployee(get_employees[get_auth.employee_id])
        setOrganization(get_selectedOrg?.organization);
        setOrganizationId(get_selectedOrg?.organization?.id);
      }
      // console.log(get_employees[get_auth.employee_id])
    }
  }, [
    get_employees,
    get_auth,
    get_organizations,
    AmbassadorId,
    EmployeeId,
    get_selectedOrg,
  ]);

  useEffect(() => {
    if (organization?.styling) {
      dispatch({
        type: SET_WHITE_LABEL,
        payload: organization.styling,
      });
    }
  }, [organization, dispatch]);

  useEffect(() => {
    // if (get_auth?.token) {
    const currentPath = location.pathname;
    setSelected(currentPath);
    // }
  }, [location]);

  useEffect(() => {
    const EnabledList = [];
    EnabledList.push("MainMenu", "SubMenu", "Common");
    setValidRoutes(EnabledList);
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization]);


  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999,
        backgroundColor: "white",
      }}
    >
      <Bar
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        
        grains={
          organization?.styling?.nav_background
            ? organization?.styling?.nav_background
            : false
        }
      >
        <LogoContainer>
          <Logo src={organizationLogo} />
        </LogoContainer>

        {NavData.map((item, i) => {
          if (!validRoutes.includes(item.group)) {
            return null;
          }
         
          if (item.header) {
            return <Header open={open} key={i}>{item.header}</Header>;
          }
          return (
            <NavItem
              data={item}
              organization={organization}
              selected={selected}
              open={open}
              key={i}
              highlight={
                organization?.styling?.highlight
                  ? organization?.styling?.highlight
                  : WhiteLabel.highlight
              }
            />
          );
        })}
      </Bar>
    </div>
  );
};

export default SideBar;

const Bar = styled.div`
  width: ${(props) => (props.open ? "225px" : "40px")};
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.5);
  margin: 0px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  box-sizing: content-box;
  position: relative;
  transition: width 400ms ease;
  overflow: hidden;
  padding-bottom: 100px;
  padding-top: 10px;
  height: 100vh;
  background-color: black;
  background-image: ${(props) =>
    props.grains
      ? `radial-gradient(#424242 10%, transparent 70%),
         linear-gradient(to bottom, ${props.background || 'black'}, ${props.background || 'black'})`
      : 'none'};
  background-position: ${(props) => (props.grains ? '0 0, 50px 50px' : 'center')};
  background-size: ${(props) => (props.grains ? '2px 2px, 100% 100%' : 'cover')};
  background-repeat: repeat, no-repeat;
`;
const Logo = styled.img`
  width: 40px;
  margin-right: 15px;
`;

const LogoContainer = styled.div`
  font-size: 35px;
  width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6.5px;
  padding-bottom: 10px;
`;

const NavContainer = styled.div`
  position: relative;
  max-height: ${(props) => (props.open ? "300px" : "25px")};
  overflow: hidden;
  transition: max-height 1s ease;
  width: 100%;
`;

const IconImage = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 6px;
  color: white;
`;

const Header = styled.div`
  color: white;
  font-size: 12px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: bold;
  width: 50px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;


const TopLevelItem = styled.div`
  display: flex;
  justify-content: space-between; /* Chevron aligns to the right */
  align-items: center;
  cursor: pointer;
  padding: 10px 15px; /* Reduce padding to minimize row height */
  background-color: ${(props) => (props.selected ? props.highlight : "transparent")};
`;

const Chevron = styled.div`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0)")};
  color: white;
  margin-left: auto; /* Make sure the chevron is aligned to the right */
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px; /* Adjust font size to fit better */
`;

const Text = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 14px; /* Adjust font size */
  margin-left: 10px; /* Reduce margin for less space */
  color: white;
`;

const SubNavigation = styled.div`
  padding-left: 30px; /* Indent the sub-navigation items */
  padding-bottom: 8px;
  color: white;
  font-size: 12px;
  transition: background 0.3s ease;
  &:hover {
    background-color: #333333; /* Highlight sub-nav item on hover */
  }
`;
