import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import List from "./List.js";
import Details from "./Details.js";
import Create from "./Create.js";
import { Grid, Header, Icon, Button } from "semantic-ui-react";
const URL = "report-templates";
function CategoryStructure() {
  const navigate = useNavigate();
  const location = useLocation();
  const [newResponse, setNewResponse] = useState(0);
  const [loadData, setLoadData] = useState(false);

  function BackArrow() {
    navigate(`/app/${URL}/`);
  }

  function GetNew() {
    setNewResponse(Math.random());
    navigate(`/app/${URL}/create/`);
  }

  const { get_report_template } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_report_template?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_report_template.pending]);

  console.log(location);
  return (
    <>
      <HeaderContainer>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as="h2" style={{ marginTop: "50px" }}>
              <Icon
                style={{ cursor: "pointer" }}
                onClick={BackArrow}
                name="home"
              />
              Report Templates
              <Header.Subheader>
                Used in Data Story and many other places.
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>

        {location?.pathname !== "/app/report-templates/create/" && (
          <AddNewButton disabled={loadData} primary onClick={GetNew}>
            Create New
          </AddNewButton>
        )}
      </HeaderContainer>

      <Routes>
        <Route path="/" element={<List />} />
        <Route
          path="details/:id"
          element={
            <Details
              newResponse={newResponse}
              setNewResponse={setNewResponse}
            />
          }
        />
        <Route
          path="create"
          element={
            <Create newResponse={newResponse} setNewResponse={setNewResponse} />
          }
        />
      </Routes>
    </>
  );
}

export default CategoryStructure;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const HeaderText = styled(Header)`
  margin-top: 40px;
  cursor: pointer;
`;

const AddNewButton = styled(Button)`
  display: ${({ hide }) => (hide ? "none" : "block")};
`;
