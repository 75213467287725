import {
  UPDATE_CHANGE_LOG_PAGE_NUMBER,
  GET_CHANGE_LOG_PENDING,
  GET_CHANGE_LOG,
  DELETE_CHANGE_LOG,
  FETCH_CHANGE_LOG,
  EDIT_CHANGE_LOG,
  ADD_CHANGE_LOG,
  CLEAR_CHANGE_LOG,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  change_log: [],
  pending: false,
  created: false,
  updated: false,
  pageNumber: 1,
};

export default function GptMember(state = initialState, action) {
  switch (action.type) {
    case GET_CHANGE_LOG_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_CHANGE_LOG_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_CHANGE_LOG:
      return {
        ...state,
        change_log: action.payload,
        pending: false,
      };

    case FETCH_CHANGE_LOG:
      return { 
        ...state, 
        [action.payload.id]: action.payload, 
        pending: false 
      };
    case EDIT_CHANGE_LOG:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: action.payload,
        pending: false,
      };
    case DELETE_CHANGE_LOG:
      return {
        ...state,
        deleted: action.payload,
        pending: false,
      };
    case ADD_CHANGE_LOG:
      return {
        ...state,
        created: action.payload,
        pending: false,
      };

    case CLEAR_CHANGE_LOG:
      return {
        ...state,
        ...initialState
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}
