import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_FETCH_SURVEY_STANDARD,
  SG_ADD_SURVEY_STANDARD,
  SG_EDIT_SURVEY_STANDARD,
  SG_DELETE_SURVEY_STANDARD,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
} from "semantic-ui-react";

import { BasicErrorMessage } from "utilities/ErrorMessages";

//
const Details = ({ employee_record_value, setReload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [orgSelected, setOrgSelected] = useState([]);
  const [valueSelected, setValueSelected] = useState([]);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  function BackArrow() {
    navigate(`/app/survey-standard`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_SURVEY_STANDARD,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_survey_standard } = useSelector(
    (state) => ({
      get_survey_standard: state.survey_standard,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_survey_standard?.[templateId]) {
      setTemplateDetails(get_survey_standard?.[templateId]);
    }
  }, [get_survey_standard, templateId]);

  useEffect(() => {
    if (get_survey_standard?.created?.id) {
      navigate(
        `/app/survey-standard/details/${get_survey_standard?.created?.id}`
      );
    }
  }, [get_survey_standard.created, navigate]);

  useEffect(() => {
    if (get_survey_standard?.deleted) {
      setReload(Math.random());
      const timer1 = setTimeout(() => {
        navigate(`/app/survey-standard`);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [get_survey_standard.deleted, navigate, setReload]);

  function UpdateQuestions() {
    setPristine(false);
    if (formErrors.length === 0) {
      const sortOrder = templateDetails?.question_sort_order.split(",").map(num => parseInt(num.trim()));

        dispatch({
          type: SG_ADD_SURVEY_STANDARD,
          payload: {
            name: templateDetails?.name,
            question_sort_order: sortOrder,
          },
        });
        // navigate(`/app/survey-standard/detail/${templateId}`);
  
      setPristine(true);
    }
  }

  // console.log(get_survey_standard)

  useEffect(() => {
    const ErrorList = [];
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name");
    }

    if (!templateDetails?.question_sort_order) {
      ErrorList.push("Add an Question Sort Order");
    }

    setFormErrors(ErrorList);
  }, [templateDetails, valueSelected, orgSelected, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = templateDetails;
    _ques[`${name}`] = e.target.value;
    setTemplateDetails(_ques);
    setValueChanged(e.target.value + name);
  }


  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Add New Survey Standard
      </Header>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>

      <SectionDiv>
        <FormRow>
          <FormLabel>
            <LabelHead>Name</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Question Sort Order</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              name="replace_by"
              defaultValue={templateDetails?.question_sort_order}
              onChange={(e) => ChangeFormValues(e, "question_sort_order")}
            />
          </FormInput>
        </FormRow>
      </SectionDiv>

      <SectionDiv lastSection={true}>
        <Button floated="right" color="green" onClick={UpdateQuestions}>
          Create
        </Button>
      </SectionDiv>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
