import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from 'date-fns'

import { Card, Image, Button, List, Icon } from 'semantic-ui-react'


const RowCard = ({ row }) => {
  const navigate = useNavigate()
  // console.log(row)
  return (
    <>
      <Card fluid key={row?.id}>

        <Card.Content>
          <Card.Header>{row?.id} # {row?.first_name}{" "}{row?.last_name}</Card.Header>
         <BoxContainer>
          <Box>
            {row?.email}{" "}{row?.mobile}
          </Box>
          <Box>
            Created:{" "}{row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy hh:mm')}{" Last Updated: "}{row?.updated_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy hh:mm')}
          </Box>
          </BoxContainer> 

          <Container>

            <List horizontal>
              <List.Item>
                <List.Content>
                <ListImageContainer>
                  <Image avatar src={row?.picture} />
                </ListImageContainer>
                  
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                  <List.Header>Account Type</List.Header>
                  {row?.account_type_text}
                </ListContainer>
    
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Role</List.Header>
                  {row?.role_text}
                </ListContainer>
                 
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                  <List.Header>Email Status</List.Header>
                  {row?.email_status_text}
                </ListContainer>
                  
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Team</List.Header>
                  {row?.team_name}
                </ListContainer>
                 
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Login Enabled</List.Header>
                  {row?.enable_login? "Yes" :"No"}
                </ListContainer>
                 
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>User Code</List.Header>
                  {row?.user_code}
                </ListContainer>
                 
                </List.Content>
              </List.Item>
            </List>
          </Container>
          <Container>

            <List horizontal>
            <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Org ID</List.Header>
                  {row?.organization}
                </ListContainer>
                  </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Joined Date</List.Header>
                  {row?.join_date && format(new Date(row?.join_date), 'dd-MMM-yyyy')}
                </ListContainer>
                  </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Survey Status</List.Header>
                  {row?.survey_status === 1? "Yes" : "No"}
                </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                <ListContainer>
                   <List.Header>Add Survey users</List.Header>
                  {row?.survey_add_users === 1? "Yes" : "No"}
                </ListContainer>
                 
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                     <List.Header>Can Schedule Surveys</List.Header>
                  {row?.survey_schedule_text}
                  </ListContainer>
                 
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                     <List.Header>Status</List.Header>
                     {row?.status === 1 ?
                <Icon color='green' name='check' />:
                <Icon color='red' name='archive' /> }
                <small>{row?.status_text}</small>
                  </ListContainer>
                 
                </List.Content>
              </List.Item>
            </List>
          </Container>

          <Card.Description>
            <Button floated='right'
              onClick={() => navigate(`/app/employees/details/${row?.id}`)}>
              More Details
            </Button>
          </Card.Description>
        </Card.Content>

      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;


const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const ListImageContainer = styled.div`
  margin: 10px 10px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`
const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Box = styled.div`
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 0px 10px;
`;
