import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ReactJson from "react-json-view";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_FETCH_SURVEY_STANDARD,
  SG_ADD_SURVEY_STANDARD,
  SG_EDIT_SURVEY_STANDARD,
  SG_DELETE_SURVEY_STANDARD,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages";

import DataRenderer from "./DataRender";

//
const Details = ({ employee_record_value, setReload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [orgSelected, setOrgSelected] = useState([]);
  const [valueSelected, setValueSelected] = useState([]);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  function BackArrow() {
    navigate(`/app/survey-standard`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_SURVEY_STANDARD,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_survey_standard } = useSelector(
    (state) => ({
      get_survey_standard: state.survey_standard,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_survey_standard?.[templateId]) {
      setTemplateDetails(get_survey_standard?.[templateId]);
    }
  }, [get_survey_standard, templateId]);

  useEffect(() => {
    if (get_survey_standard?.created?.id) {
      navigate(
        `/app/survey-standard/details/${get_survey_standard?.created?.id}`
      );
    }
  }, [get_survey_standard, navigate]);

  useEffect(() => {
    if (get_survey_standard?.deleted) {
      setReload(Math.random());
      const timer1 = setTimeout(() => {
        navigate(`/app/survey-standard`);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [get_survey_standard, navigate, setReload]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_SURVEY_STANDARD,
        payload: {
          id: templateId,
        },
      });

      // navigate(`/app/survey-standard?reload=true`)
    }
  }
  // function UpdateQuestions() {
  //   setPristine(false)
  //   if (formErrors.length === 0) {
  //     if (templateId) {
  //       dispatch({
  //         type: SG_EDIT_SURVEY_STANDARD,
  //         payload: {
  //           id: templateId,
  //           organization: orgSelected?.id,
  //           name: templateDetails?.name,
  //           replace_by: templateDetails?.replace_by,
  //           employee_record_value: valueSelected?.id,
  //         }
  //       });
  //       navigate(`/app/survey-standard/detail/${templateId}`)
  //     }
  //     setPristine(true)
  //   }
  // }

  useEffect(() => {
    const ErrorList = [];
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name");
    }
    if (!valueSelected?.id) {
      ErrorList.push("Select a Value Name");
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization");
    }

    setFormErrors(ErrorList);
  }, [templateDetails, valueSelected, orgSelected, valueChanged]);

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {templateId
          ? `Edit Details of ${templateDetails?.name}`
          : "Add New List Value"}
      </Header>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId && (
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Name</List.Header>
                    {templateDetails?.name}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Questions (sort Order)</List.Header>

                    <SectionDiv>
                      <ReactJson src={templateDetails?.question_sort_order} />
                    </SectionDiv>
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Next Run At</List.Header>
                    {templateDetails?.created_at &&
                      format(
                        new Date(templateDetails?.run_next_on),
                        "dd-MMM-yyyy hh:mm a"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Updated At</List.Header>
                    {templateDetails?.created_at &&
                      format(
                        new Date(templateDetails?.updated_at),
                        "dd-MMM-yyyy hh:mm a"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>
        </SectionDiv>
      )}
      <SectionDiv>
        <Button floated="right" color="red" onClick={DeleteQuestion}>
          Delete
        </Button>
      </SectionDiv>

      <SectionDiv>
        <ReactJson src={templateDetails?.response} />
      </SectionDiv>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
