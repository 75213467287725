import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import ReactJson from "react-json-view";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_APP_SERVER_LOG, } from "constants/actions";
//
import { Icon, Header, List, Dimmer, Loader } from "semantic-ui-react";
//
import ClickToCopy from "utilities/ClickToCopy";
//
import { format } from "date-fns";
//
import { BasicErrorMessage } from "utilities/ErrorMessages";
import LogList from "appserver/log/List";
import CreateLog from "appserver/log/Create";
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  const { get_app_server_log } = useSelector(
    (state) => ({
      get_app_server_log: state.app_server_log,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_APP_SERVER_LOG,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    if (get_app_server_log?.[templateId]) {
      setTemplateDetails(get_app_server_log?.[templateId]);
    }
    setLoading(get_app_server_log?.pending);
  }, [get_app_server_log, templateId]);

  // console.log("templateDetails", templateDetails);

  function BackArrow() {
    navigate(`/app/app-server/details/${templateDetails?.app_server}`);
  }



  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {templateId && `More Details of ${templateDetails?.server_name} # ${templateDetails?.server_ip}`}
      </Header>

      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId && (
        <>
          <SectionDiv>
            <Container>
              <List horizontal>
                <ListItem label="Id" value={templateDetails?.id} />
                <ListItem
                  label="Created"
                  value={
                    templateDetails?.created_at &&
                    format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")
                  }
                />
                 <ListItem
                  label="Updated"
                  value={
                    templateDetails?.updated_at &&
                    format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")
                  }
                />
                <ListItem label="Name" value={templateDetails?.employee_full_name} />
                <ListItem label="Url" value={templateDetails?.server_url} />
                <ListItem label="Update Type" value={`${templateDetails?.update_type} # ${templateDetails?.update_type_text}` } />
               
                {/* tech_stack_text */}
              </List>
            </Container>
          </SectionDiv>

          <SectionDiv>
           {templateDetails?.details?
           <ReactJson src={templateDetails?.details} /> :
            <p>Nothing generated yet</p>
          } 
          
          </SectionDiv>
        </>
      )}

      <Dimmer.Dimmable blurring dimmed={loading}>
        <Dimmer active={loading}>
          <Loader />
          <Header as="h2" icon inverted>
            Loading, Please wait.
          </Header>
        </Dimmer>
      </Dimmer.Dimmable>

      
    </>
  );
};

export default Details;

const ListItem = ({ label, value }) => {
  return (
    <List.Item>
      <List.Content>
        <ListContainer>
          <List.Header>{label}</List.Header>
          {value}
        </ListContainer>
      </List.Content>
    </List.Item>
  );
};
const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
const TableRow = styled.div`
  height: 82px;
  display: flex;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.87);
  }
`;

const TableContent = styled.div`
  width: 75%;
  padding-left: 10px;
  text-align: inherit;
  align-self: center;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  // margin-right: 150px;
  color: #7e7e7e;
`;

const TableHeading = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-top: 35px;
`;

const CategorySection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
`;

const CategoryRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  justify-content: space-between;
`;
