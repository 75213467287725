import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import ReactJson from "react-json-view";

import {
  Accordion,
  Icon,
  Button,
  Loader,
  Dimmer,
  Message,
} from "semantic-ui-react";


const ViewDetails = () => {


  const { get_debrief_summary, get_debrief_analytics, get_auth } = useSelector(
    (state) => ({
      get_debrief_summary: state.debrief_summary,
      get_debrief_analytics: state.debrief_analytics,
      get_auth: state.auth,
    }),
    shallowEqual
  );


  const [loadData, setLoadData] = useState(false);
  const [debriefSummary, setDebriefSummary] = useState(false);


  useEffect(() => {
    if (get_debrief_summary?.debrief_summary_next) {
      setDebriefSummary(get_debrief_summary?.debrief_summary_next);
    }
  }, [get_debrief_summary?.debrief_summary_next]);


  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_debrief_summary?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_debrief_summary.pending]);




  // console.log(question);
  // console.log(formErrors);

  return (
    <Container>
      <Dimmer active={loadData}>
        <Loader />
      </Dimmer>

      {debriefSummary?.id ? (
        <>
         
         {debriefSummary?.feedback && (
            <Title>{debriefSummary?.feedback?.length} Feedback Found</Title>
          )}

         <ReactJson
          src={debriefSummary?.feedback}
         
        />
          
        </>
      ) : (
        <>
          No feedback found
        </>
      )}
    </Container>
  );
};

export default ViewDetails;

const Container = styled.div`
  margin-top: 50px;
  padding-left: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Question = styled.div`
  font-weight: bold;
`;

const Needs = styled.div`
  white-space: pre-wrap;
  width: 50%;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const EvaluationArea = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  max-height: 700px;
  overflow-y: scroll;
`;

const Article = styled.div`
  width: 50%;
  padding: 20px;
`;

const QuestionArea = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const T2 = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const P = styled.p`
  white-space: pre-wrap;
`;

const AddNewButton = styled(Button)`
  display: ${({ hide }) => (hide ? "none" : "block")};
`;
