import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'
import { useDispatch } from "react-redux";

import {
    CLEAR_APP_SERVER_UPDATE,
  } from "constants/actions";

// 
const EmployeeRecordValue = ({ rows }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    function EditRow(row) {
        dispatch({
            type: CLEAR_APP_SERVER_UPDATE,
          });
        navigate(`/app/app-server/edit/${row?.id}`)
    }

    return (

        <>
            <Table>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1}>#ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={2}>IP</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i}>
                            <Table.Cell>{row?.id}</Table.Cell>
                            <Table.Cell>{row?.name}</Table.Cell>
                            <Table.Cell>{row?.server_ip}</Table.Cell>
                            <Table.Cell>{row?.status_text}
                              
                            </Table.Cell>
                            <Table.Cell>
                            <Icon style={{cursor: "pointer"}} 
                            onClick={() => EditRow(row)}
                            name='edit outline' />
                            </Table.Cell>
                            <Table.Cell>
                            <Icon style={{cursor: "pointer"}} 
                            onClick={() => navigate(`/app/app-server/details/${row?.id}`)}
                            name='angle double right' />
                            </Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

EmployeeRecordValue.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default EmployeeRecordValue;