import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from 'date-fns'

import { Card, Image, Button, List, Icon } from 'semantic-ui-react'


const RowCard = ({ row, setEditModelMain }) => {
  const navigate = useNavigate()
  // console.log(row)
  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Container>
            <List horizontal>
            <List.Item>
                <ListContainer>
                   <List.Header>ID</List.Header>
                   {row?.id}
                </ListContainer>
              </List.Item>

              <List.Item>
                <ListContainer>
                   <List.Header>Model</List.Header>
                   {row?.name}
                </ListContainer>
              </List.Item>
              {/* row */}
              <List.Item>
                <ListContainer>
                   <List.Header>Status</List.Header>
                   {row?.status_text}
                </ListContainer>
              </List.Item>

              <List.Item>
                <ListContainer>
                   <List.Header>URL</List.Header>
                   {row?.url}{" "}
                </ListContainer>
              </List.Item>
              {/* row */}
              <List.Item>
                  <ListContainer>
                     <List.Header>Created</List.Header>
                     {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy')}
                  </ListContainer>
              </List.Item>
              {/* row */}
              <List.Item>
                  <ListContainer>
                     <List.Header>Updated</List.Header>
                     {row?.updated_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy')}
                  </ListContainer>
              </List.Item>
              {/* row */}
            </List>
          </Container>

          <Card.Description>
          <Button floated='left'
              color="purple"
              onClick={() => setEditModelMain(row)}>
              Edit
            </Button>

            <Button floated='right'
              onClick={() => navigate(`/app/gpt/details/${row?.id}`)}>
              View Survey ...
            </Button>
          </Card.Description>
        </Card.Content>

      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;


const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const ListImageContainer = styled.div`
  margin: 10px 10px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`
