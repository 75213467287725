import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import {
  SG_GET_ORGANIZATION_SELECT,
  SG_GET_PRODUCT_PACKAGE_SELECT,
  SG_GET_PRODUCT_PRICE_SELECT,
  SG_FETCH_PROD_ORG_CONTRACT,
  SG_EDIT_PROD_ORG_CONTRACT,
  SG_ADD_PROD_ORG_CONTRACT,
  SG_DELETE_PROD_ORG_CONTRACT,
} from "constants/actions";
import { Icon, Message, Button, Header, Confirm } from "semantic-ui-react";
import { BasicErrorMessage } from "utilities/ErrorMessages";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [organizationSelected, setOrganizationSelected] = useState([]);
  const [priceSelected, setPriceSelected] = useState([]);
  const [prodPackageSelected, setProdPackageSelected] = useState([]);
  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: "",
    active: true,
    sort_order: 0,
    description: "",
    product_stage: "beta", // default value
    version: "",
    associate_product: null,
    start_date: null,
    end_date: null,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    dispatch({ type: SG_GET_ORGANIZATION_SELECT });
    dispatch({ type: SG_GET_PRODUCT_PACKAGE_SELECT });
    dispatch({ type: SG_GET_PRODUCT_PRICE_SELECT });
  }, [dispatch]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_PROD_ORG_CONTRACT,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const {
    get_prod_org_contract,
    get_product_package_select,
    get_product_price_select,
    get_organization_select,
  } = useSelector(
    (state) => ({
      get_prod_org_contract: state.prod_org_contract,
      get_product_package_select: state.product_package.select_list,
      get_product_price_select: state.product_price.select_list,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_prod_org_contract?.[templateId]) {
      setTemplateDetails(get_prod_org_contract?.[templateId]);
      setProdPackageSelected(
        get_product_package_select.find(
          (pg) =>
            pg.id === get_prod_org_contract?.[templateId].product_package_id
        )
      );
      setPriceSelected(
        get_product_price_select.find(
          (pg) => pg.id === get_prod_org_contract?.[templateId].price
        )
      );
      setOrganizationSelected(
        get_organization_select.find(
          (pg) => pg.id === get_prod_org_contract?.[templateId].organization
        )
      );
    }
  }, [
    get_prod_org_contract,
    templateId,
    get_product_package_select,
    get_organization_select,
    get_product_price_select,
  ]);

  useEffect(() => {
    if (get_prod_org_contract?.updated?.id) {
      navigate(
        `/app/predictive-model/details/${get_prod_org_contract.updated.id}`
      );
    }
  }, [get_prod_org_contract.updated, navigate]);

  useEffect(() => {
    const ErrorList = [];

    if (!templateDetails?.start_date) {
      ErrorList.push("Select a start date");
    }

    if (
      templateDetails?.end_date &&
      templateDetails?.end_date < templateDetails?.start_date
    ) {
      ErrorList.push("End date should be greater than start date");
    }

    if (!organizationSelected?.id) {
      ErrorList.push("Select an Organization");
    }

    if (!priceSelected?.id) {
      ErrorList.push("Select a Price");
    }

    if (!prodPackageSelected?.id) {
      ErrorList.push("Select a product package");
    }

    setFormErrors(ErrorList);
  }, [
    templateDetails,
    prodPackageSelected,
    priceSelected,
    organizationSelected,
  ]);

  const handleDateChange = (date, name) => {
    setTemplateDetails({ ...templateDetails, [name]: date });
  };

  const handleSubmit = () => {
    setPristine(false);

    if (formErrors.length === 0) {
      const payload = {
        // ...templateDetails,

        product_package: prodPackageSelected.id,
        organization: organizationSelected.id,
        price: priceSelected.id,
        start_date: templateDetails.start_date,
        end_date: templateDetails.end_date,
      };

      if (templateId) {
        dispatch({
          type: SG_EDIT_PROD_ORG_CONTRACT,
          payload: { id: templateId, ...payload },
        });
      } else {
        dispatch({
          type: SG_ADD_PROD_ORG_CONTRACT,
          payload,
        });
      }
    }
  };

  const handleDelete = () => {
    if (templateId) {
      dispatch({
        type: SG_DELETE_PROD_ORG_CONTRACT,
        payload: { id: templateId },
      });
    }
  };

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/app/product`)}
          name="arrow left"
        />
        {templateId
          ? `Edit Details of ${templateDetails?.name}`
          : "Add New Product"}
      </Header>

      <BasicErrorMessage />
      {formErrors.length > 0 && !pristine && (
        <Message negative header="We need these details!" list={formErrors} />
      )}

      <FormColumn>
        <FormSection>
          <LabelHead>Organization</LabelHead>
          <Select
            name="organization"
            options={get_organization_select}
            onChange={(e) => setOrganizationSelected(e)}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={organizationSelected}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Product Package</LabelHead>
          <Select
            name="product_package"
            options={get_product_package_select}
            onChange={(e) => setProdPackageSelected(e)}
            getOptionLabel={(option) => `${option.name} `}
            getOptionValue={(option) => `${option.id}`}
            value={prodPackageSelected}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Price</LabelHead>
          <Select
            name="price"
            options={get_product_price_select}
            onChange={(e) => setPriceSelected(e)}
            getOptionLabel={(option) => `${option.name} (${option.amount})`}
            getOptionValue={(option) => `${option.id}`}
            value={priceSelected}
          />
        </FormSection>

        <FormColumn>
          <FormSection>
            <LabelHead>Start Date</LabelHead>
            <DatePicker
              selected={
                templateDetails.start_date
                  ? new Date(templateDetails.start_date)
                  : null
              }
              onChange={(date) => handleDateChange(date, "start_date")}
              dateFormat="yyyy-MM-dd"
              isClearable // Allow clearing the date if needed
              placeholderText="Select a start date"
            />
          </FormSection>

          <FormSection>
            <LabelHead>End Date</LabelHead>
            <DatePicker
              selected={
                templateDetails.end_date
                  ? new Date(templateDetails.end_date)
                  : null
              }
              onChange={(date) => handleDateChange(date, "end_date")}
              dateFormat="yyyy-MM-dd"
              minDate={
                templateDetails.start_date
                  ? new Date(templateDetails.start_date)
                  : null
              }
              isClearable // Allow clearing the date if needed
              placeholderText="Select an end date"
            />
          </FormSection>
        </FormColumn>
      </FormColumn>

      <SectionDiv lastSection={true}>
        {templateId && (
          <>
            <Button
              floated="left"
              color="red"
              onClick={() => setConfirmOpen(true)}
            >
              Delete Product Group
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={handleDelete}
            />
          </>
        )}

        <Button floated="right" color="green" onClick={handleSubmit}>
          {templateId ? "Update" : "Create"}
        </Button>
      </SectionDiv>
    </>
  );
};

export default Details;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const SectionDiv = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;
