import React from "react";
import { Routes, Route } from "react-router-dom";
import List from "./List";
import Details from "./Details";
import Templates from "./Templates";
import { Header } from "semantic-ui-react";

function PdfReports() {
  return (
    <>
      <Header as="h2" style={{ marginTop: "20px" }}>
        PDF Reports
        <Header.Subheader>List of PDF reports</Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="templates" element={<Templates />} />
      </Routes>
    </>
  );
}

export default PdfReports;
