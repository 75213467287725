import React from 'react';
import BriefResponseList from './List';

const ListRecent = () => {
    return (
        <div>
            <h2>Recent Items</h2>
            {/* Add your list rendering logic here */}
            <BriefResponseList recent="true" />
        </div>
    );
};

export default ListRecent;