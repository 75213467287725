import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Segment, Dimmer, Loader, Card, Pagination, Button, } from 'semantic-ui-react'

import RowCard from "./RowCard";
import SearchBar from "./SearchBar";
import { SURVEY_TYPE, STATUS } from "data/AdminData"

import Select from "react-select";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  UPDATE_SURVEY_QUESTION_PAGE_NUMBER,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";


const Categories = ({ setOrgName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  // const [organizationSelect, setOrganizationSelect] = useState([])
  const [statusSelected, setStatusSelected] = useState([])
  const [surveyTypeSelected, setSurveyTypeSelected] = useState([])
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { get_survey_question,
    get_survey_pageNumber } = useSelector(
    (state) => ({
      get_survey_question: state.surveyquestion.survey_question,
      get_survey_pageNumber: state.surveyquestion.survey_question.pageNumber,
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `page=${page}${searchTerm ? `&sort_order=${searchTerm}` : ''}`
      });
  }, [dispatch, page, searchTerm]);

  console.log("searchTerm", searchTerm)

  useEffect(() => {
    setResults(get_survey_question?.results);
    setTotalPages(Math.ceil(get_survey_question?.count / get_survey_question?.page_size))
    if (get_survey_question?.count % get_survey_question?.page_size) {
      setTotalPages(Math.ceil(get_survey_question?.count / get_survey_question?.page_size))
    }
  }, [get_survey_question]);

  useEffect(() => {
    setPage(get_survey_pageNumber ? get_survey_pageNumber : 1)
  }, [get_survey_pageNumber]);

  useEffect(() => {
    if (ReloadPage) {
      console.log("reload page")
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: page ? `page=${page}` : ''
      });
    }
  }, [ReloadPage, navigate, dispatch, page]);

  function handlePageChange(event, value) {
    setLoading(true)

    setPage(value.activePage);
    dispatch({
      type: UPDATE_SURVEY_QUESTION_PAGE_NUMBER,
      payload: value.activePage,
    });
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000);
    return timer
  }

  function UpdateQuestions() {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `${statusSelected?.value ? `&status=${statusSelected?.value}` : ''}${surveyTypeSelected?.value ? `&survey_type=${surveyTypeSelected.value}` : ''}`
    });
  }


  return (
    <Container>
      <Message
        color='orange'
        icon='warning sign'
        header='Do not Modify before you read this'
        content={
          `This data is crucial for the entire survey, please 
          donot change or add anything to this page without 
          consulting support team. Any errors would appear below this message. 
          Please note even if there are no errors things may break if you
          change this file without proper knowledge. To modify please contact
          support.
        `}
      />


      <SingleRowContent>
      <SectionCell>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </SectionCell>
        <SectionCell>

          <Select
            name="survey_preference"
            options={SURVEY_TYPE}
            isClearable
            onChange={setSurveyTypeSelected}
            // getOptionLabel={(option) => `${option.name}`}
            // getOptionValue={(option) => `${option.id}`}
            value={surveyTypeSelected}
          />
        </SectionCell>
        <SectionCell>
          <Select
            name="survey_preference"
            options={STATUS}
            isClearable
            onChange={setStatusSelected}
            // getOptionLabel={(option) => `${option.name}`}
            // getOptionValue={(option) => `${option.id}`}
            value={statusSelected}
          />

        </SectionCell>
       
        <SectionCell>
          <Button onClick={UpdateQuestions}>Filter</Button>
          <Button
            floated='right'
            color="green"
            onClick={() => navigate(`/app/questions/new/`)}>
            Add New
          </Button>
        </SectionCell>
       

      </SingleRowContent>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results && results.map((result) => (
            <RowCard row={result} key={result?.id} />
          ))}
        </Card.Group>
      </Segment>
      <SectionDiv>

        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages ? totalPages : 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default Categories;

const Container = styled.div`
  margin-bottom: 30px;
`;

const CenteredObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 20px 15px;
    min-width: 300px
`

const Table = styled.div`
  width: 100%;
  margin-top: 30px;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;


