import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_GET_WEB_API_LOGS,
    SG_GET_ORGANIZATION_SELECT,
    SG_GET_WEB_API_CRED_LIST,
    UPDATE_WEB_API_LOG_PAGE_NUMBER
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({ hideOrgSelect }) => {

    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    const [valueNames, setValueNames] = useState([])


    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        dispatch({
            type: SG_GET_ORGANIZATION_SELECT,
        });
    }, [dispatch]);

    useEffect(() => {
        if (organizationId) {
            dispatch({
                type: SG_GET_WEB_API_CRED_LIST,
                payload: `${organizationId ? `&organization=${organizationId}` : ''}`,
            });
        }
    }, [dispatch, organizationId]);

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_WEB_API_LOGS,
            payload: `page=${page}${organizationId ? `&organization=${organizationId}` : ''}${searchTerm ? `&search=${searchTerm}` : ''}`,
        });
    }, [dispatch, page, searchTerm, organizationId,
        resetPageLoad]);

    const { get_web_api_cred_list,
        get_web_api_log,
        get_selectedOrg } = useSelector(
            (state) => ({
                get_web_api_cred_list: state.web_api_credential.web_api_cred_list,
                get_web_api_log: state.web_api_log,
                get_selectedOrg: state.selectedOrg,
                // web_api_cred_list
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationId(get_selectedOrg?.organization?.id)
        // setResetPageLoad(!resetPageLoad)
    }, [get_selectedOrg]);

    useEffect(() => {
        setValueNames(get_web_api_cred_list?.web_api_cred_list)
    }, [get_web_api_cred_list]);


    useEffect(() => {
        setResults(get_web_api_log?.web_api_log?.results);
        setPage(get_web_api_log?.pageNumber)
        setTotalPages(Math.ceil(get_web_api_log?.web_api_log?.count / get_web_api_log?.web_api_log?.page_size))
        if (get_web_api_log?.web_api_log?.count % get_web_api_log?.web_api_log?.page_size) {
            setTotalPages(Math.ceil(get_web_api_log?.web_api_log?.count / get_web_api_log?.web_api_log?.page_size))
        }
        setLoading(get_web_api_log?.pending)
    }, [get_web_api_log, page]);

    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_WEB_API_LOG_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    // useEffect(() => {
    //     if (!get_web_api_log?.pending) {
    //         const timer1 = setTimeout(() => {
    //             setLoading(get_web_api_log?.pending)
    //         }, 2000);
    //         return () => clearTimeout(timer1);
    //     }
    // }, [get_web_api_log]);

    // console.log(get_web_api_log)

    return (

        <Container>

            {!hideOrgSelect &&
                <OrganizationSelect
                    setResetPageLoad={setResetPageLoad} />
            }

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results &&
                        <RowCard
                            rows={results}
                            valueNames={valueNames}
                        />
                    }
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`