import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Image from 'assets/images/empty/graph.jpg'
import styled from "styled-components";
import {
    SG_LOGIN_UPDATE_ACCOUNT_DEFAULT, LOGOUT_REQUEST, CLEAR_ALL
} from "constants/actions";

import Select from "react-select";
import { Domain } from "WebConfig"

import UserErrorMessages from "./UserErrorMessages";

import { Message, Segment, Dimmer, Loader } from "semantic-ui-react";



const SelectOrg = ({ userInputRequired = false }) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [email, setEmail] = useState('')

    const [orgList, setOrgList] = useState([])
    const [selectedOrg, setSelectedOrg] = useState(false)

    const [formErrors, setFormErrors] = useState({});
    const [pristine, setPristine] = useState(true);

    const [userAuth, setUserAuth] = useState(false)
    const [pending, setPending] = useState(false)
    const [toPath, setToPath] = useState(false)


    const { get_auth } = useSelector(
        (state) => ({
            get_auth: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        setUserAuth(get_auth)
        setOrgList(get_auth?.organization_list)
        setEmail(get_auth?.user?.email)
        setPending(get_auth?.pending)
    }, [get_auth]);


    useEffect(() => {
        if (!get_auth?.token) {
            return navigate("/account/login");
        }

    }, [navigate, get_auth]);


    const [loginInput, setLoginInput] = useState(true)
    const [userInput, setUserInput] = useState(true)
    //   const [checkForStaff, setCheckForStaff] = useState(false);
    const IsStaff = useSelector((state) => state.auth?.user?.is_staff, shallowEqual)

    //   useEffect(() => {
    //     if (typeof IsStaff == "boolean") {
    //       // variable is a boolean
    //       if (IsStaff) {
    //         setCheckForStaff(true)
    //       }
    //       console.log("IsStaff Boolean")
    //     }
    //     if (typeof IsStaff == "string") {
    //       // variable is a boolean
    //       if (IsStaff === 'true') {
    //         setCheckForStaff(true)
    //       }
    //       console.log("IsStaff string")
    //     }

    //   }, [IsStaff]);
    // console.log(NavList)
    // useEffect(() => {
    //   const timer1 = setTimeout(() => {
    //     if (!checkForStaff) {
    //       return navigate('/app/no-access')

    //     }
    //   }, 2000);
    //   return () => clearTimeout(timer1);
    // }, [checkForStaff, navigate]);

    useEffect(() => {
        setUserInput(userInputRequired)
    }, [userInputRequired]);



    useEffect(() => {
        const ErrorList = [];
        let totalErrors = 0

        if (orgList.length > 0) {
            if (!selectedOrg.AccountID) {
                ErrorList["organization"] =
                {
                    error: true,
                    message: "Please select an Organization"
                };
                totalErrors += 1
            }
        }

        ErrorList['errors'] = totalErrors
        setFormErrors(ErrorList);
    }, [
        orgList, selectedOrg, pristine,
    ]);

    useEffect(() => {
        setLoginInput(false)
        if (!IsStaff) {
            setLoginInput(
                {
                    error: true,
                    message: `Please select a Organization where you are the staff, only staff members can login`
                }
            )
        }
    }, [IsStaff, get_auth]);

    // console.log(get_auth?.token)

    function Logout() {
       navigate("/account/logout")
    }

    const onSubmit = () => {

        if (formErrors?.errors > 0) {
            setPristine(false);
            return;
        }


        dispatch({
            type: SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
            payload: {
                email: email,
                account_id: selectedOrg?.AccountID,
                employee_id: selectedOrg?.EmployeeID,
            },
        });
        setPristine(true);
        setUserInput(false)

    };

    useEffect(() => {
        setToPath(false)
        const timer1 = setTimeout(() => {
            if (IsStaff) {
                if (get_auth?.token === get_auth?.account_default?.verify_token && !userInput) {
                    setToPath({ pathname: "/app/dashboard" })

                }
            }
        }, 2000);
        return () => clearTimeout(timer1);


    }, [get_auth, userInput, IsStaff]);

    function ChangeSelectedOrg(e) {
        setLoginInput(false)
        setSelectedOrg(e)
    }

    if (pending) {
        return (
            <Segment basic>
                <Dimmer active>
                    <Loader />
                </Dimmer>
            </Segment>
        )
    }

    if (toPath?.pathname === "/app/dashboard" && IsStaff === true) {
        return <Navigate to={toPath} />;
    }


    return (
        <Container>
            <FormContainer>
                <Box>
                    <IMG src={Image} />
                    <UserErrorMessages />
                    {loginInput?.error && (
                        <Message
                            error
                            // header={props.message}
                            content={loginInput?.message}
                        />
                    )}
                    <>
                        <Label>Select an Organization</Label>
                        <Select
                            name="organization"
                            options={orgList}
                            onChange={e => ChangeSelectedOrg(e)}
                            getOptionLabel={(option) => `${option.Name} (${option.OrganizationID})`}
                            getOptionValue={(option) => `${option.OrganizationID}`}
                            value={selectedOrg}
                        />
                        {formErrors?.organization?.error && !pristine && (
                            <Message
                                error
                                // header={props.message}
                                content={formErrors?.organization?.message}
                            />
                        )}
                    </>
                    <Button onClick={onSubmit} id="sign-in">
                        Select Org
                    </Button>
                    <Bottom>
                        <div onClick={Logout}>
                            Logout
                        </div>
                        <div></div>
                    </Bottom>
                </Box>
            </FormContainer>
        </Container>
    );
};

export default SelectOrg;

const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  width: 160px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 100px 30px 0 30px;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  color: blue;
  font-size: 16px;
  cursor: pointer;
  font-weight:900;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
    font-weight: bold;
  }
`;
