import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";
import { format } from "date-fns";
import {
  SG_GET_ORGANIZATION_SELECT,
  SG_RUN_MODEL_OUTCOME,
} from "constants/actions";
import { Icon, Message, Button, Header, Input } from "semantic-ui-react";
import { BasicErrorMessage } from "utilities/ErrorMessages";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [organizationSelected, setOrganizationSelected] = useState([]);
  const [templateDetails, setTemplateDetails] = useState({
    name: "",
    sort_order: 0,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  // Fetch product packages on mount
  useEffect(() => {
    dispatch({ type: SG_GET_ORGANIZATION_SELECT });
  }, [dispatch]);

  const { get_organization_select, get_predictive_model } = useSelector(
    (state) => ({
      get_organization_select: state.organizations.organization_select,
      get_predictive_model: state.predictive_model,
    }),
    shallowEqual
  );

  // // Navigate to duplicated package when updated
  // useEffect(() => {
  //   if (get_predictive_model?.updated?.id) {
  //     navigate(
  //       `/app/predictive-model/edit/${get_predictive_model?.updated?.id}`
  //     );
  //   }
  // }, [get_predictive_model, navigate]);

  // Validate inputs
  useEffect(() => {
    const ErrorList = [];

    if (!organizationSelected?.id) {
      ErrorList.push("Select an Organization");
    }

    setFormErrors(ErrorList);
  }, [templateDetails, organizationSelected]);

  const handleDuplicate = () => {
    setPristine(false);

    if (formErrors.length === 0) {
      dispatch({
        type: SG_RUN_MODEL_OUTCOME,
        payload: {
          organization_id: organizationSelected.id,
        },
      });
    }
  };

  return (
    <>
      <Header as="h2">
        
        Generate Predictive Model
      </Header>

      <BasicErrorMessage />
      {formErrors.length > 0 && !pristine && (
        <Message negative header="We need these details!" list={formErrors} />
      )}

      <FormColumn>
        <FormSection>
          <LabelHead>Select Organization</LabelHead>
          <Select
            name="organization"
            options={get_organization_select}
            onChange={(e) => setOrganizationSelected(e)}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={organizationSelected}
          />
        </FormSection>
      </FormColumn>

      <SectionDiv lastSection={true}>
        <Button floated="right" color="green" onClick={handleDuplicate}>
          Generate
        </Button>
      </SectionDiv>
    </>
  );
};

export default Details;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const SectionDiv = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;
