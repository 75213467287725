import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  // SG_GET_APP_LIST_URLS
  SG_GET_ORGANIZATION_SELECT,
  SG_FETCH_APP_LIST_URL,
  SG_EDIT_APP_LIST_URL,

} from "constants/actions";
//
import {
  Icon, Message,
  Header, List, Input,
  Form,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";
//
import ClickToCopy from 'utilities/ClickToCopy';
//
import { format } from 'date-fns'
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"

//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });

  const [loading, setLoading] = useState(false);

  const [showPasswords, setShowPasswords] = useState({})
  const [showPasswordChanged, setShowPasswordChanged] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)

  const [organizationSelect, setOrganizationSelect] = useState([])
  const [organizationSelected, setOrganizationSelected] = useState([])

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)

  const [informationList, setInformationList] = useState({})

  useEffect(() => {
    setTemplateId(id)
  }, [id]);


  const {
    get_app_url_list,
    get_organization_select } = useSelector(
      (state) => ({
        get_app_url_list: state.applisturl,
        get_organization_select: state.organizations.organization_select,

      }),
      shallowEqual
    );

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_APP_LIST_URL,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });

  }, [dispatch]);

  useEffect(() => {
    setOrganizationSelect(get_organization_select)
  }, [get_organization_select]);



  useEffect(() => {
    if (get_app_url_list?.[templateId]) {
      setTemplateDetails(get_app_url_list?.[templateId])
    }
    setLoading(get_app_url_list?.pending)
  }, [get_app_url_list, templateId]);

  function BackArrow() {
    navigate(`/app/app-url`)
  }

  useEffect(() => {
    if (get_app_url_list?.created?.id) {
      navigate(`/app/app-url/details/${get_app_url_list?.created?.id}`)
    }
  }, [get_app_url_list, navigate]);


  function removePermission(rec) {
    const _curList = templateDetails?.information.filter(
      cp => cp?.id !== rec?.id
    )
    dispatch({
      type: SG_EDIT_APP_LIST_URL,
      payload: {
        id: templateDetails?.id,
        information: _curList
      },
    });

  }

  function showHidePasswords(rec) {
    // console.log(rec)
    setShowPasswordChanged(uuidv4())
    const _id = rec?.id
    const _showPass = showPasswords

    if (_showPass[_id]) {
      _showPass[_id] = false
      delete _showPass[_id]
    } else {
      _showPass[_id] = true
    }

    setShowPasswords(_showPass)
  }

  function CheckIfShowSet(id) {
   return showPasswords[id] 
  }

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT
    })
  }, [dispatch]);

  useEffect(() => {
    const ErrorList = []
    if (!organizationSelected?.id) {
      ErrorList.push("Select an Organization")
    }
    if (!informationList?.username) {
      ErrorList.push("Enter a username")
    }
    if (!informationList?.password) {
      ErrorList.push("Enter a password")
    }
    setFormErrors(ErrorList)
  }, [informationList, valueChanged, organizationSelected]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = informationList
    _ques[`${name}`] = e.target.value
    setInformationList(_ques)
    setValueChanged(e.target.value + name)
  }

  function handleSavePermission() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        const _informationList = informationList
        _informationList.organization_id = organizationSelected?.id
        _informationList.id = uuidv4()

        const _tempInfo = templateDetails?.information
        _tempInfo.push(_informationList)
        dispatch({
          type: SG_EDIT_APP_LIST_URL,
          payload: {
            id: templateId,
            information: _tempInfo,
          }
        });
      }
      setInformationList({})
      setPristine(true)
    }
  }
  // console.log(informationList)
  // console.log(organizationSelected)
  // console.log(templateDetails)

  function HideShowPassword() {
    setHidePassword(!hidePassword)
  }

  return (
    <>
      <Header as='h2'>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow} name='arrow left' />
        {templateId && `More Details of ${templateDetails?.name}`}
      </Header>

      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId &&
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header># Id</List.Header>
                    {templateDetails?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Name</List.Header>
                    {templateDetails?.name}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>URL</List.Header>
                    <ClickToCopy textToCopy={templateDetails?.url} />
                  </ListContainer>
                </List.Content>
              </List.Item>

            </List>
          </Container>
        </SectionDiv>
      }

      <Dimmer.Dimmable blurring dimmed={loading}>

        {/* CATEGORY SELECTION SECTION */}
        <CategorySection>
          <CategoryHeader>
            Username Passwords in this app
          </CategoryHeader>
          <CategoryRowInfo>
            <TableContainer>
              <TopTableRow>
                <TableHeading>Organization</TableHeading>
                <TableHeading>Username</TableHeading>
                <TableHeading>Password</TableHeading>
                <TableHeading>Notes</TableHeading>
                <TableHeading></TableHeading>
              </TopTableRow>
              {templateDetails?.information &&
                templateDetails?.information.map((rec) => {
                  const _rId = rec?.id
                  return (
                    <TableRow key={rec.id}>
                      <TableContent>
                      {rec?.organization_id} - {get_organization_select.filter(or => or.id === rec?.organization_id)?.[0]?.name}
                      </TableContent>
                      <TableContent>
                        <ClickToCopy textToCopy={rec?.username} />
                      </TableContent>
                      <TableContent>
                        <ClickToCopy 
                        textToCopy={rec?.password}
                        hideTxt={showPasswords[_rId] ? false: true} />
                        {/* {showPasswordChanged} */}
                        {/* {showPasswords[_rId] ? "true": "false"} */}
                        <Icon name={CheckIfShowSet(rec?.id)? "hide": "unhide"}
                          link
                          onClick={() => showHidePasswords(rec)} />
                      </TableContent>
                      <TableContent>
                        {rec?.description}
                      </TableContent>
                      <TableContent>
                        <Icon onClick={() => removePermission(rec)} link name='trash' />
                      </TableContent>
                    </TableRow>
                  );
                })}
            </TableContainer>
          </CategoryRowInfo>


          <SectionDiv>
            {formErrors.length > 0 && !pristine &&
              <Message negative
                //   onDismiss={clearErrors}
                header='We need these details!'
                list={formErrors}
              />
            }

            <FormRow>
              <FormLabel>
                <LabelHead>Organization</LabelHead>
              </FormLabel>
              <FormInput>
                <Select
                  style={{ zIndex: 999 }}
                  name="survey_preference"
                  options={organizationSelect}
                  onChange={setOrganizationSelected}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.id}`}
                  value={organizationSelected}
                />
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
                <LabelHead>Username</LabelHead>
              </FormLabel>
              <FormInput>
                <Input
                  fluid
                  name="username"
                  defaultValue={informationList?.username}
                  autocomplete="off"
                  onChange={(e) => ChangeFormValues(e, "username")}
                />
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
                <LabelHead>Password</LabelHead>
              </FormLabel>
              <FormInput>
                <Input
                  icon={<Icon name={hidePassword ? "unhide" : "hide"} link onClick={HideShowPassword} />}
                  fluid
                  autocomplete="off"
                  type={hidePassword ? "password" : "text"}
                  name="password"
                  // defaultValue={informationList?.password}
                  defaultValue=""
                  onChange={(e) => ChangeFormValues(e, "password")}
                />
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
                <LabelHead>Notes</LabelHead>
              </FormLabel>
              <FormInput>
                <Form>
                  <Form.TextArea
                    placeholder='Describe this api...'
                    name="description"
                    // defaultValue={informationList?.description}
                    defaultValue=""
                    onChange={(e) => ChangeFormValues(e, "description")}
                  />
                </Form>
              </FormInput>
            </FormRow>

            <FormRow>
              <FormLabel>
              </FormLabel>
              <FormInput>
                <Form>
                  <Button onClick={handleSavePermission} positive>Save</Button>
                </Form>
              </FormInput>
            </FormRow>

          </SectionDiv>
        </CategorySection>

        <Dimmer active={loading}>
          <Loader />
          <Header as="h2" icon inverted>
            Loading, Please wait.
          </Header>

        </Dimmer>
      </Dimmer.Dimmable>

      <SectionDiv>

      </SectionDiv>

    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
const TableRow = styled.div`
  height: 82px;
  display: flex;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.87);
  }
`;


const TableContent = styled.div`
  width: 75%;
  padding-left: 10px;
  text-align: inherit;
  align-self: center;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  // margin-right: 150px;
  color: #7e7e7e;
`;


const TableHeading = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-top: 35px;
`;



const CategorySection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
`;



const CategoryRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  justify-content: space-between;
`;


