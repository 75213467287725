import {
  RUN_MODEL_OUTCOME,
  UPDATE_PREDICTIVE_MODEL_PAGE_NUMBER,
  GET_PREDICTIVE_MODEL_PENDING,
  GET_PREDICTIVE_MODEL,
  DELETE_PREDICTIVE_MODEL,
  FETCH_PREDICTIVE_MODEL,
  EDIT_PREDICTIVE_MODEL,
  ADD_PREDICTIVE_MODEL,
  CLEAR_PREDICTIVE_MODEL,
  RESET_PREDICTIVE_MODEL,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  predictive_model: [],
  model_outcome: false,
  pending: false,
  updated: false,
  pageNumber: 1,
};

export default function ProdOrgContract(state = initialState, action) {
  switch (action.type) {
    case RUN_MODEL_OUTCOME:
      return {
        ...state,
        model_outcome: action.payload,
        pending: false,
      };
    
    case GET_PREDICTIVE_MODEL_PENDING:
      return {
        ...state,
        pending: true,
      };
    
    case UPDATE_PREDICTIVE_MODEL_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    
    case GET_PREDICTIVE_MODEL:
      return {
        ...state,
        predictive_model: action.payload,
        pending: false,
      };

    case FETCH_PREDICTIVE_MODEL:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_PREDICTIVE_MODEL:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case DELETE_PREDICTIVE_MODEL:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case ADD_PREDICTIVE_MODEL:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case RESET_PREDICTIVE_MODEL:
      return {
        ...state,
        updated: false,
        pending: false,
      };
    
    case CLEAR_PREDICTIVE_MODEL:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
