import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { APP_UPDATE_TYPE } from "data/AdminData";
import { Button, Input, Message } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

function JsonRowEdit({ jsonRowDetails, setSelectedJsonRow, get_app_server, UpdateDataRow }) {
  const [makeCommands, setMakeCommands] = useState([]);
  const [makeSelected, setMakeSelected] = useState(null);
  const [updateType, setUpdateType] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [defaultUid, setDefaultUid] = useState(uuidv4());

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const generateUuid = () => {
    const uuid = uuidv4();
    setDefaultUid(uuid);
  };

  useEffect(() => {
    generateUuid();
  }, []);

  useEffect(() => {
    if (get_app_server?.make_commands?.response) {
      setMakeCommands(
        get_app_server.make_commands.response.map((option) => ({
          value: option,
          label: option,
        }))
      );
    }
  }, [get_app_server]);

  useEffect(() => {
    if (jsonRowDetails) {
      setFormValues(jsonRowDetails);
      if (!jsonRowDetails?.id) {
        setFormValues({ ...jsonRowDetails, id: defaultUid });
      }
      if (jsonRowDetails.make) {
        setMakeSelected(
          makeCommands.find((option) => option.value === jsonRowDetails.make)
        );
      }
      if (jsonRowDetails.update_type_id) {
        setUpdateType(
          APP_UPDATE_TYPE.find(
            (option) => option.value === jsonRowDetails.update_type_id
          )
        );
      }
    } else {
      setFormValues({ id: defaultUid });
    }
  }, [jsonRowDetails, makeCommands, defaultUid]);

  const ChangeFormValues = (field, target_value) => {
    setFormValues({
      ...formValues,
      [field]: target_value,
    });

    if (field === "make") {
      setMakeSelected(
        makeCommands.find((option) => option.value === target_value)
      );
    }
    if (field === "update_type_id") {
      setUpdateType(
        APP_UPDATE_TYPE.find((option) => option.value === target_value)
      );
    }
  };

  const UpdateRow = () => {
    setPristine(false);
    if (formErrors.length === 0) {
      UpdateDataRow(formValues);
    }
    
  };

  useEffect(() => {
    const ErrorList = [];
    if (!formValues?.id) {
      ErrorList.push("No ID found");
    }
    if (!formValues?.make) {
      ErrorList.push("Select Make");
    }
    if (!formValues?.action) {
      ErrorList.push("Enter the Button Name");
    }
    if (!formValues?.update_type_id) {
      ErrorList.push("Select Update Type");
    }
    if (!formValues?.description) {
      ErrorList.push("Write a description");
    }
    setFormErrors(ErrorList);
  }, [
    formValues,
  ]);

  return (
    <SectionDiv>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <StyledBorderDiv>
        <SingleRowContent>
          <FormHeader>Create Update Steps </FormHeader>

          {jsonRowDetails && <ClickableSpan onClick={() => setSelectedJsonRow()}>Clear</ClickableSpan>}
        </SingleRowContent>
        
        <FormRow>
          <FormLabel>
            <LabelHead>ID</LabelHead>
          </FormLabel>
          <FormInput>{formValues?.id}</FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Make Command</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="make"
              options={makeCommands}
              onChange={(selectedOption) =>
                ChangeFormValues("make", selectedOption.value)
              }
              value={makeSelected}
            />
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Update Type</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="update_type_id"
              options={APP_UPDATE_TYPE}
              onChange={(selectedOption) =>
                ChangeFormValues("update_type_id", selectedOption.value)
              }
              value={updateType}
            />
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Button Text</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="action"
              defaultValue={formValues?.action}
              onChange={(e) => ChangeFormValues("action", e.target.value)}
            />
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Next Steps</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="next_steps"
              defaultValue={formValues?.next_steps}
              onChange={(e) => ChangeFormValues("next_steps", e.target.value)}
            />
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Description</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="description"
              defaultValue={formValues?.description}
              onChange={(e) => ChangeFormValues("description", e.target.value)}
            />
          </FormInput>
        </FormRow>
        <FormRow>
          <Button floated="right" color="green" onClick={UpdateRow}>
            {formValues?.id ? "Update" : "Create"}
          </Button>
        </FormRow>
      </StyledBorderDiv>
    </SectionDiv>
  );
}

export default JsonRowEdit;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Add this line */
  align-items: center; /* Optionally center align items vertically */
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;

const StyledBorderDiv = styled.div`
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
`;

const FormHeader = styled.label`
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
`;

const ClickableSpan = styled.span`
  cursor: pointer;
  color: blue;

  &:hover {
    color: darkblue;
    text-decoration: underline;
  }
`;
