import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import make_call from "./Request";
import {
  Accordion,
  Icon,
  Button,
  Loader,
  Dimmer,
  Message,
} from "semantic-ui-react";
import Slider from "./Slider";
import knowledge_base from "./knowledge_base_tag.json";
import { SG_EDIT_DEBRIEF_SUMMARY } from "constants/actions";

const extract_random_feedback = (survey) => {
  //get random responses from the survey, between 3 and the total length of the survey responses\
  let random_responses = survey
    .sort(() => Math.random() - Math.random())
    .slice(0, Math.floor(Math.random() * (survey.length - 3) + 3));
  let feedback = "";

  if (random_responses) {
    random_responses.map((response) => {
      if (response?.response?.feedback) {
        response?.response?.feedback.map((item) => {
          feedback += "Question:" + item.question;
          feedback += "response:" + item.feedback;
          if (item.follow_up) {
            feedback += "Follow up question:" + item.follow_up_question;
            feedback += "Follow up response:" + item.follow_up;
          }
          feedback += "\n\n";
          return null;
        });
      }
      return null;
    });
  }
  return feedback;
};

function extractPoints(inputStr) {
  // Split the input string into lines
  const lines = inputStr.split("\n");

  // Filter out lines that start with a number and a dot and clean them
  const cleanedPoints = lines
    .filter((line) => /^\d+\./.test(line))
    .map((point) => {
      return point.replace(/^\d+\.\s*/, ""); // Remove the initial number, dot and any extra spaces
    });

  return cleanedPoints;
}

function getRandomPoint(pointsArray) {
  const randomIndex = Math.floor(Math.random() * pointsArray.length);
  return pointsArray[randomIndex];
}



function get_random_article() {
  let random_article =
    knowledge_base[Math.floor(Math.random() * knowledge_base.length)];
  return random_article;
}

const Matcher = ({ newResponse, setNewResponse }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { get_debrief_summary, get_debrief_analytics, get_auth } = useSelector(
    (state) => ({
      get_debrief_summary: state.debrief_summary,
      get_debrief_analytics: state.debrief_analytics,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  const [feedback, setFeedback] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [response, setResponse] = useState(null);
  const [reset, setReset] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [gptMessage, setGptMessage] = useState("");

  const [question, setQuestion] = useState("");
  const [article, setArticle] = useState(get_random_article());
  const [loadData, setLoadData] = useState(false);
  const [debriefSummary, setDebriefSummary] = useState(false);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const[currentSurvey, setCurrentSurvey] = useState(false)

  useEffect(() => {
    if (get_debrief_summary?.debrief_summary_next) {
      setDebriefSummary(get_debrief_summary?.debrief_summary_next);
    }
  }, [get_debrief_summary?.debrief_summary_next]);

  //   updated
  useEffect(() => {
    if (get_debrief_summary?.updated?.id) {
      // const timer1 = setTimeout(() => {
      navigate("/app/ml-operations/create");
      setPristine(true);
      // }, 500);
      // return () => clearTimeout(timer1);
    }
  }, [navigate, get_debrief_summary.updated]);

  //   extract_random_feedback
  useEffect(() => {
    if (debriefSummary?.total_response) {
      console.log('seeting random feedback',debriefSummary?.total_response.length)
      setFeedback(extract_random_feedback(debriefSummary?.total_response));
    }
  }, [debriefSummary?.total_response]);

  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_debrief_summary?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_debrief_summary.pending]);

  //   console.log(feedback);
  useEffect(() => {
    if (feedback && generate) {
      const prompt = `
                The following are survey questions and responses from a culture survey:
                ${feedback}

                Based on the feedback, generate a list of 4 gaps for the organization.
                The gaps should focus on specific concepts, themes, or ideas that the organization can address or take action on based on this feedback. The gaps should represent clusters of consensus opinion, and not one-off opinions. Use language, context, and ideas expressed in the feedback itself.
                Here is an example of a needs assessment list:

                Example:
                1. Current lack of transparent and timely communication within and between franchises and at all organizational levels (from executive to employees). Leads to confusion and unalignment within the comm. department.

                2. Reactive and unclear approach to everchanging objectives, initiatives, and performance goals and expectations. Causes subcultures and the development of silos between teams and/or franchises.

                3. No team-based performance goals to foster collaboration, celebrate short-term wins, and hold people accountable within the commercial department. Creates little shared ownership and understanding of how to operationally grow in conjunction with Resulta’s overall strategic vision.

                4. No personal and tangible OKRs (except generating more FTDs) that are tied to clear learning outcomes relevant to the commercial department’s goals. Produces feelings of disconnect and isolation.

                ###END of Example

                Generate a needs assessment with at most 4 needs.

                Needs assessment:
                `;

      setLoading(true);
      async function someFunction(prompt) {
        const message = await make_call(prompt);
        setGptMessage(message);
      }

      someFunction(prompt);
    }
  }, [feedback, generate]);

  useEffect(() => {
    if (gptMessage) {
      const message_array = extractPoints(gptMessage);
      const random_need = getRandomPoint(message_array);
      setMessage(random_need);
      setLoading(false);
    }
  }, [gptMessage]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const ErrorList = [];
    if (response?.length === 0 || response===null) {
      ErrorList.push("Please score this question");
    }
    setFormErrors(ErrorList);
  }, [response, pristine]);

  const Submit = () => {
    if (formErrors?.length > 0) {
      setPristine(false);
      return;
    }
    //This is the content we want to store each time we hit submit
    const content = {
      name: `${get_auth?.employee?.first_name} ${get_auth?.employee?.last_name}`,
      user_id: get_auth?.employee?.id,
      email: get_auth?.employee?.email,
      organization_id: get_auth?.employee?.organization_id,
      date: new Date().getTime(),
      doc_id: article.id,
      issues_text: message,
      rating: response,
    };

    const currentFeedback = debriefSummary?.feedback || [];
    currentFeedback.push(content);

    dispatch({
      type: SG_EDIT_DEBRIEF_SUMMARY,
      payload: {
        id: debriefSummary?.id,
        feedback: currentFeedback,
      },
    });

    setReset(true);
    setQuestion("");
    setFormErrors(false);
    setResponse(null);
    setNewResponse(Math.random());
  };

  useEffect(() => {
    if (debriefSummary?.id && currentSurvey!==debriefSummary?.id) {
      setCurrentSurvey(debriefSummary?.id)
      const _article = get_random_article();
      // setFeedback(randomize_feedback());
      setGenerate(true)
      setArticle(_article);
      setPristine(true);
      setGptMessage("");
      setMessage("")
    }
  }, [debriefSummary.id, newResponse]);

  // console.log(question);
  // console.log(formErrors);


  return (
    <Container>
      <Dimmer active={loadData}>
        <Loader />
      </Dimmer>

      {debriefSummary?.id ? (
        <>
          <EvaluationArea>
            <Needs>
              <Title>
                Needs Assessment{" "}
                <Button onClick={() => setGenerate(true)} size="tiny">
                  Generate Assessment
                </Button>
              </Title>
              {loading ? <Loader inline active /> : message}
            </Needs>

            <Article>
              <Title>{article.type}</Title>
              <T2>{article.title}</T2>

              <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  Summary
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <P>{article.summary}</P>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                 Link to document
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <a target='_blank' href={"https://docs.google.com/document/d/"+article.id}>Link to article</a>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  Full Article
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <P>{article.raw}</P>
                </Accordion.Content>
              </Accordion>
            </Article>
          </EvaluationArea>

          <QuestionArea>
            <Question>
              Please rate the quality of fit between the article presented, and
              the issues identified.
            </Question>
            <Slider
              saveUpdatedAnswers={(v) => setResponse(v)}
              reset={reset}
              setReset={setReset}
            />
            {formErrors.length > 0 && !pristine && (
              <Message
                negative
                //   onDismiss={clearErrors}
                header="We need these details!"
                list={formErrors}
              />
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button onClick={Submit}>Submit</Button>
            </div>
          </QuestionArea>

          {debriefSummary?.feedback && (
            <Title>{debriefSummary?.feedback?.length} Feedback Found</Title>
          )}
        </>
      ) : (
        <>
          {!loadData && (
            <>
              <Title>Does not look like a valid survey for tagging</Title>
              <AddNewButton
                primary
                onClick={() => navigate("/app/ml-operations/create")}
              >
                Get Another One
              </AddNewButton>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Matcher;

const Container = styled.div`
  margin-top: 50px;
  padding-left: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Question = styled.div`
  font-weight: bold;
`;

const Needs = styled.div`
  white-space: pre-wrap;
  width: 50%;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const EvaluationArea = styled.div`
  width: 100%;
  display: flex;
  min-height: 400px;
  max-height: 700px;
  overflow-y: scroll;
`;

const Article = styled.div`
  width: 50%;
  padding: 20px;
`;

const QuestionArea = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const T2 = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const P = styled.p`
  white-space: pre-wrap;
`;

const AddNewButton = styled(Button)`
  display: ${({ hide }) => (hide ? "none" : "block")};
`;
