import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from "date-fns";

import { Card, Image, Button, List, Icon } from "semantic-ui-react";

// ambassador_id
// :
// 0
// email
// :
// "ryan.davis.2851@innerlogic.datastruk.com"
// email_confirmed
// :
// false
// employee_id
// :
// 17471
// first_name
// :
// "Erik"
// id
// :
// 626
// is_active
// :
// true
// is_admin
// :
// false
// is_staff
// :
// false
// is_superuser
// :
// false
// last_login
// :
// null
// last_name
// :
// "Gjerde"
// on_boarded
// :
// false
// organization_id
// :
// 158
// resend_conf
// :
// 0
// user_code
// :
// "7a31e27e-ae34-45de-8c94-d651756dc3a6"
const RowCard = ({ row }) => {
  const navigate = useNavigate();
  // console.log(row)
  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Card.Header>
            User Id {row?.id} # {row?.first_name} {row?.last_name}
          </Card.Header>
          

          <Container>
            <List horizontal>
              <List.Item>
                <List.Content></List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Active</List.Header>
                    {row?.is_active ? "True" : "False"}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Staff</List.Header>
                    {row?.is_staff ? "Staff" : "User"}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Org Id</List.Header>
                    {row?.organization_id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Emp Id</List.Header>
                    {row?.employee_id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Email</List.Header>
                    {row?.email}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>User Code</List.Header>
                    {row?.user_code}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const ListImageContainer = styled.div`
  margin: 10px 10px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;
const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Box = styled.div`
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 0px 10px;
`;
