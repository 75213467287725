import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Header, Button, Icon } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Select from "react-select";

import {
  SG_GET_FILTER_DEBRIEF_TOKENS,
  SG_DELETE_DEBRIEF_TOKEN,
  SG_EDIT_DEBRIEF_TOKEN,
  SG_DELETE_DEBRIEF_RESPONSE,
  SG_ADD_CHANGE_LOG,
} from "constants/actions";

import { CHANGE_LOG_DATA_TYPES } from "data/AdminData";

const DeleteResponseModal = ({
  surveyToken,
  responseId,
  employeeID,
  deleteResponse,
  setDeleteResponse,
  setReloadPage,
}) => {
  const dispatch = useDispatch();
  const [explantion, setExplantion] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [typeOfLog, setTypeOfLog] = useState(false);
  const [briefTokens, setBriefTokens] = useState(false);

  const { get_auth, get_debrief_token, get_debrief_response } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_debrief_token: state.debrief_token,
      get_debrief_response: state.debrief_response,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (deleteResponse) {
      dispatch({
        type: SG_GET_FILTER_DEBRIEF_TOKENS,
        payload: `token=${surveyToken}`,
      });
    }
  }, [dispatch, surveyToken, deleteResponse]);

  useEffect(() => {
    if (get_debrief_response.deleted) {
      const timer1 = setTimeout(() => {
        setReloadPage(Math.random() * 100);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [get_debrief_response.deleted, setReloadPage]);

  useEffect(() => {
    setBriefTokens(get_debrief_token?.filtered?.results);
  }, [get_debrief_token]);

  function DeleteEmpResponse() {
    if (!explantion || explantion?.length < 1) {
      setErrorMsg("Please provide a reason for deleting this response");
      return;
    }
    if (!typeOfLog?.value) {
      setErrorMsg("Please select an action");
      return;
    }

    if (typeOfLog?.value === 4 || typeOfLog?.value === 5) {
      if (briefTokens?.length < 1) {
        setErrorMsg("Not Token found!");
        return;
      }
    }

    // console.log("get_debrief_token", briefTokens[0]?.id);
    // 2 delete survey response
    // (4, "Delete Survey Response and Reset Survey Token"),
    // (5, "Delete Survey Response and Delete Survey Token"),

    // SG_DELETE_DEBRIEF_RESPONSE
    dispatch({
      type: SG_DELETE_DEBRIEF_RESPONSE,
      payload: { id: responseId },
    });

    // // reset token
    if (typeOfLog?.value === 4) {
      dispatch({
        type: SG_EDIT_DEBRIEF_TOKEN,
        payload: {
          id: briefTokens[0]?.id,
          delivery_status: null,
          sent: null,
          deleted_on: null,
          to_delete: false,
          stored_response: null,
          valiation_token: null,
          survey_status: 1,
        },
      });
    }

    // // delete token
    if (typeOfLog?.value === 5) {
      dispatch({
        type: SG_DELETE_DEBRIEF_TOKEN,
        payload: { id: briefTokens[0]?.id },
      });
    }

    dispatch({
      type: SG_ADD_CHANGE_LOG,
      payload: {
        employee_id: employeeID,
        type_of_log: typeOfLog?.value, // delete survey response
        description: {
          id: responseId,
          reason: explantion,
          user: {
            id: get_auth?.employee?.id,
            first_name: get_auth?.employee?.first_name,
            last_name: get_auth?.employee?.last_name,
            email: get_auth?.employee?.email,
          },
        },
      },
    });
    //

    const timer1 = setTimeout(() => {
      setDeleteResponse(false);
    }, 500);

    return () => clearTimeout(timer1);
  }

  useEffect(() => {
    if (explantion) {
      setErrorMsg("");
    }
  }, [explantion]);

  return (
    <Modal
      closeIcon
      open={deleteResponse}
      onClose={() => setDeleteResponse(false)}
      onOpen={() => setDeleteResponse(true)}
    >
      <Header>Delete Response</Header>
      <Modal.Content>
        <ErrorMessage>{errorMsg}</ErrorMessage>
        <Row>
          <p>
            Are you sure you want to delete this response, please provide a
            reason?
          </p>
        </Row>

        <Row>
          <StyledInput
            value={explantion}
            onChange={(e) => setExplantion(e.target.value)}
          />
        </Row>
        <Row>
          <p>Select an Action</p>
        </Row>
        <Row>
          <Select
            name="model"
            options={CHANGE_LOG_DATA_TYPES.filter(
              (cl) => cl.value === 2 || cl.value === 4 || cl.value === 5
            )}
            // isClearable
            onChange={setTypeOfLog}
            value={typeOfLog}
          />
        </Row>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => setDeleteResponse(false)}>
          <Icon name="remove" /> No
        </Button>
        <Button color="green" onClick={DeleteEmpResponse}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteResponseModal;

const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;
