import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from "date-fns";
import { Card, Button, List } from "semantic-ui-react";

const RowCard = ({ row }) => {
  const navigate = useNavigate();
  // console.log(row)
  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Card.Header>
            {row?.first_name} {row?.last_name}
          </Card.Header>
          <Card.Meta>
            {row?.email} {row?.mobiie}
          </Card.Meta>

          <Container>
            <List horizontal>
            <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>ID</List.Header>#{row?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Organization</List.Header>#{row?.organization}{" "}
                    - {row?.organization_name}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Name</List.Header>
                    {row?.name}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {row?.created_at &&
                      format(new Date(row?.created_at), "dd-MMM-yyyy")}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Last Updated</List.Header>
                    {row?.updated_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy")}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>

          <Card.Description>
            <Button
              floated="right"
              onClick={() => navigate(`/app/categories/details/${row?.id}`)}
            >
              View More or Edit Details
            </Button>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;
