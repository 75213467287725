import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_PRODUCT_GROUP, RESET_PRODUCT_GROUP } from "constants/actions";
import { Button, Header, Icon } from "semantic-ui-react";
import { format } from "date-fns";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);

  useEffect(() => {
    setTemplateId(id);
    dispatch({ type: RESET_PRODUCT_GROUP });
    if (id) {
      dispatch({ type: SG_FETCH_PRODUCT_GROUP, payload: { id } });
    }
  }, [dispatch, id]);

  const { get_product_group } = useSelector(
    (state) => ({ get_product_group: state.product_group }),
    shallowEqual
  );

  useEffect(() => {
    if (get_product_group && templateId) {
      setTemplateDetails(get_product_group[templateId]);
    }
  }, [get_product_group, templateId]);

  const BackArrow = () => navigate(`/app/product`);

  const EditTemplate = () => navigate(`/app/product/group/edit/${templateId}`);

  return (
    <>
      <Header as="h2">
        <StyledIcon name="arrow left" onClick={BackArrow} />
        Details of {templateDetails?.name}
      </Header>
      <DetailRow label="Created">
        {templateDetails?.created_at &&
          format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Name">{templateDetails?.name}</DetailRow>
      <DetailRow label="Sort Order">{templateDetails?.sort_order}</DetailRow>
      <DetailRow label="Description">{templateDetails?.description}</DetailRow>

      <DetailRow label="Last Updated">
        {templateDetails?.updated_at &&
          format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
    </>
  );
};

const DetailRow = ({ label, children }) => (
  <RowContainer>
    <LabelCell>{label}:</LabelCell>
    <ValueCell>{children}</ValueCell>
  </RowContainer>
);

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection ? "100px" : "20px")};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const LabelCell = styled.div`
  font-weight: bold;
  width: 150px;
`;

const ValueCell = styled.div`
  flex: 1;
`;
