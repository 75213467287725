import {
  UPDATE_PRODUCT_PACKAGE_PAGE_NUMBER,
  GET_PRODUCT_PACKAGE_PENDING,
  GET_PRODUCT_PACKAGE,
  GET_PRODUCT_PACKAGE_SELECT,
  DUPLICATE_PRODUCT_PACKAGE,
  DELETE_PRODUCT_PACKAGE,
  FETCH_PRODUCT_PACKAGE,
  EDIT_PRODUCT_PACKAGE,
  ADD_PRODUCT_PACKAGE,
  CLEAR_PRODUCT_PACKAGE,
  RESET_PRODUCT_PACKAGE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  product_package: [],
  select_list:[],
  pending: false,
  pageNumber: 1,
  deleted: false,
  updated: false,
  created: false,
};

export default function ProductPackage(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_PACKAGE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_PRODUCT_PACKAGE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
        pending: false
      };
    case GET_PRODUCT_PACKAGE:
      return {
        ...state,
        product_package: action.payload,
        pending: false,
      };
    case DUPLICATE_PRODUCT_PACKAGE:
      return {
        ...state,
        updated: action.payload,
        pending: false
      };
      case GET_PRODUCT_PACKAGE_SELECT:
      return {
        ...state,
        select_list: action.payload,
        pending: false,
      };
    case FETCH_PRODUCT_PACKAGE:
      return { 
        ...state,
        [action.payload.id]: action.payload,
        pending: false
      };

    case EDIT_PRODUCT_PACKAGE:
      return {
        ...state,
        updated: action.payload,
        pending: false
      };

    case DELETE_PRODUCT_PACKAGE:
      return {
        ...state,
        updated: action.payload,
        pending: false
      };

    case ADD_PRODUCT_PACKAGE:
      return {
        ...state,
        created: action.payload,
        pending: false
      };

    case RESET_PRODUCT_PACKAGE:
      return {
        ...state,
        deleted: false,
        updated: false,
        created: false,
        pending: false
      };

    case CLEAR_PRODUCT_PACKAGE:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
