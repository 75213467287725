import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Message,
  Segment,
  Dimmer,
  Loader,
  Card,
  Pagination,
  Button,
} from "semantic-ui-react";

import RowCard from "./RowCard";

import ReactJson from "react-json-view";

import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { SG_GET_DOCUMENTATION } from "constants/actions";

const TemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [organizationSelect, setOrganizationSelect] = useState([])
  const [documentation, setDocumentation] = useState([]);

  const [loading, setLoading] = useState(false)
  const { get_documentation } = useSelector(
    (state) => ({
      get_documentation: state.documentation.documentation,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_DOCUMENTATION,
    });
  }, [dispatch]);

  return (
    <Container>
            <SectionDiv>
        <ReactJson
          src={get_documentation}
        />
      </SectionDiv>
      
    </Container>
  );
};

export default TemplateList;

const Container = styled.div`
  margin-bottom: 30px;
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const SectionCell = styled.div`
  padding: 30px 15px;
  min-width: 350px;
`;
