import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_DEBRIEF_TOKEN_PENDING,
  GET_DEBRIEF_TOKENS,
  SG_GET_DEBRIEF_TOKENS,
  GET_FILTER_DEBRIEF_TOKENS,
  SG_GET_FILTER_DEBRIEF_TOKENS,
  DELETE_DEBRIEF_TOKEN,
  SG_DELETE_DEBRIEF_TOKEN,
  FETCH_DEBRIEF_TOKEN,
  SG_FETCH_DEBRIEF_TOKEN,
  EDIT_DEBRIEF_TOKEN,
  SG_EDIT_DEBRIEF_TOKEN,
  ADD_DEBRIEF_TOKEN,
  SG_ADD_DEBRIEF_TOKEN,
  ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
    yield put({ type: GET_DEBRIEF_TOKEN_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_DEBRIEF_TOKENS, action.payload);
    yield put({ type: GET_DEBRIEF_TOKENS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_DEBRIEF_TOKENS, loadmethods);
}

function* loadFiltermethods(action) {
    yield put({ type: GET_DEBRIEF_TOKEN_PENDING });
  try {
    const json = yield call(api.GET_DEBRIEF_TOKENS, action.payload);
    yield put({ type: GET_FILTER_DEBRIEF_TOKENS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFilterLoad() {
  yield takeLatest(SG_GET_FILTER_DEBRIEF_TOKENS, loadFiltermethods);
}


function* fetchmethods(action) {
  try {
    const json = yield call(api.FETCH_DEBRIEF_TOKEN, action.payload);
    yield put({ type: FETCH_DEBRIEF_TOKEN, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_TOKEN, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_DEBRIEF_TOKEN, action.payload);
    yield put({ type: EDIT_DEBRIEF_TOKEN, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_DEBRIEF_TOKEN, updatemethods);
}

function* addmethods(action) {
  try {
    const json = yield call(api.ADD_DEBRIEF_TOKEN, action.payload);
    yield put({ type: ADD_DEBRIEF_TOKEN, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_DEBRIEF_TOKEN, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_DEBRIEF_TOKEN, action.payload);
    yield put({ type: DELETE_DEBRIEF_TOKEN, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_DEBRIEF_TOKEN, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFilterLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
