import {
  GET_DEBRIEF_TOKEN_PENDING,
  GET_DEBRIEF_TOKENS,
  GET_FILTER_DEBRIEF_TOKENS,
  DEBRIEF_TOKEN_PAGE_NUMBER,
  DELETE_DEBRIEF_TOKEN,
  FETCH_DEBRIEF_TOKEN,
  EDIT_DEBRIEF_TOKEN,
  ADD_DEBRIEF_TOKEN,
  CLEAR_DEBRIEF_TOKEN,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  debrief_token: [],
  filtered: false,
  pending: false,
  response: null,
  deleted: null,
  created: [],
  pageNumber: 1,
};

export default function DebriefToken(state = initialState, action) {
  switch (action.type) {
    case GET_DEBRIEF_TOKEN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DEBRIEF_TOKENS:
      return {
        ...state,
        debrief_token: action.payload,
        pending: false,
      };
    case GET_FILTER_DEBRIEF_TOKENS:
      return {
        ...state,
        filtered: action.payload,
        pending: false,
      };
    case DEBRIEF_TOKEN_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case FETCH_DEBRIEF_TOKEN:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_DEBRIEF_TOKEN:
      if (state.debrief_token.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          debrief_token: state.debrief_token.filter(
            (rw) => rw.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        debrief_token: [...state.debrief_token, action.payload],
      };

    case DELETE_DEBRIEF_TOKEN:
      return {
        ...state,
        deleted:  action.payload.id,
      };

    case ADD_DEBRIEF_TOKEN:
      return {
        ...state,
        created: action.payload,
        debrief_token: [...state.debrief_token, action.payload],
        response: action.payload.response,
      };

    case CLEAR_DEBRIEF_TOKEN:
      return {
        ...state,
        ...initialState
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}
