import {
  UPDATE_PROD_ORG_CONTRACT_PAGE_NUMBER,
  GET_PROD_ORG_CONTRACT_PENDING,
  GET_PROD_ORG_CONTRACT,
  DELETE_PROD_ORG_CONTRACT,
  FETCH_PROD_ORG_CONTRACT,
  EDIT_PROD_ORG_CONTRACT,
  ADD_PROD_ORG_CONTRACT,
  CLEAR_PROD_ORG_CONTRACT,
  RESET_PROD_ORG_CONTRACT,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  prod_org_contract: [],
  pending: false,
  deleted: false,
  updated: false,
  created: false,
  pageNumber: 1,
};

export default function ProdOrgContract(state = initialState, action) {
  switch (action.type) {
    case GET_PROD_ORG_CONTRACT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_PROD_ORG_CONTRACT_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_PROD_ORG_CONTRACT:
      return {
        ...state,
        prod_org_contract: action.payload,
        pending: false,
      };

    case FETCH_PROD_ORG_CONTRACT:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_PROD_ORG_CONTRACT:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case DELETE_PROD_ORG_CONTRACT:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case ADD_PROD_ORG_CONTRACT:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case RESET_PROD_ORG_CONTRACT:
      return {
        ...state,
        deleted: false,
        updated: false,
        created: false,
        pending: false,
      };
    case CLEAR_PROD_ORG_CONTRACT:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
