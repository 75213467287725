import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_SURVEY_QUESTION,
  SG_EDIT_SURVEY_QUESTION,
} from "constants/actions";
//
import ReactJson from "react-json-view";
//
import { Icon, Message, Button, Header, List, Modal } from "semantic-ui-react";
//
import { format } from "date-fns";
//
import { SURVEY_TYPE, STATUS } from "data/AdminData";

import AddEditComment from "./AddEditComment";

const arrange_Questions = (questions) => {
  // console.log(questions)
  if (questions?.dimension) {
    return questions.dimension
      .map((item, i) => {
        return item.factors.map((factor, f) => {
          return factor.questions.map((q, idx) => {
            return {
              question: q.q,
              dimension: i,
              factor: f,
              q: idx,
              role: q.role,
              reverse: q.reverse,
              order: q.id,
            };
          });
        });
      })
      .flat()
      .flat()
      .sort((a, b) => a.order - b.order);
  }
};

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [questionId, setQuestionId] = useState(false);
  const [questionDetails, setQuestionDetails] = useState(null);

  const [surveyQuestion, setSurveyQuestion] = useState([]);
  const [askComments, setAskComments] = useState([]);
  const [openSummary, setOpenSummary] = useState(false);
  const [openComment, setOpenComment] = useState(false);

  const [openCreateNew, setOpenCreateNew] = useState(false);

  const [validationSummary, setValidationSummary] = useState("");
  const [isValid, setIsValid] = useState("");

  const [questionJson, setQuestionJson] = useState({});
  // const [loading, setLoading] = useState(false)
  const [updated, setUpdated] = useState(false);
  function BackArrow() {
    navigate(`/app/questions`);
  }

  useEffect(() => {
    setQuestionId(id);
    // setLoading(true)
  }, [id]);

  useEffect(() => {
    if (questionId) {
      dispatch({
        type: SG_FETCH_SURVEY_QUESTION,
        payload: { id: questionId },
      });
    }
  }, [dispatch, questionId]);

  const { get_questions } = useSelector(
    (state) => ({
      get_questions: state.surveyquestion,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSurveyQuestion(
      arrange_Questions(get_questions?.[questionId]?.questions)
    );
    setAskComments(get_questions?.[questionId]?.questions?.comments || []);
  }, [get_questions, questionId]);

  useEffect(() => {
    setQuestionDetails(get_questions?.[questionId]);
  }, [get_questions, questionId]);

  useEffect(() => {
    setQuestionJson(
        questionDetails?.questions)
  },[questionDetails?.questions])

  // console.log(get_questions?.[questionId]?.questions);
  // console.log(askComments)
  // console.log(surveyQuestion);
  // console.log(askComments?.length)
  function UpdateComments() {
    const _questions = questionDetails.questions;
    _questions.comments = askComments;

    dispatch({
      type: SG_EDIT_SURVEY_QUESTION,
      payload: {
        id: questionId,
        questions: _questions,
      },
    });
  }

  function EditQuestions() {
    navigate(`/app/questions/edit/${questionId}`);
  }



//   create json from scratch
const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    // this should show what was pasted for user to edit
    setQuestionJson(pastedData);
    // setJsonValue(pastedData);
    validateJson(pastedData);
  };

  const validateJson = (data) => {
    setQuestionJson(data);
    try {
      JSON.parse(data);
      setValidationSummary("JSON is valid!");
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
      console.error("Error parsing pasted JSON:", error);
      setValidationSummary(`JSON is not valid: ${error.message}`);
    }
  };

  function onSave() {
    setOpenCreateNew(false);
    if (questionId && isValid) {
        const _questions = questionDetails.questions;
        _questions.comments = askComments;
    
        dispatch({
          type: SG_EDIT_SURVEY_QUESTION,
          payload: {
            id: questionId,
            questions: _questions,
          },
        });
    }
  }


  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Details of {questionDetails?.name}
      </Header>
      <SectionDiv>
        Created{" "}
        {questionDetails?.created_at &&
          format(new Date(questionDetails?.created_at), "dd-MMM-yyyy")}
      </SectionDiv>
      <SectionDiv>
        <Container>
          <List horizontal>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Id (sort_order)</List.Header>
                  {questionDetails?.sort_order}
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Survey Type</List.Header>
                  {questionDetails?.survey_type}
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Name</List.Header>
                  {questionDetails?.name}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>URL Location (for survey)</List.Header>
                  {questionDetails?.url_location}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Type</List.Header>
                  {questionDetails?.survey_type &&
                    SURVEY_TYPE.filter(
                      (sy) => sy.value === questionDetails?.survey_type
                    )?.[0]?.label}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Status</List.Header>
                  {questionDetails?.status &&
                    STATUS.filter(
                      (sy) => sy.value === questionDetails?.status
                    )?.[0]?.label}
                </ListContainer>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Created</List.Header>
                  {questionDetails?.created_at &&
                    format(
                      new Date(questionDetails?.created_at),
                      "dd-MMM-yyyy"
                    )}
                </ListContainer>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <ListContainer>
                  <List.Header>Last Updated</List.Header>
                  {questionDetails?.updated_at &&
                    format(
                      new Date(questionDetails?.updated_at),
                      "dd-MMM-yyyy"
                    )}
                </ListContainer>
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </SectionDiv>
      {/* {surveyQuestion?.length > 0 && */}
      <SectionDiv>
        <Button
          style={{ margin: "20px 0px" }}
          icon
          labelPosition="left"
          floated="left"
          onClick={() => setOpenSummary(true)}
          // disabled={surveyQuestion?.length === 0}
          size="small"
          color={surveyQuestion?.length > 0 ? "green" : "red"}
        >
          View Questions
          <Icon name="eye" />
        </Button>

        <Button
          style={{ margin: "20px 10px" }}
          icon
          labelPosition="left"
          floated="left"
          onClick={() => setOpenComment(true)}
          // disabled={askComments?.length === 0}
          size="small"
          color={askComments?.length > 0 ? "green" : "red"}
        >
          View Question Comment
          <Icon name="eye" />
        </Button>

        <Button
          floated="right"
          style={{ margin: "0px 20px" }}
          icon
          labelPosition="left"
          onClick={() => setOpenCreateNew(true)}
          // disabled={surveyQuestion?.length === 0}
          size="small"
          color="violet"
        >
          Create All Questions
          <Icon name="eye" />
        </Button>

        <Button
          floated="right"
          style={{ margin: "0px 20px" }}
          icon
          labelPosition="left"
          color="blue"
          size="small"
          onClick={EditQuestions}
        >
          Edit Question <Icon name="eye" />
        </Button>
        {/* <Header as='h3'></Header> */}
      </SectionDiv>
      {/* } */}

      <SectionDiv>
        <ReactJson
          src={questionJson}
         
        />
      </SectionDiv>

      <SectionDiv lastSection={true}></SectionDiv>

      {/*  */}
      <Modal open={openSummary} onClose={() => setOpenSummary(false)}>
        <Modal.Header>
          <Header as="h1">
            Survey Question {surveyQuestion?.length}
            <Header.Subheader>Survey Question List</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <SectionDiv>
              <pre>{JSON.stringify(surveyQuestion, null, 3)}</pre>
            </SectionDiv>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenSummary(false)} color="violet">
            Close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal open={openComment} onClose={() => setOpenComment(false)}>
        <Modal.Header>
          <Header as="h1">
            Survey Comment Question {askComments?.length}
            <Header.Subheader>Survey Comment Question</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <SectionDiv>
              <pre>{JSON.stringify(askComments, null, 3)}</pre>
            </SectionDiv>
          </Modal.Description>
          <AddEditComment
            totalQuestions={surveyQuestion?.length + askComments?.length}
            askComments={askComments}
            setAskComments={setAskComments}
            updated={updated}
            setUpdated={setUpdated}
            UpdateComments={UpdateComments}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenComment(false)} color="violet">
            Save &amp; close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Add new question */}
      <Modal open={openCreateNew} onClose={() => setOpenCreateNew(false)}>
        <Modal.Header>
          <Header as="h1">
            Add or Update the entire Question
            <Header.Subheader></Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <textarea
            placeholder="Paste your JSON here..."
            value={JSON.stringify(questionJson,null,
                2)}
            onPaste={handlePaste}
            onChange={(e) => validateJson(e.target.value)}
            style={{ width: "100%", height: "100%", minHeight: "400px" }} // Set styles for full screen
          />

          {validationSummary && (
            <div style={{ color: isValid ? "green" : "red" }}>
              {validationSummary}
            </div>
          )}
          </Modal.Description>
          <AddEditComment
            totalQuestions={surveyQuestion?.length + askComments?.length}
            askComments={askComments}
            setAskComments={setAskComments}
            updated={updated}
            setUpdated={setUpdated}
            UpdateComments={UpdateComments}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenComment(false)} color="violet">
            Save &amp; close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
  clear: both; 
`;

const Container = styled.div`
  display: flex;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const LeftHalf = styled.div`
  // background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
`;

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`;
