import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'
import { useDispatch } from "react-redux";
import { format } from 'date-fns'

const AppServerLog = ({ rows }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>#ID</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Employee</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Updated at</Table.HeaderCell>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{row?.id}</Table.Cell>
                                <Table.Cell>{row?.update_status} # {row?.update_status_text}</Table.Cell>
                                <Table.Cell>{row?.update_type} # {row?.update_type_text}</Table.Cell>
                                <Table.Cell>{row?.employee_full_name}</Table.Cell>
                                <Table.Cell>{row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy hh:mm a')}</Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(`/app/app-server/log-details/${row?.id}`)}
                                        name='angle double right'
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    );
};



export default AppServerLog;
