import {
    UPDATE_PROD_DEV_DOC_PAGE_NUMBER,
    GET_PROD_DEV_DOC_PENDING,
    GET_PROD_DEV_DOC,
    DELETE_PROD_DEV_DOC,
    FETCH_PROD_DEV_DOC,
    EDIT_PROD_DEV_DOC,
    ADD_PROD_DEV_DOC,
    CLEAR_PROD_DEV_DOC,
    RESET_PROD_DEV_DOC,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    prod_dev_doc: [],
    pending: false,
    deleted: false,
    updated: false,
    created: false,
    pageNumber: 1,
  };
  
  export default function ProdOrgContract(state = initialState, action) {
    switch (action.type) {
      case GET_PROD_DEV_DOC_PENDING:
        return {
          ...state,
          pending: true,
        };
      case UPDATE_PROD_DEV_DOC_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
        };
      case GET_PROD_DEV_DOC:
        return {
          ...state,
          prod_dev_doc: action.payload,
          pending: false,
        };
  
      case FETCH_PROD_DEV_DOC:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
        };
  
      case EDIT_PROD_DEV_DOC:
        return {
          ...state,
          updated: action.payload,
          pending: false,
        };
  
      case DELETE_PROD_DEV_DOC:
        return {
          ...state,
          updated: action.payload,
          pending: false,
        };
  
      case ADD_PROD_DEV_DOC:
        return {
          ...state,
          updated: action.payload,
          pending: false,
        };
  
      case RESET_PROD_DEV_DOC:
        return {
          ...state,
          deleted: false,
          updated: false,
          created: false,
          pending: false,
        };
      case CLEAR_PROD_DEV_DOC:
        return {
          ...state,
          ...initialState,
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };
  
      default:
        return state;
    }
  }
  