import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    UPDATE_TASK_TEMPLATE_USER_TASK_PAGE_NUMBER,
    GET_TASK_TEMPLATE_USER_TASK_PENDING,
    GET_TASK_TEMPLATE_USER_TASK,
    SG_GET_TASK_TEMPLATE_USER_TASK,
    DELETE_TASK_TEMPLATE_USER_TASK,
    SG_DELETE_TASK_TEMPLATE_USER_TASK,
    FETCH_TASK_TEMPLATE_USER_TASK,
    SG_FETCH_TASK_TEMPLATE_USER_TASK,
    EDIT_TASK_TEMPLATE_USER_TASK,
    SG_EDIT_TASK_TEMPLATE_USER_TASK,
    ADD_TASK_TEMPLATE_USER_TASK,
    SG_ADD_TASK_TEMPLATE_USER_TASK,
    CLEAR_TASK_TEMPLATE_USER_TASK,
    RESET_TASK_TEMPLATE_USER_TASK,
    ALL_ERRORS,
  } from "constants/actions";
  

function* loadmethods(action) {
    yield put({ type: GET_TASK_TEMPLATE_USER_TASK_PENDING });

  try {
    const json = yield call(api.GET_TASK_TEMPLATE_USER_TASK, action.payload);
    yield put({ type: GET_TASK_TEMPLATE_USER_TASK, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_TASK_TEMPLATE_USER_TASK, loadmethods);
}

function* fetchmethods(action) {
  yield put({ type: GET_TASK_TEMPLATE_USER_TASK_PENDING });
  try {
    const json = yield call(api.FETCH_TASK_TEMPLATE_USER_TASK, action.payload);
    yield put({ type: FETCH_TASK_TEMPLATE_USER_TASK, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_TASK_TEMPLATE_USER_TASK, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: GET_TASK_TEMPLATE_USER_TASK_PENDING });
  try {
    const json = yield call(api.EDIT_TASK_TEMPLATE_USER_TASK, action.payload);
    yield put({ type: EDIT_TASK_TEMPLATE_USER_TASK, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_TASK_TEMPLATE_USER_TASK, updatemethods);
}


function* addmethods(action) {
  yield put({ type: GET_TASK_TEMPLATE_USER_TASK_PENDING });
  try {
    const json = yield call(api.ADD_TASK_TEMPLATE_USER_TASK, action.payload);
    yield put({ type: ADD_TASK_TEMPLATE_USER_TASK, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_TASK_TEMPLATE_USER_TASK, addmethods);
}

function* deletemethods(action) {
  yield put({ type: GET_TASK_TEMPLATE_USER_TASK_PENDING });
  try {
    yield call(api.DELETE_TASK_TEMPLATE_USER_TASK, action.payload);
    yield put({ type: DELETE_TASK_TEMPLATE_USER_TASK, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_TASK_TEMPLATE_USER_TASK, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
