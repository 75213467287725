import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_FETCH_GPT_RECOMMENDATION,
  SG_DELETE_GPT_RECOMMENDATION,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import ReactJson from "react-json-view";
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages";

import { ReportDisplay, DataDisplay } from "./DataRender";

//
const Details = ({ employee_record_value, setReload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [loading, setLoading] = useState(false);

  function BackArrow() {
    navigate(`/app/gpt-recommendation`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_GPT_RECOMMENDATION,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_gpt_recommendation } = useSelector(
    (state) => ({
      get_gpt_recommendation: state.gpt_recommendation,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_gpt_recommendation?.[templateId]) {
      setTemplateDetails(get_gpt_recommendation?.[templateId]);
    }
  }, [get_gpt_recommendation, templateId]);

  useEffect(() => {
    if (get_gpt_recommendation?.created?.id) {
      navigate(
        `/app/gpt-recommendation/details/${get_gpt_recommendation?.created?.id}`
      );
    }
  }, [get_gpt_recommendation, navigate]);

  useEffect(() => {
    if (get_gpt_recommendation?.pending) {
      setLoading(get_gpt_recommendation?.pending);
    }
    const timer1 = setTimeout(() => {
      setLoading(get_gpt_recommendation?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_gpt_recommendation]);

  useEffect(() => {
    if (get_gpt_recommendation?.deleted) {
      setReload(Math.random());
      const timer1 = setTimeout(() => {
        navigate(`/app/gpt-recommendation`);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [get_gpt_recommendation, navigate, setReload]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_GPT_RECOMMENDATION,
        payload: {
          id: templateId,
        },
      });

      // navigate(`/app/gpt-recommendation?reload=true`)
    }
  }

  return (
    <>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Header as="h2">
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow}
            name="arrow left"
          />
          {`View Details of ${templateDetails?.id}`}
        </Header>

        <SectionDiv>
          <BasicErrorMessage />
        </SectionDiv>
        {templateId && (
          <SectionDiv>
            <Container>
              <List horizontal>
                <ListItemRow
                  header="Employee"
                  content={`${templateDetails?.employee} # ${templateDetails?.employee_name} - ${templateDetails?.employee_email}`}
                />
                <ListItemRow
                  header="Organization"
                  content={`${
                    templateDetails?.organization
                      ? `${templateDetails?.organization} # ${templateDetails?.organization_name}`
                      : ""
                  }`}
                />
                <ListItemRow
                  header="Status"
                  content={templateDetails?.status_text}
                />
                <ListItemRow
                  header="Tracking"
                  content={templateDetails?.tracking_code}
                />
                <ListItemRow
                  header="Created At"
                  content={
                    templateDetails?.created_at &&
                    format(
                      new Date(templateDetails?.created_at),
                      "dd-MMM-yyyy hh:mm a"
                    )
                  }
                />
                <ListItemRow
                  header="Updated At"
                  content={
                    templateDetails?.created_at &&
                    format(
                      new Date(templateDetails?.updated_at),
                      "dd-MMM-yyyy hh:mm a"
                    )
                  }
                />
              </List>
            </Container>
            <Container>
              <List horizontal>
                <ListItemRow
                  header="Template Schedule Id"
                  content={templateDetails?.scheduletemplate}
                />
                <ListItemRow
                  header="Template Schedule Type"
                  content={templateDetails?.scheduletemplate_type}
                />
                <ListItemRow
                  header="Api Version"
                  content={templateDetails?.api_version}
                />
                <ListItemRow
                  header="Recommendation Template Id"
                  content={templateDetails?.recommendation_template_id}
                />

               
              </List>
            </Container>
            <Container>
              <List horizontal>
                <ListItemRow
                  header="Email"
                  content={templateDetails?.email_status}
                />
              </List>
            </Container>
          </SectionDiv>
        )}
        <SectionDiv>
          <Button floated="right" color="red" onClick={DeleteQuestion}>
            Delete
          </Button>
        </SectionDiv>
        <SectionDiv>
          <Header as="h3">Tracking Status (tracking_status)</Header>
          <ReactJson src={templateDetails?.tracking_status} />
        </SectionDiv>
        <SectionDiv>
          <Header as="h3">User Access (user_status)</Header>
          <ReactJson src={templateDetails?.user_status} />
        </SectionDiv>
        <SectionDiv>
          <Header as="h3">Params (params)</Header>
          <ReactJson src={templateDetails?.params} />
        </SectionDiv>
        <SectionDiv>
          <Header as="h3">Recommendation (recommendation)</Header>
          <ReactJson src={templateDetails?.recommendation} />
        </SectionDiv>
        
      </Segment>
    </>
  );
};

export default Details;

function ListItemRow({ header, content }) {
  return (
    <List.Item>
      <List.Content>
        <ListContainer>
          <List.Header>{header}</List.Header>
          {content ? content : "N/A"}
        </ListContainer>
      </List.Content>
    </List.Item>
  );
}

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
