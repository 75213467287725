import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import Toolbar from "./Toolbar";
import BlogCard from "./BlogCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_BLOGS, UPDATE_BLOG_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Grid, Pagination, Segment } from 'semantic-ui-react'

const BlogList = () => {

  const apiDispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(1);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // if the response is ok 200

    apiDispatch({
      type: SG_GET_BLOGS,
      payload: `page=${page}`,
    });
    apiDispatch({
      type: UPDATE_BLOG_PAGE_NUMBER,
      payload: page,
    });
    setLoading(true)
  }, [apiDispatch, page]);

  const { get_blogs } = useSelector(
    (state) => ({
      get_blogs: state.blogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    setBlogs(get_blogs?.blogs.results);
    setCount(get_blogs?.blogs.count);
    setPageSize(get_blogs?.blogs?.page_size);
    setPage(get_blogs?.pageNumber)
  }, [get_blogs]);


  function handlePageChange(event, value) {
    setPage(value.activePage);
  }

  useEffect(() => {
    // 
    const timer1 = setTimeout(() => {
      setLoading(false)
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

  return (

    <Container>
      <Toolbar />
      <Segment basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Grid relaxed columns={4}>
          {blogs &&
            blogs.map((blog) => (
              <Grid.Column key={blog.id}>

                <BlogCard blog={blog} />

              </Grid.Column>
            ))}
        </Grid>
      </Segment>
      <Pagination
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        activePage={page ? page : 1}
        totalPages={count / pageSize > 0 ? count / pageSize : 1}
        onPageChange={handlePageChange}
      />
    </Container >

  );
};

export default BlogList;


const Container = styled.div`
  min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;