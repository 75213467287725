import {
    UPDATE_TASK_TEMPLATE_PAGE_NUMBER,
    GET_TASK_TEMPLATE_PENDING,
    GET_TASK_TEMPLATE,
    DELETE_TASK_TEMPLATE,
    FETCH_TASK_TEMPLATE,
    EDIT_TASK_TEMPLATE,
    ADD_TASK_TEMPLATE,
    CLEAR_TASK_TEMPLATE,
    RESET_TASK_TEMPLATE,
    CLEAR_ALL,
  } from "constants/actions";
  
  
  const initialState = {
    pageNumber: 1,
    task_template: [],
    updated: false,
    deleted: false,
    pending: false
  };
  
  export default function TaskTemplate(state = initialState, action) {
    switch (action.type) {
      case UPDATE_TASK_TEMPLATE_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
          pending: false,
        };
      case GET_TASK_TEMPLATE_PENDING:
        return {
          ...state,
          pending: true,
        };
      case GET_TASK_TEMPLATE:
        return {
          ...state,
          task_template: action.payload,
          pending: false,
          
        };
      case FETCH_TASK_TEMPLATE:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
        };
      case EDIT_TASK_TEMPLATE:
        return {
          ...state,
          updated: action.payload,
          pending: false,
        };
      case DELETE_TASK_TEMPLATE:
        return {
          ...state,
          deleted: action.payload,
        };
      case ADD_TASK_TEMPLATE:
        return {
          ...state,
          updated: action.payload,
          pending: false,
        };
      case RESET_TASK_TEMPLATE:
        return {
          ...state,
          updated: false,
          deleted: false,
        };
      case CLEAR_TASK_TEMPLATE:
        return {
          ...state,
          ...initialState,
        };
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };
      default:
        return state;
    }
  }
  