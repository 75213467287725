import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Message,
  Segment,
  Dimmer,
  Loader,
  Card,
  Pagination,
  Button,
} from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Select from "react-select";

import { CATEGORY_COMPLIANCE_HEADERS } from "data/AdminData";

import {
  UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER,
  SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
} from "constants/actions";

import RowCard from "./RowCard";
import SearchBar from "./SearchBar";

const TemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  // const [organizationSelect, setOrganizationSelect] = useState([])
  const [surveyTypeSelected, setSurveyTypeSelected] = useState([]);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  // const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { get_category_compliance, get_PageNumber, get_category_updated } =
    useSelector(
      (state) => ({
        get_category_compliance:
          state.surveystructurecompliance.survey_structure_compliance,
        get_PageNumber: state.surveystructurecompliance.pageNumber,
        get_category_updated: state.surveystructurecompliance,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (searchTerm) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
        payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
      });
    } else {
        dispatch({
      type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
      payload: `detail_level=1${page ? `&page=${page}` : ""}${
        surveyTypeSelected?.value
          ? `&priority=${surveyTypeSelected?.value}`
          : ""
      }`,
    });
    }
  
  }, [dispatch, page, surveyTypeSelected, searchTerm]);

  // // searchTerm
  // useEffect(() => {
  //   dispatch({
  //     type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
  //     payload: `page=1${searchTerm ? `&search=${searchTerm}` : ""}`,
  //   });
  // }, [dispatch, searchTerm]);

  useEffect(() => {
    setPage(get_PageNumber);
  }, [get_PageNumber]);

  useEffect(() => {
    setPage(1);
  }, [surveyTypeSelected]);

  useEffect(() => {
    setResults(get_category_compliance?.results);
    setTotalPages(
      Math.ceil(
        get_category_compliance?.count / get_category_compliance?.page_size
      )
    );
    if (get_category_compliance?.count % get_category_compliance?.page_size) {
      setTotalPages(
        Math.ceil(
          get_category_compliance?.count / get_category_compliance?.page_size
        )
      );
    }
  }, [get_category_compliance, page, ReloadPage]);

  useEffect(() => {
    if (get_category_updated?.created || get_category_updated?.deleted) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
        payload: "detail_level=1&page=1",
      });
    }
  }, [dispatch, get_category_updated]);

  // useEffect(() => {
  //     if (ReloadPage) {
  //         dispatch({
  //             type: UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER,
  //         });

  //         // const timer = setTimeout(() => {
  //         //   navigate('/app/questions')
  //         // }, 1000);
  //         // return timer

  //     }
  // }, [ReloadPage, navigate, dispatch]);

  function handlePageChange(_, value) {
    // console.log("page change", value.activePage)
    setPage(value.activePage);
    dispatch({
      type: UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  function UpdateCategory() {
    setPage(1);
    setSearchTerm("")
    dispatch({
      type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
      payload: `page=${page}&priority=${surveyTypeSelected?.value || ""}`,
    });
  }

  // console.log(get_category_compliance)
  // console.log(results)

  return (
    <Container>
      <Message
        color="orange"
        icon="warning sign"
        header="Do not Modify before you read this"
        content={`This data is crucial for the entire survey, please 
          donot change or add anything to this page without 
          consulting support team. Any errors would appear below this message. 
          Please note even if there are no errors things may break if you
          change this file without proper knowledge. To modify please contact
          support.
        `}
      />

      <SingleRowContent>
        <SectionCell>
          <Select
            name="survey_preference"
            options={CATEGORY_COMPLIANCE_HEADERS}
            isClearable
            onChange={setSurveyTypeSelected}
            // getOptionLabel={(option) => `${option.name}`}
            // getOptionValue={(option) => `${option.id}`}
            value={surveyTypeSelected}
          />
        </SectionCell>

        <SectionCell>
          <Button onClick={UpdateCategory}>Filter</Button>
          <Button
            floated="right"
            color="green"
            onClick={() => navigate(`/app/category-compliance/create/`)}
          >
            Add New
          </Button>
        </SectionCell>
      </SingleRowContent>
      <SectionDiv>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} setPage={setPage} />
      </SectionDiv>

      <Segment id="results" basic>
        <Dimmer
        // active={loading}
        >
          <Loader />
        </Dimmer>
        <Card.Group>
          {results ? (
            results.map((result) => (
              <RowCard
                row={result}
                key={result?.id}
                CATEGORY_COMPLIANCE_HEADERS={CATEGORY_COMPLIANCE_HEADERS}
              />
            ))
          ) : (
            <SectionDiv>
              <p>No results found</p>
            </SectionDiv>
          )}
        </Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages ? totalPages : 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default TemplateList;

const Container = styled.div`
  margin-bottom: 30px;
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const SectionCell = styled.div`
  padding: 30px 15px;
  min-width: 350px;
`;
