import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, Button, Message, Modal, Header, Input } from "semantic-ui-react";

import ReactJson from "react-json-view";

function ViewJson({ details }) {
  const [openViewSummary, setOpenViewSummary] = useState(false);
  return (
    <>
      {!openViewSummary && (
        <Button
          style={{ margin: "20px 20px" }}
          icon
          labelPosition="left"
          floated="left"
          onClick={() => setOpenViewSummary(true)}
          size="small"
          color="blue"
        >
          View as JSON
          <Icon name="eye" />
        </Button>
      )}

      <Modal open={openViewSummary} onClose={() => setOpenViewSummary(false)}>
        <Modal.Header>
          <Header as="h1">
            Category Summary
            <Header.Subheader>
              Detailed View of JSON file
            </Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <SectionDiv>
              <ReactJson src={details} />
            </SectionDiv>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenViewSummary(false)} color="violet">
            Close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default ViewJson;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;
