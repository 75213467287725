import {
    GET_REPORT_SERVER_TEST,
    GET_REPORT_SERVER_RESET_CACHE,
    GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    report_server: [],
    pending: false,
  };

  export default function Payment(state = initialState, action) {
    switch (action.type) {
      case GET_REPORT_SERVER_TEST:
        return {
          ...state,
          report_server: action.payload,
          pending: false,
        };
  
      case GET_REPORT_SERVER_RESET_CACHE:
        return {
            ...state,
            report_server: action.payload,
            pending: false,
          };
      case GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE:
        return {
            ...state,
            report_server: action.payload,
            pending: false,
          };;
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };
  
      default:
        return state;
    }
  }
  