import React from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CLEAR_BLOG,
} from 'constants/actions';

import { Button } from 'semantic-ui-react'

// CLEAR_BLOG

const Toolbar = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  function ResetBlog() {
    dispatch({
      type: CLEAR_BLOG,
    });

    navigate('/app/blogs/create');
  }

  return (
    <div>

      {/* <RouterLink to="/app/create-blog"> */}
      <Button
        color="violet"
        onClick={ResetBlog}
      >
        Add Blog
      </Button>
      
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
