import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// import { useNavigate } from "react-router-dom";

import { format } from "date-fns";

import { Table, Icon, Confirm } from "semantic-ui-react";

const RowCard = ({ rows, EditRow, DeleteRow }) => {
  // const navigate = useNavigate();
  const [deleteRow, setDeleteRow] = useState(false)
  

  function DeleteSelectedRow() {
    DeleteRow(deleteRow)
    setDeleteRow(false)
  }

  return (
    <>
      <Table stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Added</Table.HeaderCell>
            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{row?.employee?.id}</Table.Cell>
                  <Table.Cell>{row?.employee?.first_name}{" "}{row?.employee?.last_name}</Table.Cell>
                  <Table.Cell>{row?.employee?.email}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    {row?.created_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy")}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {" "}
                    <ContainerFieldSelectable onClick={() => EditRow(row)}>
                      Edit
                    </ContainerFieldSelectable>

                    <Icon link name="trash" onClick={() => setDeleteRow(row)} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Confirm
          open={deleteRow?.id ? true : false}
          header={`Delete Prompt - Sort Order ${deleteRow?.sort_order} Warning`}
          content={`Warning this sort order ${deleteRow?.sort_order} would have to manually set`}
          onCancel={() => setDeleteRow(false)}
          onConfirm={DeleteSelectedRow}
        />
    </>
  );
};

RowCard.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default RowCard;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
