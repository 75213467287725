import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Form, Button } from "semantic-ui-react";

import { SEARCH_SURVEY_STRUCTURE } from "constants/actions";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const dispatch = useDispatch();
  const [searchBox, setSearchBox] = useState("");

  const { get_survey_structure_search } = useSelector(
    (state) => ({
      get_survey_structure_search: state.surveystructure.search,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSearchBox(get_survey_structure_search);
  }, [get_survey_structure_search]);

  function OnButtonClick() {
    dispatch({
      type: SEARCH_SURVEY_STRUCTURE,
      payload: searchBox,
    });
    setSearchTerm(searchBox);
  }

  function ClearSearch() {
    dispatch({
      type: SEARCH_SURVEY_STRUCTURE,
      payload: "",
    });
    setSearchTerm('');
  }
  return (
    <StyledForm success>
      <StyledInput
        label="Search ID"
        value={searchBox}
        onChange={(e) => setSearchBox(e.target.value)}
        placeholder="Search..."
      />
      <StyledButton type="submit" onClick={OnButtonClick}>
        Search
      </StyledButton>
      <StyledButton color="blue" onClick={ClearSearch}>
          Clear
        </StyledButton>
    </StyledForm>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
};

export default SearchBar;

// Styled components
const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
`;

const StyledInput = styled(Form.Input)`
  flex: 1;
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  background-color: #0079d3;
  color: white;
  &:hover {
    background-color: #005fa3;
  }
`;
