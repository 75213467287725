import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from 'WebImage/List';
import CreateImage from 'WebImage/ImageAdd';
import { Header } from 'semantic-ui-react'

function WebImage() {

  return (
    <>
      {/* <div style={{ marginTop: "100px" }}>
        <nav>
          <Link to="demo">My Profile</Link>
        </nav>
      </div> */}
      <Header as='h2' style={{ marginTop: "50px" }}>
        Website Images
        <Header.Subheader>
          Manage Images.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="edit/:id" element={<CreateImage />} />
        <Route path="upload" element={<CreateImage />} />
      </Routes>
    </>
  );
}

export default WebImage;
