import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

const EmpList = ({ rows, completed, totalRequests }) => (
  <Page>
    <BoxContainer>
      {rows &&
        rows.map((r, i) => {
          return (
            <Box key={i}>
              <Link
                to={`/app/employees/details/${r}`}
                preventscrollreset="true"
                target="_blank"
                rel="noopener noreferrer"
              >
                {r}{" "}
                {completed?.find((c) => c === r) ? (
                  <Icon color="green" name="check" />
                ) : ""}
              </Link>
            </Box>
          );
        })}
    </BoxContainer>
  </Page>
);

export default EmpList;

const Page = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

const BoxContainer = styled.div`
  display: flex;
  //   justify-content: space-between;
  flex-wrap: wrap;
`;

const Box = styled.div`
  width: 100px;
  height: 50px;
  background-color: #ccc;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
`;
