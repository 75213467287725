import React, { useState, useEffect, Suspense, lazy } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dimmer, Loader, Card, Pagination, Segment, Icon } from "semantic-ui-react";

import { LOAD_EMPLOYEES, UPDATE_EMPLOYEE_PAGE_NUMBER, SG_GET_EMPLOYE_CATEGORY_LIST, UPDATE_EMPLOYEE_QUERY_PARAMS } from "constants/actions";

const SearchBar = lazy(() => import("./SearchBar"));
const RowCard = lazy(() => import("./RowCard"));
const OrganizationSelect = lazy(() => import("organizations/OrganizationSelect"));
const EmployeeSummary = lazy(() => import("./Summary"));
const CategoryList = lazy(() => import("./CategoryList"));

const List = ({ hideOrgSelect, orgId }) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categorySearchId, setCategorySearchId] = useState(null);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [resetPageLoad, setResetPageLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const { get_employees, get_selectedOrg, get_organization_select } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParams] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Extract query parameters and apply filters
  const applyFilters = (catId = null, pageNo = 1, orgId = organizationId, search = searchTerm) => {
    let params = [];
    if (pageNo) params.push(`page=${pageNo}`);
    if (orgId) params.push(`organization=${orgId}`);
    if (search) params.push(`search=${search}`);
    if (catId) params.push(`category=${catId}`);

    // setQueryParams(params);
    dispatch({ type: UPDATE_EMPLOYEE_QUERY_PARAMS, payload: params });
  };

  useEffect(() => {
    // On queryParams change, load employees and category list
    if (get_employees?.queryParams?.length > 0) {
      dispatch({ type: LOAD_EMPLOYEES, payload: get_employees?.queryParams.join("&") });
      dispatch({ type: SG_GET_EMPLOYE_CATEGORY_LIST, payload: `show=true&${get_employees?.queryParams.join("&")}` });
    } else {
      dispatch({ type: LOAD_EMPLOYEES });
      dispatch({ type: SG_GET_EMPLOYE_CATEGORY_LIST, payload: "show=true" });
    }
  }, [dispatch, get_employees.queryParams]);

  useEffect(() => {
    // Set initial values from employees data
    if (get_employees) {
      setPage(get_employees.pageNumber);
      setTotalPages(Math.ceil(get_employees.employees.count / get_employees.employees.page_size));
    }
  }, [get_employees]);

  const handlePageChange = (event, { activePage }) => {
    applyFilters(categorySearchId, activePage);
    setPage(activePage);
    dispatch({ type: UPDATE_EMPLOYEE_PAGE_NUMBER, payload: activePage });
  };

  const handleOrganizationChange = (orgId) => {
    setOrganizationId(orgId)
    applyFilters(null, null, orgId);
  }

  const toggleCategoryList = () => setShowCategoryList(!showCategoryList);

  return (
    <Container>
      <p>Total Employees [{get_employees?.employees?.count}]</p>
      <SectionDiv>
        <Suspense fallback={<Loader active />}>
          <SearchBar searchTerm={searchTerm} changeSearchTerm={setSearchTerm} />
        </Suspense>
      </SectionDiv>

      {!hideOrgSelect && (
        <Suspense fallback={<Loader active />}>
          <OrganizationSelect 
          changeOrganizationId={setOrganizationId}
          setResetPageLoad={setResetPageLoad} 
          handleOrganizationChange={handleOrganizationChange}
          handleResetQueryPArams={applyFilters}
          />
        </Suspense>
      )}

      {get_selectedOrg?.organization?.id && (
        <Suspense fallback={<Loader active />}>
          <EmployeeSummary get_employees={get_employees} />
        </Suspense>
      )}

      <RightAlign>
        {categorySearchId && (
          <ContainerFieldSelectable onClick={() => applyFilters(null)}>
            Reset Category <Icon name="close" />
          </ContainerFieldSelectable>
        )}
        <ContainerFieldSelectable onClick={toggleCategoryList}>
          {showCategoryList ? "Hide Category List" : "Show Category List"} <Icon name="close" />
        </ContainerFieldSelectable>
      </RightAlign>

      {showCategoryList && (
        <Suspense fallback={<Loader active />}>
          <CategoryList
            changeCategoryId={setCategorySearchId}
            categorySearchId={categorySearchId}
          />
        </Suspense>
      )}

      <Segment basic>
        <SectionDiv>
          <Pagination activePage={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </SectionDiv>

        <Dimmer active={loading}>
          <Loader />
        </Dimmer>

        <Card.Group>
          <Suspense fallback={<Loader active />}>
            {get_employees?.employees?.results?.map((result) => (
              <RowCard row={result} key={result.id} />
            ))}
          </Suspense>
        </Card.Group>

        <SectionDiv>
          <Pagination activePage={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </SectionDiv>
      </Segment>
    </Container>
  );
};

export default List;

const Container = styled.div`
  padding: 30px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: ${(props) => (props.warn ? "black" : "#dcdcdc")};
  cursor: pointer;
`;
