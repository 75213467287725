import React, { useState } from "react";
import PropTypes from "prop-types";
// SG_DELETE_WEB_IMAGE
import { useDispatch } from "react-redux";
import { SG_DELETE_WEB_IMAGE, UPDATE_WEB_IMAGE_NUMBER } from "constants/actions";

import { Card, Image, Icon, Confirm } from 'semantic-ui-react'

const BlogCard = ({ blog, setReLoad }) => {
  const apiDispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false)
  function DeleteImage() {
    apiDispatch({
      type: SG_DELETE_WEB_IMAGE,
      payload: {
        id: blog.id
      },
    });
    apiDispatch({
      type: UPDATE_WEB_IMAGE_NUMBER,
      payload: 1,
    });
    setOpenConfirm(false)
    setReLoad(blog.id)
  }
  return (
 
      <Card>
        <Image src={blog?.image} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{blog?.name}</Card.Header>
          <Card.Meta>
            <span className='date'>
              {blog?.image}
            </span>
          </Card.Meta>
          <Card.Description>
            {blog?.description}
          </Card.Description>
          <Icon name='trash'
          onClick={() => setOpenConfirm(true)}
          style={{cursor: "pointer"}} size='large' />
        </Card.Content>
        <Confirm
          open={openConfirm}
          onCancel={() => setOpenConfirm(false)}
          onConfirm={DeleteImage}
        />
     
      </Card>

  );
};

BlogCard.propTypes = {
  blog: PropTypes.object.isRequired,
};

export default BlogCard;
