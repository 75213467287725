export const ALL_ERRORS = "ALL_ERRORS";
export const CLEAR_ALL = "CLEAR_ALL";
export const VALIDATION_ERRORS = "VALIDATION_ERRORS";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const SHOW_ERRORS = "SHOW_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SG_CLEAR_ERRORS = "SG_CLEAR_ERRORS";
export const SG_CLEAR_ALL = "SG_CLEAR_ALL";
export const SET_ERROR_STATE = "SET_ERROR_STATE";
//
export const CLEAR_CACHE = "CLEAR_CACHE";
export const SG_CLEAR_CACHE = "SG_CLEAR_CACHE";
//
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
//
export const LOGIN_PENDING= "LOGIN_PENDING";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOAD_REQUEST = "LOAD_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_AMBASSADOR = "REGISTER_AMBASSADOR";
export const REGISTER_USER_TOKEN = "REGISTER_USER_TOKEN";
export const SG_REGISTER_USER_TOKEN = "SG_REGISTER_USER_TOKEN";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const WEBSITE_RESET_PASSWORD = "WEBSITE_RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS";
export const CONFIRM_RESET_PASSWORD_FAIL = "CONFIRM_RESET_PASSWORD_FAIL";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SG_FORGOT_PASSWORD = "SG_FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SG_CHANGE_PASSWORD = "SG_CHANGE_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const SG_UPDATE_PASSWORD = "SG_UPDATE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SG_RESET_PASSWORD = "SG_RESET_PASSWORD";
export const CLEAR_PASSWORD_DATA = "CLEAR_PASSWORD_DATA";
//
//
export const UPDATE_ACCOUNT_PAGE_NUMBER = "UPDATE_ACCOUNT_PAGE_NUMBER"
export const UPDATE_ACCOUNT_QUERY_PARAMS = "UPDATE_ACCOUNT_QUERY_PARAMS"
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const SG_GET_ACCOUNTS = "SG_GET_ACCOUNTS"; //saga use this
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const SG_FETCH_ACCOUNT = "SG_FETCH_ACCOUNT";//saga use this
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const SG_EDIT_ACCOUNT = "SG_EDIT_ACCOUNT"; //saga use this
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"; 
export const SG_DELETE_ACCOUNT = "SG_DELETE_ACCOUNT"; //saga use this
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";
//
//
export const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS";
export const SG_GET_SURVEY_QUESTIONS = "SG_GET_SURVEY_QUESTIONS";
export const GET_SURVEY_QUESTION_SELECT = "GET_SURVEY_QUESTION_SELECT";
export const SG_GET_SURVEY_QUESTION_SELECT = "SG_GET_SURVEY_QUESTION_SELECT"; //saga use this
export const GET_ALL_SURVEY_QUESTION_SELECT = "GET_ALL_SURVEY_QUESTION_SELECT";
export const SG_GET_ALL_SURVEY_QUESTION_SELECT = "SG_GET_ALL_SURVEY_QUESTION_SELECT"; //saga use this
export const UPDATE_SURVEY_QUESTION_PAGE_NUMBER = "UPDATE_SURVEY_QUESTION_PAGE_NUMBER";
export const FETCH_SURVEY_QUESTION = "FETCH_SURVEY_QUESTION"; //saga use this
export const SG_FETCH_SURVEY_QUESTION = "SG_FETCH_SURVEY_QUESTION"; //saga use this
export const EDIT_SURVEY_QUESTION = "EDIT_SURVEY_QUESTION";
export const SG_EDIT_SURVEY_QUESTION = "SG_EDIT_SURVEY_QUESTION";
export const ADD_SURVEY_QUESTION = "ADD_SURVEY_QUESTION";
export const SG_ADD_SURVEY_QUESTION = "SG_ADD_SURVEY_QUESTION";
export const DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION";
export const SG_DELETE_SURVEY_QUESTION = "SG_DELETE_SURVEY_QUESTION";
export const CLEAR_SURVEY_QUESTION = "CLEAR_SURVEY_QUESTION";
//
export const LOAD_EMOTION_DEFINITIONS = "LOAD_EMOTION_DEFINITIONS"; //saga use this
export const GET_EMOTION_DEFINITIONS = "GET_EMOTION_DEFINITIONS";
//
export const LOAD_EMOTIONS = "LOAD_EMOTIONS"; //saga use this
export const GET_EMOTIONS = "GET_EMOTIONS";
//
export const GET_MOODS = "GET_MOODS"; //saga use this
export const UPDATE_POSITION = "UPDATE_POSITION"; // only reducer
export const SG_GET_MOODS = "SG_GET_MOODS";
export const FETCH_MOOD = "FETCH_MOOD";
export const SG_FETCH_MOOD = "SG_FETCH_MOOD";
export const GET_MOOD_TEAMS = "GET_MOOD_TEAMS";
export const SG_GET_MOOD_TEAMS = "SG_GET_MOOD_TEAMS";
export const GET_MOOD_ORG = "GET_MOOD_ORG";
export const SG_GET_MOOD_ORG = "SG_GET_MOOD_ORG";
export const GET_MOOD_PER = "GET_MOOD_PER";
export const SG_GET_MOOD_PER = "SG_GET_MOOD_PER";
export const GET_MOOD_PER_CLEAR = "GET_MOOD_PER_CLEAR";
//
export const LOAD_PERSONALITY = "LOAD_PERSONALITY"; //saga use this
export const GET_PERSONALITY = "GET_PERSONALITY";
export const SG_GET_PERSONALITY = "SG_GET_PERSONALITY";
//
export const LOAD_TRAIT_DATA = "LOAD_TRAIT_DATA"; //saga use this
export const GET_TRAIT_DATA = "GET_TRAIT_DATA";
//
export const GET_CULTURE_DATA = "GET_CULTURE_DATA";
export const SG_GET_CULTURE_DATA = "SG_GET_CULTURE_DATA";
export const FETCH_CULTURE_DATA = "FETCH_CULTURE_DATA";
export const SG_FETCH_CULTURE_DATA = "SG_FETCH_CULTURE_DATA";
export const UPDATE_CULTURE_COMMENTS = "UPDATE_CULTURE_COMMENTS";
export const SG_UPDATE_CULTURE_COMMENTS = "SG_UPDATE_CULTURE_COMMENTS";
export const CLEAR_UPDATE_CULTURE_COMMENTS = "CLEAR_UPDATE_CULTURE_COMMENTS";
export const GET_CULTURE_DATA_TEAMS = "GET_CULTURE_DATA_TEAMS";
export const SG_GET_CULTURE_DATA_TEAMS = "SG_GET_CULTURE_DATA_TEAMS";
export const GET_CULTURE_DATA_ORG = "GET_CULTURE_DATA_ORG";
export const SG_GET_CULTURE_DATA_ORG = "SG_GET_CULTURE_DATA_ORG";
//
export const GET_CULTURE_ORG_REPORT = "GET_CULTURE_ORG_REPORT";
export const SG_GET_CULTURE_ORG_REPORT = "SG_GET_CULTURE_ORG_REPORT";
export const FETCH_CULTURE_ORG_REPORT = "FETCH_CULTURE_ORG_REPORT";
export const SG_FETCH_CULTURE_ORG_REPORT = "SG_FETCH_CULTURE_ORG_REPORT";
//
export const GET_MOOD_ORG_REPORT = "GET_MOOD_ORG_REPORT";
export const SG_GET_MOOD_ORG_REPORT = "SG_GET_MOOD_ORG_REPORT";
export const FETCH_MOOD_ORG_REPORT = "FETCH_MOOD_ORG_REPORT";
export const SG_FETCH_MOOD_ORG_REPORT = "SG_FETCH_MOOD_ORG_REPORT";
//
export const GET_LEADERSHIP_DATA = "GET_LEADERSHIP_DATA";
export const SG_GET_LEADERSHIP_DATA = "SG_GET_LEADERSHIP_DATA";
export const FETCH_LEADERSHIP_DATA = "FETCH_LEADERSHIP_DATA";
export const SG_FETCH_LEADERSHIP_DATA = "SG_FETCH_LEADERSHIP_DATA";
export const GET_LEADERSHIP_DATA_TEAMS = "GET_LEADERSHIP_DATA_TEAMS";
export const SG_GET_LEADERSHIP_DATA_TEAMS = "SG_GET_LEADERSHIP_DATA_TEAMS";
export const GET_LEADERSHIP_DATA_ORG = "GET_LEADERSHIP_DATA_ORG";
export const SG_GET_LEADERSHIP_DATA_ORG = "SG_GET_LEADERSHIP_DATA_ORG";
//
export const GET_BLOGS = "GET_BLOGS";
export const SG_GET_BLOGS = "SG_GET_BLOGS";
export const DELETE_BLOG = "DELETE_BLOG";
export const SG_DELETE_BLOG = "SG_DELETE_BLOG";
export const FETCH_BLOG = "FETCH_BLOG";
export const SG_FETCH_BLOG = "SG_FETCH_BLOG";
export const EDIT_BLOG = "EDIT_BLOG";
export const SG_EDIT_BLOG = "SG_EDIT_BLOG";
export const UPDATE_BLOG_PIC = "UPDATE_BLOG_PIC";
export const SG_UPDATE_BLOG_PIC = "SG_UPDATE_BLOG_PIC";
export const ADD_BLOG = "ADD_BLOG";
export const SG_ADD_BLOG = "SG_ADD_BLOG";
export const CLEAR_BLOG = "CLEAR_BLOG";
//
export const GET_BLOG_CATEGORIES = "GET_BLOG_CATEGORIES";
export const UPDATE_BLOG_PAGE_NUMBER = "UPDATE_BLOG_PAGE_NUMBER";
export const SG_GET_BLOG_CATEGORIES = "SG_GET_BLOG_CATEGORIES";
export const DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY";
export const SG_DELETE_BLOG_CATEGORY = "SG_DELETE_BLOG_CATEGORY";
export const FETCH_BLOG_CATEGORY = "FETCH_BLOG_CATEGORY";
export const SG_FETCH_BLOG_CATEGORY = "SG_FETCH_BLOG_CATEGORY";
export const EDIT_BLOG_CATEGORY = "EDIT_BLOG_CATEGORY";
export const SG_EDIT_BLOG_CATEGORY = "SG_EDIT_BLOG_CATEGORY";
export const ADD_BLOG_CATEGORY = "ADD_BLOG_CATEGORY";
export const SG_ADD_BLOG_CATEGORY = "SG_ADD_BLOG_CATEGORY";
export const CLEAR_BLOG_CATEGORY = "CLEAR_BLOG_CATEGORY";
//
export const GET_BLOG_TAGS = "GET_BLOG_TAGS";
export const SG_GET_BLOG_TAGS = "SG_GET_BLOG_TAGS";
export const DELETE_BLOG_TAG = "DELETE_BLOG_TAG";
export const SG_DELETE_BLOG_TAG = "SG_DELETE_BLOG_TAG";
export const FETCH_BLOG_TAG = "FETCH_BLOG_TAG";
export const SG_FETCH_BLOG_TAG = "SG_FETCH_BLOG_TAG";
export const EDIT_BLOG_TAG = "EDIT_BLOG_TAG";
export const SG_EDIT_BLOG_TAG = "SG_EDIT_BLOG_TAG";
export const ADD_BLOG_TAG = "ADD_BLOG_TAG";
export const SG_ADD_BLOG_TAG = "SG_ADD_BLOG_TAG";
export const CLEAR_BLOG_TAG = "CLEAR_BLOG_TAG";
//
export const UPDATE_WEB_IMAGE_NUMBER = "UPDATE_WEB_IMAGE_NUMBER";
export const GET_WEB_IMAGES = "GET_WEB_IMAGES";
export const SG_GET_WEB_IMAGES = "SG_GET_WEB_IMAGES"; //saga use this
export const DELETE_WEB_IMAGE = "DELETE_WEB_IMAGE";
export const SG_DELETE_WEB_IMAGE = "SG_DELETE_WEB_IMAGE"; //saga use this
export const SG_ADD_WEB_IMAGE = 'SG_ADD_WEB_IMAGE'; //saga use this
export const ADD_WEB_IMAGE = 'ADD_WEB_IMAGE'; 
export const SG_FETCH_WEB_IMAGE = 'SG_FETCH_WEB_IMAGE'; //saga use this
export const FETCH_WEB_IMAGE = 'FETCH_WEB_IMAGE'; 
export const SG_EDIT_WEB_IMAGE = 'SG_EDIT_WEB_IMAGE'; //saga use this
export const EDIT_WEB_IMAGE = 'EDIT_WEB_IMAGE';
export const CLEAR_WEB_IMAGE = 'CLEAR_WEB_IMAGE';
export const SG_CLEAR_WEB_IMAGE = 'SG_CLEAR_WEB_IMAGE';
//
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const LOAD_DEPARTMENTS = "LOAD_DEPARTMENTS"; //saga use this
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT"; //saga use this
export const PULL_DEPARTMENT = "PULL_DEPARTMENT"; //saga use this
export const FETCH_DEPARTMENT = "FETCH_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"; //saga use this
export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT";
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT"; //saga use this
export const CLEAR_DEPARTMENT = "CLEAR_DEPARTMENT";
//
export const GET_ORGANIZATION_PENDING = "GET_ORGANIZATION_PENDING";
export const GET_ORGANIZATION_SELECT = "GET_ORGANIZATION_SELECT";
export const GET_ORGANIZATION_VIEWED = "GET_ORGANIZATION_VIEWED";
export const SG_GET_ORGANIZATION_SELECT = "SG_GET_ORGANIZATION_SELECT"; //saga use this
export const FETCH_ORGANIZATION_PRIMARY = "FETCH_ORGANIZATION_PRIMARY"; 
export const SG_FETCH_ORGANIZATION_PRIMARY = "SG_FETCH_ORGANIZATION_PRIMARY";
export const GET_ORGANIZATION_STYLE = "GET_ORGANIZATION_STYLE"; 
export const SG_GET_ORGANIZATION_STYLE = "SG_GET_ORGANIZATION_STYLE"; //saga use this
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS"; // reducer
export const LOAD_ORGANIZATIONS = "LOAD_ORGANIZATIONS"; //saga use this
export const UPDATE_ORGANIZATION_PAGE_NUMBER = "UPDATE_ORGANIZATION_PAGE_NUMBER"
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const REMOVE_ORGANIZATION = "REMOVE_ORGANIZATION"; //saga use this
export const PULL_ORGANIZATION = "PULL_ORGANIZATION"; //saga use this
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION"; //saga use this
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";
export const UPDATE_ORGANIZATION_LOGO = "UPDATE_ORGANIZATION_LOGO"; //saga use this
export const EDIT_ORGANIZATION_LOGO = "EDIT_ORGANIZATION_LOGO";
export const SAVE_ORGANIZATION = "SAVE_ORGANIZATION"; //saga use this
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const CLEAR_ORGANIZATION = "CLEAR_ORGANIZATION";
export const VALIDATE_ORGANIZATION = "VALIDATE_ORGANIZATION";
export const SG_VALIDATE_ORGANIZATION = "SG_VALIDATE_ORGANIZATION";
export const CLEAR_VALIDATE_ORGANIZATION = "CLEAR_VALIDATE_ORGANIZATION";

export const GET_INDUSTRY_BENCHMARK = "GET_INDUSTRY_BENCHMARK";
export const SG_GET_INDUSTRY_BENCHMARK = "SG_GET_INDUSTRY_BENCHMARK";
export const CLEAR_INDUSTRY_BENCHMARK = "CLEAR_INDUSTRY_BENCHMARK";
//
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const LOAD_EMPLOYEES = "LOAD_EMPLOYEES"; //saga use this
export const GET_EMPLOYEE_MANAGER = "GET_EMPLOYEE_MANAGER";
export const SG_GET_EMPLOYEE_MANAGER = "SG_GET_EMPLOYEE_MANAGER"; //saga use this
export const UPDATE_EMPLOYEE_PAGE_NUMBER = "UPDATE_EMPLOYEE_PAGE_NUMBER"
export const UPDATE_EMPLOYEE_QUERY_PARAMS = "UPDATE_EMPLOYEE_QUERY_PARAMS"
// GET_EMPLOYEE_SELECT_LIST
export const GET_EMPLOYEE_SELECT_LIST = "GET_EMPLOYEE_SELECT_LIST";
export const SG_GET_EMPLOYEE_SELECT_LIST = "SG_GET_EMPLOYEE_SELECT_LIST"; //saga use this
//
export const SEARCH_EMPLOYEES = "SEARCH_EMPLOYEES";
export const SG_SEARCH_EMPLOYEES = "SG_SEARCH_EMPLOYEES"; //saga use this
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE"; //saga use this
export const PULL_EMPLOYEE = "PULL_EMPLOYEE";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE"; //saga use this
export const FETCH_USER_EMPLOYEE = "FETCH_USER_EMPLOYEE"
export const SG_FETCH_USER_EMPLOYEE = "SG_FETCH_USER_EMPLOYEE"
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE"; //saga use this
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const UPDATE_EMPLOYEE_PIC = "UPDATE_EMPLOYEE_PIC"; //saga use this
export const EDIT_EMPLOYEE_PIC = "EDIT_EMPLOYEE_PIC";
export const SAVE_EMPLOYEE = "SAVE_EMPLOYEE"; //saga use this
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const RESET_EMPLOYEE = "RESET_EMPLOYEE";
export const PENDING_EMPLOYEE = "PENDING_EMPLOYEE";
export const REGISTER_EMPLOYEE = "REGISTER_EMPLOYEE"; //saga use this to register
export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";

export const VALIDATE_EMPLOYEE = "VALIDATE_EMPLOYEE";
export const SG_VALIDATE_EMPLOYEE = "SG_VALIDATE_EMPLOYEE";
export const CLEAR_VALIDATE_EMPLOYEE = "CLEAR_VALIDATE_EMPLOYEE";

export const GET_EMPLOYEE_REPORTS = "GET_EMPLOYEE_REPORTS";
export const SG_GET_EMPLOYEE_REPORTS = "SG_GET_EMPLOYEE_REPORTS"; //saga use this
export const CLEAR_EMPLOYEE_REPORTS = "CLEAR_EMPLOYEE_REPORTS";
//
export const GET_TEAMS = "GET_TEAMS";
export const LOAD_TEAMS = "LOAD_TEAMS"; //saga use this
export const DELETE_TEAM = "DELETE_TEAM";
export const REMOVE_TEAM = "REMOVE_TEAM"; //saga use this
export const PULL_TEAM = "PULL_TEAM"; //saga use this
export const FETCH_TEAM = "FETCH_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM"; //saga use this
export const EDIT_TEAM = "EDIT_TEAM";
export const SAVE_TEAM = "SAVE_TEAM";
export const ADD_TEAM = "ADD_TEAM"; //saga use this
export const CLEAR_TEAM = "CLEAR_TEAM";
export const RESET_CREATED_TEAM = "RESET_CREATED_TEAM";
//
export const GET_TEAM_TYPES = "GET_TEAM_TYPES";
export const SG_GET_TEAM_TYPES = "SG_GET_TEAM_TYPES"; //saga use this
export const DELETE_TEAM_TYPE = "DELETE_TEAM_TYPE";
export const SG_DELETE_TEAM_TYPE = "SG_DELETE_TEAM_TYPE"; //saga use this
export const FETCH_TEAM_TYPE = "FETCH_TEAM_TYPE";
export const SG_FETCH_TEAM_TYPE = "SG_FETCH_TEAM_TYPE"; //saga use this
export const EDIT_TEAM_TYPE = "EDIT_TEAM_TYPE";
export const SG_EDIT_TEAM_TYPE = "SG_EDIT_TEAM_TYPE"; //saga use this
export const ADD_TEAM_TYPE = "ADD_TEAM_TYPE";
export const SG_ADD_TEAM_TYPE = "SG_ADD_TEAM_TYPE"; //saga use this
export const CLEAR_TEAM_TYPE = "CLEAR_TEAM_TYPE";
//
export const GET_EMPLOYEE_ROLES = "GET_EMPLOYEE_ROLES";
export const SG_GET_EMPLOYEE_ROLES = "SG_GET_EMPLOYEE_ROLES"; //saga use this
export const DELETE_EMPLOYEE_ROLE = "DELETE_EMPLOYEE_ROLE";
export const SG_DELETE_EMPLOYEE_ROLE = "SG_DELETE_EMPLOYEE_ROLE"; //saga use this
export const FETCH_EMPLOYEE_ROLE = "FETCH_EMPLOYEE_ROLE";
export const SG_FETCH_EMPLOYEE_ROLE = "SG_FETCH_EMPLOYEE_ROLE"; //saga use this
export const EDIT_EMPLOYEE_ROLE = "EDIT_EMPLOYEE_ROLE";
export const SG_EDIT_EMPLOYEE_ROLE = "SG_EDIT_EMPLOYEE_ROLE"; //saga use this
export const ADD_EMPLOYEE_ROLE = "ADD_EMPLOYEE_ROLE";
export const SG_ADD_EMPLOYEE_ROLE = "SG_ADD_EMPLOYEE_ROLE"; //saga use this
export const CLEAR_EMPLOYEE_ROLE = "CLEAR_EMPLOYEE_ROLE";
export const GET_PRICES = "GET_PRICES";
export const SG_GET_PRICES = "SG_GET_PRICES";
export const FETCH_PRICE = "FETCH_PRICE";
export const SG_FETCH_PRICE = "SG_FETCH_PRICE";
export const CLEAR_PRICE = "CLEAR_PRICE";
//
export const GET_PAYMENTS = "GET_PAYMENTS";
export const LOAD_PAYMENTS = "LOAD_PAYMENTS"; //saga use this
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT"; //saga use this

export const PULL_PAYMENT = "PULL_PAYMENT"; //saga use this
export const FETCH_PAYMENT = "FETCH_PAYMENT";

export const NEXT_INVOICE = "NEXT_INVOICE";
export const SG_NEXT_INVOICE = "SG_NEXT_INVOICE"; //saga use this

export const GET_STRIPE_TAX_RATES = "GET_STRIPE_TAX_RATES";
export const SG_GET_STRIPE_TAX_RATES = "SG_GET_STRIPE_TAX_RATES"; //saga use this

export const GET_STRIPE_PAYMENT_METHODS = "GET_STRIPE_PAYMENT_METHODS";
export const SG_GET_STRIPE_PAYMENT_METHODS = "SG_GET_STRIPE_PAYMENT_METHODS"; //saga use this

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const SG_GET_PAYMENT_METHODS = "SG_GET_PAYMENT_METHODS"; //saga use this
// export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
// export const SG_UPDATE_PAYMENT_METHOD = "SG_UPDATE_PAYMENT_METHOD";//saga use this
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const SG_DELETE_PAYMENT_METHOD = "SG_GET_DELETE_PAYMENT_METHOD"; //saga use this
export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const SG_CREATE_PAYMENT_METHOD = "SG_CREATE_PAYMENT_METHOD"; //saga use this
//

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const SG_CANCEL_SUBSCRIPTION = "SG_CANCEL_SUBSCRIPTION"; //saga use this

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const SG_UPDATE_SUBSCRIPTION = "SG_UPDATE_SUBSCRIPTION"; //saga use this

export const UPGRADE_PLAN = "UPGRADE_PLAN";
export const SG_UPGRADE_PLAN = "SG_UPGRADE_PLAN"; //saga use this

export const DOWNGRADE_PLAN = "DOWNGRADE_PLAN";
export const SG_DOWNGRADE_PLAN = "SG_DOWNGRADE_PLAN"; //saga use this

export const UPDATE_PAYMENT = "UPDATE_PAYMENT"; //saga use this
export const EDIT_PAYMENT = "EDIT_PAYMENT";

export const SAVE_PAYMENT = "SAVE_PAYMENT"; //saga use this
export const ADD_PAYMENT = "ADD_PAYMENT";

export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT";
export const SG_CREATE_PAYMENT_INTENT = "SG_CREATE_PAYMENT_INTENT"; //saga use this

export const CLEAR_PAYMENT = "CLEAR_PAYMENT";
//
export const PENDING_GLOBAL_SCHEDULES = "PENDING_GLOBAL_SCHEDULES";
export const UPDATED_GLOBAL_SCHEDULES = "UPDATED_GLOBAL_SCHEDULES";
export const REFRESHED_GLOBAL_SCHEDULES = "REFRESHED_GLOBAL_SCHEDULES";

export const GET_GLOBAL_SCHEDULES = "GET_GLOBAL_SCHEDULES";
export const SG_GET_GLOBAL_SCHEDULES = "SG_GET_GLOBAL_SCHEDULES"; //saga use this

export const DELETE_GLOBAL_SCHEDULE = "DELETE_GLOBAL_SCHEDULE";
export const SG_DELETE_GLOBAL_SCHEDULE = "SG_DELETE_GLOBAL_SCHEDULE"; //saga use this

export const SG_FETCH_GLOBAL_SCHEDULE = "SG_FETCH_GLOBAL_SCHEDULE"; //saga use this
export const FETCH_GLOBAL_SCHEDULE = "FETCH_GLOBAL_SCHEDULE";

export const SG_EDIT_GLOBAL_SCHEDULE = "SG_EDIT_GLOBAL_SCHEDULE"; //saga use this
export const EDIT_GLOBAL_SCHEDULE = "EDIT_GLOBAL_SCHEDULE";

export const SG_ADD_GLOBAL_SCHEDULE = "SG_ADD_GLOBAL_SCHEDULE"; //saga use this
export const ADD_GLOBAL_SCHEDULE = "ADD_GLOBAL_SCHEDULE";

export const CLEAR_GLOBAL_SCHEDULE = "CLEAR_GLOBAL_SCHEDULE";
export const SG_CLEAR_GLOBAL_SCHEDULE = "SG_CLEAR_GLOBAL_SCHEDULE";
//
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const SG_GET_SUBSCRIPTIONS = "SG_GET_SUBSCRIPTIONS"; //saga use this

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const SG_DELETE_SUBSCRIPTION = "SG_DELETE_SUBSCRIPTION"; //saga use this

export const SG_FETCH_SUBSCRIPTION = "SG_FETCH_SUBSCRIPTION"; //saga use this
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";

export const SG_EDIT_SUBSCRIPTION = "SG_EDIT_SUBSCRIPTION"; //saga use this
export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";

export const SG_ADD_SUBSCRIPTION = "SG_ADD_SUBSCRIPTION"; //saga use this
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";

export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";
export const SG_CLEAR_SUBSCRIPTION = "SG_CLEAR_SUBSCRIPTION";
//
export const GET_GLOBAL_SCHEDULE_FREQUENCIES =
	"GET_GLOBAL_SCHEDULE_FREQUENCIES";
export const SG_GET_GLOBAL_SCHEDULE_FREQUENCIES =
	"SG_GET_GLOBAL_SCHEDULE_FREQUENCIES"; //saga use this

export const DELETE_GLOBAL_SCHEDULE_FREQUENCY =
	"DELETE_GLOBAL_SCHEDULE_FREQUENCY";
export const SG_DELETE_GLOBAL_SCHEDULE_FREQUENCY =
	"SG_DELETE_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this

export const SG_FETCH_GLOBAL_SCHEDULE_FREQUENCY =
	"SG_FETCH_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this
export const FETCH_GLOBAL_SCHEDULE_FREQUENCY =
	"FETCH_GLOBAL_SCHEDULE_FREQUENCY";

export const SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY =
	"SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this
export const EDIT_GLOBAL_SCHEDULE_FREQUENCY = "EDIT_GLOBAL_SCHEDULE_FREQUENCY";

export const SG_ADD_GLOBAL_SCHEDULE_FREQUENCY =
	"SG_ADD_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this
export const ADD_GLOBAL_SCHEDULE_FREQUENCY = "ADD_GLOBAL_SCHEDULE_FREQUENCY";

export const CLEAR_GLOBAL_SCHEDULE_FREQUENCY =
	"CLEAR_GLOBAL_SCHEDULE_FREQUENCY";
export const SG_CLEAR_GLOBAL_SCHEDULE_FREQUENCY =
	"SG_CLEAR_GLOBAL_SCHEDULE_FREQUENCY";
//
export const GET_SURVEY_COMPLIANCES = "GET_SURVEY_COMPLIANCES";
export const SG_GET_SURVEY_COMPLIANCES = "SG_GET_SURVEY_COMPLIANCES"; //saga use this
export const UPDATE_SURVEY_COMPLIANCE_NUMBER = "UPDATE_SURVEY_COMPLIANCE_NUMBER";
export const DELETE_SURVEY_COMPLIANCE = "DELETE_SURVEY_COMPLIANCE";
export const SG_DELETE_SURVEY_COMPLIANCE = "SG_DELETE_SURVEY_COMPLIANCE"; //saga use this

export const SG_FETCH_SURVEY_COMPLIANCE = "SG_FETCH_SURVEY_COMPLIANCE"; //saga use this
export const FETCH_SURVEY_COMPLIANCE = "FETCH_SURVEY_COMPLIANCE";

export const SG_EDIT_SURVEY_COMPLIANCE = "SG_EDIT_SURVEY_COMPLIANCE"; //saga use this
export const EDIT_SURVEY_COMPLIANCE = "EDIT_SURVEY_COMPLIANCE";

export const SG_ADD_SURVEY_COMPLIANCE = "SG_ADD_SURVEY_COMPLIANCE"; //saga use this
export const ADD_SURVEY_COMPLIANCE = "ADD_SURVEY_COMPLIANCE";

export const CLEAR_SURVEY_COMPLIANCE = "CLEAR_SURVEY_COMPLIANCE";
export const SG_CLEAR_SURVEY_COMPLIANCE = "SG_CLEAR_SURVEY_COMPLIANCE";
//
export const GET_TEAMS_IN_SCHEDULES = "GET_TEAMS_IN_SCHEDULES";
export const SG_GET_TEAMS_IN_SCHEDULES = "SG_GET_TEAMS_IN_SCHEDULES"; //saga use this

export const DELETE_TEAMS_IN_SCHEDULE = "DELETE_TEAMS_IN_SCHEDULE";
export const SG_DELETE_TEAMS_IN_SCHEDULE = "SG_DELETE_TEAMS_IN_SCHEDULE"; //saga use this

export const SG_FETCH_TEAMS_IN_SCHEDULE = "SG_FETCH_TEAMS_IN_SCHEDULE"; //saga use this
export const FETCH_TEAMS_IN_SCHEDULE = "FETCH_TEAMS_IN_SCHEDULE";

export const SG_EDIT_TEAMS_IN_SCHEDULE = "SG_EDIT_TEAMS_IN_SCHEDULE"; //saga use this
export const EDIT_TEAMS_IN_SCHEDULE = "EDIT_TEAMS_IN_SCHEDULE";

export const SG_ADD_TEAMS_IN_SCHEDULE = "SG_ADD_TEAMS_IN_SCHEDULE"; //saga use this
export const ADD_TEAMS_IN_SCHEDULE = "ADD_TEAMS_IN_SCHEDULE";

export const CLEAR_TEAMS_IN_SCHEDULE = "CLEAR_TEAMS_IN_SCHEDULE";
export const SG_CLEAR_TEAMS_IN_SCHEDULE = "SG_CLEAR_TEAMS_IN_SCHEDULE";
//
export const GET_LEADERS_IN_SCHEDULES = "GET_LEADERS_IN_SCHEDULES";
export const SG_GET_LEADERS_IN_SCHEDULES = "SG_GET_LEADERS_IN_SCHEDULES"; //saga use this

export const DELETE_LEADERS_IN_SCHEDULE = "DELETE_LEADERS_IN_SCHEDULE";
export const SG_DELETE_LEADERS_IN_SCHEDULE = "SG_DELETE_LEADERS_IN_SCHEDULE"; //saga use this

export const SG_FETCH_LEADERS_IN_SCHEDULE = "SG_FETCH_LEADERS_IN_SCHEDULE"; //saga use this
export const FETCH_LEADERS_IN_SCHEDULE = "FETCH_LEADERS_IN_SCHEDULE";

export const SG_EDIT_LEADERS_IN_SCHEDULE = "SG_EDIT_LEADERS_IN_SCHEDULE"; //saga use this
export const EDIT_LEADERS_IN_SCHEDULE = "EDIT_LEADERS_IN_SCHEDULE";

export const SG_ADD_LEADERS_IN_SCHEDULE = "SG_ADD_LEADERS_IN_SCHEDULE"; //saga use this
export const ADD_LEADERS_IN_SCHEDULE = "ADD_LEADERS_IN_SCHEDULE";

export const CLEAR_LEADERS_IN_SCHEDULE = "CLEAR_LEADERS_IN_SCHEDULE";
export const SG_CLEAR_LEADERS_IN_SCHEDULE = "SG_CLEAR_LEADERS_IN_SCHEDULE";
//
export const GET_LEADER_SURVEY_PARTICIPANTS = "GET_LEADER_SURVEY_PARTICIPANTS";
export const SG_GET_LEADER_SURVEY_PARTICIPANTS =
	"SG_GET_LEADER_SURVEY_PARTICIPANTS"; //saga use this

export const DELETE_LEADER_SURVEY_PARTICIPANT =
	"DELETE_LEADER_SURVEY_PARTICIPANT";
export const SG_DELETE_LEADER_SURVEY_PARTICIPANT =
	"SG_DELETE_LEADER_SURVEY_PARTICIPANT"; //saga use this

export const SG_FETCH_LEADER_SURVEY_PARTICIPANT =
	"SG_FETCH_LEADER_SURVEY_PARTICIPANT"; //saga use this
export const FETCH_LEADER_SURVEY_PARTICIPANT =
	"FETCH_LEADER_SURVEY_PARTICIPANT";

export const SG_EDIT_LEADER_SURVEY_PARTICIPANT =
	"SG_EDIT_LEADER_SURVEY_PARTICIPANT"; //saga use this
export const EDIT_LEADER_SURVEY_PARTICIPANT = "EDIT_LEADER_SURVEY_PARTICIPANT";

export const SG_ADD_LEADER_SURVEY_PARTICIPANT =
	"SG_ADD_LEADER_SURVEY_PARTICIPANT"; //saga use this
export const ADD_LEADER_SURVEY_PARTICIPANT = "ADD_LEADER_SURVEY_PARTICIPANT";

export const CLEAR_LEADER_SURVEY_PARTICIPANT =
	"CLEAR_LEADER_SURVEY_PARTICIPANT";
export const SG_CLEAR_LEADER_SURVEY_PARTICIPANT =
	"SG_CLEAR_LEADER_SURVEY_PARTICIPANT";
//
export const GET_PERSONALITY_TEAMS = "GET_PERSONALITY_TEAMS";
export const SG_GET_PERSONALITY_TEAMS = "SG_GET_PERSONALITY_TEAMS"; //saga use this

export const DELETE_PERSONALITY_TEAM = "DELETE_PERSONALITY_TEAM";
export const SG_DELETE_PERSONALITY_TEAM = "SG_DELETE_PERSONALITY_TEAM"; //saga use this

export const SG_FETCH_PERSONALITY_TEAM = "SG_FETCH_PERSONALITY_TEAM"; //saga use this
export const FETCH_PERSONALITY_TEAM = "FETCH_PERSONALITY_TEAM";

export const SG_EDIT_PERSONALITY_TEAM = "SG_EDIT_PERSONALITY_TEAM"; //saga use this
export const EDIT_PERSONALITY_TEAM = "EDIT_PERSONALITY_TEAM";

export const SG_ADD_PERSONALITY_TEAM = "SG_ADD_PERSONALITY_TEAM"; //saga use this
export const ADD_PERSONALITY_TEAM = "ADD_PERSONALITY_TEAM";

export const CLEAR_PERSONALITY_TEAM = "CLEAR_PERSONALITY_TEAM";
export const SG_CLEAR_PERSONALITY_TEAM = "SG_CLEAR_PERSONALITY_TEAM";
//
export const GET_PERSONALITY_PARTICIPANTS = "GET_PERSONALITY_PARTICIPANTS";
export const SG_GET_PERSONALITY_PARTICIPANTS =
	"SG_GET_PERSONALITY_PARTICIPANTS"; //saga use this

export const DELETE_PERSONALITY_PARTICIPANT = "DELETE_PERSONALITY_PARTICIPANT";
export const SG_DELETE_PERSONALITY_PARTICIPANT =
	"SG_DELETE_PERSONALITY_PARTICIPANT"; //saga use this

export const SG_FETCH_PERSONALITY_PARTICIPANT =
	"SG_FETCH_PERSONALITY_PARTICIPANT"; //saga use this
export const FETCH_PERSONALITY_PARTICIPANT = "FETCH_PERSONALITY_PARTICIPANT";

export const SG_EDIT_PERSONALITY_PARTICIPANT =
	"SG_EDIT_PERSONALITY_PARTICIPANT"; //saga use this
export const EDIT_PERSONALITY_PARTICIPANT = "EDIT_PERSONALITY_PARTICIPANT";

export const SG_ADD_PERSONALITY_PARTICIPANT = "SG_ADD_PERSONALITY_PARTICIPANT"; //saga use this
export const ADD_PERSONALITY_PARTICIPANT = "ADD_PERSONALITY_PARTICIPANT";

export const CLEAR_PERSONALITY_PARTICIPANT = "CLEAR_PERSONALITY_PARTICIPANT";
export const SG_CLEAR_PERSONALITY_PARTICIPANT =
	"SG_CLEAR_PERSONALITY_PARTICIPANT";
//
export const GET_SIGNUP_TOKENS = "GET_SIGNUP_TOKENS";
export const SG_GET_SIGNUP_TOKENS = "SG_GET_SIGNUP_TOKENS"; //saga use this

export const DELETE_SIGNUP_TOKEN = "DELETE_SIGNUP_TOKEN";
export const SG_DELETE_SIGNUP_TOKEN = "SG_DELETE_SIGNUP_TOKEN"; //saga use this

export const SG_ADD_SIGNUP_TOKEN = "SG_ADD_SIGNUP_TOKEN"; //saga use this
export const ADD_SIGNUP_TOKEN = "ADD_SIGNUP_TOKEN";

export const CLEAR_SIGNUP_TOKEN = "CLEAR_SIGNUP_TOKEN";
export const SG_CLEAR_SIGNUP_TOKEN = "SG_CLEAR_SIGNUP_TOKEN";
//
export const GET_SURVEY_TOKENS = "GET_SURVEY_TOKENS";
export const SG_GET_SURVEY_TOKENS = "SG_GET_SURVEY_TOKENS"; //saga use this
//
export const SURVEY_TOKEN_PAGE_NUMBER = "SURVEY_TOKEN_PAGE_NUMBER";
//
export const FETCH_SURVEY_TOKEN = "FETCH_SURVEY_TOKEN";
export const SG_FETCH_SURVEY_TOKEN = "SG_FETCH_SURVEY_TOKEN"; //saga use this
//
export const DELETE_SURVEY_TOKEN = "DELETE_SURVEY_TOKEN";
export const SG_DELETE_SURVEY_TOKEN = "SG_DELETE_SURVEY_TOKEN"; //saga use this

export const SG_ADD_SURVEY_TOKEN = "SG_ADD_SURVEY_TOKEN"; //saga use this
export const ADD_SURVEY_TOKEN = "ADD_SURVEY_TOKEN";

export const CLEAR_SURVEY_TOKEN = "CLEAR_SURVEY_TOKEN";
export const SG_CLEAR_SURVEY_TOKEN = "SG_CLEAR_SURVEY_TOKEN";
//
export const GET_SURVEY_RESPONSES = "GET_SURVEY_RESPONSES";
export const SG_GET_SURVEY_RESPONSES = "SG_GET_SURVEY_RESPONSES"; //saga use this
//
export const FETCH_SURVEY_RESPONSE = "FETCH_SURVEY_RESPONSE";
export const SG_FETCH_SURVEY_RESPONSE = "SG_FETCH_SURVEY_RESPONSE"; //saga use this
//
export const SURVEY_RESPONSE_PAGE_NUMBER = "SURVEY_RESPONSE_PAGE_NUMBER";
export const DELETE_SURVEY_RESPONSE = "DELETE_SURVEY_RESPONSE";
export const SG_DELETE_SURVEY_RESPONSE = "SG_DELETE_SURVEY_RESPONSE"; //saga use this

export const SG_ADD_SURVEY_RESPONSE = "SG_ADD_SURVEY_RESPONSE"; //saga use this
export const ADD_SURVEY_RESPONSE = "ADD_SURVEY_RESPONSE";

export const CLEAR_SURVEY_RESPONSE = "CLEAR_SURVEY_RESPONSE";
export const SG_CLEAR_SURVEY_RESPONSE = "SG_CLEAR_SURVEY_RESPONSE";
//
export const SIGNUP_HOME = "SIGNUP_HOME";
export const SIGNUP_DEPARTMENT = "SIGNUP_DEPARTMENT";
export const SIGNUP_TEAM = "SIGNUP_TEAM";
export const SIGNUP_EMPLOYEE = "SIGNUP_EMPLOYEE";
//
export const SG_VALIDATE_EMAIL = "SG_VALIDATE_EMAIL"; //saga use this
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
//
export const SG_VALIDATE_EMAIL_LIST = "SG_VALIDATE_EMAIL_LIST"; //saga use this
export const VALIDATE_EMAIL_LIST = "VALIDATE_EMAIL_LIST";
//
export const CLEAR_VALIDATE_EMAIL = "CLEAR_VALIDATE_EMAIL";
export const SG_CLEAR_VALIDATE_EMAIL = "SG_CLEAR_VALIDATE_EMAIL";
//
export const SG_VALIDATE_TOKEN = "SG_VALIDATE_TOKEN"; //saga use this
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";

export const CLEAR_VALIDATE_TOKEN = "CLEAR_VALIDATE_TOKEN";
export const SG_CLEAR_VALIDATE_TOKEN = "SG_CLEAR_VALIDATE_TOKEN";
//
export const SG_PRE_VALIDATE_EMAIL = "SG_PRE_VALIDATE_EMAIL"; //saga use this
export const PRE_VALIDATE_EMAIL = "PRE_VALIDATE_EMAIL";

export const CLEAR_PRE_VALIDATE_EMAIL = "CLEAR_PRE_VALIDATE_EMAIL";
export const SG_CLEAR_PRE_VALIDATE_EMAIL = "SG_CLEAR_PRE_VALIDATE_EMAIL";
//
export const GET_ONBOARDINGS = "GET_ONBOARDINGS";
export const SG_GET_ONBOARDINGS = "SG_GET_ONBOARDINGS"; //saga use this

export const DELETE_ONBOARDING = "DELETE_ONBOARDING";
export const SG_DELETE_ONBOARDING = "SG_DELETE_ONBOARDING"; //saga use this

export const SG_ADD_ONBOARDING = "SG_ADD_ONBOARDING"; //saga use this
export const ADD_ONBOARDING = "ADD_ONBOARDING";

export const SG_FETCH_ONBOARDING = "SG_FETCH_ONBOARDING"; //saga use this
export const FETCH_ONBOARDING = "FETCH_ONBOARDING";

export const SG_EDIT_ONBOARDING = "SG_EDIT_ONBOARDING"; //saga use this
export const EDIT_ONBOARDING = "EDIT_ONBOARDING";

export const CLEAR_ONBOARDING = "CLEAR_ONBOARDING";
export const SG_CLEAR_ONBOARDING = "SG_CLEAR_ONBOARDING";
//
export const SG_EMAIL_DELIVERY_STATUS = "SG_EMAIL_DELIVERY_STATUS"; //saga use this
export const EMAIL_DELIVERY_STATUS = "EMAIL_DELIVERY_STATUS";

export const SG_FETCH_EMAIL_DELIVERY_STATUS = "SG_FETCH_EMAIL_DELIVERY_STATUS"; //saga use this
export const FETCH_EMAIL_DELIVERY_STATUS = "FETCH_EMAIL_DELIVERY_STATUS";

export const CLEAR_EMAIL_DELIVERY_STATUS = "CLEAR_EMAIL_DELIVERY_STATUS";
export const SG_CLEAR_EMAIL_DELIVERY_STATUS = "SG_CLEAR_EMAIL_DELIVERY_STATUS";
//
export const GET_AMBASSADORS = "GET_AMBASSADORS";
export const SG_GET_AMBASSADORS = "SG_GET_AMBASSADORS"; //saga use this
export const DELETE_AMBASSADOR = "DELETE_AMBASSADOR";
export const SG_DELETE_AMBASSADOR = "SG_DELETE_AMBASSADOR"; //saga use this
export const FETCH_AMBASSADOR = "FETCH_AMBASSADOR";
export const SG_FETCH_AMBASSADOR = "SG_FETCH_AMBASSADOR"; //saga use this
export const EDIT_AMBASSADOR = "EDIT_AMBASSADOR";
export const SG_EDIT_AMBASSADOR = "SG_EDIT_AMBASSADOR"; //saga use this
export const ADD_AMBASSADOR = "ADD_AMBASSADOR";
export const SG_ADD_AMBASSADOR = "SG_ADD_AMBASSADOR"; //saga use this
export const UPDATE_AMBASSADOR_PIC = "UPDATE_AMBASSADOR_PIC";
export const SG_UPDATE_AMBASSADOR_PIC = "SG_UPDATE_AMBASSADOR_PIC";
export const CLEAR_AMBASSADOR = "CLEAR_AMBASSADOR";
export const VALIDATE_AMBASSADOR = "VALIDATE_AMBASSADOR";
export const SG_VALIDATE_AMBASSADOR = "SG_VALIDATE_AMBASSADOR";
//
export const GET_AM_ORGANIZATION = "GET_AM_ORGANIZATION";
export const SET_AUTH_ORGANIZATION = "SET_AUTH_ORGANIZATION";
export const SET_AM_MENU = "SET_AM_MENU";
export const GET_AM_TEAM = "GET_AM_TEAM";
export const GET_AM_EMPLOYEE = "GET_AM_EMPLOYEE";
// export const GET_AM_ROLE = "GET_AM_ROLE";
// export const GET_AM_ACCOUNT = "GET_AM_ACCOUNT";
export const CLEAR_AM = "CLEAR_AM";
//
export const CLEAR_SURVEY_SETTING_LIST = "CLEAR_SURVEY_SETTING_LIST";
export const SG_CLEAR_SURVEY_SETTING_LIST = "SG_CLEAR_ONBOARDING";
export const SG_GET_SURVEY_SETTING_LIST = "SG_GET_SURVEY_SETTING_LIST"; //saga use this
export const GET_SURVEY_SETTING_LIST = "GET_SURVEY_SETTING_LIST";
export const SG_UPDATE_SURVEY_SETTING_LIST = "SG_UPDATE_SURVEY_SETTING_LIST"; //saga use this
export const UPDATE_SURVEY_SETTING_LIST = "UPDATE_SURVEY_SETTING_LIST";
export const SG_DELETE_SURVEY_SETTING_LIST = "SG_DELETE_SURVEY_SETTING_LIST"; //saga use this
export const DELETE_SURVEY_SETTING_LIST = "DELETE_SURVEY_SETTING_LIST";
//
//
export const GET_TEAM_ACCESSS = "GET_TEAM_ACCESSS";
export const SG_GET_TEAM_ACCESSS = "SG_GET_TEAM_ACCESSS"; //saga use this
export const DELETE_TEAM_ACCESS = "DELETE_TEAM_ACCESS";
export const SG_DELETE_TEAM_ACCESS = "SG_DELETE_TEAM_ACCESS"; //saga use this
export const FETCH_TEAM_ACCESS = "FETCH_TEAM_ACCESS";
export const SG_FETCH_TEAM_ACCESS = "SG_FETCH_TEAM_ACCESS"; //saga use this
export const EDIT_TEAM_ACCESS = "EDIT_TEAM_ACCESS";
export const SG_EDIT_TEAM_ACCESS = "SG_EDIT_TEAM_ACCESS"; //saga use this
export const ADD_TEAM_ACCESS = "ADD_TEAM_ACCESS";
export const SG_ADD_TEAM_ACCESS = "SG_ADD_TEAM_ACCESS"; //saga use this
export const CLEAR_TEAM_ACCESS = "CLEAR_TEAM_ACCESS";
//
export const DEBRIEF_SCHEDULE_PENDING = "DEBRIEF_SCHEDULE_PENDING";
export const GET_DEBRIEF_SCHEDULES_SELECT_LIST = "GET_DEBRIEF_SCHEDULES_SELECT_LIST";
export const SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST = "SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST";
export const GET_DEBRIEF_SCHEDULES_UPCOMING = "GET_DEBRIEF_SCHEDULES_UPCOMING";
export const SG_GET_DEBRIEF_SCHEDULES_UPCOMING = "SG_GET_DEBRIEF_SCHEDULES_UPCOMING";
export const GET_DEBRIEF_SCHEDULES = "GET_DEBRIEF_SCHEDULES";
export const SG_GET_DEBRIEF_SCHEDULES = "SG_GET_DEBRIEF_SCHEDULES"; //saga use this
export const DEBRIEF_SCHEDULE_PAGE_NUMBER = "DEBRIEF_SCHEDULE_PAGE_NUMBER"; 
export const DEBRIEF_SCHEDULE_SEARCH_TERM = "DEBRIEF_SCHEDULE_SEARCH_TERM"; 
export const POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY = "POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY";
export const SG_POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY = "SG_POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY"; //saga use this
export const DEBRIEF_SCHEDULE_SEARCH = "DEBRIEF_SCHEDULE_SEARCH";
export const DELETE_DEBRIEF_SCHEDULE = "DELETE_DEBRIEF_SCHEDULE";
export const SG_DELETE_DEBRIEF_SCHEDULE = "SG_DELETE_DEBRIEF_SCHEDULE"; //saga use this
export const SELECTED_DEBRIEF_SCHEDULE = "SELECTED_DEBRIEF_SCHEDULE";
export const RE_SELECTED_DEBRIEF_SCHEDULE = "RE_SELECTED_DEBRIEF_SCHEDULE";
export const FETCH_DEBRIEF_SCHEDULE = "FETCH_DEBRIEF_SCHEDULE";
export const FETCH_DEBRIEF_SCHEDULE_SUMMARY = "FETCH_DEBRIEF_SCHEDULE_SUMMARY";
export const SG_FETCH_DEBRIEF_SCHEDULE_SUMMARY = "SG_FETCH_DEBRIEF_SCHEDULE_SUMMARY"; //saga use this
export const SG_FETCH_DEBRIEF_SCHEDULE = "SG_FETCH_DEBRIEF_SCHEDULE"; //saga use this
export const EDIT_DEBRIEF_SCHEDULE = "EDIT_DEBRIEF_SCHEDULE";
export const SG_EDIT_DEBRIEF_SCHEDULE = "SG_EDIT_DEBRIEF_SCHEDULE"; //saga use this
export const ADD_NEW_DEBRIEF_SCHEDULE = "ADD_NEW_DEBRIEF_SCHEDULE";
export const SG_ADD_DEBRIEF_SCHEDULE = "SG_ADD_DEBRIEF_SCHEDULE"; //saga use this
export const MERGE_DEBRIEF_SCHEDULE = "MERGE_DEBRIEF_SCHEDULE";
export const SG_MERGE_DEBRIEF_SCHEDULE = "SG_MERGE_DEBRIEF_SCHEDULE"; //saga use this
export const GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE =
	"GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE";
export const ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE =
	"ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE";
export const RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE =
	"RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE";
export const CLEAR_DEBRIEF_SCHEDULE = "CLEAR_DEBRIEF_SCHEDULE";
//
export const GET_DEBRIEF_RESPONSES = "GET_DEBRIEF_RESPONSES";
export const SG_GET_DEBRIEF_RESPONSES = "SG_GET_DEBRIEF_RESPONSES"; //saga use this
export const DEBRIEF_RESPONSE_PAGE_NUMBER = "DEBRIEF_RESPONSE_PAGE_NUMBER";
export const DELETE_DEBRIEF_RESPONSE = "DELETE_DEBRIEF_RESPONSE";
export const SG_DELETE_DEBRIEF_RESPONSE = "SG_DELETE_DEBRIEF_RESPONSE"; //saga use this
export const FETCH_DEBRIEF_RESPONSE = "FETCH_DEBRIEF_RESPONSE";
export const SG_FETCH_DEBRIEF_RESPONSE = "SG_FETCH_DEBRIEF_RESPONSE"; //saga use this
export const EDIT_DEBRIEF_RESPONSE = "EDIT_DEBRIEF_RESPONSE";
export const SG_EDIT_DEBRIEF_RESPONSE = "SG_EDIT_DEBRIEF_RESPONSE"; //saga use this
export const ADD_DEBRIEF_RESPONSE = "ADD_DEBRIEF_RESPONSE";
export const SG_ADD_DEBRIEF_RESPONSE = "SG_ADD_DEBRIEF_RESPONSE"; //saga use this
export const CLEAR_DEBRIEF_RESPONSE = "CLEAR_DEBRIEF_RESPONSE";
export const UPDATE_DEBRIEF_RESPONSE = "UPDATE_DEBRIEF_RESPONSE";
export const SG_UPDATE_DEBRIEF_RESPONSE = "SG_UPDATE_DEBRIEF_RESPONSE"; //saga use this
export const CLEAR_UPDATE_DEBRIEF_RESPONSE = "CLEAR_UPDATE_DEBRIEF_RESPONSE";

//
export const GET_DEBRIEF_TOKEN_PENDING = "GET_DEBRIEF_TOKEN_PENDING";
export const GENERATE_DEBRIEF_TOKEN = "GENERATE_DEBRIEF_TOKEN";
export const SG_GENERATE_DEBRIEF_TOKEN = "SG_GENERATE_DEBRIEF_TOKEN"; //saga use this
export const SEND_DEBRIEF_TOKEN_EMAIL = "SEND_DEBRIEF_TOKEN_EMAIL";
export const SG_SEND_DEBRIEF_TOKEN_EMAIL = "SG_SEND_DEBRIEF_TOKEN_EMAIL"; //saga use this
export const GET_DEBRIEF_TOKENS = "GET_DEBRIEF_TOKENS";
export const SG_GET_DEBRIEF_TOKENS = "SG_GET_DEBRIEF_TOKENS"; //saga use this
export const GET_FILTER_DEBRIEF_TOKENS = "GET_FILTER_DEBRIEF_TOKENS";
export const SG_GET_FILTER_DEBRIEF_TOKENS = "SG_FILTER_GET_DEBRIEF_TOKENS"; //saga use this
export const DEBRIEF_TOKEN_PAGE_NUMBER = "DEBRIEF_TOKEN_PAGE_NUMBER";
export const DELETE_DEBRIEF_TOKEN = "DELETE_DEBRIEF_TOKEN";
export const SG_DELETE_DEBRIEF_TOKEN = "SG_DELETE_DEBRIEF_TOKEN"; //saga use this
export const FETCH_DEBRIEF_TOKEN = "FETCH_DEBRIEF_TOKEN";
export const SG_FETCH_DEBRIEF_TOKEN = "SG_FETCH_DEBRIEF_TOKEN"; //saga use this
export const EDIT_DEBRIEF_TOKEN = "EDIT_DEBRIEF_TOKEN";
export const SG_EDIT_DEBRIEF_TOKEN = "SG_EDIT_DEBRIEF_TOKEN"; //saga use this
export const ADD_DEBRIEF_TOKEN = "ADD_DEBRIEF_TOKEN";
export const SG_ADD_DEBRIEF_TOKEN = "SG_ADD_DEBRIEF_TOKEN"; //saga use this
export const CLEAR_DEBRIEF_TOKEN = "CLEAR_DEBRIEF_TOKEN";
//
export const SIGNUP_TOKEN = "SIGNUP_TOKEN";
export const SG_SIGNUP_TOKEN = "SG_SIGNUP_TOKEN"; //saga use this
//
export const EMAIL_TEMPLATE_SELECT = "EMAIL_TEMPLATE_SELECT";
export const SG_EMAIL_TEMPLATE_SELECT = "SG_EMAIL_TEMPLATE_SELECT"; //saga use this
export const SG_COPY_EMAIL_TEMPLATE = "SG_COPY_EMAIL_TEMPLATE"
export const COPY_EMAIL_TEMPLATE = "COPY_EMAIL_TEMPLATE"
export const UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER = "UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER";
export const GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES";
export const SG_GET_EMAIL_TEMPLATES = "SG_GET_EMAIL_TEMPLATES"; //saga use this
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const SG_DELETE_EMAIL_TEMPLATE = "SG_DELETE_EMAIL_TEMPLATE"; //saga use this
export const FETCH_EMAIL_TEMPLATE = "FETCH_EMAIL_TEMPLATE";
export const SG_FETCH_EMAIL_TEMPLATE = "SG_FETCH_EMAIL_TEMPLATE"; //saga use this
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const SG_EDIT_EMAIL_TEMPLATE = "SG_EDIT_EMAIL_TEMPLATE"; //saga use this
export const ADD_EMAIL_TEMPLATE = "ADD_EMAIL_TEMPLATE";
export const SG_ADD_EMAIL_TEMPLATE = "SG_ADD_EMAIL_TEMPLATE"; //saga use this
export const CLEAR_EMAIL_TEMPLATE = "CLEAR_EMAIL_TEMPLATE";
//
export const PDF_TEMPLATE_SELECT = "PDF_TEMPLATE_SELECT";
export const SG_PDF_TEMPLATE_SELECT = "SG_PDF_TEMPLATE_SELECT"; //saga use this
export const UPDATE_PDF_TEMPLATE_PAGE_NUMBER = "UPDATE_PDF_TEMPLATE_PAGE_NUMBER";
export const GET_PDF_TEMPLATES = "GET_PDF_TEMPLATES";
export const SG_GET_PDF_TEMPLATES = "SG_GET_PDF_TEMPLATES"; //saga use this
export const DELETE_PDF_TEMPLATE = "DELETE_PDF_TEMPLATE";
export const SG_DELETE_PDF_TEMPLATE = "SG_DELETE_PDF_TEMPLATE"; //saga use this
export const FETCH_PDF_TEMPLATE = "FETCH_PDF_TEMPLATE";
export const SG_FETCH_PDF_TEMPLATE = "SG_FETCH_PDF_TEMPLATE"; //saga use this
export const EDIT_PDF_TEMPLATE = "EDIT_PDF_TEMPLATE";
export const SG_EDIT_PDF_TEMPLATE = "SG_EDIT_PDF_TEMPLATE"; //saga use this
export const ADD_PDF_TEMPLATE = "ADD_PDF_TEMPLATE";
export const SG_ADD_PDF_TEMPLATE = "SG_ADD_PDF_TEMPLATE"; //saga use this
export const CLEAR_PDF_TEMPLATE = "CLEAR_PDF_TEMPLATE";
//
export const PENDING_SURVEY_STRUCTURE = "PENDING_SURVEY_STRUCTURE";
export const SURVEY_STRUCTURE_SELECT = "SURVEY_STRUCTURE_SELECT";
export const SG_SURVEY_STRUCTURE_SELECT = "SG_SURVEY_STRUCTURE_SELECT"; //saga use this
export const UPDATE_SURVEY_STRUCTURE_PAGE_NUMBER = "UPDATE_SURVEY_STRUCTURE_PAGE_NUMBER";
export const COPY_SURVEY_STRUCTURE = "COPY_SURVEY_STRUCTURE";
export const SG_COPY_SURVEY_STRUCTURE = "SG_COPY_SURVEY_STRUCTURE"; //saga use this
export const GET_SURVEY_STRUCTURES = "GET_SURVEY_STRUCTURES";
export const SG_GET_SURVEY_STRUCTURES = "SG_GET_SURVEY_STRUCTURES"; //saga use this
export const DELETE_SURVEY_STRUCTURE = "DELETE_SURVEY_STRUCTURE";
export const SG_DELETE_SURVEY_STRUCTURE = "SG_DELETE_SURVEY_STRUCTURE"; //saga use this
export const FETCH_SURVEY_STRUCTURE = "FETCH_SURVEY_STRUCTURE";
export const SG_FETCH_SURVEY_STRUCTURE = "SG_FETCH_SURVEY_STRUCTURE"; //saga use this
export const EDIT_SURVEY_STRUCTURE = "EDIT_SURVEY_STRUCTURE";
export const SG_EDIT_SURVEY_STRUCTURE = "SG_EDIT_SURVEY_STRUCTURE"; //saga use this
export const ADD_SURVEY_STRUCTURE = "ADD_SURVEY_STRUCTURE";
export const SG_ADD_SURVEY_STRUCTURE = "SG_ADD_SURVEY_STRUCTURE"; //saga use this
export const CSV_FILE_SURVEY_STRUCTURE = "CSV_FILE_SURVEY_STRUCTURE";
export const SG_CSV_FILE_SURVEY_STRUCTURE = "SG_CSV_FILE_SURVEY_STRUCTURE"; //saga use this
export const SEARCH_SURVEY_STRUCTURE = "SEARCH_SURVEY_STRUCTURE";
export const CLEAR_SURVEY_STRUCTURE = "CLEAR_SURVEY_STRUCTURE";
//
export const SURVEY_STRUCTURE_COMPLIANCE_SELECT = "SURVEY_STRUCTURE_COMPLIANCE_SELECT";
export const SG_SURVEY_STRUCTURE_COMPLIANCE_SELECT = "SG_SURVEY_STRUCTURE_COMPLIANCE_SELECT"; //saga use this
export const UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER = "UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER";
export const GET_SURVEY_STRUCTURE_COMPLIANCES = "GET_SURVEY_STRUCTURE_COMPLIANCES";
export const SG_GET_SURVEY_STRUCTURE_COMPLIANCES = "SG_GET_SURVEY_STRUCTURE_COMPLIANCES"; //saga use this
export const DELETE_SURVEY_STRUCTURE_COMPLIANCE = "DELETE_SURVEY_STRUCTURE_COMPLIANCE";
export const SG_DELETE_SURVEY_STRUCTURE_COMPLIANCE = "SG_DELETE_SURVEY_STRUCTURE_COMPLIANCE"; //saga use this
export const FETCH_SURVEY_STRUCTURE_COMPLIANCE = "FETCH_SURVEY_STRUCTURE_COMPLIANCE";
export const SG_FETCH_SURVEY_STRUCTURE_COMPLIANCE = "SG_FETCH_SURVEY_STRUCTURE_COMPLIANCE"; //saga use this
export const EDIT_SURVEY_STRUCTURE_COMPLIANCE = "EDIT_SURVEY_STRUCTURE_COMPLIANCE";
export const SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE = "SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE"; //saga use this
export const ADD_SURVEY_STRUCTURE_COMPLIANCE = "ADD_SURVEY_STRUCTURE_COMPLIANCE";
export const SG_ADD_SURVEY_STRUCTURE_COMPLIANCE = "SG_ADD_SURVEY_STRUCTURE_COMPLIANCE"; //saga use this
export const CLEAR_SURVEY_STRUCTURE_COMPLIANCE = "CLEAR_SURVEY_STRUCTURE_COMPLIANCE";
//
export const GET_FILE_UPLOADS = "GET_FILE_UPLOADS";
export const SG_GET_FILE_UPLOADS = "SG_GET_FILE_UPLOADS"; //saga use this
export const UPDATE_FILE_UPLOAD_PAGE_NUMBER = "UPDATE_FILE_UPLOAD_PAGE_NUMBER";
export const DELETE_FILE_UPLOAD = "DELETE_FILE_UPLOAD";
export const SG_DELETE_FILE_UPLOAD = "SG_DELETE_FILE_UPLOAD"; //saga use this
export const FETCH_FILE_UPLOAD = "FETCH_FILE_UPLOAD";
export const SG_FETCH_FILE_UPLOAD = "SG_FETCH_FILE_UPLOAD"; //saga use this
export const EDIT_FILE_UPLOAD = "EDIT_FILE_UPLOAD";
export const SG_EDIT_FILE_UPLOAD = "SG_EDIT_FILE_UPLOAD"; //saga use this
export const ADD_FILE_UPLOAD = "ADD_FILE_UPLOAD";
export const SG_ADD_FILE_UPLOAD = "SG_ADD_FILE_UPLOAD"; //saga use this
export const CLEAR_FILE_UPLOAD = "CLEAR_FILE_UPLOAD";
export const CLEAR_CREATED_FILE_UPLOAD = "CLEAR_CREATED_FILE_UPLOAD";
//
//
export const EMPLOYEE_CATEGORY_PENDING = "EMPLOYEE_CATEGORY_PENDING";
export const GET_EMPLOYEE_CATEGORIES = "GET_EMPLOYEE_CATEGORIES";
export const SG_GET_EMPLOYEE_CATEGORIES = "SG_GET_EMPLOYEE_CATEGORIES"; //saga use this
export const DELETE_EMPLOYEE_CATEGORY = "DELETE_EMPLOYEE_CATEGORY";
export const SG_DELETE_EMPLOYEE_CATEGORY = "SG_DELETE_EMPLOYEE_CATEGORY"; //saga use this
export const FETCH_EMPLOYEE_CATEGORY = "FETCH_EMPLOYEE_CATEGORY";
export const SG_FETCH_EMPLOYEE_CATEGORY = "SG_FETCH_EMPLOYEE_CATEGORY"; //saga use this
export const EDIT_EMPLOYEE_CATEGORY = "EDIT_EMPLOYEE_CATEGORY";
export const SG_EDIT_EMPLOYEE_CATEGORY = "SG_EDIT_EMPLOYEE_CATEGORY"; //saga use this
export const ADD_EMPLOYEE_CATEGORY = "ADD_EMPLOYEE_CATEGORY";
export const SG_ADD_EMPLOYEE_CATEGORY = "SG_ADD_EMPLOYEE_CATEGORY"; //saga use this
export const CLEAR_EMPLOYEE_CATEGORY = "CLEAR_EMPLOYEE_CATEGORY";
//

export const EMPLOYEE_PERMISSION_PENDING = "EMPLOYEE_PERMISSION_PENDING";
export const GET_EMPLOYEE_PERMISSIONS = "GET_EMPLOYEE_PERMISSIONS";
export const SG_GET_EMPLOYEE_PERMISSIONS = "SG_GET_EMPLOYEE_PERMISSIONS"; //saga use this
export const DELETE_EMPLOYEE_PERMISSION = "DELETE_EMPLOYEE_PERMISSION";
export const SG_DELETE_EMPLOYEE_PERMISSION = "SG_DELETE_EMPLOYEE_PERMISSION"; //saga use this
export const FETCH_EMPLOYEE_PERMISSION = "FETCH_EMPLOYEE_PERMISSION";
export const SG_FETCH_EMPLOYEE_PERMISSION = "SG_FETCH_EMPLOYEE_PERMISSION"; //saga use this
export const EDIT_EMPLOYEE_PERMISSION = "EDIT_EMPLOYEE_PERMISSION";
export const SG_EDIT_EMPLOYEE_PERMISSION = "SG_EDIT_EMPLOYEE_PERMISSION"; //saga use this
export const ADD_EMPLOYEE_PERMISSION = "ADD_EMPLOYEE_PERMISSION";
export const SG_ADD_EMPLOYEE_PERMISSION = "SG_ADD_EMPLOYEE_PERMISSION"; //saga use this
export const CLEAR_EMPLOYEE_PERMISSION = "CLEAR_EMPLOYEE_PERMISSION";
//
export const GET_WHITE_LABEL = "GET_WHITE_LABEL";
export const SET_WHITE_LABEL = "SET_WHITE_LABEL";
//
//
export const GET_FILTERED_DATA = "GET_FILTERED_DATA";
export const SET_FILTERED_DATA = "SET_FILTERED_DATA";
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";
export const SET_DATA_DATES = "SET_DATA_DATES";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const SET_RAW_DATA = "SET_RAW_DATA";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_SUB_NAV = "SET_SUB_NAV";
export const SET_NAVIGATION = "SET_NAVIGATION";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_DATA_ACNHOR = "SET_DATA_ACNHOR";
//
//
export const UPDATE_APP_LIST_URL_PAGE_NUMBER = "UPDATE_APP_LIST_URL_PAGE_NUMBER"
export const APP_LIST_URL_PENDING = "APP_LIST_URL_PENDING";
export const GET_APP_LIST_URLS = "GET_APP_LIST_URLS";
export const SG_GET_APP_LIST_URLS = "SG_GET_APP_LIST_URLS"; //saga use this
export const DELETE_APP_LIST_URL = "DELETE_APP_LIST_URL";
export const SG_DELETE_APP_LIST_URL = "SG_DELETE_APP_LIST_URL"; //saga use this
export const FETCH_APP_LIST_URL = "FETCH_APP_LIST_URL";
export const SG_FETCH_APP_LIST_URL = "SG_FETCH_APP_LIST_URL"; //saga use this
export const EDIT_APP_LIST_URL = "EDIT_APP_LIST_URL";
export const SG_EDIT_APP_LIST_URL = "SG_EDIT_APP_LIST_URL"; //saga use this
export const ADD_APP_LIST_URL = "ADD_APP_LIST_URL";
export const SG_ADD_APP_LIST_URL = "SG_ADD_APP_LIST_URL"; //saga use this
export const CLEAR_APP_LIST_URL = "CLEAR_APP_LIST_URL";
//
//
export const UPDATE_APP_SERVER_PAGE_NUMBER = "UPDATE_APP_SERVER_PAGE_NUMBER"
export const RESET_APP_SERVER = "RESET_APP_SERVER";
export const APP_SERVER_PENDING = "APP_SERVER_PENDING";
export const GET_APP_SERVERS = "GET_APP_SERVERS";
export const SG_GET_APP_SERVERS = "SG_GET_APP_SERVERS"; //saga use this
export const DELETE_APP_SERVER = "DELETE_APP_SERVER";
export const SG_DELETE_APP_SERVER = "SG_DELETE_APP_SERVER"; //saga use this
export const FETCH_APP_SERVER = "FETCH_APP_SERVER";
export const SG_FETCH_APP_SERVER = "SG_FETCH_APP_SERVER"; //saga use this
export const EDIT_APP_SERVER = "EDIT_APP_SERVER";
export const SG_EDIT_APP_SERVER = "SG_EDIT_APP_SERVER"; //saga use this
export const ADD_APP_SERVER = "ADD_APP_SERVER";
export const SG_ADD_APP_SERVER = "SG_ADD_APP_SERVER"; //saga use this
export const CLEAR_APP_SERVER_UPDATE = "CLEAR_APP_SERVER_UPDATE";
export const CLEAR_APP_SERVER = "CLEAR_APP_SERVER";
//
export const GET_APP_SERVERS_MAKE_COMMANDS = "GET_APP_SERVERS_MAKE_COMMANDS";
export const SG_GET_APP_SERVERS_MAKE_COMMANDS = "SG_GET_APP_SERVERS_MAKE_COMMANDS"; //saga use this
export const POST_APP_SERVERS_MAKE_BUILD = "POST_APP_SERVERS_MAKE_BUILD";
export const SG_POST_APP_SERVERS_MAKE_BUILD = "SG_POST_APP_SERVERS_MAKE_BUILD"; //saga use this
//
//
export const UPDATE_APP_SERVER_LOG_PAGE_NUMBER = "UPDATE_APP_SERVER_LOG_PAGE_NUMBER"
export const APP_SERVER_LOG_PENDING = "APP_SERVER_LOG_PENDING";
export const GET_APP_SERVER_LOGS = "GET_APP_SERVER_LOGS";
export const SG_GET_APP_SERVER_LOGS = "SG_GET_APP_SERVER_LOGS"; //saga use this
export const DELETE_APP_SERVER_LOG = "DELETE_APP_SERVER_LOG";
export const SG_DELETE_APP_SERVER_LOG = "SG_DELETE_APP_SERVER_LOG"; //saga use this
export const FETCH_APP_SERVER_LOG = "FETCH_APP_SERVER_LOG";
export const SG_FETCH_APP_SERVER_LOG = "SG_FETCH_APP_SERVER_LOG"; //saga use this
export const EDIT_APP_SERVER_LOG = "EDIT_APP_SERVER_LOG";
export const SG_EDIT_APP_SERVER_LOG = "SG_EDIT_APP_SERVER_LOG"; //saga use this
export const ADD_APP_SERVER_LOG = "ADD_APP_SERVER_LOG";
export const SG_ADD_APP_SERVER_LOG = "SG_ADD_APP_SERVER_LOG"; //saga use this
export const CLEAR_APP_SERVER_LOG_UPDATE = "CLEAR_APP_SERVER_LOG_UPDATE";
export const CLEAR_APP_SERVER_LOG = "CLEAR_APP_SERVER_LOG";
//
//
export const UPDATE_WEB_API_CREDENTIAL_PAGE_NUMBER = "UPDATE_WEB_API_CREDENTIAL_PAGE_NUMBER"
export const WEB_API_CREDENTIAL_PENDING = "WEB_API_CREDENTIAL_PENDING";
export const GET_WEB_API_CREDENTIALS = "GET_WEB_API_CREDENTIALS";
export const SG_GET_WEB_API_CREDENTIALS = "SG_GET_WEB_API_CREDENTIALS"; //saga use this
export const GET_WEB_API_CRED_LIST = "GET_WEB_API_CRED_LIST";
export const SG_GET_WEB_API_CRED_LIST = "SG_GET_WEB_API_CRED_LIST"; //saga use this
export const DELETE_WEB_API_CREDENTIAL = "DELETE_WEB_API_CREDENTIAL";
export const SG_DELETE_WEB_API_CREDENTIAL = "SG_DELETE_WEB_API_CREDENTIAL"; //saga use this
export const FETCH_WEB_API_CREDENTIAL = "FETCH_WEB_API_CREDENTIAL";
export const SG_FETCH_WEB_API_CREDENTIAL = "SG_FETCH_WEB_API_CREDENTIAL"; //saga use this
export const EDIT_WEB_API_CREDENTIAL = "EDIT_WEB_API_CREDENTIAL";
export const SG_EDIT_WEB_API_CREDENTIAL = "SG_EDIT_WEB_API_CREDENTIAL"; //saga use this
export const ADD_WEB_API_CREDENTIAL = "ADD_WEB_API_CREDENTIAL";
export const SG_ADD_WEB_API_CREDENTIAL = "SG_ADD_WEB_API_CREDENTIAL"; //saga use this
export const CLEAR_WEB_API_CREDENTIAL = "CLEAR_WEB_API_CREDENTIAL";
//
//
export const UPDATE_WEB_API_LOG_PAGE_NUMBER = "UPDATE_WEB_API_LOG_PAGE_NUMBER"
export const CLEAR_WEB_API_LOG_DATA = "CLEAR_WEB_API_LOG_DATA";
export const WEB_API_LOG_PENDING = "WEB_API_LOG_PENDING";
export const GET_WEB_API_LOGS = "GET_WEB_API_LOGS";
export const SG_GET_WEB_API_LOGS = "SG_GET_WEB_API_LOGS"; //saga use this
export const DELETE_WEB_API_LOG = "DELETE_WEB_API_LOG";
export const SG_DELETE_WEB_API_LOG = "SG_DELETE_WEB_API_LOG"; //saga use this
export const FETCH_WEB_API_LOG = "FETCH_WEB_API_LOG";
export const SG_FETCH_WEB_API_LOG = "SG_FETCH_WEB_API_LOG"; //saga use this
export const EDIT_WEB_API_LOG = "EDIT_WEB_API_LOG";
export const SG_EDIT_WEB_API_LOG = "SG_EDIT_WEB_API_LOG"; //saga use this
export const ADD_WEB_API_LOG = "ADD_WEB_API_LOG";
export const SG_ADD_WEB_API_LOG = "SG_ADD_WEB_API_LOG"; //saga use this
export const CLEAR_WEB_API_LOG = "CLEAR_WEB_API_LOG";
//
// EMPLOYEE_LOG Activities
export const GET_EMPLOYEE_LOG = "GET_EMPLOYEE_LOG";
export const SG_GET_EMPLOYEE_LOG = "SG_GET_EMPLOYEE_LOG";
export const ADD_EMPLOYEE_LOG = "ADD_EMPLOYEE_LOG";
export const SG_ADD_EMPLOYEE_LOG = "SG_ADD_EMPLOYEE_LOG";
export const DELETE_EMPLOYEE_LOG = "DELETE_EMPLOYEE_LOG";
export const SG_DELETE_EMPLOYEE_LOG = "SG_DELETE_EMPLOYEE_LOG";
export const UPDATE_EMPLOYEE_LOG = "UPDATE_EMPLOYEE_LOG";
export const SG_UPDATE_EMPLOYEE_LOG = "SG_UPDATE_EMPLOYEE_LOG";
export const FETCH_EMPLOYEE_LOG = "FETCH_EMPLOYEE_LOG";
export const SG_FETCH_EMPLOYEE_LOG = "SG_FETCH_EMPLOYEE_LOG";
export const CLEAR_EMPLOYEE_LOG = "CLEAR_EMPLOYEE_LOG";
export const UPDATE_EMPLOYEE_LOG_PAGE_NUMBER = "UPDATE_EMPLOYEE_LOG_PAGE_NUMBER";
// EMPLOYEE_RECORD Activities
export const GET_EMPLOYEE_RECORD = "GET_EMPLOYEE_RECORD";
export const SG_GET_EMPLOYEE_RECORD = "SG_GET_EMPLOYEE_RECORD";
export const ADD_EMPLOYEE_RECORD = "ADD_EMPLOYEE_RECORD";
export const SG_ADD_EMPLOYEE_RECORD = "SG_ADD_EMPLOYEE_RECORD";
export const DELETE_EMPLOYEE_RECORD = "DELETE_EMPLOYEE_RECORD";
export const SG_DELETE_EMPLOYEE_RECORD = "SG_DELETE_EMPLOYEE_RECORD";
export const UPDATE_EMPLOYEE_RECORD = "UPDATE_EMPLOYEE_RECORD";
export const SG_UPDATE_EMPLOYEE_RECORD = "SG_UPDATE_EMPLOYEE_RECORD";
export const FETCH_EMPLOYEE_RECORD = "FETCH_EMPLOYEE_RECORD";
export const SG_FETCH_EMPLOYEE_RECORD = "SG_FETCH_EMPLOYEE_RECORD";
export const CLEAR_EMPLOYEE_RECORD = "CLEAR_EMPLOYEE_RECORD";
export const UPDATE_EMPLOYEE_RECORD_PAGE_NUMBER = "UPDATE_EMPLOYEE_RECORD_PAGE_NUMBER";
// EMPLOYEE_RECORD_LIST Activities
export const GET_EMPLOYEE_RECORD_LIST = "GET_EMPLOYEE_RECORD_LIST";
export const SG_GET_EMPLOYEE_RECORD_LIST = "SG_GET_EMPLOYEE_RECORD_LIST";
export const ADD_EMPLOYEE_RECORD_LIST = "ADD_EMPLOYEE_RECORD_LIST";
export const SG_ADD_EMPLOYEE_RECORD_LIST = "SG_ADD_EMPLOYEE_RECORD_LIST";
export const DELETE_EMPLOYEE_RECORD_LIST = "DELETE_EMPLOYEE_RECORD_LIST";
export const SG_DELETE_EMPLOYEE_RECORD_LIST = "SG_DELETE_EMPLOYEE_RECORD_LIST";
export const UPDATE_EMPLOYEE_RECORD_LIST = "UPDATE_EMPLOYEE_RECORD_LIST";
export const SG_UPDATE_EMPLOYEE_RECORD_LIST = "SG_UPDATE_EMPLOYEE_RECORD_LIST";
export const FETCH_EMPLOYEE_RECORD_LIST = "FETCH_EMPLOYEE_RECORD_LIST";
export const SG_FETCH_EMPLOYEE_RECORD_LIST = "SG_FETCH_EMPLOYEE_RECORD_LIST";
export const CLEAR_EMPLOYEE_RECORD_LIST = "CLEAR_EMPLOYEE_RECORD_LIST";
export const UPDATE_EMPLOYEE_RECORD_LIST_PAGE_NUMBER = "UPDATE_EMPLOYEE_RECORD_LIST_PAGE_NUMBER";
// EMPLOYEE_RECORD_VALUE Activities
export const GET_EMPLOYEE_RECORD_VALUE = "GET_EMPLOYEE_RECORD_VALUE";
export const SG_GET_EMPLOYEE_RECORD_VALUE = "SG_GET_EMPLOYEE_RECORD_VALUE";
export const ADD_EMPLOYEE_RECORD_VALUE = "ADD_EMPLOYEE_RECORD_VALUE";
export const SG_ADD_EMPLOYEE_RECORD_VALUE = "SG_ADD_EMPLOYEE_RECORD_VALUE";
export const DELETE_EMPLOYEE_RECORD_VALUE = "DELETE_EMPLOYEE_RECORD_VALUE";
export const SG_DELETE_EMPLOYEE_RECORD_VALUE = "SG_DELETE_EMPLOYEE_RECORD_VALUE";
export const UPDATE_EMPLOYEE_RECORD_VALUE = "UPDATE_EMPLOYEE_RECORD_VALUE";
export const SG_UPDATE_EMPLOYEE_RECORD_VALUE = "SG_UPDATE_EMPLOYEE_RECORD_VALUE";
export const FETCH_EMPLOYEE_RECORD_VALUE = "FETCH_EMPLOYEE_RECORD_VALUE";
export const SG_FETCH_EMPLOYEE_RECORD_VALUE = "SG_FETCH_EMPLOYEE_RECORD_VALUE";
export const CLEAR_EMPLOYEE_RECORD_VALUE = "CLEAR_EMPLOYEE_RECORD_VALUE";
export const UPDATE_EMPLOYEE_RECORD_VALUE_PAGE_NUMBER = "UPDATE_EMPLOYEE_RECORD_VALUE_PAGE_NUMBER";

// 
// SURVEY STANDARD LOG Activities
export const GET_SURVEY_STANDARD_LOG = "GET_SURVEY_STANDARD_LOG";
export const SG_GET_SURVEY_STANDARD_LOG = "SG_GET_SURVEY_STANDARD_LOG";
export const ADD_SURVEY_STANDARD_LOG = "ADD_SURVEY_STANDARD_LOG";
export const SG_ADD_SURVEY_STANDARD_LOG = "SG_ADD_SURVEY_STANDARD_LOG";
export const DELETE_SURVEY_STANDARD_LOG = "DELETE_SURVEY_STANDARD_LOG";
export const SG_DELETE_SURVEY_STANDARD_LOG = "SG_DELETE_SURVEY_STANDARD_LOG";
export const UPDATE_SURVEY_STANDARD_LOG = "UPDATE_SURVEY_STANDARD_LOG";
export const SG_UPDATE_SURVEY_STANDARD_LOG = "SG_UPDATE_SURVEY_STANDARD_LOG";
export const FETCH_SURVEY_STANDARD_LOG = "FETCH_SURVEY_STANDARD_LOG";
export const SG_FETCH_SURVEY_STANDARD_LOG = "SG_FETCH_SURVEY_STANDARD_LOG";
export const CLEAR_SURVEY_STANDARD_LOG = "CLEAR_SURVEY_STANDARD_LOG";
// 
// 
export const GOOGLE_AUTH_PENDING = "GOOGLE_AUTH_PENDING";
export const LOGIN_GOOGLE_AUTH = "LOGIN_GOOGLE_AUTH";
export const SG_LOGIN_GOOGLE_AUTH = "SG_LOGIN_GOOGLE_AUTH";
export const REGISTER_GOOGLE_AUTH = "REGISTER_GOOGLE_AUTH";
export const SG_REGISTER_GOOGLE_AUTH = "SG_REGISTER_GOOGLE_AUTH";
export const REGISTER_GOOGLE_AUTH_TOKEN = "REGISTER_GOOGLE_AUTH_TOKEN";
export const SG_REGISTER_GOOGLE_AUTH_TOKEN = "SG_REGISTER_GOOGLE_AUTH_TOKEN";
export const CLEAR_GOOGLE_AUTH = "CLEAR_GOOGLE_AUTH";
// 
export const UPDATE_ACCOUNT_DEFAULT_PENDING = "UPDATE_ACCOUNT_DEFAULT_PENDING";
export const LOGIN_UPDATE_ACCOUNT_DEFAULT = "LOGIN_UPDATE_ACCOUNT_DEFAULT";
export const SG_LOGIN_UPDATE_ACCOUNT_DEFAULT = "SG_LOGIN_UPDATE_ACCOUNT_DEFAULT";
export const CLEAR_UPDATE_ACCOUNT_DEFAULT = "CLEAR_UPDATE_ACCOUNT_DEFAULT";
// 
export const LOGIN_REDIRECT = "LOGIN_REDIRECT"
//
export const UPDATE_ADMIN_DEFAULT_PAGE_NUMBER = "UPDATE_ADMIN_DEFAULT_PAGE_NUMBER";
export const GET_ADMIN_DEFAULTS = "GET_ADMIN_DEFAULTS";
export const SG_GET_ADMIN_DEFAULTS = "SG_GET_ADMIN_DEFAULTS"; //saga use this
export const DELETE_ADMIN_DEFAULT = "DELETE_ADMIN_DEFAULT";
export const SG_DELETE_ADMIN_DEFAULT = "SG_DELETE_ADMIN_DEFAULT"; //saga use this
export const FETCH_ADMIN_DEFAULT = "FETCH_ADMIN_DEFAULT";
export const SG_FETCH_ADMIN_DEFAULT = "SG_FETCH_ADMIN_DEFAULT"; //saga use this
export const EDIT_ADMIN_DEFAULT = "EDIT_ADMIN_DEFAULT";
export const SG_EDIT_ADMIN_DEFAULT = "SG_EDIT_ADMIN_DEFAULT"; //saga use this
export const ADD_ADMIN_DEFAULT = "ADD_ADMIN_DEFAULT";
export const SG_ADD_ADMIN_DEFAULT = "SG_ADD_ADMIN_DEFAULT"; //saga use this
export const CLEAR_ADMIN_DEFAULT = "CLEAR_ADMIN_DEFAULT";
//
// CHAT GPT 
export const CHAT_GPT_PENDING = "CHAT_GPT_PENDING";
export const POST_CHAT_GPT = "POST_CHAT_GPT";
export const SG_POST_CHAT_GPT = "SG_POST_CHAT_GPT";
export const GET_CHAT_GPT = "GET_CHAT_GPT";
export const SG_GET_CHAT_GPT = "SG_GET_CHAT_GPT";
export const CLEAR_CHAT_GPT = "CLEAR_CHAT_GPT";
//
//
// CHAT GPT Summary
export const UPDATE_GPT_SUMMARY_PAGE_NUMBER = "UPDATE_GPT_SUMMARY_PAGE_NUMBER";
export const GET_GPT_SUMMARY_PENDING = "GET_GPT_SUMMARY_PENDING";
export const GET_GPT_SUMMARY = "GET_GPT_SUMMARY";
export const SG_GET_GPT_SUMMARY = "SG_GET_GPT_SUMMARY"; //saga use this
export const DELETE_GPT_SUMMARY = "DELETE_GPT_SUMMARY";
export const SG_DELETE_GPT_SUMMARY = "SG_DELETE_GPT_SUMMARY"; //saga use this
export const FETCH_GPT_SUMMARY = "FETCH_GPT_SUMMARY";
export const SG_FETCH_GPT_SUMMARY = "SG_FETCH_GPT_SUMMARY"; //saga use this
export const EDIT_GPT_SUMMARY = "EDIT_GPT_SUMMARY";
export const SG_EDIT_GPT_SUMMARY = "SG_EDIT_GPT_SUMMARY"; //saga use this
export const ADD_GPT_SUMMARY = "ADD_GPT_SUMMARY";
export const SG_ADD_GPT_SUMMARY = "SG_ADD_GPT_SUMMARY"; //saga use this
export const CLEAR_GPT_SUMMARY = "CLEAR_GPT_SUMMARY";
//
//
export const UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER = "UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER";
export const GET_GPT_MODEL_MAIN_PENDING = "GET_GPT_MODEL_MAIN_PENDING";
export const GET_GPT_MODEL_MAINS = "GET_GPT_MODEL_MAINS";
export const SG_GET_GPT_MODEL_MAINS = "SG_GET_GPT_MODEL_MAINS"; //saga use this
export const GET_GPT_MODEL_MAIN_SELECT = "GET_GPT_MODEL_MAIN_SELECT";
export const SG_GET_GPT_MODEL_MAIN_SELECT = "SG_GET_GPT_MODEL_MAIN_SELECT"; //saga use this
export const DELETE_GPT_MODEL_MAIN = "DELETE_GPT_MODEL_MAIN";
export const SG_DELETE_GPT_MODEL_MAIN = "SG_DELETE_GPT_MODEL_MAIN"; //saga use this
export const FETCH_GPT_MODEL_MAIN = "FETCH_GPT_MODEL_MAIN";
export const SG_FETCH_GPT_MODEL_MAIN = "SG_FETCH_GPT_MODEL_MAIN"; //saga use this
export const EDIT_GPT_MODEL_MAIN = "EDIT_GPT_MODEL_MAIN";
export const SG_EDIT_GPT_MODEL_MAIN = "SG_EDIT_GPT_MODEL_MAIN"; //saga use this
export const ADD_GPT_MODEL_MAIN = "ADD_GPT_MODEL_MAIN";
export const SG_ADD_GPT_MODEL_MAIN = "SG_ADD_GPT_MODEL_MAIN"; //saga use this
export const CLEAR_GPT_MODEL_MAIN = "CLEAR_GPT_MODEL_MAIN";
//
//
export const UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER = "UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER";
export const GET_GPT_RESPONSE_MAIN_PENDING = "GET_GPT_RESPONSE_MAIN_PENDING";
export const GET_GPT_RESPONSE_MAINS = "GET_GPT_RESPONSE_MAINS";
export const SG_GET_GPT_RESPONSE_MAINS = "SG_GET_GPT_RESPONSE_MAINS"; //saga use this
export const GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE = "GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE";
export const SG_GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE = "SG_GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE"; //saga use this
export const SG_BRIEF_AI_SUMMARY = "SG_BRIEF_AI_SUMMARY"
export const BRIEF_AI_SUMMARY = "BRIEF_AI_SUMMARY"
// 
export const DELETE_GPT_RESPONSE_MAIN = "DELETE_GPT_RESPONSE_MAIN";
export const SG_DELETE_GPT_RESPONSE_MAIN = "SG_DELETE_GPT_RESPONSE_MAIN"; //saga use this
export const FETCH_GPT_RESPONSE_MAIN = "FETCH_GPT_RESPONSE_MAIN";
export const SG_FETCH_GPT_RESPONSE_MAIN = "SG_FETCH_GPT_RESPONSE_MAIN"; //saga use this
export const EDIT_GPT_RESPONSE_MAIN = "EDIT_GPT_RESPONSE_MAIN";
export const SG_EDIT_GPT_RESPONSE_MAIN = "SG_EDIT_GPT_RESPONSE_MAIN"; //saga use this
export const ADD_GPT_RESPONSE_MAIN = "ADD_GPT_RESPONSE_MAIN";
export const SG_ADD_GPT_RESPONSE_MAIN = "SG_ADD_GPT_RESPONSE_MAIN"; //saga use this
export const CLEAR_GPT_RESPONSE_MAIN = "CLEAR_GPT_RESPONSE_MAIN";
//
//
export const UPDATE_GPT_RESPONSE_SCORE_PAGE_NUMBER = "UPDATE_GPT_RESPONSE_SCORE_PAGE_NUMBER";
export const GET_GPT_RESPONSE_SCORE_PENDING = "GET_GPT_RESPONSE_SCORE_PENDING";
export const GET_GPT_RESPONSE_SCORES = "GET_GPT_RESPONSE_SCORES";
export const SG_GET_GPT_RESPONSE_SCORES = "SG_GET_GPT_RESPONSE_SCORES"; //saga use this
export const DELETE_GPT_RESPONSE_SCORE = "DELETE_GPT_RESPONSE_SCORE";
export const SG_DELETE_GPT_RESPONSE_SCORE = "SG_DELETE_GPT_RESPONSE_SCORE"; //saga use this
export const FETCH_GPT_RESPONSE_SCORE = "FETCH_GPT_RESPONSE_SCORE";
export const SG_FETCH_GPT_RESPONSE_SCORE = "SG_FETCH_GPT_RESPONSE_SCORE"; //saga use this
export const EDIT_GPT_RESPONSE_SCORE = "EDIT_GPT_RESPONSE_SCORE";
export const SG_EDIT_GPT_RESPONSE_SCORE = "SG_EDIT_GPT_RESPONSE_SCORE"; //saga use this
export const ADD_GPT_RESPONSE_SCORE = "ADD_GPT_RESPONSE_SCORE";
export const SG_ADD_GPT_RESPONSE_SCORE = "SG_ADD_GPT_RESPONSE_SCORE"; //saga use this
export const CLEAR_GPT_RESPONSE_SCORE = "CLEAR_GPT_RESPONSE_SCORE";
//
//
export const UPDATE_GPT_MEMBER_PAGE_NUMBER = "UPDATE_GPT_MEMBER_PAGE_NUMBER";
export const GET_GPT_MEMBER_PENDING = "GET_GPT_MEMBER_PENDING";
export const GET_GPT_MEMBERS = "GET_GPT_MEMBERS";
export const SG_GET_GPT_MEMBERS = "SG_GET_GPT_MEMBERS"; //saga use this
export const GET_GPT_MEMBER_SELECT = "GET_GPT_MEMBER_SELECT";
export const SG_GET_GPT_MEMBER_SELECT = "SG_GET_GPT_MEMBER_SELECT"; //saga use this
export const DELETE_GPT_MEMBER = "DELETE_GPT_MEMBER";
export const SG_DELETE_GPT_MEMBER = "SG_DELETE_GPT_MEMBER"; //saga use this
export const FETCH_GPT_MEMBER = "FETCH_GPT_MEMBER";
export const SG_FETCH_GPT_MEMBER = "SG_FETCH_GPT_MEMBER"; //saga use this
export const EDIT_GPT_MEMBER = "EDIT_GPT_MEMBER";
export const SG_EDIT_GPT_MEMBER = "SG_EDIT_GPT_MEMBER"; //saga use this
export const ADD_GPT_MEMBER = "ADD_GPT_MEMBER";
export const SG_ADD_GPT_MEMBER = "SG_ADD_GPT_MEMBER"; //saga use this
export const CLEAR_GPT_MEMBER = "CLEAR_GPT_MEMBER";
//
//
export const GET_EMPLOYE_CATEGORY_LIST = "GET_EMPLOYE_CATEGORY_LIST";
export const SG_GET_EMPLOYE_CATEGORY_LIST = "SG_GET_EMPLOYE_CATEGORY_LIST"; //saga use this
//
// export const GET_GOOGLE_DEMO_SHEET = "GET_GOOGLE_DEMO_SHEET";
// export const SG_GET_GOOGLE_DEMO_SHEET = "SG_GET_GOOGLE_DEMO_SHEET"; //saga use this
export const POST_GOOGLE_DEMO_SHEET = "POST_GOOGLE_DEMO_SHEET";
export const SG_POST_GOOGLE_DEMO_SHEET = "SG_POST_GOOGLE_DEMO_SHEET"; //saga use this
//
//
export const UPDATE_GPT_WEB_CHAT_PAGE_NUMBER = "UPDATE_GPT_WEB_CHAT_PAGE_NUMBER";
export const GET_GPT_WEB_CHAT_PENDING = "GET_GPT_WEB_CHAT_PENDING";
export const GET_GPT_WEB_CHATS = "GET_GPT_WEB_CHATS";
export const SG_GET_GPT_WEB_CHATS = "SG_GET_GPT_WEB_CHATS"; //saga use this
export const DELETE_GPT_WEB_CHAT = "DELETE_GPT_WEB_CHAT";
export const SG_DELETE_GPT_WEB_CHAT = "SG_DELETE_GPT_WEB_CHAT"; //saga use this
export const FETCH_GPT_WEB_CHAT = "FETCH_GPT_WEB_CHAT";
export const SG_FETCH_GPT_WEB_CHAT = "SG_FETCH_GPT_WEB_CHAT"; //saga use this
export const EDIT_GPT_WEB_CHAT = "EDIT_GPT_WEB_CHAT";
export const SG_EDIT_GPT_WEB_CHAT = "SG_EDIT_GPT_WEB_CHAT"; //saga use this
export const ADD_GPT_WEB_CHAT = "ADD_GPT_WEB_CHAT";
export const SG_ADD_GPT_WEB_CHAT = "SG_ADD_GPT_WEB_CHAT"; //saga use this
export const CLEAR_GPT_WEB_CHAT = "CLEAR_GPT_WEB_CHAT";
//
//
export const UPDATE_MARKETING_ROI_PAGE_NUMBER = "UPDATE_MARKETING_ROI_PAGE_NUMBER";
export const GET_MARKETING_ROI_PENDING = "GET_MARKETING_ROI_PENDING";
export const GET_MARKETING_ROIS = "GET_MARKETING_ROIS";
export const SG_GET_MARKETING_ROIS = "SG_GET_MARKETING_ROIS"; //saga use this
export const DELETE_MARKETING_ROI = "DELETE_MARKETING_ROI";
export const SG_DELETE_MARKETING_ROI = "SG_DELETE_MARKETING_ROI"; //saga use this
export const FETCH_MARKETING_ROI = "FETCH_MARKETING_ROI";
export const SG_FETCH_MARKETING_ROI = "SG_FETCH_MARKETING_ROI"; //saga use this
export const EDIT_MARKETING_ROI = "EDIT_MARKETING_ROI";
export const SG_EDIT_MARKETING_ROI = "SG_EDIT_MARKETING_ROI"; //saga use this
export const ADD_MARKETING_ROI = "ADD_MARKETING_ROI";
export const SG_ADD_MARKETING_ROI = "SG_ADD_MARKETING_ROI"; //saga use this
export const CLEAR_MARKETING_ROI = "CLEAR_MARKETING_ROI";
//
//
export const UPDATE_ACTIVITY_LOG_PAGE_NUMBER = "UPDATE_ACTIVITY_LOG_PAGE_NUMBER";
export const GET_ACTIVITY_LOG_PENDING = "GET_ACTIVITY_LOG_PENDING";
export const GET_ACTIVITY_LOGS = "GET_ACTIVITY_LOGS";
export const SG_GET_ACTIVITY_LOGS = "SG_GET_ACTIVITY_LOGS"; //saga use this
export const DELETE_ACTIVITY_LOG = "DELETE_ACTIVITY_LOG";
export const SG_DELETE_ACTIVITY_LOG = "SG_DELETE_ACTIVITY_LOG"; //saga use this
export const FETCH_ACTIVITY_LOG = "FETCH_ACTIVITY_LOG";
export const SG_FETCH_ACTIVITY_LOG = "SG_FETCH_ACTIVITY_LOG"; //saga use this
export const FETCH_ACTIVITY_LOG_STATUS = "FETCH_ACTIVITY_LOG_STATUS";
export const SG_FETCH_ACTIVITY_LOG_STATUS = "SG_FETCH_ACTIVITY_LOG_STATUS"; //saga use this
export const EDIT_ACTIVITY_LOG = "EDIT_ACTIVITY_LOG";
export const SG_EDIT_ACTIVITY_LOG = "SG_EDIT_ACTIVITY_LOG"; //saga use this
export const ADD_ACTIVITY_LOG = "ADD_ACTIVITY_LOG";
export const SG_ADD_ACTIVITY_LOG = "SG_ADD_ACTIVITY_LOG"; //saga use this
export const CLEAR_ACTIVITY_LOG = "CLEAR_ACTIVITY_LOG";
//
//
// SURVEY STANDARD Activities
export const UPDATE_SURVEY_STANDARD_PAGE_NUMBER = "UPDATE_SURVEY_STANDARD_PAGE_NUMBER";
export const GET_SURVEY_STANDARD_PENDING = "GET_SURVEY_STANDARD_PENDING";

export const CLEAR_SERVER_CACHE_MSG = "CLEAR_SERVER_CACHE_MSG";
export const CLEAR_SERVER_CACHE = "CLEAR_SERVER_CACHE";
export const SG_CLEAR_SERVER_CACHE = "SG_CLEAR_SERVER_CACHE"
export const GET_APP_SURVEY_STANDARDS = "GET_APP_SURVEY_STANDARDS";
export const SG_GET_APP_SURVEY_STANDARDS = "SG_GET_APP_SURVEY_STANDARDS"; //saga use this

export const GET_SURVEY_STANDARDS = "GET_SURVEY_STANDARDS";
export const SG_GET_SURVEY_STANDARDS = "SG_GET_SURVEY_STANDARDS"; //saga use this
export const DELETE_SURVEY_STANDARD = "DELETE_SURVEY_STANDARD";
export const SG_DELETE_SURVEY_STANDARD = "SG_DELETE_SURVEY_STANDARD"; //saga use this
export const FETCH_SURVEY_STANDARD = "FETCH_SURVEY_STANDARD";
export const SG_FETCH_SURVEY_STANDARD = "SG_FETCH_SURVEY_STANDARD"; //saga use this
export const EDIT_SURVEY_STANDARD = "EDIT_SURVEY_STANDARD";
export const SG_EDIT_SURVEY_STANDARD = "SG_EDIT_SURVEY_STANDARD"; //saga use this
export const ADD_SURVEY_STANDARD = "ADD_SURVEY_STANDARD";
export const SG_ADD_SURVEY_STANDARD = "SG_ADD_SURVEY_STANDARD"; //saga use this
export const CLEAR_SURVEY_STANDARD = "CLEAR_SURVEY_STANDARD";
//
//
// CHAT GPT RECOMMENDATION
export const UPDATE_GPT_RECOMMENDATION_PAGE_NUMBER = "UPDATE_GPT_RECOMMENDATION_PAGE_NUMBER";
export const GET_GPT_RECOMMENDATION_PENDING = "GET_GPT_RECOMMENDATION_PENDING";
export const GET_GPT_RECOMMENDATION = "GET_GPT_RECOMMENDATION";
export const SG_GET_GPT_RECOMMENDATION = "SG_GET_GPT_RECOMMENDATION"; //saga use this
export const DELETE_GPT_RECOMMENDATION = "DELETE_GPT_RECOMMENDATION";
export const SG_DELETE_GPT_RECOMMENDATION = "SG_DELETE_GPT_RECOMMENDATION"; //saga use this
export const FETCH_GPT_RECOMMENDATION = "FETCH_GPT_RECOMMENDATION";
export const SG_FETCH_GPT_RECOMMENDATION = "SG_FETCH_GPT_RECOMMENDATION"; //saga use this
export const EDIT_GPT_RECOMMENDATION = "EDIT_GPT_RECOMMENDATION";
export const SG_EDIT_GPT_RECOMMENDATION = "SG_EDIT_GPT_RECOMMENDATION"; //saga use this
export const ADD_GPT_RECOMMENDATION = "ADD_GPT_RECOMMENDATION";
export const SG_ADD_GPT_RECOMMENDATION = "SG_ADD_GPT_RECOMMENDATION"; //saga use this
export const CLEAR_GPT_RECOMMENDATION = "CLEAR_GPT_RECOMMENDATION";
//
// CHAT GPT RECOMMENDATION_LOG
export const UPDATE_GPT_RECOMMENDATION_LOG_PAGE_NUMBER = "UPDATE_GPT_RECOMMENDATION_LOG_PAGE_NUMBER";
export const GET_GPT_RECOMMENDATION_LOG_PENDING = "GET_GPT_RECOMMENDATION_LOG_PENDING";
export const GET_GPT_RECOMMENDATION_LOG = "GET_GPT_RECOMMENDATION_LOG";
export const SG_GET_GPT_RECOMMENDATION_LOG = "SG_GET_GPT_RECOMMENDATION_LOG"; //saga use this
export const DELETE_GPT_RECOMMENDATION_LOG = "DELETE_GPT_RECOMMENDATION_LOG";
export const SG_DELETE_GPT_RECOMMENDATION_LOG = "SG_DELETE_GPT_RECOMMENDATION_LOG"; //saga use this
export const FETCH_GPT_RECOMMENDATION_LOG = "FETCH_GPT_RECOMMENDATION_LOG";
export const SG_FETCH_GPT_RECOMMENDATION_LOG = "SG_FETCH_GPT_RECOMMENDATION_LOG"; //saga use this
export const EDIT_GPT_RECOMMENDATION_LOG = "EDIT_GPT_RECOMMENDATION_LOG";
export const SG_EDIT_GPT_RECOMMENDATION_LOG = "SG_EDIT_GPT_RECOMMENDATION_LOG"; //saga use this
export const ADD_GPT_RECOMMENDATION_LOG = "ADD_GPT_RECOMMENDATION_LOG";
export const SG_ADD_GPT_RECOMMENDATION_LOG = "SG_ADD_GPT_RECOMMENDATION_LOG"; //saga use this
export const CLEAR_GPT_RECOMMENDATION_LOG = "CLEAR_GPT_RECOMMENDATION_LOG";
//
// CHAT GPT PROCESS
export const GET_GPT_PROCESS_PENDING = "GET_GPT_PROCESS_PENDING";
export const CLEAR_GPT_PROCESS = "CLEAR_GPT_PROCESS"; //saga use this
export const POST_GPT_PROCESS = "POST_GPT_PROCESS";
export const SG_POST_GPT_PROCESS = "SG_POST_GPT_PROCESS"; //saga use this
//
//
export const UPDATE_DEBRIEF_SUMMARY_PAGE_NUMBER = "UPDATE_DEBRIEF_SUMMARY_PAGE_NUMBER";
export const GET_DEBRIEF_SUMMARY_PENDING = "GET_DEBRIEF_SUMMARY_PENDING";
export const GET_DEBRIEF_SUMMARY = "GET_DEBRIEF_SUMMARY";
export const SG_GET_DEBRIEF_SUMMARY = "SG_GET_DEBRIEF_SUMMARY"; //saga use this
export const GET_DEBRIEF_SUMMARY_NEXT = "GET_DEBRIEF_SUMMARY_NEXT";
export const SG_GET_DEBRIEF_SUMMARY_NEXT = "SG_GET_DEBRIEF_SUMMARY_NEXT"; //saga use this
export const DELETE_DEBRIEF_SUMMARY = "DELETE_DEBRIEF_SUMMARY";
export const SG_DELETE_DEBRIEF_SUMMARY = "SG_DELETE_DEBRIEF_SUMMARY"; //saga use this
export const FETCH_DEBRIEF_SUMMARY = "FETCH_DEBRIEF_SUMMARY";
export const SG_FETCH_DEBRIEF_SUMMARY = "SG_FETCH_DEBRIEF_SUMMARY"; //saga use this
export const EDIT_DEBRIEF_SUMMARY = "EDIT_DEBRIEF_SUMMARY";
export const SG_EDIT_DEBRIEF_SUMMARY = "SG_EDIT_DEBRIEF_SUMMARY"; //saga use this
export const ADD_DEBRIEF_SUMMARY = "ADD_DEBRIEF_SUMMARY";
export const SG_ADD_DEBRIEF_SUMMARY = "SG_ADD_DEBRIEF_SUMMARY"; //saga use this
export const CLEAR_DEBRIEF_SUMMARY = "CLEAR_DEBRIEF_SUMMARY";
//
//
export const UPDATE_DEBRIEF_ANALYTICS_PAGE_NUMBER = "UPDATE_DEBRIEF_ANALYTICS_PAGE_NUMBER";
export const GET_DEBRIEF_ANALYTICS_PENDING = "GET_DEBRIEF_ANALYTICS_PENDING";
export const GET_DEBRIEF_ANALYTICS = "GET_DEBRIEF_ANALYTICS";
export const SG_GET_DEBRIEF_ANALYTICS = "SG_GET_DEBRIEF_ANALYTICS"; //saga use this
export const DELETE_DEBRIEF_ANALYTICS = "DELETE_DEBRIEF_ANALYTICS";
export const SG_DELETE_DEBRIEF_ANALYTICS = "SG_DELETE_DEBRIEF_ANALYTICS"; //saga use this
export const FETCH_DEBRIEF_ANALYTICS = "FETCH_DEBRIEF_ANALYTICS";
export const SG_FETCH_DEBRIEF_ANALYTICS = "SG_FETCH_DEBRIEF_ANALYTICS"; //saga use this
export const EDIT_DEBRIEF_ANALYTICS = "EDIT_DEBRIEF_ANALYTICS";
export const SG_EDIT_DEBRIEF_ANALYTICS = "SG_EDIT_DEBRIEF_ANALYTICS"; //saga use this
export const ADD_DEBRIEF_ANALYTICS = "ADD_DEBRIEF_ANALYTICS";
export const SG_ADD_DEBRIEF_ANALYTICS = "SG_ADD_DEBRIEF_ANALYTICS"; //saga use this
export const CLEAR_DEBRIEF_ANALYTICS = "CLEAR_DEBRIEF_ANALYTICS";
//
//
export const UPDATE_REPORT_TEMPLATE_PAGE_NUMBER = "UPDATE_REPORT_TEMPLATE_PAGE_NUMBER";
export const GET_REPORT_TEMPLATE_PENDING = "GET_REPORT_TEMPLATE_PENDING";
export const GET_REPORT_TEMPLATE_SELECT = "GET_REPORT_TEMPLATE_SELECT";
export const SG_GET_REPORT_TEMPLATE_SELECT = "SG_GET_REPORT_TEMPLATE_SELECT"; //saga use this
export const GET_REPORT_TEMPLATE = "GET_REPORT_TEMPLATE";
export const SG_GET_REPORT_TEMPLATE = "SG_GET_REPORT_TEMPLATE"; //saga use this
export const DELETE_REPORT_TEMPLATE = "DELETE_REPORT_TEMPLATE";
export const SG_DELETE_REPORT_TEMPLATE = "SG_DELETE_REPORT_TEMPLATE"; //saga use this
export const FETCH_REPORT_TEMPLATE = "FETCH_REPORT_TEMPLATE";
export const SG_FETCH_REPORT_TEMPLATE = "SG_FETCH_REPORT_TEMPLATE"; //saga use this
export const EDIT_REPORT_TEMPLATE = "EDIT_REPORT_TEMPLATE";
export const SG_EDIT_REPORT_TEMPLATE = "SG_EDIT_REPORT_TEMPLATE"; //saga use this
export const ADD_REPORT_TEMPLATE = "ADD_REPORT_TEMPLATE";
export const SG_ADD_REPORT_TEMPLATE = "SG_ADD_REPORT_TEMPLATE"; //saga use this
export const CLEAR_REPORT_TEMPLATE = "CLEAR_REPORT_TEMPLATE";
export const SG_CLEAR_REPORT_TEMPLATE = "SG_CLEAR_REPORT_TEMPLATE"
//
//
export const GET_RECOMMENDATION_MODELS = "GET_RECOMMENDATION_MODELS";
export const SG_GET_RECOMMENDATION_MODELS = "SG_GET_RECOMMENDATION_MODELS"; //saga use this
export const CLEAR_RECOMMENDATION_MODELS = "CLEAR_RECOMMENDATION_MODELS";
export const GET_RECOMMENDATION_MODELS_PENDING = "GET_RECOMMENDATION_MODELS_PENDING";
//
//
export const GET_DOCUMENTATION_PENDING = "GET_DOCUMENTATION_PENDING";
export const GET_DOCUMENTATION = "GET_DOCUMENTATION";
export const SG_GET_DOCUMENTATION = "SG_GET_DOCUMENTATION"; //saga use this
export const CLEAR_DOCUMENTATION = "CLEAR_DOCUMENTATION";
//
//
export const UPDATE_LOGIN_HISTORY_PAGE_NUMBER = "UPDATE_LOGIN_HISTORY_PAGE_NUMBER";
export const GET_LOGIN_HISTORY_PENDING = "GET_LOGIN_HISTORY_PENDING";
export const GET_LOGIN_HISTORY = "GET_LOGIN_HISTORY";
export const SG_GET_LOGIN_HISTORY = "SG_GET_LOGIN_HISTORY"; //saga use this
export const DELETE_LOGIN_HISTORY = "DELETE_LOGIN_HISTORY";
export const SG_DELETE_LOGIN_HISTORY = "SG_DELETE_LOGIN_HISTORY"; //saga use this
export const FETCH_LOGIN_HISTORY = "FETCH_LOGIN_HISTORY";
export const SG_FETCH_LOGIN_HISTORY = "SG_FETCH_LOGIN_HISTORY"; //saga use this
export const EDIT_LOGIN_HISTORY = "EDIT_LOGIN_HISTORY";
export const SG_EDIT_LOGIN_HISTORY = "SG_EDIT_LOGIN_HISTORY"; //saga use this
export const ADD_LOGIN_HISTORY = "ADD_LOGIN_HISTORY";
export const SG_ADD_LOGIN_HISTORY = "SG_ADD_LOGIN_HISTORY"; //saga use this
export const CLEAR_LOGIN_HISTORY = "CLEAR_LOGIN_HISTORY";
export const SG_CLEAR_LOGIN_HISTORY = "SG_CLEAR_LOGIN_HISTORY"
//
//
export const UPDATE_CHANGE_LOG_PAGE_NUMBER = "UPDATE_CHANGE_LOG_PAGE_NUMBER";
export const GET_CHANGE_LOG_PENDING = "GET_CHANGE_LOG_PENDING";
export const GET_CHANGE_LOG = "GET_CHANGE_LOG";
export const SG_GET_CHANGE_LOG = "SG_GET_CHANGE_LOG"; //saga use this
export const DELETE_CHANGE_LOG = "DELETE_CHANGE_LOG";
export const SG_DELETE_CHANGE_LOG = "SG_DELETE_CHANGE_LOG"; //saga use this
export const FETCH_CHANGE_LOG = "FETCH_CHANGE_LOG";
export const SG_FETCH_CHANGE_LOG = "SG_FETCH_CHANGE_LOG"; //saga use this
export const EDIT_CHANGE_LOG = "EDIT_CHANGE_LOG";
export const SG_EDIT_CHANGE_LOG = "SG_EDIT_CHANGE_LOG"; //saga use this
export const ADD_CHANGE_LOG = "ADD_CHANGE_LOG";
export const SG_ADD_CHANGE_LOG = "SG_ADD_CHANGE_LOG"; //saga use this
export const CLEAR_CHANGE_LOG = "CLEAR_CHANGE_LOG";
//
//
export const UPDATE_RECOMMENDATION_MODEL_RATING_PAGE_NUMBER = "UPDATE_RECOMMENDATION_MODEL_RATING_PAGE_NUMBER";
export const GET_RECOMMENDATION_MODEL_RATING_PENDING = "GET_RECOMMENDATION_MODEL_RATING_PENDING";
export const GET_RECOMMENDATION_MODEL_RATING = "GET_RECOMMENDATION_MODEL_RATING";
export const SG_GET_RECOMMENDATION_MODEL_RATING = "SG_GET_RECOMMENDATION_MODEL_RATING"; //saga use this
export const DELETE_RECOMMENDATION_MODEL_RATING = "DELETE_RECOMMENDATION_MODEL_RATING";
export const SG_DELETE_RECOMMENDATION_MODEL_RATING = "SG_DELETE_RECOMMENDATION_MODEL_RATING"; //saga use this
export const FETCH_RECOMMENDATION_MODEL_RATING = "FETCH_RECOMMENDATION_MODEL_RATING";
export const SG_FETCH_RECOMMENDATION_MODEL_RATING = "SG_FETCH_RECOMMENDATION_MODEL_RATING"; //saga use this
export const EDIT_RECOMMENDATION_MODEL_RATING = "EDIT_RECOMMENDATION_MODEL_RATING";
export const SG_EDIT_RECOMMENDATION_MODEL_RATING = "SG_EDIT_RECOMMENDATION_MODEL_RATING"; //saga use this
export const ADD_RECOMMENDATION_MODEL_RATING = "ADD_RECOMMENDATION_MODEL_RATING";
export const SG_ADD_RECOMMENDATION_MODEL_RATING = "SG_ADD_RECOMMENDATION_MODEL_RATING"; //saga use this
export const CLEAR_RECOMMENDATION_MODEL_RATING = "CLEAR_RECOMMENDATION_MODEL_RATING";
//
//
export const UPDATE_SCHEDULE_TEMPLATE_PAGE_NUMBER = "UPDATE_SCHEDULE_TEMPLATE_PAGE_NUMBER";
export const GET_SCHEDULE_TEMPLATE_PENDING = "GET_SCHEDULE_TEMPLATE_PENDING";
export const GET_SCHEDULE_TEMPLATE = "GET_SCHEDULE_TEMPLATE";
export const SG_GET_SCHEDULE_TEMPLATE = "SG_GET_SCHEDULE_TEMPLATE"; //saga use this
export const DELETE_SCHEDULE_TEMPLATE = "DELETE_SCHEDULE_TEMPLATE";
export const SG_DELETE_SCHEDULE_TEMPLATE = "SG_DELETE_SCHEDULE_TEMPLATE"; //saga use this
export const FETCH_SCHEDULE_TEMPLATE = "FETCH_SCHEDULE_TEMPLATE";
export const SG_FETCH_SCHEDULE_TEMPLATE = "SG_FETCH_SCHEDULE_TEMPLATE"; //saga use this
export const EDIT_SCHEDULE_TEMPLATE = "EDIT_SCHEDULE_TEMPLATE";
export const SG_EDIT_SCHEDULE_TEMPLATE = "SG_EDIT_SCHEDULE_TEMPLATE"; //saga use this
export const ADD_SCHEDULE_TEMPLATE = "ADD_SCHEDULE_TEMPLATE";
export const SG_ADD_SCHEDULE_TEMPLATE = "SG_ADD_SCHEDULE_TEMPLATE"; //saga use this
export const CLEAR_SCHEDULE_TEMPLATE = "CLEAR_SCHEDULE_TEMPLATE";
export const RESET_SCHEDULE_TEMPLATE = "RESET_SCHEDULE_TEMPLATE";
//
//
// // REACT PDF  PDF_REPORT PDF REPORT TEMPLATES
export const UPDATE_DEBRIEF_PDF_REPORT_PAGE_NUMBER = "UPDATE_DEBRIEF_PDF_REPORT_PAGE_NUMBER";
export const GET_DEBRIEF_PDF_REPORT_PENDING = "GET_DEBRIEF_PDF_REPORT_PENDING";
export const GET_DEBRIEF_PDF_REPORT_TEMPLATES = "GET_DEBRIEF_PDF_REPORT_TEMPLATES";
export const SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES = "SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES";
export const GET_DEBRIEF_PDF_REPORT = "GET_DEBRIEF_PDF_REPORT";
export const SG_GET_DEBRIEF_PDF_REPORT = "SG_GET_DEBRIEF_PDF_REPORT";
export const FETCH_DEBRIEF_PDF_REPORT = "FETCH_DEBRIEF_PDF_REPORT";
export const SG_FETCH_DEBRIEF_PDF_REPORT = "SG_FETCH_DEBRIEF_PDF_REPORT";
// export const CREATE_DEBRIEF_PDF_REPORT = "CREATE_DEBRIEF_PDF_REPORT";
// export const SG_CREATE_DEBRIEF_PDF_REPORT = "SG_CREATE_DEBRIEF_PDF_REPORT";
export const REGENERATE_DEBRIEF_PDF_REPORT = "REGENERATE_DEBRIEF_PDF_REPORT";
export const SG_REGENERATE_DEBRIEF_PDF_REPORT = "SG_REGENERATE_DEBRIEF_PDF_REPORT";
export const GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK = "GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK";
export const SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK = "SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK";
export const DELETE_DEBRIEF_PDF_REPORT = "DELETE_DEBRIEF_PDF_REPORT";
export const SG_DELETE_DEBRIEF_PDF_REPORT = "SG_DELETE_DEBRIEF_PDF_REPORT";
export const UPDATE_DEBRIEF_PDF_REPORT = "UPDATE_DEBRIEF_PDF_REPORT";
export const SG_UPDATE_DEBRIEF_PDF_REPORT = "SG_UPDATE_DEBRIEF_PDF_REPORT";
export const CLEAR_DEBRIEF_PDF_REPORT = "CLEAR_DEBRIEF_PDF_REPORT";
export const RESET_DEBRIEF_PDF_REPORT = "RESET_DEBRIEF_PDF_REPORT";
export const RESET_DEBRIEF_PDF_REPORT_UPDATES = "RESET_DEBRIEF_PDF_REPORT_UPDATES";
export const UPDATE_DEBRIEF_PDF_REPORT_QUERY_PARAMS = "UPDATE_DEBRIEF_PDF_REPORT_QUERY_PARAMS";
//
//
export const UPDATE_TASK_TEMPLATE_PAGE_NUMBER = "UPDATE_TASK_TEMPLATE_PAGE_NUMBER";
export const GET_TASK_TEMPLATE_PENDING = "GET_TASK_TEMPLATE_PENDING";
export const GET_TASK_TEMPLATE = "GET_TASK_TEMPLATE";
export const SG_GET_TASK_TEMPLATE = "SG_GET_TASK_TEMPLATE"; //saga use this
export const DELETE_TASK_TEMPLATE = "DELETE_TASK_TEMPLATE";
export const SG_DELETE_TASK_TEMPLATE = "SG_DELETE_TASK_TEMPLATE"; //saga use this
export const FETCH_TASK_TEMPLATE = "FETCH_TASK_TEMPLATE";
export const SG_FETCH_TASK_TEMPLATE = "SG_FETCH_TASK_TEMPLATE"; //saga use this
export const EDIT_TASK_TEMPLATE = "EDIT_TASK_TEMPLATE";
export const SG_EDIT_TASK_TEMPLATE = "SG_EDIT_TASK_TEMPLATE"; //saga use this
export const ADD_TASK_TEMPLATE = "ADD_TASK_TEMPLATE";
export const SG_ADD_TASK_TEMPLATE = "SG_ADD_TASK_TEMPLATE"; //saga use this
export const CLEAR_TASK_TEMPLATE = "CLEAR_TASK_TEMPLATE";
export const RESET_TASK_TEMPLATE = "RESET_TASK_TEMPLATE";
//
//
export const UPDATE_TASK_TEMPLATE_USER_TASK_PAGE_NUMBER = "UPDATE_TASK_TEMPLATE_USER_TASK_PAGE_NUMBER";
export const GET_TASK_TEMPLATE_USER_TASK_PENDING = "GET_TASK_TEMPLATE_USER_TASK_PENDING";
export const GET_TASK_TEMPLATE_USER_TASK = "GET_TASK_TEMPLATE_USER_TASK";
export const SG_GET_TASK_TEMPLATE_USER_TASK = "SG_GET_TASK_TEMPLATE_USER_TASK"; //saga use this
export const DELETE_TASK_TEMPLATE_USER_TASK = "DELETE_TASK_TEMPLATE_USER_TASK";
export const SG_DELETE_TASK_TEMPLATE_USER_TASK = "SG_DELETE_TASK_TEMPLATE_USER_TASK"; //saga use this
export const FETCH_TASK_TEMPLATE_USER_TASK = "FETCH_TASK_TEMPLATE_USER_TASK";
export const SG_FETCH_TASK_TEMPLATE_USER_TASK = "SG_FETCH_TASK_TEMPLATE_USER_TASK"; //saga use this
export const EDIT_TASK_TEMPLATE_USER_TASK = "EDIT_TASK_TEMPLATE_USER_TASK";
export const SG_EDIT_TASK_TEMPLATE_USER_TASK = "SG_EDIT_TASK_TEMPLATE_USER_TASK"; //saga use this
export const ADD_TASK_TEMPLATE_USER_TASK = "ADD_TASK_TEMPLATE_USER_TASK";
export const SG_ADD_TASK_TEMPLATE_USER_TASK = "SG_ADD_TASK_TEMPLATE_USER_TASK"; //saga use this
export const CLEAR_TASK_TEMPLATE_USER_TASK = "CLEAR_TASK_TEMPLATE_USER_TASK";
export const RESET_TASK_TEMPLATE_USER_TASK = "RESET_TASK_TEMPLATE_USER_TASK";
//
//
export const UPDATE_TASK_TEMPLATE_USER_RESPONSE_PAGE_NUMBER = "UPDATE_TASK_TEMPLATE_USER_RESPONSE_PAGE_NUMBER";
export const GET_TASK_TEMPLATE_USER_RESPONSE_PENDING = "GET_TASK_TEMPLATE_USER_RESPONSE_PENDING";
export const GET_TASK_TEMPLATE_USER_RESPONSE = "GET_TASK_TEMPLATE_USER_RESPONSE";
export const SG_GET_TASK_TEMPLATE_USER_RESPONSE = "SG_GET_TASK_TEMPLATE_USER_RESPONSE"; //saga use this
export const DELETE_TASK_TEMPLATE_USER_RESPONSE = "DELETE_TASK_TEMPLATE_USER_RESPONSE";
export const SG_DELETE_TASK_TEMPLATE_USER_RESPONSE = "SG_DELETE_TASK_TEMPLATE_USER_RESPONSE"; //saga use this
export const FETCH_TASK_TEMPLATE_USER_RESPONSE = "FETCH_TASK_TEMPLATE_USER_RESPONSE";
export const SG_FETCH_TASK_TEMPLATE_USER_RESPONSE = "SG_FETCH_TASK_TEMPLATE_USER_RESPONSE"; //saga use this
export const EDIT_TASK_TEMPLATE_USER_RESPONSE = "EDIT_TASK_TEMPLATE_USER_RESPONSE";
export const SG_EDIT_TASK_TEMPLATE_USER_RESPONSE = "SG_EDIT_TASK_TEMPLATE_USER_RESPONSE"; //saga use this
export const ADD_TASK_TEMPLATE_USER_RESPONSE = "ADD_TASK_TEMPLATE_USER_RESPONSE";
export const SG_ADD_TASK_TEMPLATE_USER_RESPONSE = "SG_ADD_TASK_TEMPLATE_USER_RESPONSE"; //saga use this
export const CLEAR_TASK_TEMPLATE_USER_RESPONSE = "CLEAR_TASK_TEMPLATE_USER_RESPONSE";
export const RESET_TASK_TEMPLATE_USER_RESPONSE = "RESET_TASK_TEMPLATE_USER_RESPONSE";
//
//
export const UPDATE_TASK_TEMPLATE_USER_REQUEST_PAGE_NUMBER = "UPDATE_TASK_TEMPLATE_USER_REQUEST_PAGE_NUMBER";
export const GET_TASK_TEMPLATE_USER_REQUEST_PENDING = "GET_TASK_TEMPLATE_USER_REQUEST_PENDING";
export const GET_TASK_TEMPLATE_USER_REQUEST = "GET_TASK_TEMPLATE_USER_REQUEST";
export const SG_GET_TASK_TEMPLATE_USER_REQUEST = "SG_GET_TASK_TEMPLATE_USER_REQUEST"; //saga use this
export const DELETE_TASK_TEMPLATE_USER_REQUEST = "DELETE_TASK_TEMPLATE_USER_REQUEST";
export const SG_DELETE_TASK_TEMPLATE_USER_REQUEST = "SG_DELETE_TASK_TEMPLATE_USER_REQUEST"; //saga use this
export const FETCH_TASK_TEMPLATE_USER_REQUEST = "FETCH_TASK_TEMPLATE_USER_REQUEST";
export const SG_FETCH_TASK_TEMPLATE_USER_REQUEST = "SG_FETCH_TASK_TEMPLATE_USER_REQUEST"; //saga use this
export const EDIT_TASK_TEMPLATE_USER_REQUEST = "EDIT_TASK_TEMPLATE_USER_REQUEST";
export const SG_EDIT_TASK_TEMPLATE_USER_REQUEST = "SG_EDIT_TASK_TEMPLATE_USER_REQUEST"; //saga use this
export const ADD_TASK_TEMPLATE_USER_REQUEST = "ADD_TASK_TEMPLATE_USER_REQUEST";
export const SG_ADD_TASK_TEMPLATE_USER_REQUEST = "SG_ADD_TASK_TEMPLATE_USER_REQUEST"; //saga use this
export const CLEAR_TASK_TEMPLATE_USER_REQUEST = "CLEAR_TASK_TEMPLATE_USER_REQUEST";
export const RESET_TASK_TEMPLATE_USER_REQUEST = "RESET_TASK_TEMPLATE_USER_REQUEST";
//
//
export const UPDATE_SURVEY_AI_LOG_PAGE_NUMBER = "UPDATE_SURVEY_AI_LOG_PAGE_NUMBER";
export const GET_SURVEY_AI_LOG_PENDING = "GET_SURVEY_AI_LOG_PENDING";
export const GET_SURVEY_AI_LOG = "GET_SURVEY_AI_LOG";
export const SG_GET_SURVEY_AI_LOG = "SG_GET_SURVEY_AI_LOG"; 
export const CLEAR_SURVEY_AI_LOG = "CLEAR_SURVEY_AI_LOG";
export const RESET_SURVEY_AI_LOG = "RESET_SURVEY_AI_LOG";//
//
//
export const GET_REPORT_SERVER_TEST = "GET_REPORT_SERVER_TEST";
export const SG_GET_REPORT_SERVER_TEST = "SG_GET_REPORT_SERVER_TEST"; 
export const GET_REPORT_SERVER_RESET_CACHE = "GET_REPORT_SERVER_RESET_CACHE";
export const SG_GET_REPORT_SERVER_RESET_CACHE = "SG_GET_REPORT_SERVER_RESET_CACHE"; 
export const GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE = "GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE";
export const SG_GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE = "SG_GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE";
//
//
export const UPDATE_PRODUCT_GROUP_PAGE_NUMBER = "UPDATE_PRODUCT_GROUP_PAGE_NUMBER";
export const GET_PRODUCT_GROUP_PENDING = "GET_PRODUCT_GROUP_PENDING";
export const GET_PRODUCT_GROUP = "GET_PRODUCT_GROUP";
export const SG_GET_PRODUCT_GROUP = "SG_GET_PRODUCT_GROUP"; //saga use this
export const GET_PRODUCT_GROUP_SELECT = "GET_PRODUCT_GROUP_SELECT";
export const SG_GET_PRODUCT_GROUP_SELECT = "SG_GET_PRODUCT_GROUP_SELECT"; //saga use this
export const DELETE_PRODUCT_GROUP = "DELETE_PRODUCT_GROUP";
export const SG_DELETE_PRODUCT_GROUP = "SG_DELETE_PRODUCT_GROUP"; //saga use this
export const FETCH_PRODUCT_GROUP = "FETCH_PRODUCT_GROUP";
export const SG_FETCH_PRODUCT_GROUP = "SG_FETCH_PRODUCT_GROUP"; //saga use this
export const EDIT_PRODUCT_GROUP = "EDIT_PRODUCT_GROUP";
export const SG_EDIT_PRODUCT_GROUP = "SG_EDIT_PRODUCT_GROUP"; //saga use this
export const ADD_PRODUCT_GROUP = "ADD_PRODUCT_GROUP";
export const SG_ADD_PRODUCT_GROUP = "SG_ADD_PRODUCT_GROUP"; //saga use this
export const CLEAR_PRODUCT_GROUP = "CLEAR_PRODUCT_GROUP";
export const RESET_PRODUCT_GROUP = "RESET_PRODUCT_GROUP";
//
//
export const UPDATE_PRODUCT_PAGE_NUMBER = "UPDATE_PRODUCT_PAGE_NUMBER";
export const GET_PRODUCT_PENDING = "GET_PRODUCT_PENDING";
export const GET_PRODUCT = "GET_PRODUCT";
export const SG_GET_PRODUCT = "SG_GET_PRODUCT"; //saga use this
export const GET_PRODUCT_SELECT = "GET_PRODUCT_SELECT";
export const SG_GET_PRODUCT_SELECT = "SG_GET_PRODUCT_SELECT"; //saga use this
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const SG_DELETE_PRODUCT = "SG_DELETE_PRODUCT"; //saga use this
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const SG_FETCH_PRODUCT = "SG_FETCH_PRODUCT"; //saga use this
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const SG_EDIT_PRODUCT = "SG_EDIT_PRODUCT"; //saga use this
export const ADD_PRODUCT = "ADD_PRODUCT";
export const SG_ADD_PRODUCT = "SG_ADD_PRODUCT"; //saga use this
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";
//
//
export const UPDATE_PROD_ORG_CONTRACT_PAGE_NUMBER = "UPDATE_PROD_ORG_CONTRACT_PAGE_NUMBER";
export const GET_PROD_ORG_CONTRACT_PENDING = "GET_PROD_ORG_CONTRACT_PENDING";
export const GET_PROD_ORG_CONTRACT = "GET_PROD_ORG_CONTRACT";
export const SG_GET_PROD_ORG_CONTRACT = "SG_GET_PROD_ORG_CONTRACT"; //saga use this
export const DELETE_PROD_ORG_CONTRACT = "DELETE_PROD_ORG_CONTRACT";
export const SG_DELETE_PROD_ORG_CONTRACT = "SG_DELETE_PROD_ORG_CONTRACT"; //saga use this
export const FETCH_PROD_ORG_CONTRACT = "FETCH_PROD_ORG_CONTRACT";
export const SG_FETCH_PROD_ORG_CONTRACT = "SG_FETCH_PROD_ORG_CONTRACT"; //saga use this
export const EDIT_PROD_ORG_CONTRACT = "EDIT_PROD_ORG_CONTRACT";
export const SG_EDIT_PROD_ORG_CONTRACT = "SG_EDIT_PROD_ORG_CONTRACT"; //saga use this
export const ADD_PROD_ORG_CONTRACT = "ADD_PROD_ORG_CONTRACT";
export const SG_ADD_PROD_ORG_CONTRACT = "SG_ADD_PROD_ORG_CONTRACT"; //saga use this
export const CLEAR_PROD_ORG_CONTRACT = "CLEAR_PROD_ORG_CONTRACT";
export const RESET_PROD_ORG_CONTRACT = "RESET_PROD_ORG_CONTRACT";
//
//
export const UPDATE_PRODUCT_PRICE_PAGE_NUMBER = "UPDATE_PRODUCT_PRICE_PAGE_NUMBER";
export const GET_PRODUCT_PRICE_PENDING = "GET_PRODUCT_PRICE_PENDING";
export const GET_PRODUCT_PRICE = "GET_PRODUCT_PRICE";
export const SG_GET_PRODUCT_PRICE = "SG_GET_PRODUCT_PRICE"; //saga use this
export const GET_PRODUCT_PRICE_SELECT = "GET_PRODUCT_PRICE_SELECT";
export const SG_GET_PRODUCT_PRICE_SELECT = "SG_GET_PRODUCT_PRICE_SELECT"; //saga use this
export const DELETE_PRODUCT_PRICE = "DELETE_PRODUCT_PRICE";
export const SG_DELETE_PRODUCT_PRICE = "SG_DELETE_PRODUCT_PRICE"; //saga use this
export const FETCH_PRODUCT_PRICE = "FETCH_PRODUCT_PRICE";
export const SG_FETCH_PRODUCT_PRICE = "SG_FETCH_PRODUCT_PRICE"; //saga use this
export const EDIT_PRODUCT_PRICE = "EDIT_PRODUCT_PRICE";
export const SG_EDIT_PRODUCT_PRICE = "SG_EDIT_PRODUCT_PRICE"; //saga use this
export const ADD_PRODUCT_PRICE = "ADD_PRODUCT_PRICE";
export const SG_ADD_PRODUCT_PRICE = "SG_ADD_PRODUCT_PRICE"; //saga use this
export const CLEAR_PRODUCT_PRICE = "CLEAR_PRODUCT_PRICE";
export const RESET_PRODUCT_PRICE = "RESET_PRODUCT_PRICE";
//
//
export const UPDATE_PRODUCT_PACKAGE_PAGE_NUMBER = "UPDATE_PRODUCT_PACKAGE_PAGE_NUMBER";
export const GET_PRODUCT_PACKAGE_PENDING = "GET_PRODUCT_PACKAGE_PENDING";
export const GET_PRODUCT_PACKAGE = "GET_PRODUCT_PACKAGE";
export const SG_GET_PRODUCT_PACKAGE = "SG_GET_PRODUCT_PACKAGE"; //saga use this
export const GET_PRODUCT_PACKAGE_SELECT = "GET_PRODUCT_PACKAGE_SELECT";
export const SG_GET_PRODUCT_PACKAGE_SELECT = "SG_GET_PRODUCT_PACKAGE_SELECT"; //saga use this
export const DUPLICATE_PRODUCT_PACKAGE = "DUPLICATE_PRODUCT_PACKAGE";
export const SG_DUPLICATE_PRODUCT_PACKAGE = "SG_DUPLICATE_PRODUCT_PACKAGE"; //saga use this
export const DELETE_PRODUCT_PACKAGE = "DELETE_PRODUCT_PACKAGE";
export const SG_DELETE_PRODUCT_PACKAGE = "SG_DELETE_PRODUCT_PACKAGE"; //saga use this
export const FETCH_PRODUCT_PACKAGE = "FETCH_PRODUCT_PACKAGE";
export const SG_FETCH_PRODUCT_PACKAGE = "SG_FETCH_PRODUCT_PACKAGE"; //saga use this
export const EDIT_PRODUCT_PACKAGE = "EDIT_PRODUCT_PACKAGE";
export const SG_EDIT_PRODUCT_PACKAGE = "SG_EDIT_PRODUCT_PACKAGE"; //saga use this
export const ADD_PRODUCT_PACKAGE = "ADD_PRODUCT_PACKAGE";
export const SG_ADD_PRODUCT_PACKAGE = "SG_ADD_PRODUCT_PACKAGE"; //saga use this
export const CLEAR_PRODUCT_PACKAGE = "CLEAR_PRODUCT_PACKAGE";
export const RESET_PRODUCT_PACKAGE = "RESET_PRODUCT_PACKAGE";
//
//
export const UPDATE_PROD_DEV_DOC_PAGE_NUMBER = "UPDATE_PROD_DEV_DOC_PAGE_NUMBER";
export const GET_PROD_DEV_DOC_PENDING = "GET_PROD_DEV_DOC_PENDING";
export const GET_PROD_DEV_DOC = "GET_PROD_DEV_DOC";
export const SG_GET_PROD_DEV_DOC = "SG_GET_PROD_DEV_DOC"; //saga use this
export const DELETE_PROD_DEV_DOC = "DELETE_PROD_DEV_DOC";
export const SG_DELETE_PROD_DEV_DOC = "SG_DELETE_PROD_DEV_DOC"; //saga use this
export const FETCH_PROD_DEV_DOC = "FETCH_PROD_DEV_DOC";
export const SG_FETCH_PROD_DEV_DOC = "SG_FETCH_PROD_DEV_DOC"; //saga use this
export const EDIT_PROD_DEV_DOC = "EDIT_PROD_DEV_DOC";
export const SG_EDIT_PROD_DEV_DOC = "SG_EDIT_PROD_DEV_DOC"; //saga use this
export const ADD_PROD_DEV_DOC = "ADD_PROD_DEV_DOC";
export const SG_ADD_PROD_DEV_DOC = "SG_ADD_PROD_DEV_DOC"; //saga use this
export const CLEAR_PROD_DEV_DOC = "CLEAR_PROD_DEV_DOC";
export const RESET_PROD_DEV_DOC = "RESET_PROD_DEV_DOC";
//
//
export const RUN_MODEL_OUTCOME = "RUN_MODEL_OUTCOME";
export const SG_RUN_MODEL_OUTCOME = "SG_RUN_MODEL_OUTCOME"; //saga use this
//
//

export const UPDATE_PREDICTIVE_MODEL_PAGE_NUMBER = "UPDATE_PREDICTIVE_MODEL_PAGE_NUMBER";
export const GET_PREDICTIVE_MODEL_PENDING = "GET_PREDICTIVE_MODEL_PENDING";
export const GET_PREDICTIVE_MODEL = "GET_PREDICTIVE_MODEL";
export const SG_GET_PREDICTIVE_MODEL = "SG_GET_PREDICTIVE_MODEL"; //saga use this
export const DELETE_PREDICTIVE_MODEL = "DELETE_PREDICTIVE_MODEL";
export const SG_DELETE_PREDICTIVE_MODEL = "SG_DELETE_PREDICTIVE_MODEL"; //saga use this
export const FETCH_PREDICTIVE_MODEL = "FETCH_PREDICTIVE_MODEL";
export const SG_FETCH_PREDICTIVE_MODEL = "SG_FETCH_PREDICTIVE_MODEL"; //saga use this
export const EDIT_PREDICTIVE_MODEL = "EDIT_PREDICTIVE_MODEL";
export const SG_EDIT_PREDICTIVE_MODEL = "SG_EDIT_PREDICTIVE_MODEL"; //saga use this
export const ADD_PREDICTIVE_MODEL = "ADD_PREDICTIVE_MODEL";
export const SG_ADD_PREDICTIVE_MODEL = "SG_ADD_PREDICTIVE_MODEL"; //saga use this
export const CLEAR_PREDICTIVE_MODEL = "CLEAR_PREDICTIVE_MODEL";
export const RESET_PREDICTIVE_MODEL = "RESET_PREDICTIVE_MODEL";
//
//