import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_ACCOUNTS,
  UPDATE_ACCOUNT_PAGE_NUMBER,
  UPDATE_ACCOUNT_QUERY_PARAMS,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Icon,
} from "semantic-ui-react";

const List = ({ orgId }) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(orgId);
  const [resetPageLoad, setResetPageLoad] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [queryParams, setQueryParams] = useState([]);

  const { get_accounts } = useSelector(
    (state) => ({
      get_accounts: state.account,
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationId(orgId);
  }, [orgId]);

  function applyFilters(pageNo = false, orgId = false) {
    // setTimeout(() => {
    // if the response is ok 200
    let _queryParams = [];
    // Check if report status filter is present
    if (pageNo) {
      setPage(pageNo);
      _queryParams.push(`page=${pageNo}`);
    }
    if (orgId) {
      _queryParams.push(`organization_id=${orgId}`);
    }

    dispatch({
      type: UPDATE_ACCOUNT_QUERY_PARAMS,
      payload: _queryParams,
    });
    // }, 500);
  }

  useEffect(() => {
    if (queryParams && queryParams?.length > 0) {
      dispatch({
        type: SG_GET_ACCOUNTS,
        payload: queryParams.join("&"),
      });
    } else if (orgId) {
      dispatch({
        type: SG_GET_ACCOUNTS,
        payload: queryParams.join("&"),
      });
      dispatch({
        type: UPDATE_ACCOUNT_QUERY_PARAMS,
        payload: [`organization_id=${orgId}`],
      });
    } else {
      dispatch({
        type: SG_GET_ACCOUNTS,
      });
    }
  }, [dispatch, queryParams, orgId]);

  useEffect(() => {
    if (get_accounts?.queryParams) {
      setQueryParams(get_accounts?.queryParams);
    }
  }, [get_accounts]);

  useEffect(() => {
    setResults(get_accounts?.accounts?.results);
    setPage(get_accounts?.pageNumber);
    setTotalPages(
      Math.floor(
        get_accounts?.accounts?.count / get_accounts?.accounts?.page_size
      )
    );
    if (get_accounts?.accounts?.count % get_accounts?.accounts?.page_size) {
      setTotalPages(
        Math.floor(
          get_accounts?.accounts?.count / get_accounts?.accounts?.page_size
        )
      );
    }
  }, [get_accounts, page]);

  function handlePageChange(event, value) {
    applyFilters(value.activePage, organizationId);

    setPage(value.activePage);
    dispatch({
      type: UPDATE_ACCOUNT_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_accounts?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_accounts]);

  return (
    <Container>
      <Segment id="results" basic>
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) => <RowCard row={result} key={result?.id} />)}
        </Card.Group>
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
      </Segment>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: ${(props) => (props.warn ? "black" : "#dcdcdc")};
  cursor: pointer;
`;

const WarningDiv = styled.span`
  color: red;
  font-weight: 800;
`;
