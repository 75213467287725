import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_FETCH_SCHEDULE_TEMPLATE,
  SG_DELETE_SCHEDULE_TEMPLATE,
} from "constants/actions";
//
import {
  Icon,
  Button,
  Header,
} from "semantic-ui-react";
//
import DataForm from "./DataForm";
//
const EditForm = ({ setReload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });

  function BackArrow() {
    navigate(`/app/schedule-template-report`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_SCHEDULE_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_schedule_template } = useSelector(
    (state) => ({
      get_schedule_template: state.schedule_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_schedule_template?.[templateId]) {
      setTemplateDetails(get_schedule_template?.[templateId]);
    }
  }, [get_schedule_template, templateId]);

  useEffect(() => {
    if (get_schedule_template?.updated?.id) {
      navigate(
        `/app/schedule-template-report/details/${get_schedule_template?.updated?.id}`
      );
    }
  }, [get_schedule_template, navigate]);

  useEffect(() => {
    if (get_schedule_template?.deleted) {
      setReload(Math.random());
      const timer1 = setTimeout(() => {
        navigate(`/app/schedule-template-report`);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [get_schedule_template, navigate, setReload]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_SCHEDULE_TEMPLATE,
        payload: {
          id: templateId,
        },
      });
    }
  }

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {templateId&& `Details of ${templateDetails?.external_model}`}
      </Header>
      

      {templateId && (
        <DataForm templateDetails={templateDetails} />
      )}


      <SectionDiv>
        <Button floated="left" color="red" onClick={DeleteQuestion}>
          Delete
        </Button>
      </SectionDiv>
    </>
  );
};

export default EditForm;


const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
