import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  // SG_GET_APP_LIST_URLS
  SG_GET_ORGANIZATION_SELECT,
  SG_FETCH_APP_LIST_URL,
  SG_EDIT_APP_LIST_URL,
  SG_ADD_APP_LIST_URL,
  SG_DELETE_APP_LIST_URL,

} from "constants/actions";
//
import {
  Icon, Message, Button, Header, List, Input,
  Confirm, Form
} from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"

import { WEB_API_STATUS, WEB_API_DIRECTION } from "data/AdminData"
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });


  const [valueNames, setValueNames] = useState([])


  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_APP_LIST_URL,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });

  }, [dispatch]);

  const {
    get_app_url_list } = useSelector(
      (state) => ({
        get_app_url_list: state.applisturl,
      
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_app_url_list?.[templateId]) {
      setTemplateDetails(get_app_url_list?.[templateId])
      
    
    }
  }, [get_app_url_list, templateId, valueNames]);

  function BackArrow() {
    navigate(`/app/app-url`)
  }

  useEffect(() => {
    if (get_app_url_list?.created?.id) {
      navigate(`/app/app-url/details/${get_app_url_list?.created?.id}`)
    }
  }, [get_app_url_list, navigate]);



  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_APP_LIST_URL,
        payload: {
          id: templateId,
        }
      });
      navigate(`/app/app-url?reload=true`)
    }
  }
  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_EDIT_APP_LIST_URL,
          payload: {
            id: templateId,
            name: templateDetails?.name,
            url: templateDetails?.url,
            description: templateDetails?.description,
          }
        });
        navigate(`/app/app-url`)
      } else {

        dispatch({
          type: SG_ADD_APP_LIST_URL,
          payload: {
            name: templateDetails?.name,
            url: templateDetails?.url,
            description: templateDetails?.description,

          }
        });
      }
      setPristine(true)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name")
    }
    if (!templateDetails?.url) {
      ErrorList.push("Select an url")
    }
    setFormErrors(ErrorList)
  }, [templateDetails, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = templateDetails
    _ques[`${name}`] = e.target.value
    setTemplateDetails(_ques)
    setValueChanged(e.target.value + name)
  }

  return (
    <>
      <Header as='h2'>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow} name='arrow left' />
        {templateId ? `Edit Details of ${templateDetails?.name}` : "Add New App URL"}
      </Header>
      {formErrors.length > 0 && !pristine &&
        <Message negative
          //   onDismiss={clearErrors}
          header='We need these details!'
          list={formErrors}
        />
      }
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId &&
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header># Id</List.Header>
                    {templateDetails?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>
        </SectionDiv>
      }

      <SectionDiv>
        <FormRow>
          <FormLabel>
            <LabelHead>Name</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>External URL</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="url"
              defaultValue={templateDetails?.url}
              onChange={(e) => ChangeFormValues(e, "url")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Description</LabelHead>
          </FormLabel>
          <FormInput>
            <Form>
              <Form.TextArea
                placeholder='Describe this api...'
                name="description"
                defaultValue={templateDetails?.description}
                onChange={(e) => ChangeFormValues(e, "description")}
              />
            </Form>
          </FormInput>
        </FormRow>
        {/* sample_response */}
      </SectionDiv>

      <SectionDiv lastSection={true}>
        {templateId &&
          <>
            <Button floated='left'
              color="red"
              onClick={() => setConfirmOpen(true)}>
              Delete
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={DeleteQuestion}
            />
          </>}

        <Button floated='right'
          color="green"
          onClick={UpdateQuestions}>
          {templateId ? "Update" : "Create"}
        </Button>
      </SectionDiv>
      <SectionDiv>

      </SectionDiv>

    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`
const SectionCell = styled.div`
    padding: 10px 10px;
    min-width: 350px;
    display: flex;
    flex-direction: row;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;

`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
