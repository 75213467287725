import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_WEB_API_CREDENTIALS, 
    SG_GET_ORGANIZATION_SELECT,
    UPDATE_WEB_API_CREDENTIAL_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({hideOrgSelect, orgId}) => {

    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        if (orgId) {
            setOrganizationId(orgId)
        }
    }, [orgId]);

    const [searchTerm] = useState('')

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_ORGANIZATION_SELECT,
        });
    }, [dispatch]);

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_WEB_API_CREDENTIALS,
            payload: `page=${page}${organizationId?`&organization=${organizationId}`:''}${searchTerm?`&search=${searchTerm}` : ''}`,
        });
        setLoading(true)
    }, [dispatch, page, searchTerm, organizationId,
        resetPageLoad]);

    const { 
        get_web_api_creds,
        get_selectedOrg } = useSelector(
        (state) => ({
        
            get_web_api_creds: state.web_api_credential,
            get_selectedOrg: state.selectedOrg,
        }),
        shallowEqual
    );

    useEffect(() => {
		setOrganizationId(get_selectedOrg?.organization?.id)
		// setResetPageLoad(!resetPageLoad)
	}, [get_selectedOrg]);



    useEffect(() => {
        setLoading(get_web_api_creds?.pending)
        setResults(get_web_api_creds?.web_api_credential?.results);
        setPage(get_web_api_creds?.pageNumber)
        setTotalPages(Math.ceil(get_web_api_creds?.web_api_credential?.count / get_web_api_creds?.web_api_credential?.page_size)) 
        if (get_web_api_creds?.web_api_credential?.count % get_web_api_creds?.web_api_credential?.page_size) {
            setTotalPages(Math.ceil(get_web_api_creds?.web_api_credential?.count / get_web_api_creds?.employees?.page_size))   
        }
        
    }, [get_web_api_creds, page]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_WEB_API_CREDENTIAL_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    // useEffect(() => {
    //     // 
    //     const timer1 = setTimeout(() => {
    //         setLoading(false)
    //     }, 2000);
    //     return () => clearTimeout(timer1);
    // }, [loading]);

    return (

        <Container>
            
            <SectionDiv>
                {/* <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm} /> */}

            </SectionDiv>
            {!hideOrgSelect &&
            <OrganizationSelect
				setResetPageLoad={setResetPageLoad}  />
            }
            

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && 
                    <RowCard 
                        rows={results} 
                    />
                    }
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`