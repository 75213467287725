import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_TASK_TEMPLATE,
  SG_GET_ORGANIZATION_SELECT,
  UPDATE_TASK_TEMPLATE_PAGE_NUMBER,
  RESET_TASK_TEMPLATE,
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";

import OrganizationSelect from "organizations/OrganizationSelect";

const List = ({ hideOrgSelect }) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(false);
  const [resetPageLoad, setResetPageLoad] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [valueNames, setValueNames] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);


  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_TASK_TEMPLATE,
      payload: `page=${page}${
        organizationId ? `&organization=${organizationId}` : ""
      }${searchTerm ? `&search=${searchTerm}` : ""}`,
    });
  }, [dispatch, page, searchTerm, organizationId, resetPageLoad]);

  const { get_task_template, get_selectedOrg } = useSelector(
    (state) => ({
      get_task_template: state.task_template,
      get_selectedOrg: state.selectedOrg,
      // web_api_cred_list
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationId(get_selectedOrg?.organization?.id);
    // setResetPageLoad(!resetPageLoad)
  }, [get_selectedOrg]);

  // console.log(get_task_template?.task_template);

  useEffect(() => {
    setResults(get_task_template?.task_template?.results);
    setPage(get_task_template?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_task_template?.task_template?.count /
          get_task_template?.task_template?.page_size
      )
    );
    if (
      get_task_template?.task_template?.count %
      get_task_template?.task_template?.page_size
    ) {
      setTotalPages(
        Math.ceil(
          get_task_template?.task_template?.count /
            get_task_template?.task_template?.page_size
        )
      );
    }
    setLoading(get_task_template?.pending);
  }, [get_task_template, page]);

  function handlePageChange(event, value) {
    dispatch({
      type: RESET_TASK_TEMPLATE,
    });
    setPage(value.activePage);
    dispatch({
      type: UPDATE_TASK_TEMPLATE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }


  return (
    <Container>
      {!hideOrgSelect && (
        <OrganizationSelect setResetPageLoad={setResetPageLoad} />
      )}

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results && <RowCard rows={results} valueNames={valueNames} />}
        </Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
