import {
  GET_DEBRIEF_RESPONSES,
  DELETE_DEBRIEF_RESPONSE,
  DEBRIEF_RESPONSE_PAGE_NUMBER,
  UPDATE_DEBRIEF_RESPONSE,
  FETCH_DEBRIEF_RESPONSE,
  EDIT_DEBRIEF_RESPONSE,
  ADD_DEBRIEF_RESPONSE,
  CLEAR_UPDATE_DEBRIEF_RESPONSE,
  CLEAR_DEBRIEF_RESPONSE,
  CLEAR_ALL,

} from "constants/actions";

const initialState = {
  debrief_response: [],
  pending: false,
  response: null,
  created: [],
  updated: [],
  deleted: null,
  pageNumber: 1,
};

export default function DebriefResponse(state = initialState, action) {
  switch (action.type) {

    case GET_DEBRIEF_RESPONSES:
      return {
        ...state,
        debrief_response: action.payload,
        pending: false,
      };
    case CLEAR_UPDATE_DEBRIEF_RESPONSE:
      return {
        ...state,
        updated: [],
        pending: false,
      };
    case DEBRIEF_RESPONSE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      }
    case FETCH_DEBRIEF_RESPONSE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false
      };

    case UPDATE_DEBRIEF_RESPONSE:
      return {
        ...state,
        updated: action.payload,
        pending: false
      };

    case EDIT_DEBRIEF_RESPONSE:
      return {
        ...state,
        debrief_response: [...state.debrief_response, action.payload],
      };

    case DELETE_DEBRIEF_RESPONSE:

      return {
        ...state,
        deleted:  action.payload.id,
      };

    case ADD_DEBRIEF_RESPONSE:
      return {
        ...state,
        created: action.payload,
        debrief_response: [...state.debrief_response, action.payload],
        response: action.payload.response,
      };

    case CLEAR_DEBRIEF_RESPONSE:
      return {
        ...state,
        ...initialState
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}
