import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Toolbar = ({ searchTerm, setSearchTerm, setPage }) => {
  const [inputText, setInputText] = useState(searchTerm);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.nextSibling.click();
      setSearchTerm(e.target.value);
    }
  };

  useEffect(() => {
    setInputText(searchTerm);
  }, [searchTerm]);

  function startSearch() {
    setSearchTerm(inputText);
    setPage(1)
  }

  return (
    <Container>
      <StyledInput
        label="Search"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Search..."
        onKeyPress={handleKeyPress}
      />
      <StyledButton onClick={startSearch}>Search</StyledButton>
    </Container>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;

const Container = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between; /* Adjust the alignment of input and button */
`;

const StyledInput = styled.input`
  flex: 1; /* Take up remaining space */
  border: 1px solid lightgray;
  padding: 5px;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: highlight;
  }
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
`;
