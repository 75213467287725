import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_DOCUMENTATION,
    SG_GET_DOCUMENTATION,
    GET_DOCUMENTATION_PENDING,
    ALL_ERRORS,
  } from "constants/actions";
  

  function* loadmethods(action) {
    yield put({ type: GET_DOCUMENTATION_PENDING });

  try {
    const json = yield call(api.GET_DOCUMENTATION, action.payload);
    yield put({ type: GET_DOCUMENTATION, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_DOCUMENTATION, loadmethods);
}

export default function* index() {
    yield all([
      methodLoad(),
    ]);
  }
  