import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_PROD_ORG_CONTRACT, RESET_PROD_ORG_CONTRACT } from "constants/actions";
import { Button, Header, Icon } from "semantic-ui-react";
import { format } from "date-fns";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);

  useEffect(() => {
    setTemplateId(id);
    dispatch({ type: RESET_PROD_ORG_CONTRACT });
    if (id) {
      dispatch({ type: SG_FETCH_PROD_ORG_CONTRACT, payload: { id } });
    }
  }, [dispatch, id]);

  const { get_prod_org_contract } = useSelector(
    (state) => ({ get_prod_org_contract: state.prod_org_contract }),
    shallowEqual
  );

  useEffect(() => {
    if (get_prod_org_contract && templateId) {
      setTemplateDetails(get_prod_org_contract[templateId]);
    }
  }, [get_prod_org_contract, templateId]);

  const BackArrow = () => navigate(`/app/predictive-model`);

 
  return (
    <>
      <Header as="h2">
        <StyledIcon name="arrow left" onClick={BackArrow} />
        Details of Contract
      </Header>
      <DetailRow label="Created">
        {templateDetails?.created_at &&
          format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Organization">{templateDetails?.organization} - {templateDetails?.organization_name}</DetailRow>
      
      
      <DetailRow label="Price">{templateDetails?.price} - {templateDetails?.price_name}</DetailRow>

      <DetailRow label="Start Date">
        {templateDetails?.start_date &&
          format(new Date(templateDetails?.start_date), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>

      <DetailRow label="End Date">
        {templateDetails?.end_date &&
          format(new Date(templateDetails?.end_date), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>

      <DetailRow label="Last Updated">
        {templateDetails?.updated_at &&
          format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Package">{templateDetails?.product_package_id} - {templateDetails?.product_package?.name} [{templateDetails?.product_package?.sort_order}]</DetailRow>
      {templateDetails?.product_package?.product_list &&
        Object.values(templateDetails.product_package.product_list).map((product, index) => (
          <SubBlock key={product.id}>
            <DetailRow label="Name">{product.name}</DetailRow>
            <DetailRow label="Version">{product.version}</DetailRow>
            <DetailRow label="Sort Order">{product.sort_order}</DetailRow>
            <DetailRow label="Stage">{product.product_stage}</DetailRow>
          </SubBlock>
        ))}
    </>
  );
};

const DetailRow = ({ label, children }) => (
  <RowContainer>
    <LabelCell>{label}:</LabelCell>
    <ValueCell>{children}</ValueCell>
  </RowContainer>
);

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection ? "100px" : "20px")};
`;

const SubBlock = styled.div`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const LabelCell = styled.div`
  font-weight: bold;
  width: 150px;
`;

const ValueCell = styled.div`
  flex: 1;
`;
