import React from "react";
import styled from "styled-components";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  // convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { Button, TextArea } from 'semantic-ui-react'

function EditorConvertToHTML(props) {

  const [switchHtml, setSwitchHtml] = React.useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [editorHtml, setEditorHtml] = React.useState("");

  React.useEffect(() => {
    if (props.editContent) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(props.editContent))
        )
      );
    }
  }, [props]);

  React.useEffect(() => {
    if (editorState) {
      setEditorHtml(stateToHTML(editorState.getCurrentContent()));
    }
  }, [editorState]);

  // console.log(editorHtml)

  return (
    <>
      <Button
        color="primary"
        size="large"
        type="submit"
        onClick={() => setSwitchHtml(!switchHtml)}
      >
        {switchHtml ? "Show HTML Editor" : " Show Html Code"}
      </Button>

      {switchHtml ? (
        <SectionDiv>
          <SectionHeader>
            {props.heading} Edit in HTML (dangerous)
          </SectionHeader>

          <TextArea
            fullWidth
            name="heading"
            style={{ minHeight: 300, width: "100%" }}
            rows={3}
            onChange={(e) => setEditorHtml(e.target.value)}
            defaultValue={editorHtml}
          />

          <SectionButton>
            <Button
              color="red"
              size="large"
              type="submit"
              onClick={() =>
                props.BtnCallBack(
                  editorHtml
                )
              }
            >
              Update
            </Button>
          </SectionButton>

        </SectionDiv>
      ) : (
        <SectionDiv>
          <SectionHeader>{props.heading}</SectionHeader>

          <Editor
            editorState={editorState}
            editorStyle={{ minHeight: "300px", border: "1px solid #C0C0C0" }}
            // wrapperClassName="demo-wrapper"
            // editorClassName="demo-editor"
            onEditorStateChange={setEditorState}
          />


          <SectionButton>
            <Button
              color="green"
              size="large"
              type="submit"
              onClick={() =>
                props.BtnCallBack(
                  stateToHTML(editorState.getCurrentContent())
                )
              }
            >
              Update
            </Button>
          </SectionButton>

        </SectionDiv>
      )}
    </>
  );
}

export default EditorConvertToHTML;


const SectionDiv = styled.div`
  padding-top: 10px;
  margin-top:10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`
const SectionHeader = styled.h3`
  font-size: 110%
`

const SectionButton = styled.div`
  padding: 0;
  margin-top: 10px;
  list-style: none;
  display: flex;
  justify-content: flex-end; 
`