import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { APP_UPDATE_TYPE } from "data/AdminData";
import { FETCH_GLOBAL_SCHEDULE } from "constants/actions";

function JsonTable({ rowData, setSelectedJsonRow = FETCH_GLOBAL_SCHEDULE }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(rowData || []);
  }, [rowData]);

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader>ID</TableHeader>
            <TableHeader>Make</TableHeader>
            <TableHeader>Next Steps</TableHeader>
            <TableHeader></TableHeader>
            {/* {!viewOnly && <TableHeader>Actions</TableHeader>} */}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.make}</TableCell>
                <TableCell>{row.next_steps}</TableCell>

                <TableCell>
                  <Button primary onClick={() => setSelectedJsonRow(row)}>
                    View
                  </Button>
                </TableCell>

                {/* {!viewOnly && <TableCell>Delete</TableCell>} */}
              </TableRow>
            ))}

          {/* Add more rows as needed */}
        </tbody>
      </Table>
    </TableContainer>
  );
}

export default JsonTable;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
`;

const TableHeader = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
`;
