import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_PRODUCT_GROUP_PENDING,
  GET_PRODUCT_GROUP,
  SG_GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUP_SELECT,
  SG_GET_PRODUCT_GROUP_SELECT,
  DELETE_PRODUCT_GROUP,
  SG_DELETE_PRODUCT_GROUP,
  FETCH_PRODUCT_GROUP,
  SG_FETCH_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
  SG_EDIT_PRODUCT_GROUP,
  ADD_PRODUCT_GROUP,
  SG_ADD_PRODUCT_GROUP,
  ALL_ERRORS,
} from "constants/actions";

function* loadprocess(action) {
  yield put({ type: GET_PRODUCT_GROUP_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PRODUCT_GROUP, action.payload);
    yield put({ type: GET_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processLoad() {
  yield takeLatest(SG_GET_PRODUCT_GROUP, loadprocess);
}

function* selectprocess(action) {
  yield put({ type: GET_PRODUCT_GROUP_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PRODUCT_GROUP_SELECT, action.payload);
    yield put({ type: GET_PRODUCT_GROUP_SELECT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processSelect() {
  yield takeLatest(SG_GET_PRODUCT_GROUP_SELECT, selectprocess);
}

function* fetchprocess(action) {
  try {
    const json = yield call(api.FETCH_PRODUCT_GROUP, action.payload);
    yield put({ type: FETCH_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processFetch() {
  yield takeLatest(SG_FETCH_PRODUCT_GROUP, fetchprocess);
}

function* updateprocess(action) {
  try {
    const json = yield call(api.EDIT_PRODUCT_GROUP, action.payload);
    yield put({ type: EDIT_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processUpdate() {
  yield takeLatest(SG_EDIT_PRODUCT_GROUP, updateprocess);
}

function* addprocess(action) {
  try {
    const json = yield call(api.ADD_PRODUCT_GROUP, action.payload);
    yield put({ type: ADD_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processAdd() {
  yield takeLatest(SG_ADD_PRODUCT_GROUP, addprocess);
}

function* deleteprocess(action) {
  try {
    const json = yield call(api.DELETE_PRODUCT_GROUP, action.payload);
    yield put({ type: DELETE_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processDelete() {
  yield takeLatest(SG_DELETE_PRODUCT_GROUP, deleteprocess);
}

export default function* index() {
  yield all([
    processLoad(),
    processSelect(),
    processFetch(),
    processUpdate(),
    processAdd(),
    processDelete(),
  ]);
}
