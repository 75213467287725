import React from "react";
import PropTypes from "prop-types";


import { format } from 'date-fns'

import { Link as RouterLink } from "react-router-dom";

import { Card, Image } from 'semantic-ui-react'

const BlogCard = ({ blog }) => {

  return (
    <RouterLink to={`/app/blogs/edit/${blog?.id}`}>
      <Card>
        <Image src={blog?.image} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{blog?.heading}</Card.Header>
          <Card.Meta>
            <span className='date'>
              {blog?.meta_description}
            </span>
          </Card.Meta>
          <Card.Description>
            {blog?.meta_description}
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Image
            floated='right'
            size='mini'
            // alt={blog?.employee_full_name}
            src={blog?.employee_picture}
          // src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
          />
          {blog?.status}
          <Card.Header>{blog?.employee?.full_name}</Card.Header>
          <Card.Meta>Some name</Card.Meta>
          <Card.Description>
            {blog?.employee_notes}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {blog?.status_text}
          {blog?.publish_date && format(new Date(blog?.publish_date), 'dd-MMM-yyyy')}
          {/* {bgcolor=`${d2 < d1 ? "success.main" : "info.main"}`  */}
          {/* {bgcolor={blog.status === 2  */} 
        </Card.Content>

      </Card>
    </RouterLink>
  );
};

BlogCard.propTypes = {
  blog: PropTypes.object.isRequired,
};

export default BlogCard;
