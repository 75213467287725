import {
    UPDATE_APP_SERVER_PAGE_NUMBER,
    RESET_APP_SERVER,
    APP_SERVER_PENDING,
    GET_APP_SERVERS,
    DELETE_APP_SERVER,
    FETCH_APP_SERVER,
    EDIT_APP_SERVER,
    ADD_APP_SERVER,
    CLEAR_APP_SERVER,
    CLEAR_APP_SERVER_UPDATE,
    GET_APP_SERVERS_MAKE_COMMANDS,
    POST_APP_SERVERS_MAKE_BUILD,
    CLEAR_ALL,
    } from "constants/actions";
  
  const initialState = {
    app_server_list: [],
    pageNumber: 1,
    pending: false,
    changed: false,
    deleted: false,
    make_commands : [],
    make_build : false,

  };
  
  export default function AppUrlList(state = initialState, action) {
    switch (action.type) {
      case UPDATE_APP_SERVER_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
      case GET_APP_SERVERS_MAKE_COMMANDS:
        return {
          ...state,
          make_commands: action.payload,
          pending: false,
        }
      case POST_APP_SERVERS_MAKE_BUILD:
        return {
          ...state,
          make_build: action.payload,
          changed: action.payload,
          pending: false,
        }
      case RESET_APP_SERVER:
        return {
          ...state,
          changed: false,
          make_build: false,
        }
      case APP_SERVER_PENDING: {
        return {
          ...state,
          pending: true
        }
      }
      case GET_APP_SERVERS:
        return {
          ...state,
          app_server_list: action.payload,
          pending: false,
        };
      case FETCH_APP_SERVER:
        return { 
          ...state, 
          [action.payload.id]: action.payload, 
          pending: false
         };
      case EDIT_APP_SERVER:
        return {
          ...state,
          [action.payload.id]: action.payload,
          changed: action.payload,
          pending: false,
        };
      case DELETE_APP_SERVER:
        return {
          ...state,
          changed: action.payload,
          pending: false,
        };
  
      case ADD_APP_SERVER:
        return {
          ...state,
          changed: action.payload,
          pending: false,
        };
     case CLEAR_APP_SERVER_UPDATE:
      return {
        ...state,
        changed: false,
        created: false,
        updated: false,
        deleted: false,
        make_build: false,
      }
      case CLEAR_APP_SERVER:
        return {
          ...state,
          ...initialState
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState
        };
  
      default:
        return state;
    }
  }
  