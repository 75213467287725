import {
  GET_DOCUMENTATION_PENDING,
  GET_DOCUMENTATION,
  CLEAR_DOCUMENTATION,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  documentation: [],
  pending: false,
  pageNumber: 1,
};

export default function Documentation(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTATION_PENDING:
      return {
        ...state,
        pending: true,
        updated: false,
      };
    case GET_DOCUMENTATION:
      return {
        ...state,
        documentation: action.payload,
        pending: false,
        updated: false,
      };

    case CLEAR_DOCUMENTATION:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
