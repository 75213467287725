export const serverApi = 'https://api.innerlogic.com'
// export const staginApi = 'https://stg-api.innerlogic.ca'
export const staginApi = 'https://django-api-staging.innerlogic.ca'
export const localApi = 'http://127.0.0.1:8000'

export const GOOGLE_CLIENT_ID='444691808577-2ok712r0f1umjrgl5ndo0s3rt08p7lii.apps.googleusercontent.com'
export const GOOGLE_SCOPE = "https://www.googleapis.com/auth/userinfo.email"

// go auth login 
export const goAuthApiServer = 'https://auth.innerlogic.com'
export const goAuthApiStaging = 'https://stg-auth-api.innerlogic.ca'
export const goAuthApiLocal = 'http://0.0.0.0:8080'

// PRocess URL
export const processApiServer = 'https://ml.innerlogic.ca'
export const processApiStaging = 'https://stg-chat-process.innerlogic.ca'
export const processApiLocal = 'http://127.0.0.1:9000'

// Devops url
export const devopsApi = 'https://devops-api.innerlogic.ca'
export const devopsStagingApi = 'https://stg-devops-api.innerlogic.ca'
export const devopsLocal = 'http://127.0.0.1:8000'
//
export const serverUrl = "https://stg-admin.innerlogic.com"
//
export const Domain = ''

// Publishable key
export const Environment = 'Development'
export const SentryDSN_DEV = "" 
// export const SentryDSN_PROD = "https://53de57189a184c5db40d1d7ea9394a96@o1167548.ingest.sentry.io/6258716"
// export const SentryDSN_STG = "https://40343aed15d841c6b882b632552007ee@o1167548.ingest.sentry.io/6258777"

export const CreateDemoResponses = true 

export const StripeKey = 'pk_test_bowWtykmtAwZxvD0bvdXEycG00LczLxeDB'
export const currentApi = staginApi
export const curGoApi = goAuthApiStaging
export const curProcessUrl = processApiStaging
const curDevopsApi = devopsStagingApi

export const config = {
    	// switch to go for login
    	loginapi: `${curGoApi}`,
    	// use django api for login changed Oct 3 2022
    	// loginapi: `${currentApi}/api/account`,
	devopsApi: `${curDevopsApi}`,
	portalapi: `${currentApi}/api/organization`,
    	appapi: `${currentApi}/api/support`,
    	clearCache: `${currentApi}/clear-cache/`,
    	aiProcessUrl: `${curProcessUrl}`,
};

export default config

