import {
  UPDATE_PRODUCT_GROUP_PAGE_NUMBER,
  GET_PRODUCT_GROUP_PENDING,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUP_SELECT,
  DELETE_PRODUCT_GROUP,
  FETCH_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
  ADD_PRODUCT_GROUP,
  CLEAR_PRODUCT_GROUP,
  RESET_PRODUCT_GROUP,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  product_group: [],
  select_list: [],
  pending: false,
  pageNumber: 1,
  deleted: false,
  updated: false,
  created: false,
};

export default function ProductGroup(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_GROUP_PENDING:
      return {
        ...state,
        pending: true,
      };
      
    case UPDATE_PRODUCT_GROUP_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };

    case GET_PRODUCT_GROUP:
      return {
        ...state,
        product_group: action.payload,
        pending: false,
      };

    case GET_PRODUCT_GROUP_SELECT:
      return {
        ...state,
        select_list: action.payload,
        pending: false,
      };

    case FETCH_PRODUCT_GROUP:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_PRODUCT_GROUP:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case DELETE_PRODUCT_GROUP:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case ADD_PRODUCT_GROUP:
      return {
        ...state,
        updated: action.payload,
        pending: false,
      };

    case RESET_PRODUCT_GROUP:
      return {
        ...state,
        deleted: false,
        updated: false,
        created: false,
        pending: false,
      };
    case CLEAR_PRODUCT_GROUP:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
