import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Button, Message } from "semantic-ui-react";

import { format, set } from "date-fns";

import { BasicErrorMessage } from "utilities/ErrorMessages";

import {
  SG_GET_ORGANIZATION_SELECT,
//   SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST,
//   SG_FETCH_ORGANIZATION_PRIMARY,
  SG_GET_REPORT_TEMPLATE_SELECT,
  SG_GET_RECOMMENDATION_MODELS,
  SG_GET_EMPLOYEE_SELECT_LIST,
} from "constants/actions";

// status 1 Run Now
// status 9 Schedule

const SearchListComponent = ({ searchList, setSearchList, setPage }) => {
  const dispatch = useDispatch();

  const [template, setTemplate] = useState({})

  const [organizationId, setOrganizationId] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState([]);

  const [reportTemplate, setReportTemplate] = useState([]);
  const [reportTemplateSelected, setReportTemplateSelected] = useState("");

  const [recommendationModels, setRecommendationModels] = useState([]);
  const [recommendationModelSelected, setRecommendationModelSelected] =
    useState("");

  const [organizationPrimary, setOrganizationPrimary] = useState([]);
  const [organizationPrimarySelected, setOrganizationPrimarySelected] =
    useState("");

  const [debriefSurveyList, setDebriefSurveyList] = useState([]);
  const [debriefSurveySelected, setDebriefSurveySelected] = useState("");

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [categoryListSelected, setCategoryListSelected] = useState([]);

  const [categoryOption, setCategoryOption] = useState([]);
  const [categoryOptionSelected, setCategoryOptionSelected] = useState([]);

  const [testOutput, setTestOutput] = useState(false);

  const [employeeAddedList, setEmployeeAddedList] = useState([]);

  const {
    get_organization,
    get_debrief_schedule,
    get_recommendation_models,
    get_report_template,
    get_employee_select_list,
  } = useSelector(
    (state) => ({
      get_organization: state.organizations,
      get_debrief_schedule: state.debrief_schedule,
      get_recommendation_models: state.recommendation_models,
      get_report_template: state.report_template,
      get_employee_select_list: state.employees.employee_select_list,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (searchList?.organization) {
      setOrganizationId(searchList?.organization);
    }
  }, [searchList]);

  useEffect(() => {
    if (organizationSelected?.id) {
      setOrganizationId(organizationSelected?.id);
    }
  }, [organizationSelected]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
    dispatch({
      type: SG_GET_RECOMMENDATION_MODELS,
    });
    dispatch({
      type: SG_GET_REPORT_TEMPLATE_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_EMPLOYEE_SELECT_LIST,
        payload: `organization=${organizationId}`,
      });
    //   dispatch({
    //     type: SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST,
    //     payload: `organization=${organizationId}`,
    //   });
    //   dispatch({
    //     type: SG_FETCH_ORGANIZATION_PRIMARY,
    //     payload: { id: organizationId },
    //   });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setOrganizationList(get_organization?.organization_select);
  }, [dispatch, get_organization]);

  useEffect(() => {
    if (get_organization?.primary_select?.response) {
      setOrganizationPrimary(
        get_organization?.primary_select?.response?.filter(
          (item) => item?.priority === "primary"
        )
      );
      setCategoryList(
        get_organization?.primary_select?.response
          ?.filter((item) => item?.priority !== "primary")
          ?.map((item) => ({ name: item?.name, id: item?.id }))
      );
    }
  }, [dispatch, get_organization]);

  useEffect(() => {
    if (
      get_organization?.primary_select?.response &&
      categoryListSelected?.id
    ) {
      setCategoryOption(
        get_organization?.primary_select?.response
          ?.filter((item) => item?.id === categoryListSelected?.id)
          ?.map((item) => item?.options)[0]
      );
    }
  }, [dispatch, get_organization, categoryListSelected]);

  useEffect(() => {
    setEmployeeList(get_employee_select_list);
  }, [get_employee_select_list]);

  useEffect(() => {
    setRecommendationModels(
      get_recommendation_models?.recommendation_models?.response
    );
  }, [get_recommendation_models]);

  useEffect(() => {
    setReportTemplate(get_report_template?.select);
  }, [get_report_template]);

  useEffect(() => {
    setDebriefSurveyList(get_debrief_schedule?.select_list);
  }, [dispatch, get_debrief_schedule]);

  useEffect(() => {
    if (searchList?.organization) {
      setOrganizationSelected(
        organizationList.filter((o) => o.id === searchList?.organization)
      );
    }
    if (searchList?.external_model) {
      setRecommendationModelSelected(
        recommendationModels.filter(
          (o) => o.folder === searchList?.external_model
        )
      );
    }
    if (searchList?.recommendation_template) {
      setReportTemplateSelected(
        reportTemplate.filter(
          (o) => o.id === searchList?.recommendation_template
        )
      );
    }

    if (searchList?.primary_uid) {
      setOrganizationPrimarySelected(
        organizationPrimary?.[0]?.options?.filter(
          (o) => o.id === searchList?.primary_uid
        )
      );
    }

    if (searchList?.detailed_info?.category_head) {
      setCategoryListSelected(
        categoryList.filter(
          (o) => o.id === searchList?.detailed_info?.category_head
        )?.[0]
      );
      if (searchList?.detailed_info?.category_option) {
        setCategoryOptionSelected(
          categoryOption.filter(
            (o) => o.id === searchList?.detailed_info?.category_option
          )
        );
      }
    }
    if (searchList?.detailed_info?.test_output) {
      setTestOutput(searchList?.detailed_info?.test_output);
    }

    if (searchList?.receipient_list?.length > 0) {
      setEmployeeAddedList(reportTemplate.receipient_list);
    }
  }, [
    searchList,
    organizationList,
    reportTemplate,
    recommendationModels,
    organizationPrimary,
    categoryList,
    categoryOption,
  ]);

  function BasedOneEmployee(object) {
    setEmployeeSelected(object);

    setDebriefSurveySelected("");
    setOrganizationPrimarySelected("");
  }

  function SearchResults() {
    const _templateData = {
      organization: organizationId,
      recommendation_template: reportTemplateSelected?.id,
      external_model: recommendationModelSelected?.folder,
      employee_id: employeeSelected?.id,
      test_output: testOutput,
    };
    let result_string = Object.entries(_templateData)
    .filter(([key, value]) => value !== null && value !== undefined && value !== '' && value !== false && value !== 0)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

    setTemplate(_templateData)
    setSearchList(result_string);
    setPage(1)
  }


  return (
    <Container>
      <ColoredContainer>
        <LargeLabel>Search</LargeLabel>
        <Row>
          <LeftColumn>
            <Label htmlFor="organization">Organization:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="organization"
              options={organizationList}
              isClearable
              onChange={setOrganizationSelected}
              value={organizationSelected}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="reportTemplate">Template:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="reportTemplate"
              options={reportTemplate}
              isClearable
              onChange={setReportTemplateSelected}
              value={reportTemplateSelected}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </RightColumn>
        </Row>
        <Row>
          <LeftColumn>
            <Label htmlFor="model">Model:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="model"
              options={recommendationModels}
              isClearable
              onChange={setRecommendationModelSelected}
              value={recommendationModelSelected}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.folder}
            />
          </RightColumn>
        </Row>
        {/* <Row>
          <LeftColumn>Show Test Output:</LeftColumn>
          <RightColumn>
            <input
              type="checkbox"
              checked={testOutput}
              onChange={(e) => setTestOutput(e.target.checked)}
            />{" "}
            <span>Show the results would be used only for testing</span>
          </RightColumn>
        </Row> */}

        {/* <Row>
          <LeftColumn>
            <Label htmlFor="primary_uid">Survey Primary:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="primary_uid"
              options={organizationPrimary}
              isClearable
              onChange={(e) => AnySurveyWithPrimary(e)}
              value={organizationPrimarySelected}
              getOptionLabel={(option) => `${option.name} - ${option.id}`}
              getOptionValue={(option) => option.id}
            />
          </RightColumn>
        </Row> */}

        {/* <Row>
            <LeftColumn>
              <Label htmlFor="debrief_schedule">Specific Survey:</Label>
            </LeftColumn>
            <RightColumn>
              <Select
                name="debrief_schedule"
                options={debriefSurveyList}
                isClearable
                onChange={(e) => SpecificSurvey(e)}
                value={debriefSurveySelected}
                getOptionLabel={(option) =>
                  `${option.id}-${option.name}-${
                    option?.status_text
                  }-#-${format(
                    new Date(option?.close_at),
                    "dd-MMM-yyyy hh:mm a"
                  )}`
                }
                getOptionValue={(option) => option.id}
              />
            </RightColumn>
          </Row> */}

        <Row>
          <LeftColumn>
            <Label htmlFor="primary_uid">Employee:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="employeeList"
              options={employeeList}
              isClearable
              onChange={(e) => BasedOneEmployee(e)}
              getOptionLabel={(option) =>
                `${option?.first_name} ${option?.last_name} - ${option?.email}`
              }
              getOptionValue={(option) => `${option?.id}`}
              value={employeeSelected}
            />
          </RightColumn>
        </Row>
        <SectionDiv>
          <Button floated="right" color="primary" onClick={SearchResults}>
            Search
          </Button>
        </SectionDiv>
      </ColoredContainer>
    </Container>
  );
};

export default SearchListComponent;

function OrSeperator({ TextValue }) {
  return (
    <OrSeperatorContainer>
      <VerticalLine />
      <OrText>{TextValue}</OrText>
      <VerticalLine />
    </OrSeperatorContainer>
  );
}

const Container = styled.div`
  /* Add your custom styles here */
`;

const LargeLabel = styled.label`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
const ColoredContainer = styled.div`
  /* Add your custom styles here */
  border: 1px solid #ff0000; /* Red border */
  padding: 30px 10px;
  margin: 30px 0px;
`;

const LeftColumn = styled.div`
  flex: 1;
  text-align: left;
`;

const RightColumn = styled.div`
  flex: 4;
`;

const RightAlignedColumn = styled.div`
  justify-content: flex-end;
`;

const Label = styled.label`
  font-size: 16px;
`;

const OrSeperatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;

const OrText = styled.p`
  margin: 0 10px;
  font-weight: bold;
`;

const StyledButton = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;
