import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SEARCH_SURVEY_STRUCTURE,
  CLEAR_SURVEY_STRUCTURE,
  CLEAR_AM,
} from "constants/actions";
import List from "./List/Index.js";
import Details from "./Detail/Index.js";
import { Header, Icon, Button } from "semantic-ui-react";

function CategoryStructure() {
  const [orgName, setOrgName] = useState(false);
  
  return (
    <>
      {ShowArrow(orgName)}
      <Routes>
        <Route path="/" element={<List 
        setOrgName={setOrgName} />} />
        <Route
          path="details/:id"
          element={<Details setOrgName={setOrgName} />}
        />
        <Route path="create" element={<Details setOrgName={setOrgName} />} />
      </Routes>
    </>
  );
}

export default CategoryStructure;

function ShowArrow(orgName) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchBox, setSearchBox] = useState("");

  const { get_survey_structure_search } = useSelector(
    (state) => ({
      get_survey_structure_search: state.surveystructure,
    }),
    shallowEqual
  );
  useEffect(() => {
    setSearchBox(get_survey_structure_search?.search);
  }, [get_survey_structure_search]);

  function ClearSearch() {
    dispatch({
      type: SEARCH_SURVEY_STRUCTURE,
      payload: "",
    });
    // navigate("/app/categories/");
  }

  function ClearAll() {
    dispatch({
      type: CLEAR_SURVEY_STRUCTURE,
    });
    dispatch({
      type: CLEAR_AM,
    });
  }

  function BackArrow() {
    navigate("/app/categories/");
  }
  if (orgName) {
    return (
      <Header as="h2" style={{ marginTop: "20px" }}>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Category Structure of {orgName} <ButtonContainer>
      </ButtonContainer>
        <Header.Subheader>
          Manage Category Structure for Survey.
        </Header.Subheader>
      </Header>
    );
  }
  return (
    <Header as="h2" style={{ marginTop: "20px" }}>
      Category Structure
      <ButtonContainer>
        <Button color="violet" onClick={ClearAll}>Clear All</Button>
        {searchBox && <Button color="orange" onClick={ClearSearch}>Clear Search</Button>}
      </ButtonContainer>
      <Header.Subheader>Manage Category Structure for Survey.</Header.Subheader>
    </Header>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
