import React, { useState } from 'react';
import styled from "styled-components";
import { Popup, Icon } from 'semantic-ui-react'

export const ClickToCopy = ({ textToCopy, hideTxt=false }) => {
    const [copied, setCopied] = useState('click to copy')
    function ClickToClip() {
        navigator.clipboard.writeText(textToCopy)
        setCopied('copied')
    }

    return (
        <>
            <Popup content={copied} trigger={<Container onClick={ClickToClip}>
                {hideTxt ? '*'.repeat(textToCopy.length) : ''}
                <Icon  color={copied === "copied"? 'green' : 'grey'}
                 name={copied === "copied"? 'check' : 'copy outline'} />
                 {!hideTxt && textToCopy}
            </Container>} />
        </>

    );
};

export default ClickToCopy;

const Container = styled.span`
padding: 0 5px;
font-style: italic;
font-weight: thinner;
cursor: pointer;
`