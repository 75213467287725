import React from "react";
import { Routes, Route } from "react-router-dom";

// import { useNavigate, useLocation } from "react-router-dom";

import List from "./List";
// import AddEdit from './AddEdit';
import Details from "./AddEdit";
import { Header, Grid } from "semantic-ui-react";

function MarketingRoi() {
  // const location = useLocation()
  // const navigate = useNavigate()

  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2" style={{ marginTop: "50px" }}>
            Activity Log
            <Header.Subheader>Background Task Details</Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default MarketingRoi;
