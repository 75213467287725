import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_PRODUCT_PRICE, RESET_PRODUCT_PRICE } from "constants/actions";
import { Button, Header, Icon } from "semantic-ui-react";
import { format } from "date-fns";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);

  useEffect(() => {
    setTemplateId(id);
    dispatch({ type: RESET_PRODUCT_PRICE });
    if (id) {
      dispatch({ type: SG_FETCH_PRODUCT_PRICE, payload: { id } });
    }
  }, [dispatch, id]);

  const { get_product_price } = useSelector(
    (state) => ({ get_product_price: state.product_price }),
    shallowEqual
  );

  useEffect(() => {
    if (get_product_price && templateId) {
      setTemplateDetails(get_product_price[templateId]);
    }
  }, [get_product_price, templateId]);

  const BackArrow = () => navigate(`/app/product`);
console.log("templateDetails", templateDetails);
  return (
    <>
      <Header as="h2">
        <StyledIcon name="arrow left" onClick={BackArrow} />
        Details of {templateDetails?.name}
      </Header>
      <DetailRow label="Created">
        {templateDetails?.created_at &&
          format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Name">{templateDetails?.name}</DetailRow>
      <DetailRow label="Amount">{templateDetails?.amount}</DetailRow>
      <DetailRow label="Currency">{templateDetails?.currency}</DetailRow>
      <DetailRow label="Frequency">{templateDetails?.frequency}</DetailRow>
      <DetailRow label="Interval">{templateDetails?.interval}</DetailRow>
      <DetailRow label="Usage">{templateDetails?.usage_type}</DetailRow>
      <DetailRow label="Active">{templateDetails?.active? "True": "False"}</DetailRow>
      <DetailRow label="Last Updated">
        {templateDetails?.updated_at &&
          format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
    </>
  );
};

const DetailRow = ({ label, children }) => (
  <RowContainer>
    <LabelCell>{label}:</LabelCell>
    <ValueCell>{children}</ValueCell>
  </RowContainer>
);

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection ? "100px" : "20px")};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const LabelCell = styled.div`
  font-weight: bold;
  width: 150px;
`;

const ValueCell = styled.div`
  flex: 1;
`;
