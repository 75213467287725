import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Pagination,
} from "semantic-ui-react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  UPDATE_PRODUCT_PRICE_PAGE_NUMBER,
  SG_GET_PRODUCT_PRICE,
  RESET_PRODUCT_PRICE,
} from "constants/actions";

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1);

  const { get_product_price } = useSelector(
    (state) => ({
      get_product_price: state.product_price.product_price,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: RESET_PRODUCT_PRICE,
    });
    dispatch({
      type: SG_GET_PRODUCT_PRICE,
    });
  }, [dispatch]);

  useEffect(() => {
    setResults(get_product_price?.results);
    setPage(get_product_price?.pageNumber);
    setTotalPages(
      Math.ceil(get_product_price?.count / get_product_price?.page_size)
    );
    if (get_product_price?.count % get_product_price?.page_size) {
      setTotalPages(
        Math.ceil(get_product_price?.count / get_product_price?.page_size)
      );
    }
  }, [get_product_price, page]);

  function handlePageChange(e, { activePage }) {
    setPage(activePage);
    dispatch({
      type: UPDATE_PRODUCT_PRICE_PAGE_NUMBER,
      payload: activePage,
    });
    dispatch({
      type: SG_GET_PRODUCT_PRICE,
      payload: `page=${activePage}`
    });
  }

  function EditTemplate(templateId) {
    navigate(`/app/product/price/edit/${templateId}`);
  }

  function TemplateDetails(templateId) {
    navigate(`/app/product/price/details/${templateId}`);
  }

  return (
    <>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Amount</th>
              <th>CUR</th>
              <th>Interval</th>
              <th>Stage</th>
            </tr>
          </thead>
          <tbody>
            {results &&
              results.map((product, index) => (
                <tr key={index}>
                  <td>{product.id}</td>
                  <td>{product.name}</td>
                  <td>{product.amount}</td>
                  <td>{product.currency}</td>
                  <td>{product.interval}</td>
                  <td>
                    <Clickable onClick={() => EditTemplate(product?.id)}>
                      Edit{" "}
                    </Clickable>{" "}
                    |
                    <Clickable onClick={() => TemplateDetails(product.id)}>
                      Details{" "}
                    </Clickable>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </TableContainer>

      <Pagination
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        activePage={page}
        totalPages={totalPages ? totalPages : 0}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default List;

const TableContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }
`;

const Clickable = styled.span`
  color: blue;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;
