import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";


import { BasicErrorMessage } from "utilities/ErrorMessages"

import "date-fns";
// import Page from "components/Page";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";

import {
  SG_EDIT_BLOG,
  SG_ADD_BLOG,
  SG_FETCH_BLOG,
  SG_GET_BLOG_CATEGORIES,
  LOAD_EMPLOYEES,
  SG_GET_BLOG_TAGS,
  SG_ADD_BLOG_TAG,
} from "constants/actions";

import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import "react-datepicker/dist/react-datepicker.css";

import EditorConvertToHTML from "Draftjs/EditorConvertToHTML";
import { Icon, Message, Button, Header, Form } from 'semantic-ui-react'

import ImageAdd from "./ImageAdd";


const StatusList = [
  { value: 1, label: "New" },
  { value: 2, label: "Active" },
  { value: 3, label: "Delisted" },
]

const CreateEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { handleSubmit, register, control, errors, setValue } = useForm();
  const { id } = useParams();

  const [genUrl, setGenUrl] = useState("");
  const [blogId, setBlogId] = useState(null);
  const [blog, setBlog] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [editSummary, setEditSummary] = useState();
  const [editContent, setEditContent] = useState();
  const [blogCategory, setBlogCategory] = useState([]);
  const [headingText, setHeadingText] = useState('');
  const [categorySel, setCategorySel] = useState([null]);
  const [employeeSel, setEmployeeSel] = useState(null);
  const [blogMeta, setBlogMeta] = useState('');
  const [blogTag, setBlogTag] = useState([]);
  const [selectedBlogTag, setSelectedBlogTag] = useState([]);

  const [checkForErrors, setCheckForErrors] = useState(false)
  const [errorList, setErrorList] = useState([])

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [radioValue, setRadioValue] = useState(StatusList[0]);
  const handleStatusChange = (event) => {
    setRadioValue(event);
  };

  useEffect(() => {
    dispatch({ type: SG_GET_BLOG_CATEGORIES });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: LOAD_EMPLOYEES });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: SG_GET_BLOG_TAGS });
  }, [dispatch]);

  const onSubmit = () => {
    setCheckForErrors(true)
    if (errorList.length !==0) {
      return
    }
    if (blog?.id) {
      dispatch({
        type: SG_EDIT_BLOG,
        payload: {
          id: blog.id,
          heading: headingText,
          url: genUrl,
          meta_description: blogMeta,
          category: categorySel.id,
          employee: employeeSel?.id,
          publish_date: selectedDate,
          status: radioValue?.value,
          summary: editSummary,
          content: editContent,
        },
      });
    } else {
      dispatch({
        type: SG_ADD_BLOG,
        payload: {
          heading: headingText,
          url: genUrl,
          meta_description: blogMeta,
          category: categorySel.id,
          employee: employeeSel?.id,
          publish_date: selectedDate,
          status: radioValue?.value,
        },
      });
    }
    // navigate('/app/blogs', { replace: true });
  };


  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_BLOG,
        payload: { id: id },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    setBlogId(id);
  }, [id]);

  const CreateUrl = (str) => {
    setGenUrl(
      str.target.value
        .replace(/\s+/g, "-")
        .replace(/[^A-Z0-9]/gi, "-")
        .toLowerCase()
    );
    setHeadingText(str.target.value)
  };

  const {
    get_blogs,
    get_employees,
    get_blog_created,
    get_blog_categories,
    get_blog_tags,
  } = useSelector(
    (state) => ({
      get_blogs: state.blogs,
      get_employees: state.employees,
      get_blog_created: state.blogs.created,
      get_blog_categories: state.blogCategory.blog_category,
      get_blog_tags: state.blogTag.blog_tags,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_blogs?.[blogId]) {
      setBlog(get_blogs[blogId]);
      setGenUrl(get_blogs[blogId]?.url);
      setHeadingText(get_blogs[blogId]?.heading)
      setCategorySel(get_blog_categories.find(({ id }) => id === get_blogs[blogId]?.category))
      setBlogMeta(get_blogs[blogId]?.meta)
      setEditSummary(get_blogs[blogId]?.summary);
      if (get_blogs[blogId]?.employee) {
        setEmployeeSel(get_employees?.employees.find(({ id }) => id === get_blogs[blogId]?.employee))
      }
      setEditContent(get_blogs[blogId]?.content);
      if (get_blogs[blogId]?.publish_date) {
        setSelectedDate(new Date(get_blogs[blogId]?.publish_date));
      }
      setRadioValue(StatusList.find(({ value }) => value === get_blogs[blogId]?.status))
    }
  }, [get_blogs, get_employees,
    blogId, get_blog_categories, get_blog_tags]);

  useEffect(() => {
    setEmployees(get_employees.employees);
  }, [get_employees.employees]);

  // # convert blog blogId to blog text for ui
  useEffect(() => {
    if (get_blogs[id]?.tags) {
      if (get_blog_tags) {
        const _All_Tags = [];
        get_blogs[id]?.tags.map((tg) => {
          _All_Tags.push(get_blog_tags.find(({ id }) => id === tg).name);

          return setSelectedBlogTag(_All_Tags);
        });
      }
    }
  }, [get_blogs, id, get_blog_tags]);

  useEffect(() => {
    if (get_blog_created) {
      setBlog(get_blog_created);
      setBlogId(get_blog_created.id);
    }
  }, [get_blog_created]);

  useEffect(() => {
    if (get_blog_categories) {
      setBlogCategory(get_blog_categories);
    }
  }, [get_blog_categories]);

  useEffect(() => {
    if (get_blog_tags) {
      setBlogTag(get_blog_tags);
    }
  }, [get_blog_tags]);


  const BtnCallBackSummary = (data) => {
    if (blog?.id) {
      dispatch({
        type: SG_EDIT_BLOG,
        payload: {
          id: blog.id,
          summary: data
        },
      });
    }
  };

  const BtnCallBackContent = (data) => {
    if (blog?.id) {
      dispatch({
        type: SG_EDIT_BLOG,
        payload: {
          id: blog.id,
          content: data,
        },
      });
    }
  };

  function ChangedMutliTab(value) {
    setSelectedBlogTag(value);
  }

  function AddTagToBlogs() {
    if (blogId) {
      dispatch({
        type: SG_ADD_BLOG_TAG,
        payload: {
          id: blog.id,
          tags: [],
          text_tags: selectedBlogTag,
        },
      });
    }
  }

  function ChangeEmployee(e) {
    setEmployeeSel(e)
  }

  function BackArrow() {
    navigate("/app/blogs")
  }

  // validation
  useEffect(() => {
    
    let _ErrorMessages = []
    if (headingText?.length === 0) {
      _ErrorMessages.push("No Heading Entered")
    }
    if (blogMeta?.length === 0) {
      _ErrorMessages.push("No Meta Description Entered")
    }
    if (!categorySel?.id) {
      _ErrorMessages.push("No Category Selected")
    }
    if (!employeeSel?.id) {
      _ErrorMessages.push("No Employee Selected")
    }
    if (!selectedDate) {
      _ErrorMessages.push("No Date Selected")
    }
    if (!radioValue?.value) {
      _ErrorMessages.push("No Status Selected")
    }

    setErrorList(_ErrorMessages)

  }, [genUrl, headingText, 
    categorySel, employeeSel, selectedDate, 
    radioValue, blogMeta]);

    function ClearErrors() {
      setCheckForErrors(false)
      setErrorList([])
    }
  return (
    <Container>
      <Header as='h2'><Icon
        style={{ cursor: "pointer" }}
        onClick={BackArrow} name='arrow left' />
        {blogId ? "Edit Existing " : "Create New "}

        Blog
      </Header>

      <BasicErrorMessage />

      {errorList?.length > 0 && checkForErrors &&
        <Message negative
          onDismiss={ClearErrors}
          header='New Site Features' list={errorList} />
      }


      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Heading</label>
          <input placeholder='heading'
            name="heading"
            defaultValue={headingText}
            onBlur={CreateUrl}
          />
        </Form.Field>
        <Form.Field>
          <label>URL</label>
          <input placeholder='url'
            disabled
            value={genUrl}
            name="url"
          />
        </Form.Field>
        <Form.Field>
          <label>Summary (Meta Description) Limit 150 Chars</label>
          <input placeholder='meta description'
            // disabled
            value={blogMeta}
            onChange={(e) => setBlogMeta(e.target.value)}
            name="meta_description"
          />
        </Form.Field>

        <Form.Field>
          <label>Select category</label>
          <Select
            placeholder="Select category"
            onChange={setCategorySel}
            value={categorySel}
            // isClearable
            options={blogCategory}
            // isLoading={loadingTeam}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        </Form.Field>

        <Form.Field>
          <label>Select employee</label>
          <Select
            placeholder="Select employee"
            onChange={ChangeEmployee}
            value={employeeSel}
            // isClearable
            options={employees}
            // isLoading={loadingTeam}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name} 
            ${option.email}`}
            getOptionValue={(option) => option.id}
          />
        </Form.Field>
        <FormContainer>
          <label>Select a date and time</label>
          <div>

            <DateTimePicker

              onChange={handleDateChange}
              value={selectedDate}
            />

          </div>

        </FormContainer>
        <Form.Field>
          <label>Select Status</label>
          <Select
            placeholder="Select Status"
            onChange={handleStatusChange}
            value={radioValue}
            // isClearable
            options={StatusList}
          // isLoading={loadingTeam}
          />
        </Form.Field>

        {/* <Button type='submit'>Submit</Button> */}

        <Button
          color="violet"
          disabled={!categorySel}
          // fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {blogId ? "Update" : "Save & Next"}
        </Button>


      </Form >


      <SectionDiv>

        {blogId && <ImageAdd blogId={blog?.id} imageUrl={blog?.image} />}

      </SectionDiv>

      {
        blogId && (
          <SectionDiv>


            <EditorConvertToHTML
              heading="Summary"
              editContent={editSummary}
              BtnCallBack={BtnCallBackSummary}
            />


          </SectionDiv>
        )
      }
      {
        blogId && (
          <SectionDiv>


            <EditorConvertToHTML
              heading="Content"
              editContent={editContent}
              BtnCallBack={BtnCallBackContent}
            />

          </SectionDiv>
        )
      }

      {
        blogId && (
          <Form.Field>
            <label>Select category</label>
            <Select
              placeholder="Select category"
              onChange={ChangedMutliTab}
              value={selectedBlogTag}
              // isClearable
              options={blogTag}
              // isLoading={loadingTeam}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            <Button
              color="violet"
              size="large"
              onClick={AddTagToBlogs}
              style={{ marginTop: "10px" }}
            >
              Save Tags
            </Button>
          </Form.Field>

        )
      }
    </Container >

  );
};

export default CreateEdit;


const Container = styled.div`
  min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding-top: 10px;
  margin-top:10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

const FormContainer = styled.div`
    max-width: 300px;
    margin-bottom: 20px;
`;
