import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_PROD_ORG_CONTRACT_PENDING,
  GET_PROD_ORG_CONTRACT,
  SG_GET_PROD_ORG_CONTRACT,
  DELETE_PROD_ORG_CONTRACT,
  SG_DELETE_PROD_ORG_CONTRACT,
  FETCH_PROD_ORG_CONTRACT,
  SG_FETCH_PROD_ORG_CONTRACT,
  EDIT_PROD_ORG_CONTRACT,
  SG_EDIT_PROD_ORG_CONTRACT,
  ADD_PROD_ORG_CONTRACT,
  SG_ADD_PROD_ORG_CONTRACT,
  ALL_ERRORS,
} from "constants/actions";

function* loadprocess(action) {
  yield put({ type: GET_PROD_ORG_CONTRACT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PROD_ORG_CONTRACT, action.payload);
    yield put({ type: GET_PROD_ORG_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processLoad() {
  yield takeLatest(SG_GET_PROD_ORG_CONTRACT, loadprocess);
}

function* fetchprocess(action) {
  try {
    const json = yield call(api.FETCH_PROD_ORG_CONTRACT, action.payload);
    yield put({ type: FETCH_PROD_ORG_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processFetch() {
  yield takeLatest(SG_FETCH_PROD_ORG_CONTRACT, fetchprocess);
}

function* updateprocess(action) {
  try {
    const json = yield call(api.EDIT_PROD_ORG_CONTRACT, action.payload);
    yield put({ type: EDIT_PROD_ORG_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processUpdate() {
  yield takeLatest(SG_EDIT_PROD_ORG_CONTRACT, updateprocess);
}

function* addprocess(action) {
  try {
    const json = yield call(api.ADD_PROD_ORG_CONTRACT, action.payload);
    yield put({ type: ADD_PROD_ORG_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processAdd() {
  yield takeLatest(SG_ADD_PROD_ORG_CONTRACT, addprocess);
}

function* deleteprocess(action) {
  try {
    const json = yield call(api.DELETE_PROD_ORG_CONTRACT, action.payload);
    yield put({ type: DELETE_PROD_ORG_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processDelete() {
  yield takeLatest(SG_DELETE_PROD_ORG_CONTRACT, deleteprocess);
}

export default function* index() {
  yield all([
    processLoad(),
    processFetch(),
    processUpdate(),
    processAdd(),
    processDelete(),
  ]);
}
