import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Pagination } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { format } from "date-fns";

import {
  UPDATE_PRODUCT_PACKAGE_PAGE_NUMBER,
  SG_GET_PRODUCT_PACKAGE,
  RESET_PRODUCT_PACKAGE,
} from "constants/actions";

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { get_product_package } = useSelector(
    (state) => ({
      get_product_package: state.product_package.product_package,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: RESET_PRODUCT_PACKAGE,
    });
    dispatch({
      type: SG_GET_PRODUCT_PACKAGE,
    });
  }, [dispatch]);

  useEffect(() => {
    setResults(get_product_package?.results);
    setPage(get_product_package?.pageNumber);
    setTotalPages(
      Math.ceil(get_product_package?.count / get_product_package?.page_size)
    );
    if (get_product_package?.count % get_product_package?.page_size) {
      setTotalPages(
        Math.ceil(get_product_package?.count / get_product_package?.page_size)
      );
    }
  }, [get_product_package, page, ReloadPage]);

  function handlePageChange(e, { activePage }) {
    setPage(activePage);
    dispatch({
      type: UPDATE_PRODUCT_PACKAGE_PAGE_NUMBER,
      payload: activePage,
    });
    dispatch({
      type: SG_GET_PRODUCT_PACKAGE,
      payload: `page=${activePage}`,
    });
  }

  function EditTemplate(templateId) {
    navigate(`/app/product/package/edit/${templateId}`);
  }

  function TemplateDetails(templateId) {
    navigate(`/app/product/package/details/${templateId}`);
  }

  return (
    <>
      <CardContainer>
        {results &&
          results.map((product, index) => (
            <Card key={index}>
              <CardContent>
                <h3>{product.name}</h3>
                <p>Sort Order: {product.sort_order}</p>
              </CardContent>

              <DetailRow label="Created">
                {product?.created_at &&
                  format(new Date(product?.created_at), "dd-MMM-yyyy hh:mm a")}
              </DetailRow>

              <DetailRow label="Last Updated">
                {product?.updated_at &&
                  format(new Date(product?.updated_at), "dd-MMM-yyyy hh:mm a")}
              </DetailRow>

              <FormSection>
                <LabelHead>Product in Package</LabelHead>
                {product?.product_list &&
                  Object.keys(product?.product_list).map((sortOrder) => (
                    <ProductItem key={sortOrder}>
                      <DetailRow label="Product Name">
                        {product?.product_list[sortOrder]?.name}
                      </DetailRow>
                      <DetailRow label="Sort Order">
                        {product?.product_list[sortOrder]?.sort_order}
                      </DetailRow>
                      <DetailRow label="Stage">
                        <StageBadge stage={product?.product_list[sortOrder]?.product_stage}>
                          {product?.product_list[sortOrder]?.product_stage}
                        </StageBadge>
                      </DetailRow>
                    </ProductItem>
                  ))}
              </FormSection>

              <CardActions>
                <Clickable onClick={() => EditTemplate(product?.id)}>
                  Edit
                </Clickable>
                <span> | </span>
                <Clickable onClick={() => TemplateDetails(product.id)}>
                  Details
                </Clickable>
              </CardActions>
            </Card>
          ))}
      </CardContainer>

      <Pagination
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        activePage={page}
        totalPages={totalPages ? totalPages : 0}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default List;

// DetailRow Component Definition
const DetailRow = ({ label, children }) => (
  <RowContainer>
    <LabelCell>{label}:</LabelCell>
    <ValueCell>{children}</ValueCell>
  </RowContainer>
);

// Styled Components

const StageBadge = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  ${({ stage }) => {
    switch (stage) {
      case "new":
        return `
          background-color: #007bff;
        `;
      case "testing":
        return `
          background-color: #ffc107;
        `;
      case "beta":
        return `
          background-color: #17a2b8;
        `;
      case "live":
        return `
          background-color: #28a745;
        `;
      case "retired":
        return `
          background-color: #dc3545;
        `;
      default:
        return `
          background-color: #6c757d;
        `;
    }
  }}
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
`;

const Card = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 400px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardContent = styled.div`
  h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }

  p {
    margin: 8px 0;
    font-size: 1rem;
    color: #666;
  }
`;

const CardActions = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

const Clickable = styled.span`
  color: blue;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 5px;
  display: block;
  font-size: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const LabelCell = styled.div`
  font-weight: bold;
  width: 150px;
`;

const ValueCell = styled.div`
  flex: 1;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px;
`;

const ProductItem = styled.div`
  margin: 10px;
  padding: 10px;
`;

