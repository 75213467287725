import React from "react";
import { Routes, Route } from "react-router-dom";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import List from "./List";
import AddEdit from "./AddEdit";
import Details from "./AddEdit";
import { Header, Grid, Button } from "semantic-ui-react";

import { CLEAR_WEB_API_LOG_DATA } from "constants/actions";

function TaskTemplate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  function clearData() {
    dispatch({
      type: CLEAR_WEB_API_LOG_DATA,
      payload: null,
    });
    setTimeout(() => {
      navigate(`/app/task-template/user-templates`);
    }, 500);
  }

  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2" style={{ marginTop: "50px" }}>
            Task Template
            <Header.Subheader>
              View the task template with user tasks and task responses.
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          {location.pathname !== "/app/task-template/user-templates" && (
            <Button primary onClick={clearData}>
              View User Templates
            </Button>
          )}
          
        </Grid.Column>
      </Grid.Row>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="user-templates" element={<AddEdit />} />
      </Routes>
    </>
  );
}

export default TaskTemplate;
